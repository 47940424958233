import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';

const EquisOneInfoDataPage = ({ user, background, number }) => {
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>1. Datos de identificación</h1>
          <div>
            <ul>
              <li>
                <div className='list-item-label'>Nombre:</div>
                <span>{`${user.lastname}, ${user.name}`}</span>
              </li>
              <li>
                <div className='list-item-label'>RUT:</div>
                <span>{user.RUT}</span>
              </li>
              <li>
                <div className='list-item-label'>Sexo: </div>
                <span>{user.SEX}</span>
              </li>
              <li>
                <div className='list-item-label'>Fecha nacimiento:</div>
                <span>
                  {user.BIRTH ? new Date(user.BIRTH).toLocaleDateString() : ''}
                </span>
              </li>
              <li>
                <div className='list-item-label'>Edad:</div>
                <span>{user.age}</span>
              </li>
              <li>
                <div className='list-item-label'>Curso:</div>
                <span>{user.grade}</span>
              </li>
              <li>
                <div className='list-item-label'>Colegio: </div>
                <span>{user.school}</span>
              </li>
              <li>
                <div className='list-item-label'>Fecha evaluación: </div>
                <span>
                  {user.updatedat
                    ? new Date(user.updatedat).toLocaleDateString()
                    : ''}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOneInfoDataPage;
