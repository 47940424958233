// dimensiones prioritarias
const CN = 'Ciencias y Naturaleza';
const FM = 'Fabricación y Mecánica';
const CD = 'Ciencia de Datos';
const AP = 'Administración y Proyectos';
const AL = 'Artes y Literatura';
const SE = 'Soporte Social y Educación';
const IP = 'Influencia y Personas';
const LM = 'Liderazgo y Management';

const CN_Short = 'Ciencias y Naturaleza';
const FM_Short = 'Fabricación y Mecánica';
const CD_Short = 'Ciencia de Datos';
const AP_Short = 'Administración y Proyectos';
const AL_Short = 'Artes y Literatura';
const SE_Short = 'Sop. Social y Ed.';
const IP_Short = 'Influencia y Personas';
const LM_Short = 'Liderazgo y Management';

// areas de conocimiento
const A_AC = 'Administración y Comercio';
const A_AG = 'Agropecuaria';
const A_AR = 'Arte y Arquitectura';
const A_CN = 'Ciencias Exactas y Naturales';
const A_CS = 'Ciencias Sociales';
const A_D = 'Derecho';
const A_E = 'Educación';
const A_H = 'Humanidades';
const A_S = 'Salud';
const A_T = 'Tecnología';

// areas de conocimiento
const A_AC_Short = 'Administ. y Comercio';
const A_AG_Short = 'Agropecuaria';
const A_AR_Short = 'Arte y Arquitectura';
const A_CN_Short = 'Ciencias Exact. y Naturales';
const A_CS_Short = 'Ciencias Sociales';
const A_D_Short = 'Derecho';
const A_E_Short = 'Educación';
const A_H_Short = 'Humanidades';
const A_S_Short = 'Salud';
const A_T_Short = 'Tecnología';

export const colors = ['#ffffb2', '#fecc5c', '#fd8d3c', '#f03b20', '#bd0026'];
export const dimColors = [
  '#f6eff7',
  '#bdc9e1',
  '#67a9cf',
  '#1c9099',
  '#016c59'
];
// https://github.com/d3/d3-scale-chromatic/blob/master/src/diverging/RdYlGn.js
export const polarColors = [
  '#d7191c',
  '#fdae61',
  '#ffffbf',
  '#a6d96a',
  '#1a9641'
];

export const getDimensionFullName = dim => {
  let name = '';
  switch (dim) {
    case 'CN':
      name = CN;
      break;
    case 'FM':
      name = FM;
      break;
    case 'CD':
      name = CD;
      break;
    case 'AP':
      name = AP;
      break;
    case 'AL':
      name = AL;
      break;
    case 'SE':
      name = SE;
      break;
    case 'IP':
      name = IP;
      break;
    case 'LM':
      name = LM;
      break;
    default:
      name = CN;
      break;
  }
  return name;
};

export const getDimensionShortName = dim => {
  let name = '';
  switch (dim) {
    case 'CN':
      name = CN_Short;
      break;
    case 'FM':
      name = FM_Short;
      break;
    case 'CD':
      name = CD_Short;
      break;
    case 'AP':
      name = AP_Short;
      break;
    case 'AL':
      name = AL_Short;
      break;
    case 'SE':
      name = SE_Short;
      break;
    case 'IP':
      name = IP_Short;
      break;
    case 'LM':
      name = LM_Short;
      break;
    default:
      name = CN_Short;
      break;
  }
  return name;
};

export const getAreaFullName = area => {
  let name = '';
  switch (area) {
    case 'AC':
      name = A_AC;
      break;
    case 'AG':
      name = A_AG;
      break;
    case 'AR':
      name = A_AR;
      break;
    case 'CN':
      name = A_CN;
      break;
    case 'CS':
      name = A_CS;
      break;
    case 'D':
      name = A_D;
      break;
    case 'E':
      name = A_E;
      break;
    case 'H':
      name = A_H;
      break;
    case 'S':
      name = A_S;
      break;
    case 'T':
      name = A_T;
      break;
    default:
      name = A_AC;
      break;
  }
  return name;
};

export const getAreaShortName = area => {
  let name = '';
  switch (area) {
    case 'AC':
      name = A_AC_Short;
      break;
    case 'AG':
      name = A_AG_Short;
      break;
    case 'AR':
      name = A_AR_Short;
      break;
    case 'CN':
      name = A_CN_Short;
      break;
    case 'CS':
      name = A_CS_Short;
      break;
    case 'D':
      name = A_D_Short;
      break;
    case 'E':
      name = A_E_Short;
      break;
    case 'H':
      name = A_H_Short;
      break;
    case 'S':
      name = A_S_Short;
      break;
    case 'T':
      name = A_T_Short;
      break;
    default:
      name = A_AC_Short;
      break;
  }
  return name;
};

export const getAreaCareers = area => {
  let careers = [];
  switch (area) {
    case 'AC':
      careers = carrerasAdministracionYComercio;
      break;
    case 'AG':
      careers = carrerasAgropecuaria;
      break;
    case 'AR':
      careers = carrerasArteYArquitectura;
      break;
    case 'CN':
      careers = carrerasCienciasExactasYNaturales;
      break;
    case 'CS':
      careers = carrerasCienciasSociales;
      break;
    case 'D':
      careers = carrerasDerecho;
      break;
    case 'E':
      careers = carrerasEducacion;
      break;
    case 'H':
      careers = carrerasHumanidades;
      break;
    case 'S':
      careers = carrerasSalud;
      break;
    case 'T':
      careers = carrerasTecnologia;
      break;
    default:
      careers = [];
      break;
  }
  return careers;
};

export const getCareersTable = area => {
  let careers = [];
  switch (area) {
    case 'AC':
      careers = tableAdministracionYComercio;
      break;
    case 'AG':
      careers = tableAgropecuaria;
      break;
    case 'AR':
      careers = tableArteYArquitectura;
      break;
    case 'CN':
      careers = tableCienciasExactasYNaturales;
      break;
    case 'CS':
      careers = tableCienciasSociales;
      break;
    case 'D':
      careers = tableDerecho;
      break;
    case 'E':
      careers = tableEducacion;
      break;
    case 'H':
      careers = tableHumanidades;
      break;
    case 'S':
      careers = tableSalud;
      break;
    case 'T':
      careers = tableTecnologia;
      break;
    default:
      careers = [];
      break;
  }
  return careers;
};

const carrerasAgropecuaria = [
  {
    title:
      'Pesca y Piscicultura (Peces y Fauna Marina, Recursos Marinos, Biología Marina, Acuicultura)'
  },
  { title: 'Agronomía y Agricultura' },
  { title: 'Forestal (maderas, Bosques, Silvicultura)' },
  { title: 'Ciencias Pecuarias (Producción Animal, zootecnia y Ganadería)' },
  { title: 'Ciencias Veterinarias' },
  {
    title:
      'Ciencias del Suelo (Fruticultura, Viticultura, Horticultura y Producción Vegetal)'
  }
];

const carrerasArteYArquitectura = [
  {
    title:
      'Bellas Artes (Dibujo, Pintura, Escultura, Joyería, Comics, Ilustración, Estética)'
  },
  { title: 'Arquitectura y Urbanismo (Paisajismo, dibujo Arquitectónico)' },
  { title: 'Artes Escénicas (Arte Dramático, Danza, Escenografía Teatral)' },
  {
    title:
      'Diseño (Vestuario, Muebles, Gráfico, Interior y Decoración, Publicitario, Jardines, Ambientes, Etc.)'
  },
  {
    title:
      'Comunicación Audiovisual y de Medios (Artes Visuales, Cien, Televisión, fotografía, Multimedia)'
  },
  { title: 'Música (Interpretación, Instrumentista, Composición)' }
];

const carrerasCienciasExactasYNaturales = [
  {
    title:
      'Biología general (Biología, Zoología, Microbiología, Celular o Molecular)'
  },
  {
    title:
      'Ciencias de la vida (Ciencias en General, Botánica, Biología Humana, Fisiología Humana)'
  },
  { title: 'Química y Bioquímica' },
  { title: 'Física General Atómica o Nuclear' },
  { title: 'Matemática (Probabilidad, Análisis Numérico, Geometría)' },
  { title: 'Geología y Mineralogía (Geodesia, Geofísica)' },
  { title: 'Oceanografía' },
  { title: 'Ecología (Climatología, Meteorología, Ciencias de la Atmósfera)' },
  {
    title: 'Ciencias Especiales (Astronomía, Ciencias del Espacio, Cosmología'
  },
  { title: 'Astronomía' }
];

const carrerasCienciasSociales = [
  { title: 'Periodismo' },
  {
    title:
      'Ciencias Políticas y Administrativas   (Administración Pública, Políticas Públicas, Evaluación de Proyectos,   Relaciones Internacionales, Gobierno y Gerencia Pública, Gestión Pública)'
  },
  { title: 'Ciencias Económicas (Ingeniería   Comercial, Economía)' },
  { title: 'Trabajo Social (consejería Familiar,   Orientación Familiar)' },
  { title: 'Sociología' },
  {
    title:
      'Psicología (del Niño   y del Adolescente, Asesoramiento y Orientación Psicológica, Psicología   Industria, Personalidad)'
  },
  {
    title:
      'Antropología (Género, Cultura, Antropología Sociocultural, Asentamientos Humano, Arqueología)'
  },
  {
    title:
      'Comunicación Social y Dirección (Comunicación Organizacional, Corporativa, Persuasiva)'
  },
  { title: 'Relaciones Públicas' },
  { title: 'Publicidad' },
  { title: 'Historia y Geografía (cartografía)' },
  { title: 'Ciencias Sociales' }
];

const carrerasDerecho = [
  { title: 'Derecho' },
  { title: 'Apoyo Jurídico' },
  { title: 'Especialización Jurídica' }
];

const carrerasHumanidades = [
  { title: 'Filosofía' },
  { title: 'Lenguas Extranjeras (Traducción e Interpretación)' },
  { title: 'Ciencias Religiosas (Teología)' },
  { title: 'Literatura y Bibliotecología (Lingüística, Letras)' },
  { title: 'Ética' },
  { title: 'Humanidades' }
];

const carrerasEducacion = [
  { title: 'Ed. Parvularia' },
  { title: 'Ed. Básica y Rural' },
  { title: 'Ed. Media' },
  { title: 'Ed. Diferencial' },
  { title: 'Psicopedagogía' },
  {
    title:
      'Organización Educacional (Planificación de la Educación, Consejería)'
  },
  { title: 'Pedagogía Técnico Profesional' },
  { title: 'Deportes y Recreación' },
  { title: 'Ciencias de la Educación' }
];

const carrerasTecnologia = [
  { title: 'Ingeniería Civil (puras y con mención)' },
  { title: 'Obras civiles y Construcción (Hormigones, Estructura)' },
  {
    title:
      'Industria y Procesos Industriales (Civil, Automatización, Control Industrial, Control de Calidad, Dibujo Técnico, Diseño Industrial y Técnico, Textil, Celulosa y Papel, Matricería, Madrera, Agroindustria)'
  },
  { title: 'Prevención de Riesgos' },
  { title: 'Electricidad y Electrónica (Civil, Instalaciones)' },
  { title: 'Sonido y Acústica' },
  { title: 'Metalurgia y Minas (Civil, Procesos Mineros, Recursos Mineros)' },
  {
    title:
      'Química y bioquímica (Civil, Analista Químico, Químico Laboratorista, Química Industrial)'
  },
  {
    title:
      'Mecánica (Vehículos, Maquinaria Pesada, Oleo hidráulica, Neumática, Naval)'
  },
  { title: 'Geomensura, Topografía y Geología' },
  { title: 'Biotecnología y Bioprocesos' },
  {
    title:
      'Transporte y telecomunicaciones (Aviación Comercial, Transporte Marítimo Portuaria)'
  },
  {
    title:
      'Computación e Informática (Gestión Informática, Software, Redes y Conectividad)'
  },
  {
    title:
      'Climatización e Instalaciones (Gas, Instalaciones Sanitarias, Calefacción, Refrigeración)'
  },
  {
    title: 'Procesamiento y Tecnología de Alimentos (Conservación de Alimentos)'
  },
  { title: 'Ciencias de la Ingeniería' },
  { title: 'Ciencias del Medio Ambiente' },
  { title: 'Estadísticas y Matemáticas' }
];

const carrerasSalud = [
  { title: 'Medicina' },
  { title: 'Enfermería' },
  { title: 'Odontología' },
  { title: 'Química y Farmacia' },
  { title: 'Fonoaudiología' },
  { title: 'Kinesiología' },
  { title: 'Nutrición y Dietética' },
  { title: 'Obstetricia y Puericultura' },
  { title: 'Tecnología Médica' }
];

const carrerasAdministracionYComercio = [
  { title: 'Contabilidad (Gestión Tributaria y Computarizada)' },
  { title: 'Actividad Turística (Turismo, Hotelería, Gastronomía)' },
  {
    title:
      'Administración Financiera y Bancaria (Comercio, Relaciones Comerciales Internacionales, comercio Electrónico)'
  },
  {
    title:
      'Administración de Empresas (Ventas y Marketing, de Personal, Gestión de Empresas, Logística, Recursos Humanos)'
  },
  { title: 'Secretariado' },
  { title: 'Agropecuaria (Administración de Agronegocios)' }
];

const tableAgropecuaria = [
  { title: 'Agronomía', UNI: true, IP: false, CFT: false },
  {
    title: 'Ingeniería en Acuicultura y Pesca',
    UNI: true,
    IP: true,
    CFT: false
  },
  { title: 'Medicina Veterinaria', UNI: true, IP: false, CFT: false },
  {
    title: 'Biología Marina y Ecología Marina',
    UNI: true,
    IP: false,
    CFT: false
  },
  { title: 'Ingeniería Forestal', UNI: true, IP: false, CFT: false },
  { title: 'Ingeniería Agrícola', UNI: false, IP: true, CFT: false },
  { title: 'Técnico Veterinario', UNI: false, IP: true, CFT: false },
  { title: 'Técnico en Acuicultura y Pesca', UNI: false, IP: false, CFT: true }
];

const tableAdministracionYComercio = [
  {
    title: 'Administración Turística y Hotelera',
    UNI: true,
    IP: true,
    CFT: false
  },
  { title: 'Contador Auditor', UNI: true, IP: true, CFT: false },
  {
    title: 'Administración de Empresas e Ing. Asociadas',
    UNI: true,
    IP: true,
    CFT: false
  },
  { title: 'Ingeniería Comercial', UNI: true, IP: false, CFT: false },
  { title: 'Ingeniería en Comercio Exterior', UNI: true, IP: true, CFT: false },
  {
    title: 'Ingeniería en Control de Gestión',
    UNI: true,
    IP: false,
    CFT: false
  },
  { title: 'Ingeniería en Logística', UNI: true, IP: true, CFT: false },
  {
    title: 'Técnico en Administración de Empresas',
    UNI: true,
    IP: false,
    CFT: true
  },
  { title: 'Técnico Agropecuario', UNI: true, IP: true, CFT: true },
  { title: 'Técnico en Contabilidad General', UNI: true, IP: true, CFT: true },
  {
    title: 'Técnico en Producción de Eventos',
    UNI: true,
    IP: false,
    CFT: false
  },
  { title: 'Administración Gastronómica', UNI: false, IP: true, CFT: false },
  {
    title: 'Ingeniería (en Marketing; en Finanzas; en Recursos Humanos)',
    UNI: false,
    IP: true,
    CFT: false
  },
  {
    title: 'Técnico en Administración de Recursos Humanos y Personal',
    UNI: false,
    IP: true,
    CFT: true
  },
  {
    title: 'Técnico en Administración de Ventas',
    UNI: false,
    IP: true,
    CFT: false
  },
  { title: 'Técnico en Comercio Exterior', UNI: false, IP: true, CFT: true },
  {
    title: 'Técnico (en Gastronomía y Cocina; en Turismo y Hotelería)',
    UNI: false,
    IP: true,
    CFT: true
  },
  {
    title: 'Secretariado (Bilingüe; Ejecutivo; Computacional)',
    UNI: false,
    IP: false,
    CFT: true
  },
  {
    title:
      'Técnico (en Administración Financiera y Finanzas; en Contabilidad Computacional; en Contabilidad Tributaria)',
    UNI: false,
    IP: false,
    CFT: true
  }
];

const tableArteYArquitectura = [
  { title: 'Arquitectura', UNI: true, IP: false, CFT: false },
  { title: 'Diseño', UNI: true, IP: false, CFT: false },
  { title: 'Actuación y Teatro', UNI: true, IP: true, CFT: false },
  { title: 'Artes y Licenciatura en Artes', UNI: true, IP: false, CFT: false },
  {
    title: 'Comunicación Audiovisual y/o Multimedia',
    UNI: true,
    IP: true,
    CFT: false
  },
  { title: 'Diseño de Vestuario', UNI: true, IP: true, CFT: false },
  { title: 'Música, Canto o Danza', UNI: true, IP: true, CFT: false },
  { title: 'Diseño Gráfico', UNI: true, IP: true, CFT: false },
  {
    title: 'Realizador de Cine y Televisión',
    UNI: true,
    IP: false,
    CFT: false
  },
  {
    title: 'Diseño de Ambientes e Interiores',
    UNI: false,
    IP: true,
    CFT: false
  },
  { title: 'Fotografía', UNI: false, IP: true, CFT: false },
  {
    title: 'Técnico en Comunicación Audiovisual',
    UNI: false,
    IP: true,
    CFT: false
  },
  {
    title: 'Técnico en Dibujo Arquitectónico',
    UNI: false,
    IP: true,
    CFT: true
  },
  { title: 'Técnico en Diseño Gráfico', UNI: false, IP: true, CFT: false }
];

const tableCienciasExactasYNaturales = [
  { title: 'Analista Químico', UNI: true, IP: false, CFT: false },
  { title: 'Geología', UNI: true, IP: false, CFT: false },
  { title: 'Química, Licenciado en Química', UNI: true, IP: false, CFT: false },
  {
    title: 'Técnico en Química (Análisis e Industrial)',
    UNI: true,
    IP: false,
    CFT: false
  },
  { title: 'Bioquímica', UNI: true, IP: false, CFT: false },
  { title: 'Matemáticas y/o Estadísticas', UNI: true, IP: false, CFT: false }
];

const tableCienciasSociales = [
  { title: 'Administración Pública', UNI: true, IP: false, CFT: false },
  { title: 'Ciencias Políticas', UNI: true, IP: false, CFT: false },
  { title: 'Periodismo', UNI: true, IP: false, CFT: false },
  { title: 'Antropología', UNI: true, IP: false, CFT: false },
  { title: 'Geografía', UNI: true, IP: false, CFT: false },
  { title: 'Psicología', UNI: true, IP: false, CFT: false },
  { title: 'Psicopedagogía', UNI: true, IP: true, CFT: false },
  { title: 'Publicidad', UNI: true, IP: true, CFT: false },
  { title: 'Sociología', UNI: true, IP: false, CFT: false },
  { title: 'Trabajo Social', UNI: true, IP: true, CFT: false },
  { title: 'Relaciones Públicas', UNI: true, IP: true, CFT: false },
  { title: 'Técnico en Relaciones Públicas', UNI: false, IP: true, CFT: false },
  {
    title: 'Técnico en Administración Pública o Municipal',
    UNI: false,
    IP: false,
    CFT: true
  },
  { title: 'Técnico en Servicio Social', UNI: false, IP: false, CFT: true }
];

const tableDerecho = [
  { title: 'Derecho', UNI: true, IP: false, CFT: false },
  { title: 'Técnico Jurídico', UNI: true, IP: true, CFT: true }
];

const tableEducacion = [
  { title: 'Pedagogía en Artes y Música', UNI: true, IP: false, CFT: false },
  { title: 'Pedagogía en Ciencias', UNI: true, IP: false, CFT: false },
  { title: 'Pedagogía en Educación Básica', UNI: true, IP: true, CFT: false },
  {
    title: 'Pedagogía en Educación de Párvulos',
    UNI: true,
    IP: true,
    CFT: false
  },
  {
    title: 'Pedagogía en Educación Diferencial',
    UNI: true,
    IP: true,
    CFT: false
  },
  { title: 'Pedagogía en Educación Física', UNI: true, IP: false, CFT: false },
  {
    title: 'Pedagogía en Filosofía y Religión',
    UNI: true,
    IP: false,
    CFT: false
  },
  {
    title: 'Pedagogía en Historia, Geografía y Ciencias Sociales',
    UNI: true,
    IP: false,
    CFT: false
  },
  { title: 'Pedagogía en Idiomas', UNI: true, IP: true, CFT: false },
  {
    title: 'Pedagogía en Lenguaje, Comunicación y/o Castellano',
    UNI: true,
    IP: false,
    CFT: false
  },
  {
    title: 'Pedagogía en Matemáticas y Computación',
    UNI: true,
    IP: false,
    CFT: false
  },
  {
    title: 'Técnico Asistente del Educador de Párvulos',
    UNI: true,
    IP: true,
    CFT: true
  },
  {
    title: 'Técnico Asistente del Educador Diferencial',
    UNI: false,
    IP: true,
    CFT: true
  },
  {
    title: 'Técnico en Deporte, Recreación y Preparación Física',
    UNI: true,
    IP: true,
    CFT: true
  }
];

const tableHumanidades = [
  { title: 'Bibliotecología', UNI: true, IP: false, CFT: false },
  { title: 'Traducción e Interpretación', UNI: true, IP: false, CFT: false },
  {
    title: 'Licenciatura en Letras y Literatura',
    UNI: true,
    IP: false,
    CFT: false
  },
  {
    title: 'Técnico en Traducción e Interpretariado',
    UNI: false,
    IP: true,
    CFT: false
  },
  { title: 'Traducción e Interpretación', UNI: false, IP: true, CFT: false }
];

const tableSalud = [
  { title: 'Enfermería ', UNI: true, IP: false, CFT: false },
  { title: 'Kinesiología', UNI: true, IP: false, CFT: false },
  { title: 'Nutrición y Dietética', UNI: true, IP: false, CFT: false },
  { title: 'Odontología', UNI: true, IP: false, CFT: false },
  { title: 'Fonoaudiología', UNI: true, IP: false, CFT: false },
  { title: 'Medicina', UNI: true, IP: false, CFT: false },
  { title: 'Obstetricia y Puericultura', UNI: true, IP: false, CFT: false },
  { title: 'Química y Farmacia', UNI: true, IP: false, CFT: false },
  { title: 'Técnico en Enfermería', UNI: true, IP: true, CFT: true },
  { title: 'Tecnología Médica', UNI: true, IP: false, CFT: false },
  { title: 'Terapia Ocupacional', UNI: true, IP: false, CFT: false },
  {
    title: 'Técnico Dental y Asistente de Odontología',
    UNI: false,
    IP: true,
    CFT: true
  },
  { title: 'Técnico en Laboratorio Clínico', UNI: false, IP: true, CFT: true },
  {
    title: 'Técnico en Radiología y Radioterapia',
    UNI: false,
    IP: true,
    CFT: false
  },
  {
    title: 'Técnico Agente o Visitador Médico',
    UNI: false,
    IP: false,
    CFT: true
  },
  { title: 'Técnico en Farmacia', UNI: false, IP: false, CFT: true },
  { title: 'Técnico en Podología', UNI: false, IP: false, CFT: true },
  { title: 'Técnico Laboratorista Dental', UNI: false, IP: false, CFT: true }
];

const tableTecnologia = [
  { title: 'Construcción Civil', UNI: true, IP: true, CFT: false },
  { title: 'Diseño Industrial', UNI: true, IP: true, CFT: false },
  {
    title:
      'Ingeniería Civil (Plan Común, Ambiental, Química, Bioquímica, Industrial, Mecánica, Eléctrica, Electrónica, metalúrgica, en Computación, en Minas, en Obras Civiles, en Sonido y Acústica)',
    UNI: true,
    IP: false,
    CFT: false
  },
  {
    title:
      'Ingenierías I (Agroindustrial,  Química, en Biotecnología y Bioingeniería, en Matemáticas y Estadística, en Geomensura, en Transporte y Tránsito, en Alimentos, en Recursos Renovables, Marina)',
    UNI: true,
    IP: false,
    CFT: false
  },
  {
    title:
      'Ingenierías II (Mecánica, Industrial, en Automatización, Instrumentación y Control, en Computación e Informática, en Conectividad y Redes, en Sonido)',
    UNI: true,
    IP: true,
    CFT: false
  },
  {
    title:
      'Ingenierías III (en Construcción, en Electricidad, en Electrónica, en Minas y Metalurgia, en Medio Ambiente, en Prevención de Riesgos)',
    UNI: true,
    IP: true,
    CFT: false
  },
  {
    title:
      'Ingenierías IV (en Mecánica Automotriz, en Refrigeración y Climatización, en Telecomunicaciones)',
    UNI: false,
    IP: true,
    CFT: false
  },
  { title: 'Química Industrial', UNI: true, IP: false, CFT: false },
  {
    title:
      'Técnico (en Administración de Redes y Soporte; en Computación e Informática; en Instrumentación, Automatización y Control Industrial; en Construcción y Obras Civiles; en Electricidad y Electricidad Industrial; en Mecánica Automotriz, en Prevención de Riesgos)',
    UNI: true,
    IP: true,
    CFT: true
  },
  { title: 'Técnico en Alimentos', UNI: true, IP: false, CFT: true },
  {
    title: 'Técnico en Análisis de Sistemas',
    UNI: false,
    IP: false,
    CFT: true
  },
  {
    title: 'Técnico en Biotecnología Industrial',
    UNI: false,
    IP: false,
    CFT: true
  },
  {
    title:
      'Técnico (en Dibujo Técnico e Industrial;  en Producción Gráfica y Multimedia; en Telecomunicaciones)',
    UNI: false,
    IP: false,
    CFT: true
  },
  { title: 'Técnico en Electromecánica', UNI: false, IP: true, CFT: false },
  {
    title: 'Técnico en Gestión y Control de Calidad; Técnico en Logística',
    UNI: false,
    IP: false,
    CFT: true
  },
  {
    title:
      'Técnico (en Mantenimiento Industrial;  en Mecánica Industrial, en Minería y Metalurgia)',
    UNI: true,
    IP: false,
    CFT: true
  },
  {
    title: 'Técnico en Procesos Industriales',
    UNI: false,
    IP: true,
    CFT: true
  },
  {
    title: 'Técnico en Refrigeración y Climatización',
    UNI: false,
    IP: false,
    CFT: true
  },
  { title: 'Técnico en Sonido', UNI: false, IP: true, CFT: false },
  { title: 'Técnico en Topografía', UNI: false, IP: true, CFT: true }
];
// { title: '', UNI: false, IP: false, CFT: false },
