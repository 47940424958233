import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer, ProgressBox, ProgressStat } from '../styles';
import { polarColors } from '../constants';

const getColor = value => {
  if (value < 25) {
    return polarColors[4];
  }
  if (value < 50) {
    return polarColors[3];
  }
  if (value < 75) {
    return polarColors[1];
  }
  return polarColors[0];
};

const DysfunctionalBeliefsSecondPage = ({ user, background, number }) => {
  const destinyRole = parseFloat(user['Rol de la casualidad']);
  const implicanciasDecision = parseFloat(user['Implicancias de la Decisión']);
  const rolDeOtros = parseFloat(user['Rol de otros significativos']);

  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h3>4.1.1. El rol de la casualidad y el destino</h3>
          <p className='justified'>
            Creer que el destino, el tiempo o la casualidad determinan nuestro
            futuro, y por tanto, no es necesario invertir tanto esfuerzo y
            tiempo en decidir sobre la carrera. Esto puede llevar a no analizar
            en profundidad nuestras opciones y tomar decisiones menos óptimas.
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details-single'>
                <ProgressBox
                  progress={destinyRole}
                  backColor={() => getColor(destinyRole)}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{destinyRole}</ProgressStat>
              </div>
            </div>
          </div>
          <br />
          <h3>4.1.2. Implicancias de la Decisión</h3>
          <p className='justified'>
            Creer que la elección de carrera es una decisión de por vida, y por
            tanto crucial, como un asunto de vida o muerte. Este tipo de
            creencias puede generar mucho estrés sobre la decisión y llevarnos
            incluso a evitar el proceso.
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details-single'>
                <ProgressBox
                  progress={implicanciasDecision}
                  backColor={() => getColor(implicanciasDecision)}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{implicanciasDecision}</ProgressStat>
              </div>
            </div>
          </div>
          <br />
          <h3>4.1.3. Creencias sobre el rol de otros en la decisión</h3>
          <p className='justified'>
            Creer que otras personas significativas, como amigos o familiares,
            pueden evaluar de mejor manera nuestras habilidades o
            preferencias, o que pueden saber mejor que uno que camino debemos
            seguir o qué carrera debemos elegir. Este tipo de creencias puede
            llevarnos a dar demasiada importancia a la opinión de los demás o a
            intentar satisfacerlos a la hora de tomar la decisión.
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details-single'>
                <ProgressBox
                  progress={rolDeOtros}
                  backColor={() => getColor(rolDeOtros)}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{rolDeOtros}</ProgressStat>
              </div>
            </div>
          </div>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default DysfunctionalBeliefsSecondPage;
