import { ResponsivePie } from '@nivo/pie';
import { animated } from 'react-spring';
// import { getDimensionShortName } from '../constants';

// const colors = ['#ffffb2', '#fecc5c', '#fd8d3c', '#f03b20', '#bd0026'];

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

// perc_AL_SUM: '96.875',
// perc_AP_SUM: '34.375',
// perc_CD_SUM: '31.25',
// perc_CN_SUM: '53.125',
// perc_FM_SUM: '37.5',
// perc_IP_SUM: '53.125',
// perc_LM_SUM: '37.5',
// perc_SE_SUM: '56.25',

const MyResponsiveRadar = ({ scale, responses }) => {
  // motivation sub-scales
  // const {
  //   MA_SUB_SUM,
  //   ML_SUB_SUM,
  //   MS_SUB_SUM,
  //   ES_SUB_SUM,
  //   AP_SUB_SUM,
  //   EL_SUB_SUM
  // } = responses[3].data;
  // console.log({ scale, responses });
  // const scaleResponses = responses.map(resp => {
  //   const dataPoint = resp.data[`${scale}_SUB_SUM`];
  //   const value = (parseInt(dataPoint) / 30) * 100;
  //   return value;
  // });
  // console.log({ scaleResponses });
  const scaleHighCases = responses.filter(resp => {
    const dataPoint = resp.data[`${scale}_SUB_SUM`];
    return dataPoint >= 24;
  });
  const scaleLowCases = responses.filter(resp => {
    const dataPoint = resp.data[`${scale}_SUB_SUM`];
    return dataPoint <= 12;
  });
  // const scaleAverage =
  //   scaleResponses.reduce((acc, curr) => acc + curr, 0) / scaleResponses.length;
  // console.log({ scaleAverage });
  // console.log({ scaleHighCases, length: scaleHighCases.length });
  const highCasesAverage = (scaleHighCases.length / responses.length) * 100;
  const lowCasesAverage = (scaleLowCases.length / responses.length) * 100;
  // console.log({scaleResponses, responses, highCasesAverage})
  const data = [
    {
      id: 'Total',
      label: 'Total',
      value: 100 - highCasesAverage
    },
    {
      id: scale,
      label: scale,
      value: highCasesAverage
    },
    {
      id: `${scale}_low`,
      label: scale,
      value: lowCasesAverage
    }
  ];
  const theme = {
    background: '#ffffff',
    textColor: '#333333',
    fontSize: 42
  };

  const CenteredMetric = node => {
    const { dataWithArc, centerX, centerY } = node;

    // console.log({ node });
    // const notTotal = dataWithArc.filter(datum => datum.id !== 'Total');
    const scaleVal = dataWithArc.find(datum => datum.id === scale);
    const scaleValLow = dataWithArc.find(datum => datum.id === `${scale}_low`);
    // console.log({ notTotal, scaleVal, scaleValLow });
    // let sum = 0;
    // notTotal.forEach(datum => {
    //   sum += datum.value;
    // });
    return (
      <>
        <text
          x={centerX}
          y={centerY - 35}
          textAnchor='middle'
          dominantBaseline='central'
          style={{
            fontSize: '22px',
            fontWeight: '700'
          }}
        >
          Alto
        </text>
        <text
          x={centerX}
          y={centerY - 5}
          textAnchor='middle'
          dominantBaseline='central'
          style={{
            fontSize: '42px',
            fontWeight: '600'
          }}
        >
          {scaleVal.value.toFixed()}%
        </text>
        <text
          x={centerX}
          y={centerY + 30}
          textAnchor='middle'
          dominantBaseline='central'
          style={{
            fontSize: '20px',
            fontWeight: '400'
          }}
        >
          Bajo: {scaleValLow.value.toFixed()}%
        </text>
      </>
    );
  };

  return (
    <ResponsivePie
      data={data}
      theme={theme}
      margin={{ top: 2, right: 2, bottom: 2, left: 2 }}
      innerRadius={0.7}
      padAngle={0}
      cornerRadius={0}
      borderWidth={0}
      // colors={['#ffffff', '#ff8300', '#ffd2a3']}
      colors={['#ffffff', '#ff8300', '#ffd2a3']}
      // borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextColor='#333333'
      radialLabelsLinkColor={{ from: 'color' }}
      sliceLabelsSkipAngle={10}
      sliceLabelsTextColor='#333333'
      isInteractive={false}
      animate={false}
      enableSliceLabels={false}
      enableArcLinkLabels={false}
      radialLabel={dp => dp.label}
      // radialLabel={d => `${d.id} (${d.formattedValue})`}
      arcLabel={dp => dp.value.toFixed(0)}
      arcLabelsSkipAngle={20}
      arcLabelsRadiusOffset={0.55}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 0.6]]
      }}
      arcLinkLabelsOffset={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLinkLabelsThickness={2}
      enableArcLabels={false}
      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
      // arcLabelsComponent={({ datum, label, style }) => (
      //   <animated.g
      //     transform={style.transform}
      //     style={{ pointerEvents: 'none' }}
      //   >
      //     <circle fill={style.textColor} cy={6} r={24} />
      //     <circle fill='#ffffff' stroke={datum.color} strokeWidth={2} r={24} />
      //     <text
      //       textAnchor='middle'
      //       dominantBaseline='central'
      //       fill={style.textColor}
      //       style={{
      //         fontSize: 16,
      //         fontWeight: 800
      //       }}
      //     >
      //       {label}
      //     </text>
      //   </animated.g>
      // )}
      // legends={[
      //   {
      //     anchor: 'top-right',
      //     direction: 'column',
      //     justify: false,
      //     translateX: 80,
      //     translateY: 0,
      //     itemsSpacing: 5,
      //     itemWidth: 80,
      //     itemHeight: 18,
      //     itemTextColor: '#999',
      //     itemDirection: 'left-to-right',
      //     itemOpacity: 1,
      //     symbolSize: 18,
      //     symbolShape: 'circle',
      //     effects: [
      //       {
      //         on: 'hover',
      //         style: {
      //           itemTextColor: '#000'
      //         }
      //       }
      //     ]
      //   }
      // ]}
    />
  );
};

export default MyResponsiveRadar;
