import React, { useState, useEffect, Fragment } from 'react';
import { Deck, Slide } from 'spectacle';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import theme from './theme';
import { mergeTheme, oneTheme } from './deckTheme';
import topBackground from './imgs/02-top_equis_1.png';
import backBackground from './imgs/02-back_m.png';
// import innerPresentationBack from './imgs/02-inner_equis1.png';
// import innerPlainBack from './imgs/02-inner_equis1_plain.png';
import HolosLogo from './imgs/logo_holos.png';
import UaimetricsLogo from './imgs/uaimetrics_logo.png';
import BHPLogo from './imgs/BHP_orange.png';

import { ResetCSS, GlobalStyle, PageWrapper, PageNumber } from './styles';
// pages
import EquisOneTopPage from './Pages/Top';
import EquisOneClosePage from './Pages/Close';
// import PresentationPage from './Pages/Presentation';
import InfoDataPage from './Pages/InfoData';
// import OrientationDescriptionPage from './Pages/OrientationDescription';
// import AreasGraphPage from './Pages/AreasGraph';
// import AreasDescriptionPage from './Pages/AreasDescription';
// import PreferencesGraphPage from './Pages/PreferencesGraph';
// import PreferencesClarityPage from './Pages/PreferencesClarity';
// import KnowledgeAreasGraphPage from './Pages/KnowledgeAreasGraph';
// import CarrerasTop1FirstPage from './Pages/CarrerasTop1First';
// import CarrerasTop1SecondPage from './Pages/CarrerasTop1Second';
// import CarrerasTop2FirstPage from './Pages/CarrerasTop2First';
// import CarrerasTop2SecondPage from './Pages/CarrerasTop2Second';
// import DysfunctionalBeliefsFirstPage from './Pages/DysfunctionalBeliefsFirst';
// import DysfunctionalBeliefsSecondPage from './Pages/DysfunctionalBeliefsSecond';
// import DysfunctionalBeliefsThirdPage from './Pages/DysfunctionalBeliefsThird';
// import DysfunctionalBeliefsForthPage from './Pages/DysfunctionalBeliefsForth';
// import DecisionMakingFirstPage from './Pages/DecisionMakingFirst';
// import DecisionMakingSecondPage from './Pages/DecisionMakingSecond';
// import DecisionMakingThirdPage from './Pages/DecisionMakingThird';
// import VocationalMaturityFirstPage from './Pages/VocationalMaturityFirst';
// import VocationalMaturitySecondPage from './Pages/VocationalMaturitySecond';
import AboutUsPage from './Pages/AboutUs';
import EstiloConduccionPage from './Pages/EstiloConduccionPage';
import EstiloConduccionPageCont from './Pages/EstiloConduccionPageCont';
import SimulatorResults from './Pages/SimulatorResults';
import EvaluacionInstructor from './Pages/EvaluacionInstructor';
import ResumenEstilosConduccion from './Pages/ResumenEstilosConduccion';
import RecomendacionesInstructor from './Pages/RecomendacionesInstructor';

// Slider Deck theme
const deckTheme = mergeTheme(oneTheme);

// https://github.com/FormidableLabs/spectacle/blob/master/docs/content/advanced-concepts.md#query-parameters
// add to url
// &exportMode=true
export const SlidePage = ({
  number,
  children,
  background,
  header = true,
  footer = false
}) => {
  const isEven = number % 2 === 0;
  // console.log({ number, isEven });
  return (
    <PageWrapper backgroundImage={background}>
      {header && (
        <div className='holos-header'>
          <div className='logos-container'>
            <div className='logo-wrapper'>
              <img src={BHPLogo} alt='bhp-logo' />
            </div>
            <div className='logo-wrapper'>
              <img src={HolosLogo} alt='holos-logo' />
            </div>
          </div>
        </div>
      )}
      {children}
      {footer && (
        <div className='bhp-footer'>
          <div className='logos-container'>
            <div className='logo-wrapper'>
              <img src={BHPLogo} alt='bhp-logo' />
            </div>
          </div>
        </div>
      )}
      {number && <PageNumber even={isEven}>{number}</PageNumber>}
    </PageWrapper>
  );
};

// /holos-bhp/u/1dLThFNsTUZpiitU0A9XtMElzBPdk426Ejzgu-_Ue9Kc/consolidated/11.819.147-1?exportMode=true

const EquisOne = props => {
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  // console.log({ props });
  const {
    match: { params }
  } = props;

  useEffect(() => {
    // console.log({ params });

    const fetchUser = async () => {
      // fetch user row
      const response = await fetch(`/api/get-user`, {
        method: 'POST',
        body: JSON.stringify({
          rut: params.rut,
          SHEET_ID: params.sid,
          SHEET_TITLE: params.stitle
        })
      });
      const responseData = await response.json();
      // console.log({ responseData });
      const userData = responseData.user;
      // console.log({ userData });
      // FETCH user sheet
      const sheet = await fetch(`/api/get-sheet`, {
        method: 'POST',
        body: JSON.stringify({
          rut: params.rut,
          SHEET_ID: params.sid,
          SHEET_TITLE: params.rut
        })
      });
      const sheetData = await sheet.json();
      const rowsData = sheetData.rows;
      // console.log({ rowsData });
      // const userData = dummyUser;
      // const rowsData = dummyRows;
      const parsedUser = {
        ...userData,
        results: rowsData
      };
      setUser(parsedUser);
      setLoading(false);
    };
    fetchUser();
  }, [params]);

  // console.log({ user });

  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Helmet>
          <title>{`${user.RUT}_Informe_Ejercicio_Simulacion_BHP`}</title>
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <Deck theme={deckTheme} backgroundColor='#fff'>
          <Slide backgroundColor='#fff'>
            <EquisOneTopPage
              user={user}
              // background={topBackground}
              header={false}
            />
          </Slide>

          {/** BLANK PAGE */}
          <Slide backgroundColor='#fff'>
            <SlidePage header={false}></SlidePage>
          </Slide>

          <Slide backgroundColor='#fff'>
            <InfoDataPage user={user} number={1} />
          </Slide>
          <Slide backgroundColor='#fff'>
            <EstiloConduccionPage user={user} number={2} />
          </Slide>
          <Slide backgroundColor='#fff'>
            <EstiloConduccionPageCont user={user} number={3} />
          </Slide>
          <Slide backgroundColor='#fff'>
            <SimulatorResults user={user} number={4} />
          </Slide>
          <Slide backgroundColor='#fff'>
            <ResumenEstilosConduccion user={user} number={5} />
          </Slide>
          <Slide backgroundColor='#fff'>
            <EvaluacionInstructor user={user} number={6} />
          </Slide>
          <Slide backgroundColor='#fff'>
            <RecomendacionesInstructor user={user} number={7} />
          </Slide>

          <Slide backgroundColor='#fff'>
            <AboutUsPage number={8} />
          </Slide>


          <Slide backgroundColor='#fff'>
            <EquisOneClosePage header={false} />
          </Slide>
        </Deck>
      </Fragment>
    </ThemeProvider>
  );
};

export default EquisOne;

const dummyUser = {
  caseid: 'f0f7b93f-f663-4afc-96a9-06b5eded5053',
  createdat: 'Fri, 09 Apr 2021 15:31:07 GMT',
  updatedat: 'Fri, 09 Apr 2021 15:54:48 GMT',
  finished: '1',
  email: '',
  STAGE: '6',
  PRE: '1',
  RUT: '11.819.147-1',
  NAME: 'Manuel jesus',
  LASTNAME: 'Nievas villalobos',
  SEX: '1',
  AGE: '49',
  D2: '07/1995',
  D3_BRAND: 'Volvo',
  D3_MODEL: 'FH 12',
  D3_TYPE: '6x4',
  D3_YEAR: '2014',
  D4_01: 'Scania G420',
  D4_02: 'Mercedes benz 2538 ',
  D4_03: 'Freiliner 112 ',
  D4_04: '',
  D4_05: '',
  D5_01: '2500',
  D5_02: '50',
  D5_SUM: '2550',
  D6: 'Curso de mercedes y volvo',
  D7: '0',
  D8: '0',
  D9: '0',
  D10: '0',
  D11: '0',
  D12: '0',
  D13_01_YEAR: '',
  D13_01_INFRACT: '',
  D13_02_YEAR: '',
  D13_02_INFRACT: '',
  D13_03_YEAR: '',
  D13_03_INFRACT: '',
  D13_04_YEAR: '',
  D13_04_INFRACT: '',
  D13_05_YEAR: '',
  D13_05_INFRACT: '',
  D13_06_YEAR: '',
  D13_06_INFRACT: '',
  D13_07_YEAR: '',
  D13_07_INFRACT: '',
  D13_08_YEAR: '',
  D13_08_INFRACT: '',
  D13_09_YEAR: '',
  D13_09_INFRACT: '',
  D13_10_YEAR: '',
  D13_10_INFRACT: '',
  DBQ_01: '1',
  DBQ_02: '1',
  DBQ_03: '0',
  DBQ_04: '0',
  DBQ_05: '1',
  DBQ_06: '1',
  DBQ_07: '1',
  DBQ_08: '0',
  DBQ_09: '1',
  DBQ_10: '2',
  DBQ_11: '0',
  DBQ_12: '0',
  DBQ_13: '0',
  DBQ_14: '0',
  DBQ_15: '1',
  DBQ_16: '0',
  DBQ_17: '0',
  DBQ_18: '1',
  DBQ_19: '0',
  DBQ_20: '0',
  DBQ_21: '1',
  DBQ_22: '0',
  DBQ_23: '0',
  DBQ_24: '1',
  DBQ_25: '0',
  DBQ_26: '0',
  DBQ_27: '0',
  DBQ_28: '0',
  TLX_01: '',
  TLX_02: '',
  TLX_03: '',
  TLX_04: '',
  TLX_05: '',
  TLX_06: '',
  SSQ_01: '1',
  SSQ_02: '0',
  SSQ_03: '1',
  SSQ_04: '0',
  SSQ_05: '1',
  SSQ_06: '2',
  SSQ_07: '1',
  SSQ_08: '2',
  SSQ_09: '1',
  SSQ_10: '1',
  SSQ_11: '2',
  SSQ_12: '2',
  SSQ_13: '1',
  SSQ_14: '1',
  SSQ_15: '0',
  SSQ_16: '0',
  REACT_01: '3',
  REACT_02: '3',
  REACT_03: '2',
  REACT_04: '2',
  REACT_05: '1',
  REACT_06: '5',
  REACT_07: '1',
  REACT_08: '5',
  REACT_09: '1',
  REACT_10: '1',
  REACT_11: '2',
  REACT_12: '5',
  AFC_01: '',
  AFC_02: '',
  AFC_03: '',
  AFC_04: '',
  AFC_05: '',
  AFC_06: '',
  AFC_07: '',
  AFC_08: '',
  AFC_09: '',
  AFC_10: '',
  AFC_11: '',
  AFC_12: '',
  AFC_13: '',
  AFC_14: '',
  AFC_15: '',
  AFC_16: '',
  FEED_01: '2',
  FEED_02:
    'Esta muy lejos de la realidad, prefiro el metodo antuguo en un vehiculo.',
  caseid_ev: '02703691-c457-422f-ae04-ab1dc32989e8',
  createdat_ev: 'Mon, 12 Apr 2021 14:06:01 GMT',
  updatedat_ev: 'Mon, 12 Apr 2021 14:15:05 GMT',
  finished_ev: '1',
  email_ev: '',
  STAGE_ev: '7',
  RUT_ev: '11.819.147-1',
  DPE_01: '1',
  DPE_02: '1',
  DPE_03: '1',
  DPE_04: '3',
  DPE_05: '3',
  DPE_06: '3',
  DPE_07: '2',
  DPE_08: '1',
  DPE_09: '1',
  DPE_10: '1',
  DPE_11: '1',
  DPE_12: '1',
  DPE_13: '1',
  DPE_14: '1',
  DPE_15: '1',
  DPE_16: '1',
  DPE_17: '2',
  DPE_18: '1',
  DPE_19: '1',
  DPE_20: '3',
  DPE_21: '1',
  DPE_22: '3',
  DPE_23: '3',
  DPE_24: '1',
  DPE_25: '1',
  DPE_26: '1',
  DPE_27: '1',
  DPE_28: '1',
  DPE_29: '1',
  DPE_30: '3',
  DPE_31: '1',
  DPE_32: '1',
  DPE_33: '1',
  DPE_34: '1',
  DPE_35: '1',
  DPE_36: '1',
  DPE_37: '1',
  DPE_38: '1',
  DPE_39: '1',
  DPE_40: '1',
  DPE_41: '1',
  DPE_42: '1',
  DPE_43: '1',
  DPE_44: '3',
  DPE_45: '1',
  DPE_46: '1',
  DPE_47: '1',
  DPE_48: '1',
  DPE_49: '1',
  DPE_50: '1',
  DPE_51: '1',
  DPE_52: '1',
  DPE_53: '1',
  DPE_54: '1',
  DPE_55: '1',
  DPE_56: '1',
  DPE_57: '1',
  DPE_58: '1',
  DPE_59: '1',
  DPE_60: '1',
  DPE_61: '2',
  DPE_62: '1',
  FEV_01: '1',
  FEV_02: '',
  FEV_03:
    'Conductor se pone nervioso y comete errores, posterior a eso, solicita terminar con el practico a los 2 minutos de iniciado. '
};

const dummyRows = [
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:10 AM',
    'Posicion.Camion': 'X=235959.172 Y=237657.875 Z=-21521.617',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '0.5',
    X: '235959.172',
    Y: '237657.875',
    Z: '-21521.617'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:11 AM',
    'Posicion.Camion': 'X=235959.438 Y=237658.406 Z=-21628.936',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.167538',
    'Refresh.Rate': '0.5',
    Time: '1',
    X: '235959.438',
    Y: '237658.406',
    Z: '-21628.936'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:11 AM',
    'Posicion.Camion': 'X=235959.844 Y=237658.234 Z=-21621.412',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.103623',
    'Refresh.Rate': '0.5',
    Time: '1.5',
    X: '235959.844',
    Y: '237658.234',
    Z: '-21621.412'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:12 AM',
    'Posicion.Camion': 'X=235959.641 Y=237659.125 Z=-21617.264',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.012379',
    'Refresh.Rate': '0.5',
    Time: '2',
    X: '235959.641',
    Y: '237659.125',
    Z: '-21617.264'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:12 AM',
    'Posicion.Camion': 'X=235959.438 Y=237658.875 Z=-21619.082',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '2.5',
    X: '235959.438',
    Y: '237658.875',
    Z: '-21619.082'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:13 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '3',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:13 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '3.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:14 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '4',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:14 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '4.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:15 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:15 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '5.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:16 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '6',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:16 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '6.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:17 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '7',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:17 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '7.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:18 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '8',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:18 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '8.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:19 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '9',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:20 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '9.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:20 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '10',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:21 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '10.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:21 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '11',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:22 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '11.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:22 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '12',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:23 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '12.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:23 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '13',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:24 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '13.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:24 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '14',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:25 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '14.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:25 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '15',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:26 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '15.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:26 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '16',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:27 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '16.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:27 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '17',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:28 AM',
    'Posicion.Camion': 'X=235959.531 Y=237658.812 Z=-21619.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '17.5',
    X: '235959.531',
    Y: '237658.812',
    Z: '-21619.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:28 AM',
    'Posicion.Camion': 'X=158011.172 Y=195939.016 Z=-24364.133',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '18',
    X: '158011.172',
    Y: '195939.016',
    Z: '-24364.133'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:29 AM',
    'Posicion.Camion': 'X=158011.875 Y=195935.484 Z=-24373.727',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.039919',
    'Refresh.Rate': '0.5',
    Time: '18.5',
    X: '158011.875',
    Y: '195935.484',
    Z: '-24373.727'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:29 AM',
    'Posicion.Camion': 'X=158010.547 Y=195936.516 Z=-24365.965',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.062868',
    'Refresh.Rate': '0.5',
    Time: '19',
    X: '158010.547',
    Y: '195936.516',
    Z: '-24365.965'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:30 AM',
    'Posicion.Camion': 'X=158010.359 Y=195936.547 Z=-24367.855',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '19.5',
    X: '158010.359',
    Y: '195936.547',
    Z: '-24367.855'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:30 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '20',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:31 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '20.5',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:31 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '21',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:32 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '21.5',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:32 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '22',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:33 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '22.5',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:33 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '23',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:34 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '23.5',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:34 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '24',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:35 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '24.5',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:35 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '25',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:36 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '25.5',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:36 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '26',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:37 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '26.5',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:37 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '27',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:38 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '27.5',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:38 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '28',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:39 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '28.5',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:39 AM',
    'Posicion.Camion': 'X=158010.391 Y=195936.500 Z=-24367.896',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '29',
    X: '158010.391',
    Y: '195936.5',
    Z: '-24367.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:40 AM',
    'Posicion.Camion': 'X=158010.406 Y=195936.484 Z=-24368.018',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '29.5',
    X: '158010.406',
    Y: '195936.484',
    Z: '-24368.018'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:41 AM',
    'Posicion.Camion': 'X=158010.547 Y=195936.406 Z=-24367.730',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '30',
    X: '158010.547',
    Y: '195936.406',
    Z: '-24367.73'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:41 AM',
    'Posicion.Camion': 'X=158010.547 Y=195936.391 Z=-24367.666',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000147',
    'Refresh.Rate': '0.5',
    Time: '30.5',
    X: '158010.547',
    Y: '195936.391',
    Z: '-24367.666'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:42 AM',
    'Posicion.Camion': 'X=158010.547 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000057',
    'Refresh.Rate': '0.5',
    Time: '31',
    X: '158010.547',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:42 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.00005',
    'Refresh.Rate': '0.5',
    Time: '31.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:43 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000047',
    'Refresh.Rate': '0.5',
    Time: '32',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:43 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000049',
    'Refresh.Rate': '0.5',
    Time: '32.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:44 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000046',
    'Refresh.Rate': '0.5',
    Time: '33',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:44 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000048',
    'Refresh.Rate': '0.5',
    Time: '33.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:45 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000049',
    'Refresh.Rate': '0.5',
    Time: '34',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:45 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000044',
    'Refresh.Rate': '0.5',
    Time: '34.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:46 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000047',
    'Refresh.Rate': '0.5',
    Time: '35',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:46 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000046',
    'Refresh.Rate': '0.5',
    Time: '35.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:47 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000047',
    'Refresh.Rate': '0.5',
    Time: '36',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:47 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '36.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:48 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '37',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:48 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '37.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:49 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '38',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:49 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '38.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:50 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '39',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:51 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '39.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:51 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '40',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:52 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '40.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:52 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '41',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:53 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '41.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:53 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '42',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:54 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '42.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:54 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '43',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:55 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '43.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:55 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '44',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:56 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '44.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:56 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '45',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:57 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '45.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:57 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '46',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:58 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '46.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:59 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '47',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:39:59 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '47.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:00 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '48',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:00 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '48.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:01 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '49',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:01 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000051',
    'Refresh.Rate': '0.5',
    Time: '49.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:02 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000048',
    'Refresh.Rate': '0.5',
    Time: '50',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:02 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000048',
    'Refresh.Rate': '0.5',
    Time: '50.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:03 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000053',
    'Refresh.Rate': '0.5',
    Time: '51',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:03 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000047',
    'Refresh.Rate': '0.5',
    Time: '51.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:04 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000053',
    'Refresh.Rate': '0.5',
    Time: '52',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:04 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000048',
    'Refresh.Rate': '0.5',
    Time: '52.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:05 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000052',
    'Refresh.Rate': '0.5',
    Time: '53',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:05 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000053',
    'Refresh.Rate': '0.5',
    Time: '53.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:06 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000055',
    'Refresh.Rate': '0.5',
    Time: '54',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:06 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000065',
    'Refresh.Rate': '0.5',
    Time: '54.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:07 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000055',
    'Refresh.Rate': '0.5',
    Time: '55',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:08 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000047',
    'Refresh.Rate': '0.5',
    Time: '55.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:08 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000029',
    'Refresh.Rate': '0.5',
    Time: '56',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:09 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000059',
    'Refresh.Rate': '0.5',
    Time: '56.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:09 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000042',
    'Refresh.Rate': '0.5',
    Time: '57',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:10 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000066',
    'Refresh.Rate': '0.5',
    Time: '57.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:10 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.00005',
    'Refresh.Rate': '0.5',
    Time: '58',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:11 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000065',
    'Refresh.Rate': '0.5',
    Time: '58.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:11 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000067',
    'Refresh.Rate': '0.5',
    Time: '59',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:12 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000057',
    'Refresh.Rate': '0.5',
    Time: '59.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:12 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000052',
    'Refresh.Rate': '0.5',
    Time: '60',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:13 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000049',
    'Refresh.Rate': '0.5',
    Time: '60.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:13 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000051',
    'Refresh.Rate': '0.5',
    Time: '61',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:14 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000051',
    'Refresh.Rate': '0.5',
    Time: '61.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:14 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000048',
    'Refresh.Rate': '0.5',
    Time: '62',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:15 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000053',
    'Refresh.Rate': '0.5',
    Time: '62.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:15 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000053',
    'Refresh.Rate': '0.5',
    Time: '63',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:16 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000055',
    'Refresh.Rate': '0.5',
    Time: '63.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:17 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000053',
    'Refresh.Rate': '0.5',
    Time: '64',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:17 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000056',
    'Refresh.Rate': '0.5',
    Time: '64.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:18 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0.000051',
    'Refresh.Rate': '0.5',
    Time: '65',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:18 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.227451',
    'Posicon.Acelerador': '0',
    Speed: '0.000055',
    'Refresh.Rate': '0.5',
    Time: '65.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:19 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000051',
    'Refresh.Rate': '0.5',
    Time: '66',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:19 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.196078',
    'Posicon.Acelerador': '0',
    Speed: '0.000052',
    'Refresh.Rate': '0.5',
    Time: '66.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:20 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.196078',
    'Posicon.Acelerador': '0',
    Speed: '0.000051',
    'Refresh.Rate': '0.5',
    Time: '67',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:20 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.196078',
    'Posicon.Acelerador': '0',
    Speed: '0.000052',
    'Refresh.Rate': '0.5',
    Time: '67.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:21 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.196078',
    'Posicon.Acelerador': '0',
    Speed: '0.000052',
    'Refresh.Rate': '0.5',
    Time: '68',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:21 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.196078',
    'Posicon.Acelerador': '0',
    Speed: '0.000056',
    'Refresh.Rate': '0.5',
    Time: '68.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:22 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.196078',
    'Posicon.Acelerador': '0',
    Speed: '0.00005',
    'Refresh.Rate': '0.5',
    Time: '69',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:22 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.196078',
    'Posicon.Acelerador': '0',
    Speed: '0.000056',
    'Refresh.Rate': '0.5',
    Time: '69.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:23 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.196078',
    'Posicon.Acelerador': '0',
    Speed: '0.000048',
    'Refresh.Rate': '0.5',
    Time: '70',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:23 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.196078',
    'Posicon.Acelerador': '0',
    Speed: '0.000055',
    'Refresh.Rate': '0.5',
    Time: '70.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:24 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000051',
    'Refresh.Rate': '0.5',
    Time: '71',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:25 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000049',
    'Refresh.Rate': '0.5',
    Time: '71.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:25 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000052',
    'Refresh.Rate': '0.5',
    Time: '72',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:26 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000056',
    'Refresh.Rate': '0.5',
    Time: '72.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:26 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000051',
    'Refresh.Rate': '0.5',
    Time: '73',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:27 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000052',
    'Refresh.Rate': '0.5',
    Time: '73.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:27 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000044',
    'Refresh.Rate': '0.5',
    Time: '74',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:28 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000056',
    'Refresh.Rate': '0.5',
    Time: '74.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:28 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000051',
    'Refresh.Rate': '0.5',
    Time: '75',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:29 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000054',
    'Refresh.Rate': '0.5',
    Time: '75.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:29 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000053',
    'Refresh.Rate': '0.5',
    Time: '76',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:30 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000052',
    'Refresh.Rate': '0.5',
    Time: '76.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:30 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000052',
    'Refresh.Rate': '0.5',
    Time: '77',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:31 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000032',
    'Refresh.Rate': '0.5',
    Time: '77.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:31 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000028',
    'Refresh.Rate': '0.5',
    Time: '78',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:32 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000038',
    'Refresh.Rate': '0.5',
    Time: '78.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:33 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000044',
    'Refresh.Rate': '0.5',
    Time: '79',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:33 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000071',
    'Refresh.Rate': '0.5',
    Time: '79.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:34 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.00006',
    'Refresh.Rate': '0.5',
    Time: '80',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:34 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000027',
    'Refresh.Rate': '0.5',
    Time: '80.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:35 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.00005',
    'Refresh.Rate': '0.5',
    Time: '81',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:35 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000043',
    'Refresh.Rate': '0.5',
    Time: '81.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:36 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000047',
    'Refresh.Rate': '0.5',
    Time: '82',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:36 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000048',
    'Refresh.Rate': '0.5',
    Time: '82.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:37 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000041',
    'Refresh.Rate': '0.5',
    Time: '83',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:37 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000033',
    'Refresh.Rate': '0.5',
    Time: '83.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:38 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000047',
    'Refresh.Rate': '0.5',
    Time: '84',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:38 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000057',
    'Refresh.Rate': '0.5',
    Time: '84.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:39 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000089',
    'Refresh.Rate': '0.5',
    Time: '85',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:39 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000037',
    'Refresh.Rate': '0.5',
    Time: '85.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:40 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000054',
    'Refresh.Rate': '0.5',
    Time: '86',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:41 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000036',
    'Refresh.Rate': '0.5',
    Time: '86.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:41 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000049',
    'Refresh.Rate': '0.5',
    Time: '87',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:42 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000051',
    'Refresh.Rate': '0.5',
    Time: '87.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:42 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.00005',
    'Refresh.Rate': '0.5',
    Time: '88',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:43 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000046',
    'Refresh.Rate': '0.5',
    Time: '88.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:43 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000048',
    'Refresh.Rate': '0.5',
    Time: '89',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:44 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000042',
    'Refresh.Rate': '0.5',
    Time: '89.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:44 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000046',
    'Refresh.Rate': '0.5',
    Time: '90',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:45 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000054',
    'Refresh.Rate': '0.5',
    Time: '90.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:45 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.00007',
    'Refresh.Rate': '0.5',
    Time: '91',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:46 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.176471',
    'Posicon.Acelerador': '0',
    Speed: '0.000061',
    'Refresh.Rate': '0.5',
    Time: '91.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:46 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000052',
    'Refresh.Rate': '0.5',
    Time: '92',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:47 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.219608',
    'Posicon.Acelerador': '0',
    Speed: '0.000047',
    'Refresh.Rate': '0.5',
    Time: '92.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:47 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.231372',
    'Posicon.Acelerador': '0',
    Speed: '0.000045',
    'Refresh.Rate': '0.5',
    Time: '93',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:48 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.231372',
    'Posicon.Acelerador': '0',
    Speed: '0.000074',
    'Refresh.Rate': '0.5',
    Time: '93.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:49 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.00005',
    'Refresh.Rate': '0.5',
    Time: '94',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:49 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000048',
    'Refresh.Rate': '0.5',
    Time: '94.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:50 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000051',
    'Refresh.Rate': '0.5',
    Time: '95',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:50 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000078',
    'Refresh.Rate': '0.5',
    Time: '95.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:51 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000039',
    'Refresh.Rate': '0.5',
    Time: '96',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:51 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000055',
    'Refresh.Rate': '0.5',
    Time: '96.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:52 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000029',
    'Refresh.Rate': '0.5',
    Time: '97',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:52 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '97.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:53 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '98',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:53 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '98.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:54 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '99',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:54 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '99.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:55 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '100',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:55 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '100.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:56 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '101',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:56 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '101.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:57 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '102',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:57 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '102.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:58 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '103',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:58 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '103.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:40:59 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '104',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:00 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '104.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:00 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '105',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:01 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.883',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000055',
    'Refresh.Rate': '0.5',
    Time: '105.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.883'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:01 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.727',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000136',
    'Refresh.Rate': '0.5',
    Time: '106',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.727'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:02 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.666',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000082',
    'Refresh.Rate': '0.5',
    Time: '106.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.666'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:02 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000019',
    'Refresh.Rate': '0.5',
    Time: '107',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:03 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000042',
    'Refresh.Rate': '0.5',
    Time: '107.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:03 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000044',
    'Refresh.Rate': '0.5',
    Time: '108',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:04 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000043',
    'Refresh.Rate': '0.5',
    Time: '108.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:04 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000041',
    'Refresh.Rate': '0.5',
    Time: '109',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:05 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '109.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:05 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '110',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:06 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '110.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:06 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '111',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:07 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '111.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:07 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '112',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:08 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '112.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:09 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '113',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:09 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '113.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:10 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '114',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:10 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '114.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:11 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '115',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:12 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '115.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:12 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '116',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:13 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.795',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0.000038',
    'Refresh.Rate': '0.5',
    Time: '116.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.795'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:13 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.670',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000055',
    'Refresh.Rate': '0.5',
    Time: '117',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.67'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:14 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.192157',
    'Posicon.Acelerador': '0',
    Speed: '0.000021',
    'Refresh.Rate': '0.5',
    Time: '117.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:15 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '118',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:16 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '118.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:16 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '119',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:17 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '119.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:17 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '120',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:18 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '120.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:18 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.816',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000143',
    'Refresh.Rate': '0.5',
    Time: '121',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.816'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:19 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.676',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000084',
    'Refresh.Rate': '0.5',
    Time: '121.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.676'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:19 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.670',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000032',
    'Refresh.Rate': '0.5',
    Time: '122',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.67'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:20 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000032',
    'Refresh.Rate': '0.5',
    Time: '122.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:20 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000051',
    'Refresh.Rate': '0.5',
    Time: '123',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:21 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.00005',
    'Refresh.Rate': '0.5',
    Time: '123.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:21 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000068',
    'Refresh.Rate': '0.5',
    Time: '124',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:22 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.391 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.00013',
    'Refresh.Rate': '0.5',
    Time: '124.5',
    X: '158010.562',
    Y: '195936.391',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:22 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000065',
    'Refresh.Rate': '0.5',
    Time: '125',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:23 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000021',
    'Refresh.Rate': '0.5',
    Time: '125.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:23 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000065',
    'Refresh.Rate': '0.5',
    Time: '126',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:24 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000026',
    'Refresh.Rate': '0.5',
    Time: '126.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:25 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000021',
    'Refresh.Rate': '0.5',
    Time: '127',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:25 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000066',
    'Refresh.Rate': '0.5',
    Time: '127.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:26 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000051',
    'Refresh.Rate': '0.5',
    Time: '128',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:26 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000026',
    'Refresh.Rate': '0.5',
    Time: '128.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:27 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000025',
    'Refresh.Rate': '0.5',
    Time: '129',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:27 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000074',
    'Refresh.Rate': '0.5',
    Time: '129.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:28 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000019',
    'Refresh.Rate': '0.5',
    Time: '130',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:28 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000063',
    'Refresh.Rate': '0.5',
    Time: '130.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:29 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000052',
    'Refresh.Rate': '0.5',
    Time: '131',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:29 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000049',
    'Refresh.Rate': '0.5',
    Time: '131.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:30 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000046',
    'Refresh.Rate': '0.5',
    Time: '132',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:30 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000078',
    'Refresh.Rate': '0.5',
    Time: '132.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:31 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000049',
    'Refresh.Rate': '0.5',
    Time: '133',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:31 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000049',
    'Refresh.Rate': '0.5',
    Time: '133.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:32 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0',
    Speed: '0.000049',
    'Refresh.Rate': '0.5',
    Time: '134',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:32 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0.176471',
    'Posicon.Acelerador': '0',
    Speed: '0.000027',
    'Refresh.Rate': '0.5',
    Time: '134.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:33 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000045',
    'Refresh.Rate': '0.5',
    Time: '135',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:33 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.00005',
    'Refresh.Rate': '0.5',
    Time: '135.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:34 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000053',
    'Refresh.Rate': '0.5',
    Time: '136',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:34 AM',
    'Posicion.Camion': 'X=158010.562 Y=195936.406 Z=-24367.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.101961',
    Speed: '0.000053',
    'Refresh.Rate': '0.5',
    Time: '136.5',
    X: '158010.562',
    Y: '195936.406',
    Z: '-24367.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:35 AM',
    'Posicion.Camion': 'X=158009.938 Y=195937.062 Z=-24367.715',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.109804',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '137',
    X: '158009.938',
    Y: '195937.062',
    Z: '-24367.715'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:35 AM',
    'Posicion.Camion': 'X=158009.312 Y=195937.641 Z=-24367.777',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.160784',
    Speed: '0.100935',
    'Refresh.Rate': '0.5',
    Time: '137.5',
    X: '158009.312',
    Y: '195937.641',
    Z: '-24367.777'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:36 AM',
    'Posicion.Camion': 'X=158009.344 Y=195936.594 Z=-24367.748',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.211765',
    Speed: '0.133951',
    'Refresh.Rate': '0.5',
    Time: '138',
    X: '158009.344',
    Y: '195936.594',
    Z: '-24367.748'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:37 AM',
    'Posicion.Camion': 'X=158011.375 Y=195929.812 Z=-24367.531',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.32549',
    Speed: '1.04155',
    'Refresh.Rate': '0.5',
    Time: '138.5',
    X: '158011.375',
    Y: '195929.812',
    Z: '-24367.531'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:37 AM',
    'Posicion.Camion': 'X=158017.516 Y=195911.500 Z=-24366.809',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.32549',
    Speed: '1.981207',
    'Refresh.Rate': '0.5',
    Time: '139',
    X: '158017.516',
    Y: '195911.5',
    Z: '-24366.809'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:38 AM',
    'Posicion.Camion': 'X=158027.469 Y=195882.438 Z=-24365.430',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.32549',
    Speed: '2.833052',
    'Refresh.Rate': '0.5',
    Time: '139.5',
    X: '158027.469',
    Y: '195882.438',
    Z: '-24365.43'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:38 AM',
    'Posicion.Camion': 'X=158041.250 Y=195842.797 Z=-24363.598',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.32549',
    Speed: '3.690383',
    'Refresh.Rate': '0.5',
    Time: '140',
    X: '158041.25',
    Y: '195842.797',
    Z: '-24363.598'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:39 AM',
    'Posicion.Camion': 'X=158059.484 Y=195792.891 Z=-24361.303',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.32549',
    Speed: '4.551112',
    'Refresh.Rate': '0.5',
    Time: '140.5',
    X: '158059.484',
    Y: '195792.891',
    Z: '-24361.303'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:39 AM',
    'Posicion.Camion': 'X=158083.703 Y=195733.875 Z=-24358.285',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.341176',
    Speed: '5.355639',
    'Refresh.Rate': '0.5',
    Time: '141',
    X: '158083.703',
    Y: '195733.875',
    Z: '-24358.285'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:40 AM',
    'Posicion.Camion': 'X=158116.000 Y=195667.578 Z=-24354.574',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.345098',
    Speed: '6.05547',
    'Refresh.Rate': '0.5',
    Time: '141.5',
    X: '158116',
    Y: '195667.578',
    Z: '-24354.574'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:40 AM',
    'Posicion.Camion': 'X=158154.875 Y=195593.656 Z=-24350.068',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.34902',
    Speed: '6.865279',
    'Refresh.Rate': '0.5',
    Time: '142',
    X: '158154.875',
    Y: '195593.656',
    Z: '-24350.068'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:41 AM',
    'Posicion.Camion': 'X=158198.109 Y=195511.078 Z=-24344.768',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.34902',
    Speed: '7.617032',
    'Refresh.Rate': '0.5',
    Time: '142.5',
    X: '158198.109',
    Y: '195511.078',
    Z: '-24344.768'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:41 AM',
    'Posicion.Camion': 'X=158247.984 Y=195420.547 Z=-24339.359',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.34902',
    Speed: '8.412915',
    'Refresh.Rate': '0.5',
    Time: '143',
    X: '158247.984',
    Y: '195420.547',
    Z: '-24339.359'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:42 AM',
    'Posicion.Camion': 'X=158304.656 Y=195323.125 Z=-24332.709',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.34902',
    Speed: '9.110015',
    'Refresh.Rate': '0.5',
    Time: '143.5',
    X: '158304.656',
    Y: '195323.125',
    Z: '-24332.709'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:42 AM',
    'Posicion.Camion': 'X=158368.484 Y=195220.047 Z=-24325.330',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.313725',
    Speed: '9.687044',
    'Refresh.Rate': '0.5',
    Time: '144',
    X: '158368.484',
    Y: '195220.047',
    Z: '-24325.33'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:43 AM',
    'Posicion.Camion': 'X=158439.438 Y=195112.891 Z=-24318.293',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.313725',
    Speed: '10.25613',
    'Refresh.Rate': '0.5',
    Time: '144.5',
    X: '158439.438',
    Y: '195112.891',
    Z: '-24318.293'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:43 AM',
    'Posicion.Camion': 'X=158517.594 Y=195002.094 Z=-24310.443',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.309804',
    Speed: '10.833007',
    'Refresh.Rate': '0.5',
    Time: '145',
    X: '158517.594',
    Y: '195002.094',
    Z: '-24310.443'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:44 AM',
    'Posicion.Camion': 'X=158603.484 Y=194886.719 Z=-24304.295',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.301961',
    Speed: '11.575692',
    'Refresh.Rate': '0.5',
    Time: '145.5',
    X: '158603.484',
    Y: '194886.719',
    Z: '-24304.295'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:44 AM',
    'Posicion.Camion': 'X=158623.188 Y=194842.469 Z=-24298.297',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.27451',
    Speed: '0.134911',
    'Refresh.Rate': '0.5',
    Time: '146',
    X: '158623.188',
    Y: '194842.469',
    Z: '-24298.297'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:45 AM',
    'Posicion.Camion': 'X=158623.328 Y=194841.719 Z=-24301.996',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.262745',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '146.5',
    X: '158623.328',
    Y: '194841.719',
    Z: '-24301.996'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:46 AM',
    'Posicion.Camion': 'X=158623.375 Y=194842.469 Z=-24301.480',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.431373',
    Speed: '0.017334',
    'Refresh.Rate': '0.5',
    Time: '147',
    X: '158623.375',
    Y: '194842.469',
    Z: '-24301.48'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:46 AM',
    'Posicion.Camion': 'X=158623.531 Y=194842.734 Z=-24301.342',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.54902',
    Speed: '0.030968',
    'Refresh.Rate': '0.5',
    Time: '147.5',
    X: '158623.531',
    Y: '194842.734',
    Z: '-24301.342'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:47 AM',
    'Posicion.Camion': 'X=158623.609 Y=194842.922 Z=-24301.422',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.603922',
    Speed: '0.006022',
    'Refresh.Rate': '0.5',
    Time: '148',
    X: '158623.609',
    Y: '194842.922',
    Z: '-24301.422'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:47 AM',
    'Posicion.Camion': 'X=158623.969 Y=194842.859 Z=-24301.387',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.67451',
    Speed: '0.007906',
    'Refresh.Rate': '0.5',
    Time: '148.5',
    X: '158623.969',
    Y: '194842.859',
    Z: '-24301.387'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:48 AM',
    'Posicion.Camion': 'X=158624.062 Y=194843.016 Z=-24301.391',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.701961',
    Speed: '0.006686',
    'Refresh.Rate': '0.5',
    Time: '149',
    X: '158624.062',
    Y: '194843.016',
    Z: '-24301.391'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:48 AM',
    'Posicion.Camion': 'X=158624.078 Y=194843.078 Z=-24301.389',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.721569',
    Speed: '0.011039',
    'Refresh.Rate': '0.5',
    Time: '149.5',
    X: '158624.078',
    Y: '194843.078',
    Z: '-24301.389'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:49 AM',
    'Posicion.Camion': 'X=158624.125 Y=194843.141 Z=-24301.381',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.721569',
    Speed: '0.011067',
    'Refresh.Rate': '0.5',
    Time: '150',
    X: '158624.125',
    Y: '194843.141',
    Z: '-24301.381'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:49 AM',
    'Posicion.Camion': 'X=158624.156 Y=194843.188 Z=-24301.393',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.678431',
    Speed: '0.021699',
    'Refresh.Rate': '0.5',
    Time: '150.5',
    X: '158624.156',
    Y: '194843.188',
    Z: '-24301.393'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:50 AM',
    'Posicion.Camion': 'X=158624.188 Y=194843.672 Z=-24301.508',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.043137',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '151',
    X: '158624.188',
    Y: '194843.672',
    Z: '-24301.508'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:50 AM',
    'Posicion.Camion': 'X=158621.672 Y=194848.312 Z=-24302.064',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.384314',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '151.5',
    X: '158621.672',
    Y: '194848.312',
    Z: '-24302.064'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:51 AM',
    'Posicion.Camion': 'X=158621.969 Y=194849.391 Z=-24302.203',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.631373',
    Speed: '0.534616',
    'Refresh.Rate': '0.5',
    Time: '152',
    X: '158621.969',
    Y: '194849.391',
    Z: '-24302.203'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:51 AM',
    'Posicion.Camion': 'X=158621.984 Y=194849.312 Z=-24301.799',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.807843',
    Speed: '0.038458',
    'Refresh.Rate': '0.5',
    Time: '152.5',
    X: '158621.984',
    Y: '194849.312',
    Z: '-24301.799'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:52 AM',
    'Posicion.Camion': 'X=158621.797 Y=194849.016 Z=-24301.789',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.945098',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '153',
    X: '158621.797',
    Y: '194849.016',
    Z: '-24301.789'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:52 AM',
    'Posicion.Camion': 'X=158621.562 Y=194848.359 Z=-24301.699',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.984314',
    Speed: '0.042564',
    'Refresh.Rate': '0.5',
    Time: '153.5',
    X: '158621.562',
    Y: '194848.359',
    Z: '-24301.699'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:53 AM',
    'Posicion.Camion': 'X=158621.125 Y=194847.734 Z=-24301.590',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.984314',
    Speed: '0.041998',
    'Refresh.Rate': '0.5',
    Time: '154',
    X: '158621.125',
    Y: '194847.734',
    Z: '-24301.59'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:54 AM',
    'Posicion.Camion': 'X=158620.672 Y=194847.000 Z=-24301.492',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.984314',
    Speed: '0.041301',
    'Refresh.Rate': '0.5',
    Time: '154.5',
    X: '158620.672',
    Y: '194847',
    Z: '-24301.492'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:54 AM',
    'Posicion.Camion': 'X=158620.219 Y=194846.188 Z=-24301.396',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.988235',
    Speed: '0.041276',
    'Refresh.Rate': '0.5',
    Time: '155',
    X: '158620.219',
    Y: '194846.188',
    Z: '-24301.396'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:55 AM',
    'Posicion.Camion': 'X=158619.766 Y=194845.375 Z=-24301.346',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.060388',
    'Refresh.Rate': '0.5',
    Time: '155.5',
    X: '158619.766',
    Y: '194845.375',
    Z: '-24301.346'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:55 AM',
    'Posicion.Camion': 'X=158619.281 Y=194844.516 Z=-24301.297',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.055366',
    'Refresh.Rate': '0.5',
    Time: '156',
    X: '158619.281',
    Y: '194844.516',
    Z: '-24301.297'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:56 AM',
    'Posicion.Camion': 'X=158618.828 Y=194843.719 Z=-24301.236',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.026375',
    'Refresh.Rate': '0.5',
    Time: '156.5',
    X: '158618.828',
    Y: '194843.719',
    Z: '-24301.236'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:56 AM',
    'Posicion.Camion': 'X=158618.344 Y=194842.844 Z=-24301.162',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.040566',
    'Refresh.Rate': '0.5',
    Time: '157',
    X: '158618.344',
    Y: '194842.844',
    Z: '-24301.162'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:57 AM',
    'Posicion.Camion': 'X=158617.859 Y=194841.969 Z=-24301.086',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.057369',
    'Refresh.Rate': '0.5',
    Time: '157.5',
    X: '158617.859',
    Y: '194841.969',
    Z: '-24301.086'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:57 AM',
    'Posicion.Camion': 'X=158617.422 Y=194841.172 Z=-24301.027',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.552941',
    Speed: '0.035626',
    'Refresh.Rate': '0.5',
    Time: '158',
    X: '158617.422',
    Y: '194841.172',
    Z: '-24301.027'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:58 AM',
    'Posicion.Camion': 'X=158617.438 Y=194841.172 Z=-24301.305',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.015562',
    'Refresh.Rate': '0.5',
    Time: '158.5',
    X: '158617.438',
    Y: '194841.172',
    Z: '-24301.305'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:58 AM',
    'Posicion.Camion': 'X=158617.594 Y=194841.422 Z=-24301.377',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.019316',
    'Refresh.Rate': '0.5',
    Time: '159',
    X: '158617.594',
    Y: '194841.422',
    Z: '-24301.377'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:59 AM',
    'Posicion.Camion': 'X=158617.672 Y=194841.578 Z=-24301.270',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.6',
    Speed: '0.03492',
    'Refresh.Rate': '0.5',
    Time: '159.5',
    X: '158617.672',
    Y: '194841.578',
    Z: '-24301.27'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:41:59 AM',
    'Posicion.Camion': 'X=158617.391 Y=194841.125 Z=-24301.029',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.059496',
    'Refresh.Rate': '0.5',
    Time: '160',
    X: '158617.391',
    Y: '194841.125',
    Z: '-24301.029'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:00 AM',
    'Posicion.Camion': 'X=158616.906 Y=194840.266 Z=-24300.930',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.046099',
    'Refresh.Rate': '0.5',
    Time: '160.5',
    X: '158616.906',
    Y: '194840.266',
    Z: '-24300.93'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:00 AM',
    'Posicion.Camion': 'X=158616.453 Y=194839.391 Z=-24300.885',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.057063',
    'Refresh.Rate': '0.5',
    Time: '161',
    X: '158616.453',
    Y: '194839.391',
    Z: '-24300.885'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:01 AM',
    'Posicion.Camion': 'X=158615.984 Y=194838.500 Z=-24300.805',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.060732',
    'Refresh.Rate': '0.5',
    Time: '161.5',
    X: '158615.984',
    Y: '194838.5',
    Z: '-24300.805'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:02 AM',
    'Posicion.Camion': 'X=158615.516 Y=194837.656 Z=-24300.740',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.022824',
    'Refresh.Rate': '0.5',
    Time: '162',
    X: '158615.516',
    Y: '194837.656',
    Z: '-24300.74'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:02 AM',
    'Posicion.Camion': 'X=158615.031 Y=194836.734 Z=-24300.666',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.037968',
    'Refresh.Rate': '0.5',
    Time: '162.5',
    X: '158615.031',
    Y: '194836.734',
    Z: '-24300.666'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:03 AM',
    'Posicion.Camion': 'X=158614.562 Y=194835.844 Z=-24300.588',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.055352',
    'Refresh.Rate': '0.5',
    Time: '163',
    X: '158614.562',
    Y: '194835.844',
    Z: '-24300.588'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:03 AM',
    'Posicion.Camion': 'X=158614.125 Y=194835.000 Z=-24300.527',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.023486',
    'Refresh.Rate': '0.5',
    Time: '163.5',
    X: '158614.125',
    Y: '194835',
    Z: '-24300.527'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:04 AM',
    'Posicion.Camion': 'X=158613.656 Y=194834.125 Z=-24300.449',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.034287',
    'Refresh.Rate': '0.5',
    Time: '164',
    X: '158613.656',
    Y: '194834.125',
    Z: '-24300.449'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:04 AM',
    'Posicion.Camion': 'X=158613.203 Y=194833.203 Z=-24300.377',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.060788',
    'Refresh.Rate': '0.5',
    Time: '164.5',
    X: '158613.203',
    Y: '194833.203',
    Z: '-24300.377'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:05 AM',
    'Posicion.Camion': 'X=158612.734 Y=194832.312 Z=-24300.307',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.032035',
    'Refresh.Rate': '0.5',
    Time: '165',
    X: '158612.734',
    Y: '194832.312',
    Z: '-24300.307'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:05 AM',
    'Posicion.Camion': 'X=158612.250 Y=194831.344 Z=-24300.227',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.03984',
    'Refresh.Rate': '0.5',
    Time: '165.5',
    X: '158612.25',
    Y: '194831.344',
    Z: '-24300.227'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:06 AM',
    'Posicion.Camion': 'X=158611.797 Y=194830.422 Z=-24300.154',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.061168',
    'Refresh.Rate': '0.5',
    Time: '166',
    X: '158611.797',
    Y: '194830.422',
    Z: '-24300.154'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:06 AM',
    'Posicion.Camion': 'X=158611.344 Y=194829.531 Z=-24300.104',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.026116',
    'Refresh.Rate': '0.5',
    Time: '166.5',
    X: '158611.344',
    Y: '194829.531',
    Z: '-24300.104'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:07 AM',
    'Posicion.Camion': 'X=158610.859 Y=194828.578 Z=-24300.020',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '0.038604',
    'Refresh.Rate': '0.5',
    Time: '167',
    X: '158610.859',
    Y: '194828.578',
    Z: '-24300.02'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:07 AM',
    'Posicion.Camion': 'X=158610.438 Y=194827.703 Z=-24299.959',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.760784',
    Speed: '0.039341',
    'Refresh.Rate': '0.5',
    Time: '167.5',
    X: '158610.438',
    Y: '194827.703',
    Z: '-24299.959'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:08 AM',
    'Posicion.Camion': 'X=158610.312 Y=194827.641 Z=-24300.180',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '168',
    X: '158610.312',
    Y: '194827.641',
    Z: '-24300.18'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:08 AM',
    'Posicion.Camion': 'X=158610.375 Y=194827.953 Z=-24300.301',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.004871',
    'Refresh.Rate': '0.5',
    Time: '168.5',
    X: '158610.375',
    Y: '194827.953',
    Z: '-24300.301'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:09 AM',
    'Posicion.Camion': 'X=158610.438 Y=194828.328 Z=-24300.289',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.011911',
    'Refresh.Rate': '0.5',
    Time: '169',
    X: '158610.438',
    Y: '194828.328',
    Z: '-24300.289'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:09 AM',
    'Posicion.Camion': 'X=158610.531 Y=194828.672 Z=-24300.320',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.00848',
    'Refresh.Rate': '0.5',
    Time: '169.5',
    X: '158610.531',
    Y: '194828.672',
    Z: '-24300.32'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:10 AM',
    'Posicion.Camion': 'X=158610.312 Y=194829.312 Z=-24300.396',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '170',
    X: '158610.312',
    Y: '194829.312',
    Z: '-24300.396'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:11 AM',
    'Posicion.Camion': 'X=158606.562 Y=194834.562 Z=-24300.740',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '170.5',
    X: '158606.562',
    Y: '194834.562',
    Z: '-24300.74'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:11 AM',
    'Posicion.Camion': 'X=158598.141 Y=194845.500 Z=-24301.369',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '171',
    X: '158598.141',
    Y: '194845.5',
    Z: '-24301.369'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:12 AM',
    'Posicion.Camion': 'X=158584.906 Y=194862.250 Z=-24302.332',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '171.5',
    X: '158584.906',
    Y: '194862.25',
    Z: '-24302.332'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:12 AM',
    'Posicion.Camion': 'X=158567.141 Y=194884.328 Z=-24303.441',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '172',
    X: '158567.141',
    Y: '194884.328',
    Z: '-24303.441'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:13 AM',
    'Posicion.Camion': 'X=158544.656 Y=194911.906 Z=-24304.938',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '172.5',
    X: '158544.656',
    Y: '194911.906',
    Z: '-24304.938'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:13 AM',
    'Posicion.Camion': 'X=158516.672 Y=194945.656 Z=-24307.049',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '173',
    X: '158516.672',
    Y: '194945.656',
    Z: '-24307.049'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:14 AM',
    'Posicion.Camion': 'X=158483.016 Y=194985.781 Z=-24309.590',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '173.5',
    X: '158483.016',
    Y: '194985.781',
    Z: '-24309.59'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:14 AM',
    'Posicion.Camion': 'X=158443.344 Y=195032.516 Z=-24312.709',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '174',
    X: '158443.344',
    Y: '195032.516',
    Z: '-24312.709'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:15 AM',
    'Posicion.Camion': 'X=158396.250 Y=195087.641 Z=-24316.883',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '174.5',
    X: '158396.25',
    Y: '195087.641',
    Z: '-24316.883'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:15 AM',
    'Posicion.Camion': 'X=158341.641 Y=195151.312 Z=-24321.990',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '175',
    X: '158341.641',
    Y: '195151.312',
    Z: '-24321.99'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:16 AM',
    'Posicion.Camion': 'X=158279.109 Y=195223.828 Z=-24327.953',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '175.5',
    X: '158279.109',
    Y: '195223.828',
    Z: '-24327.953'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:16 AM',
    'Posicion.Camion': 'X=158208.469 Y=195306.203 Z=-24334.768',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '176',
    X: '158208.469',
    Y: '195306.203',
    Z: '-24334.768'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:17 AM',
    'Posicion.Camion': 'X=158135.766 Y=195390.984 Z=-24341.691',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '176.5',
    X: '158135.766',
    Y: '195390.984',
    Z: '-24341.691'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:17 AM',
    'Posicion.Camion': 'X=158049.906 Y=195491.828 Z=-24349.834',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '177',
    X: '158049.906',
    Y: '195491.828',
    Z: '-24349.834'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:18 AM',
    'Posicion.Camion': 'X=158013.625 Y=195534.703 Z=-24354.314',
    'Posicio.Freno': '0.133333',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '177.5',
    X: '158013.625',
    Y: '195534.703',
    Z: '-24354.314'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:18 AM',
    'Posicion.Camion': 'X=158012.266 Y=195536.094 Z=-24353.723',
    'Posicio.Freno': '0.133333',
    'Posicon.Acelerador': '0',
    Speed: '0.141724',
    'Refresh.Rate': '0.5',
    Time: '178',
    X: '158012.266',
    Y: '195536.094',
    Z: '-24353.723'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:19 AM',
    'Posicion.Camion': 'X=158011.734 Y=195536.484 Z=-24353.424',
    'Posicio.Freno': '0.133333',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '178.5',
    X: '158011.734',
    Y: '195536.484',
    Z: '-24353.424'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:20 AM',
    'Posicion.Camion': 'X=158011.969 Y=195536.234 Z=-24353.512',
    'Posicio.Freno': '0.152941',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '179',
    X: '158011.969',
    Y: '195536.234',
    Z: '-24353.512'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:20 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.535',
    'Posicio.Freno': '0.129412',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '179.5',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.535'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:21 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.535',
    'Posicio.Freno': '0.129412',
    'Posicon.Acelerador': '0',
    Speed: '0.000233',
    'Refresh.Rate': '0.5',
    Time: '180',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.535'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:21 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.533',
    'Posicio.Freno': '0.12549',
    'Posicon.Acelerador': '0',
    Speed: '0.000031',
    'Refresh.Rate': '0.5',
    Time: '180.5',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.533'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:22 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.533',
    'Posicio.Freno': '0.12549',
    'Posicon.Acelerador': '0',
    Speed: '0.000028',
    'Refresh.Rate': '0.5',
    Time: '181',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.533'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:22 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.533',
    'Posicio.Freno': '0.12549',
    'Posicon.Acelerador': '0',
    Speed: '0.000026',
    'Refresh.Rate': '0.5',
    Time: '181.5',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.533'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:23 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.533',
    'Posicio.Freno': '0.12549',
    'Posicon.Acelerador': '0',
    Speed: '0.000034',
    'Refresh.Rate': '0.5',
    Time: '182',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.533'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:23 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.533',
    'Posicio.Freno': '0.12549',
    'Posicon.Acelerador': '0',
    Speed: '0.00004',
    'Refresh.Rate': '0.5',
    Time: '182.5',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.533'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:24 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.533',
    'Posicio.Freno': '0.12549',
    'Posicon.Acelerador': '0',
    Speed: '0.000038',
    'Refresh.Rate': '0.5',
    Time: '183',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.533'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:24 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.533',
    'Posicio.Freno': '0.12549',
    'Posicon.Acelerador': '0',
    Speed: '0.000051',
    'Refresh.Rate': '0.5',
    Time: '183.5',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.533'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:25 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.533',
    'Posicio.Freno': '0.12549',
    'Posicon.Acelerador': '0',
    Speed: '0.00003',
    'Refresh.Rate': '0.5',
    Time: '184',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.533'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:25 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.533',
    'Posicio.Freno': '0.12549',
    'Posicon.Acelerador': '0',
    Speed: '0.000065',
    'Refresh.Rate': '0.5',
    Time: '184.5',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.533'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:26 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.533',
    'Posicio.Freno': '0.12549',
    'Posicon.Acelerador': '0',
    Speed: '0.000034',
    'Refresh.Rate': '0.5',
    Time: '185',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.533'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:26 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.533',
    'Posicio.Freno': '0.121569',
    'Posicon.Acelerador': '0',
    Speed: '0.00003',
    'Refresh.Rate': '0.5',
    Time: '185.5',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.533'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:27 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.533',
    'Posicio.Freno': '0.121569',
    'Posicon.Acelerador': '0',
    Speed: '0.000046',
    'Refresh.Rate': '0.5',
    Time: '186',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.533'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:27 AM',
    'Posicion.Camion': 'X=158012.062 Y=195536.156 Z=-24353.533',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000031',
    'Refresh.Rate': '0.5',
    Time: '186.5',
    X: '158012.062',
    Y: '195536.156',
    Z: '-24353.533'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:28 AM',
    'Posicion.Camion': 'X=158011.922 Y=195536.266 Z=-24353.539',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.105882',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '187',
    X: '158011.922',
    Y: '195536.266',
    Z: '-24353.539'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:28 AM',
    'Posicion.Camion': 'X=158011.062 Y=195537.219 Z=-24353.623',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.396078',
    Speed: '0.18445',
    'Refresh.Rate': '0.5',
    Time: '187.5',
    X: '158011.062',
    Y: '195537.219',
    Z: '-24353.623'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:29 AM',
    'Posicion.Camion': 'X=158017.109 Y=195529.953 Z=-24353.131',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.439216',
    Speed: '1.270269',
    'Refresh.Rate': '0.5',
    Time: '188',
    X: '158017.109',
    Y: '195529.953',
    Z: '-24353.131'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:29 AM',
    'Posicion.Camion': 'X=158032.781 Y=195511.328 Z=-24351.592',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.560784',
    Speed: '2.644465',
    'Refresh.Rate': '0.5',
    Time: '188.5',
    X: '158032.781',
    Y: '195511.328',
    Z: '-24351.592'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:30 AM',
    'Posicion.Camion': 'X=158061.578 Y=195477.359 Z=-24348.928',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.635294',
    Speed: '4.380309',
    'Refresh.Rate': '0.5',
    Time: '189',
    X: '158061.578',
    Y: '195477.359',
    Z: '-24348.928'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:31 AM',
    'Posicion.Camion': 'X=158108.859 Y=195421.938 Z=-24344.584',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.694118',
    Speed: '6.756272',
    'Refresh.Rate': '0.5',
    Time: '189.5',
    X: '158108.859',
    Y: '195421.938',
    Z: '-24344.584'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:31 AM',
    'Posicion.Camion': 'X=158176.609 Y=195343.812 Z=-24338.117',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.694118',
    Speed: '9.115217',
    'Refresh.Rate': '0.5',
    Time: '190',
    X: '158176.609',
    Y: '195343.812',
    Z: '-24338.117'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:32 AM',
    'Posicion.Camion': 'X=158265.625 Y=195244.062 Z=-24329.904',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.701961',
    Speed: '11.477567',
    'Refresh.Rate': '0.5',
    Time: '190.5',
    X: '158265.625',
    Y: '195244.062',
    Z: '-24329.904'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:32 AM',
    'Posicion.Camion': 'X=158377.891 Y=195124.031 Z=-24319.863',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.721569',
    Speed: '13.934625',
    'Refresh.Rate': '0.5',
    Time: '191',
    X: '158377.891',
    Y: '195124.031',
    Z: '-24319.863'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:33 AM',
    'Posicion.Camion': 'X=158516.719 Y=194984.453 Z=-24309.824',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.721569',
    Speed: '16.566484',
    'Refresh.Rate': '0.5',
    Time: '191.5',
    X: '158516.719',
    Y: '194984.453',
    Z: '-24309.824'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:33 AM',
    'Posicion.Camion': 'X=158669.656 Y=194834.156 Z=-24299.373',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.698039',
    Speed: '15.414926',
    'Refresh.Rate': '0.5',
    Time: '192',
    X: '158669.656',
    Y: '194834.156',
    Z: '-24299.373'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:34 AM',
    'Posicion.Camion': 'X=158815.688 Y=194711.906 Z=-24271.559',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.698039',
    Speed: '14.201782',
    'Refresh.Rate': '0.5',
    Time: '192.5',
    X: '158815.688',
    Y: '194711.906',
    Z: '-24271.559'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:34 AM',
    'Posicion.Camion': 'X=158930.266 Y=194590.469 Z=-24261.152',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.698039',
    Speed: '11.753179',
    'Refresh.Rate': '0.5',
    Time: '193',
    X: '158930.266',
    Y: '194590.469',
    Z: '-24261.152'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:35 AM',
    'Posicion.Camion': 'X=159020.844 Y=194485.188 Z=-24237.387',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.698039',
    Speed: '10.457759',
    'Refresh.Rate': '0.5',
    Time: '193.5',
    X: '159020.844',
    Y: '194485.188',
    Z: '-24237.387'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:35 AM',
    'Posicion.Camion': 'X=159125.094 Y=194393.422 Z=-24220.373',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.854902',
    Speed: '11.223774',
    'Refresh.Rate': '0.5',
    Time: '194',
    X: '159125.094',
    Y: '194393.422',
    Z: '-24220.373'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:36 AM',
    'Posicion.Camion': 'X=159229.625 Y=194284.906 Z=-24202.848',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.870588',
    Speed: '12.945235',
    'Refresh.Rate': '0.5',
    Time: '194.5',
    X: '159229.625',
    Y: '194284.906',
    Z: '-24202.848'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:36 AM',
    'Posicion.Camion': 'X=159372.719 Y=194153.484 Z=-24182.072',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.901961',
    Speed: '17.058096',
    'Refresh.Rate': '0.5',
    Time: '195',
    X: '159372.719',
    Y: '194153.484',
    Z: '-24182.072'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:37 AM',
    'Posicion.Camion': 'X=159547.859 Y=193992.719 Z=-24159.283',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.901961',
    Speed: '20.197088',
    'Refresh.Rate': '0.5',
    Time: '195.5',
    X: '159547.859',
    Y: '193992.719',
    Z: '-24159.283'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:37 AM',
    'Posicion.Camion': 'X=159745.922 Y=193795.219 Z=-24136.219',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.901961',
    Speed: '23.300892',
    'Refresh.Rate': '0.5',
    Time: '196',
    X: '159745.922',
    Y: '193795.219',
    Z: '-24136.219'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:38 AM',
    'Posicion.Camion': 'X=159959.922 Y=193557.078 Z=-24108.723',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.901961',
    Speed: '26.48361',
    'Refresh.Rate': '0.5',
    Time: '196.5',
    X: '159959.922',
    Y: '193557.078',
    Z: '-24108.723'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:38 AM',
    'Posicion.Camion': 'X=160184.312 Y=193274.672 Z=-24078.102',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.901961',
    Speed: '29.603329',
    'Refresh.Rate': '0.5',
    Time: '197',
    X: '160184.312',
    Y: '193274.672',
    Z: '-24078.102'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:39 AM',
    'Posicion.Camion': 'X=160413.609 Y=192959.688 Z=-24039.406',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.901961',
    Speed: '30.226488',
    'Refresh.Rate': '0.5',
    Time: '197.5',
    X: '160413.609',
    Y: '192959.688',
    Z: '-24039.406'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:39 AM',
    'Posicion.Camion': 'X=160649.453 Y=192646.375 Z=-23984.885',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.607843',
    Speed: '30.046953',
    'Refresh.Rate': '0.5',
    Time: '198',
    X: '160649.453',
    Y: '192646.375',
    Z: '-23984.885'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:40 AM',
    'Posicion.Camion': 'X=160876.844 Y=192342.641 Z=-23927.777',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.647059',
    Speed: '30.003744',
    'Refresh.Rate': '0.5',
    Time: '198.5',
    X: '160876.844',
    Y: '192342.641',
    Z: '-23927.777'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:40 AM',
    'Posicion.Camion': 'X=161091.781 Y=192028.406 Z=-23872.551',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.627451',
    Speed: '29.974003',
    'Refresh.Rate': '0.5',
    Time: '199',
    X: '161091.781',
    Y: '192028.406',
    Z: '-23872.551'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:41 AM',
    'Posicion.Camion': 'X=161300.688 Y=191708.297 Z=-23816.881',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.627451',
    Speed: '30.05472',
    'Refresh.Rate': '0.5',
    Time: '199.5',
    X: '161300.688',
    Y: '191708.297',
    Z: '-23816.881'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:42 AM',
    'Posicion.Camion': 'X=161511.984 Y=191389.141 Z=-23761.121',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.627451',
    Speed: '30.116175',
    'Refresh.Rate': '0.5',
    Time: '200',
    X: '161511.984',
    Y: '191389.141',
    Z: '-23761.121'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:42 AM',
    'Posicion.Camion': 'X=161717.359 Y=191065.484 Z=-23705.051',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.627451',
    Speed: '30.15593',
    'Refresh.Rate': '0.5',
    Time: '200.5',
    X: '161717.359',
    Y: '191065.484',
    Z: '-23705.051'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:43 AM',
    'Posicion.Camion': 'X=161916.250 Y=190737.141 Z=-23648.705',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.627451',
    Speed: '30.202353',
    'Refresh.Rate': '0.5',
    Time: '201',
    X: '161916.25',
    Y: '190737.141',
    Z: '-23648.705'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:43 AM',
    'Posicion.Camion': 'X=162114.344 Y=190407.578 Z=-23593.049',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.627451',
    Speed: '30.327219',
    'Refresh.Rate': '0.5',
    Time: '201.5',
    X: '162114.344',
    Y: '190407.578',
    Z: '-23593.049'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:44 AM',
    'Posicion.Camion': 'X=162310.672 Y=190076.297 Z=-23547.650',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.615686',
    Speed: '30.780142',
    'Refresh.Rate': '0.5',
    Time: '202',
    X: '162310.672',
    Y: '190076.297',
    Z: '-23547.65'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:44 AM',
    'Posicion.Camion': 'X=162507.656 Y=189730.875 Z=-23506.324',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.568627',
    Speed: '31.308945',
    'Refresh.Rate': '0.5',
    Time: '202.5',
    X: '162507.656',
    Y: '189730.875',
    Z: '-23506.324'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:45 AM',
    'Posicion.Camion': 'X=162701.953 Y=189376.641 Z=-23460.297',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.560784',
    Speed: '31.569962',
    'Refresh.Rate': '0.5',
    Time: '203',
    X: '162701.953',
    Y: '189376.641',
    Z: '-23460.297'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:45 AM',
    'Posicion.Camion': 'X=162876.906 Y=189045.609 Z=-23415.920',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.156863',
    Speed: '30.871277',
    'Refresh.Rate': '0.5',
    Time: '203.5',
    X: '162876.906',
    Y: '189045.609',
    Z: '-23415.92'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:46 AM',
    'Posicion.Camion': 'X=163042.984 Y=188725.594 Z=-23376.100',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '29.884218',
    'Refresh.Rate': '0.5',
    Time: '204',
    X: '163042.984',
    Y: '188725.594',
    Z: '-23376.1'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:46 AM',
    'Posicion.Camion': 'X=163198.453 Y=188422.234 Z=-23342.389',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.05098',
    Speed: '27.477152',
    'Refresh.Rate': '0.5',
    Time: '204.5',
    X: '163198.453',
    Y: '188422.234',
    Z: '-23342.389'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:47 AM',
    'Posicion.Camion': 'X=163342.453 Y=188128.094 Z=-23312.268',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.505882',
    Speed: '27.423656',
    'Refresh.Rate': '0.5',
    Time: '205',
    X: '163342.453',
    Y: '188128.094',
    Z: '-23312.268'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:47 AM',
    'Posicion.Camion': 'X=163483.344 Y=187828.906 Z=-23279.295',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.666667',
    Speed: '27.492592',
    'Refresh.Rate': '0.5',
    Time: '205.5',
    X: '163483.344',
    Y: '187828.906',
    Z: '-23279.295'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:48 AM',
    'Posicion.Camion': 'X=163623.641 Y=187520.859 Z=-23245.576',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.729412',
    Speed: '29.083321',
    'Refresh.Rate': '0.5',
    Time: '206',
    X: '163623.641',
    Y: '187520.859',
    Z: '-23245.576'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:48 AM',
    'Posicion.Camion': 'X=163762.875 Y=187201.891 Z=-23229.469',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.792157',
    Speed: '30.631601',
    'Refresh.Rate': '0.5',
    Time: '206.5',
    X: '163762.875',
    Y: '187201.891',
    Z: '-23229.469'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:49 AM',
    'Posicion.Camion': 'X=163912.375 Y=186845.500 Z=-23223.266',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.796078',
    Speed: '33.520962',
    'Refresh.Rate': '0.5',
    Time: '207',
    X: '163912.375',
    Y: '186845.5',
    Z: '-23223.266'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:49 AM',
    'Posicion.Camion': 'X=164070.047 Y=186456.469 Z=-23215.150',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.796078',
    Speed: '35.720947',
    'Refresh.Rate': '0.5',
    Time: '207.5',
    X: '164070.047',
    Y: '186456.469',
    Z: '-23215.15'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:50 AM',
    'Posicion.Camion': 'X=164237.828 Y=186041.500 Z=-23205.520',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.796078',
    Speed: '38.506721',
    'Refresh.Rate': '0.5',
    Time: '208',
    X: '164237.828',
    Y: '186041.5',
    Z: '-23205.52'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:50 AM',
    'Posicion.Camion': 'X=164417.953 Y=185599.125 Z=-23195.551',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.796078',
    Speed: '40.742264',
    'Refresh.Rate': '0.5',
    Time: '208.5',
    X: '164417.953',
    Y: '185599.125',
    Z: '-23195.551'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:51 AM',
    'Posicion.Camion': 'X=164606.594 Y=185127.625 Z=-23184.904',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.796078',
    Speed: '43.408535',
    'Refresh.Rate': '0.5',
    Time: '209',
    X: '164606.594',
    Y: '185127.625',
    Z: '-23184.904'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:51 AM',
    'Posicion.Camion': 'X=164799.391 Y=184626.453 Z=-23173.469',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.796078',
    Speed: '45.673935',
    'Refresh.Rate': '0.5',
    Time: '209.5',
    X: '164799.391',
    Y: '184626.453',
    Z: '-23173.469'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:52 AM',
    'Posicion.Camion': 'X=164997.938 Y=184099.625 Z=-23173.816',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.745098',
    Speed: '48.519493',
    'Refresh.Rate': '0.5',
    Time: '210',
    X: '164997.938',
    Y: '184099.625',
    Z: '-23173.816'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:52 AM',
    'Posicion.Camion': 'X=165214.219 Y=183544.156 Z=-23188.066',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.152941',
    Speed: '50.078983',
    'Refresh.Rate': '0.5',
    Time: '210.5',
    X: '165214.219',
    Y: '183544.156',
    Z: '-23188.066'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:53 AM',
    'Posicion.Camion': 'X=165437.656 Y=182984.219 Z=-23199.512',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '50.042274',
    'Refresh.Rate': '0.5',
    Time: '211',
    X: '165437.656',
    Y: '182984.219',
    Z: '-23199.512'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:54 AM',
    'Posicion.Camion': 'X=165654.953 Y=182422.812 Z=-23210.799',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '50.01696',
    'Refresh.Rate': '0.5',
    Time: '211.5',
    X: '165654.953',
    Y: '182422.812',
    Z: '-23210.799'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:54 AM',
    'Posicion.Camion': 'X=165861.531 Y=181857.938 Z=-23222.018',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.962921',
    'Refresh.Rate': '0.5',
    Time: '212',
    X: '165861.531',
    Y: '181857.938',
    Z: '-23222.018'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:55 AM',
    'Posicion.Camion': 'X=166067.625 Y=181293.438 Z=-23233.631',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.950577',
    'Refresh.Rate': '0.5',
    Time: '212.5',
    X: '166067.625',
    Y: '181293.438',
    Z: '-23233.631'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:55 AM',
    'Posicion.Camion': 'X=166285.375 Y=180733.703 Z=-23247.846',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.965942',
    'Refresh.Rate': '0.5',
    Time: '213',
    X: '166285.375',
    Y: '180733.703',
    Z: '-23247.846'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:56 AM',
    'Posicion.Camion': 'X=166513.734 Y=180176.969 Z=-23262.400',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '50.019897',
    'Refresh.Rate': '0.5',
    Time: '213.5',
    X: '166513.734',
    Y: '180176.969',
    Z: '-23262.4'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:56 AM',
    'Posicion.Camion': 'X=166742.734 Y=179620.016 Z=-23276.771',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '50.057781',
    'Refresh.Rate': '0.5',
    Time: '214',
    X: '166742.734',
    Y: '179620.016',
    Z: '-23276.771'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:57 AM',
    'Posicion.Camion': 'X=166966.422 Y=179060.406 Z=-23291.090',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '50.105495',
    'Refresh.Rate': '0.5',
    Time: '214.5',
    X: '166966.422',
    Y: '179060.406',
    Z: '-23291.09'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:57 AM',
    'Posicion.Camion': 'X=167185.016 Y=178498.266 Z=-23305.211',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '50.125782',
    'Refresh.Rate': '0.5',
    Time: '215',
    X: '167185.016',
    Y: '178498.266',
    Z: '-23305.211'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:58 AM',
    'Posicion.Camion': 'X=167404.203 Y=177934.875 Z=-23313.467',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '50.004929',
    'Refresh.Rate': '0.5',
    Time: '215.5',
    X: '167404.203',
    Y: '177934.875',
    Z: '-23313.467'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:58 AM',
    'Posicion.Camion': 'X=167624.047 Y=177375.781 Z=-23318.631',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.84745',
    'Refresh.Rate': '0.5',
    Time: '216',
    X: '167624.047',
    Y: '177375.781',
    Z: '-23318.631'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:59 AM',
    'Posicion.Camion': 'X=167842.875 Y=176818.531 Z=-23324.877',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.695782',
    'Refresh.Rate': '0.5',
    Time: '216.5',
    X: '167842.875',
    Y: '176818.531',
    Z: '-23324.877'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:42:59 AM',
    'Posicion.Camion': 'X=168058.047 Y=176261.469 Z=-23330.963',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.53022',
    'Refresh.Rate': '0.5',
    Time: '217',
    X: '168058.047',
    Y: '176261.469',
    Z: '-23330.963'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:00 AM',
    'Posicion.Camion': 'X=168269.047 Y=175704.938 Z=-23336.912',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.378162',
    'Refresh.Rate': '0.5',
    Time: '217.5',
    X: '168269.047',
    Y: '175704.938',
    Z: '-23336.912'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:00 AM',
    'Posicion.Camion': 'X=168478.109 Y=175150.094 Z=-23344.348',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.262383',
    'Refresh.Rate': '0.5',
    Time: '218',
    X: '168478.109',
    Y: '175150.094',
    Z: '-23344.348'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:01 AM',
    'Posicion.Camion': 'X=168689.047 Y=174596.641 Z=-23353.289',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.17551',
    'Refresh.Rate': '0.5',
    Time: '218.5',
    X: '168689.047',
    Y: '174596.641',
    Z: '-23353.289'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:01 AM',
    'Posicion.Camion': 'X=168902.469 Y=174045.109 Z=-23361.908',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.085247',
    'Refresh.Rate': '0.5',
    Time: '219',
    X: '168902.469',
    Y: '174045.109',
    Z: '-23361.908'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:02 AM',
    'Posicion.Camion': 'X=169115.125 Y=173494.547 Z=-23370.496',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.004353',
    'Refresh.Rate': '0.5',
    Time: '219.5',
    X: '169115.125',
    Y: '173494.547',
    Z: '-23370.496'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:02 AM',
    'Posicion.Camion': 'X=169323.953 Y=172943.641 Z=-23379.010',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '48.90686',
    'Refresh.Rate': '0.5',
    Time: '220',
    X: '169323.953',
    Y: '172943.641',
    Z: '-23379.01'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:03 AM',
    'Posicion.Camion': 'X=169527.375 Y=172393.297 Z=-23391.625',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '48.971626',
    'Refresh.Rate': '0.5',
    Time: '220.5',
    X: '169527.375',
    Y: '172393.297',
    Z: '-23391.625'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:03 AM',
    'Posicion.Camion': 'X=169726.594 Y=171839.203 Z=-23414.135',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.183441',
    'Refresh.Rate': '0.5',
    Time: '221',
    X: '169726.594',
    Y: '171839.203',
    Z: '-23414.135'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:04 AM',
    'Posicion.Camion': 'X=169922.812 Y=171278.609 Z=-23435.715',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.414795',
    'Refresh.Rate': '0.5',
    Time: '221.5',
    X: '169922.812',
    Y: '171278.609',
    Z: '-23435.715'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:04 AM',
    'Posicion.Camion': 'X=170114.953 Y=170714.641 Z=-23456.785',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.632118',
    'Refresh.Rate': '0.5',
    Time: '222',
    X: '170114.953',
    Y: '170714.641',
    Z: '-23456.785'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:05 AM',
    'Posicion.Camion': 'X=170303.172 Y=170146.609 Z=-23477.994',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.856354',
    'Refresh.Rate': '0.5',
    Time: '222.5',
    X: '170303.172',
    Y: '170146.609',
    Z: '-23477.994'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:05 AM',
    'Posicion.Camion': 'X=170473.078 Y=169619.516 Z=-23500.311',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '50.164921',
    'Refresh.Rate': '0.5',
    Time: '223',
    X: '170473.078',
    Y: '169619.516',
    Z: '-23500.311'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:06 AM',
    'Posicion.Camion': 'X=170652.688 Y=169045.172 Z=-23543.477',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '50.869114',
    'Refresh.Rate': '0.5',
    Time: '223.5',
    X: '170652.688',
    Y: '169045.172',
    Z: '-23543.477'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:06 AM',
    'Posicion.Camion': 'X=170831.484 Y=168454.875 Z=-23590.111',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '51.628616',
    'Refresh.Rate': '0.5',
    Time: '224',
    X: '170831.484',
    Y: '168454.875',
    Z: '-23590.111'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:07 AM',
    'Posicion.Camion': 'X=171007.484 Y=167855.125 Z=-23635.109',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '52.382191',
    'Refresh.Rate': '0.5',
    Time: '224.5',
    X: '171007.484',
    Y: '167855.125',
    Z: '-23635.109'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:08 AM',
    'Posicion.Camion': 'X=171180.531 Y=167245.766 Z=-23680.947',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '53.133362',
    'Refresh.Rate': '0.5',
    Time: '225',
    X: '171180.531',
    Y: '167245.766',
    Z: '-23680.947'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:08 AM',
    'Posicion.Camion': 'X=171349.188 Y=166627.578 Z=-23732.988',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '54.052891',
    'Refresh.Rate': '0.5',
    Time: '225.5',
    X: '171349.188',
    Y: '166627.578',
    Z: '-23732.988'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:09 AM',
    'Posicion.Camion': 'X=171514.500 Y=165995.641 Z=-23797.197',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '55.119633',
    'Refresh.Rate': '0.5',
    Time: '226',
    X: '171514.5',
    Y: '165995.641',
    Z: '-23797.197'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:09 AM',
    'Posicion.Camion': 'X=171677.406 Y=165347.672 Z=-23860.973',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '56.180401',
    'Refresh.Rate': '0.5',
    Time: '226.5',
    X: '171677.406',
    Y: '165347.672',
    Z: '-23860.973'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:10 AM',
    'Posicion.Camion': 'X=171837.062 Y=164686.719 Z=-23925.537',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '57.237896',
    'Refresh.Rate': '0.5',
    Time: '227',
    X: '171837.062',
    Y: '164686.719',
    Z: '-23925.537'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:10 AM',
    'Posicion.Camion': 'X=171993.031 Y=164012.188 Z=-23991.537',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '58.254642',
    'Refresh.Rate': '0.5',
    Time: '227.5',
    X: '171993.031',
    Y: '164012.188',
    Z: '-23991.537'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:11 AM',
    'Posicion.Camion': 'X=172143.688 Y=163322.750 Z=-24054.559',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '59.198826',
    'Refresh.Rate': '0.5',
    Time: '228',
    X: '172143.688',
    Y: '163322.75',
    Z: '-24054.559'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:11 AM',
    'Posicion.Camion': 'X=172289.453 Y=162622.047 Z=-24117.504',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '60.137497',
    'Refresh.Rate': '0.5',
    Time: '228.5',
    X: '172289.453',
    Y: '162622.047',
    Z: '-24117.504'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:12 AM',
    'Posicion.Camion': 'X=172430.375 Y=161908.906 Z=-24182.174',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '61.068672',
    'Refresh.Rate': '0.5',
    Time: '229',
    X: '172430.375',
    Y: '161908.906',
    Z: '-24182.174'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:12 AM',
    'Posicion.Camion': 'X=172566.156 Y=161182.859 Z=-24246.578',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '61.917252',
    'Refresh.Rate': '0.5',
    Time: '229.5',
    X: '172566.156',
    Y: '161182.859',
    Z: '-24246.578'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:13 AM',
    'Posicion.Camion': 'X=172696.109 Y=160444.219 Z=-24300.320',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '62.596321',
    'Refresh.Rate': '0.5',
    Time: '230',
    X: '172696.109',
    Y: '160444.219',
    Z: '-24300.32'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:13 AM',
    'Posicion.Camion': 'X=172819.156 Y=159699.078 Z=-24353.936',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '63.264698',
    'Refresh.Rate': '0.5',
    Time: '230.5',
    X: '172819.156',
    Y: '159699.078',
    Z: '-24353.936'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:14 AM',
    'Posicion.Camion': 'X=172939.188 Y=158956.797 Z=-24411.881',
    'Posicio.Freno': '0.435294',
    'Posicon.Acelerador': '0.286274',
    Speed: '58.794189',
    'Refresh.Rate': '0.5',
    Time: '231',
    X: '172939.188',
    Y: '158956.797',
    Z: '-24411.881'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:14 AM',
    'Posicion.Camion': 'X=173018.281 Y=158374.953 Z=-24443.057',
    'Posicio.Freno': '0.439216',
    'Posicon.Acelerador': '0.298039',
    Speed: '40.617977',
    'Refresh.Rate': '0.5',
    Time: '231.5',
    X: '173018.281',
    Y: '158374.953',
    Z: '-24443.057'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:15 AM',
    'Posicion.Camion': 'X=173089.500 Y=157997.719 Z=-24460.857',
    'Posicio.Freno': '0.454902',
    'Posicon.Acelerador': '0.305882',
    Speed: '23.201666',
    'Refresh.Rate': '0.5',
    Time: '232',
    X: '173089.5',
    Y: '157997.719',
    Z: '-24460.857'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:15 AM',
    'Posicion.Camion': 'X=173147.031 Y=157840.844 Z=-24467.102',
    'Posicio.Freno': '0.454902',
    'Posicon.Acelerador': '0.305882',
    Speed: '6.73332',
    'Refresh.Rate': '0.5',
    Time: '232.5',
    X: '173147.031',
    Y: '157840.844',
    Z: '-24467.102'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:16 AM',
    'Posicion.Camion': 'X=173167.625 Y=157817.297 Z=-24471.633',
    'Posicio.Freno': '0.368627',
    'Posicon.Acelerador': '0.266667',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '233',
    X: '173167.625',
    Y: '157817.297',
    Z: '-24471.633'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:16 AM',
    'Posicion.Camion': 'X=173167.000 Y=157815.812 Z=-24471.623',
    'Posicio.Freno': '0.345098',
    'Posicon.Acelerador': '0.25098',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '233.5',
    X: '173167',
    Y: '157815.812',
    Z: '-24471.623'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:17 AM',
    'Posicion.Camion': 'X=173168.328 Y=157815.891 Z=-24471.971',
    'Posicio.Freno': '0.333333',
    'Posicon.Acelerador': '0.247059',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '234',
    X: '173168.328',
    Y: '157815.891',
    Z: '-24471.971'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:17 AM',
    'Posicion.Camion': 'X=173168.453 Y=157815.938 Z=-24471.848',
    'Posicio.Freno': '0.321569',
    'Posicon.Acelerador': '0.239216',
    Speed: '0.000607',
    'Refresh.Rate': '0.5',
    Time: '234.5',
    X: '173168.453',
    Y: '157815.938',
    Z: '-24471.848'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:18 AM',
    'Posicion.Camion': 'X=173168.500 Y=157815.984 Z=-24471.756',
    'Posicio.Freno': '0.188235',
    'Posicon.Acelerador': '0.133333',
    Speed: '0.00118',
    'Refresh.Rate': '0.5',
    Time: '235',
    X: '173168.5',
    Y: '157815.984',
    Z: '-24471.756'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:18 AM',
    'Posicion.Camion': 'X=173168.516 Y=157816.016 Z=-24471.703',
    'Posicio.Freno': '0.160784',
    'Posicon.Acelerador': '0.098039',
    Speed: '0.000911',
    'Refresh.Rate': '0.5',
    Time: '235.5',
    X: '173168.516',
    Y: '157816.016',
    Z: '-24471.703'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:19 AM',
    'Posicion.Camion': 'X=173168.531 Y=157816.047 Z=-24471.668',
    'Posicio.Freno': '0.117647',
    'Posicon.Acelerador': '0.078431',
    Speed: '0.00068',
    'Refresh.Rate': '0.5',
    Time: '236',
    X: '173168.531',
    Y: '157816.047',
    Z: '-24471.668'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:19 AM',
    'Posicion.Camion': 'X=173168.547 Y=157816.062 Z=-24471.643',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000243',
    'Refresh.Rate': '0.5',
    Time: '236.5',
    X: '173168.547',
    Y: '157816.062',
    Z: '-24471.643'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:20 AM',
    'Posicion.Camion': 'X=173168.547 Y=157816.078 Z=-24471.623',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0.000114',
    'Refresh.Rate': '0.5',
    Time: '237',
    X: '173168.547',
    Y: '157816.078',
    Z: '-24471.623'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:20 AM',
    'Posicion.Camion': 'X=173168.547 Y=157816.094 Z=-24471.613',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '237.5',
    X: '173168.547',
    Y: '157816.094',
    Z: '-24471.613'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:21 AM',
    'Posicion.Camion': 'X=173168.547 Y=157816.094 Z=-24471.607',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '238',
    X: '173168.547',
    Y: '157816.094',
    Z: '-24471.607'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:21 AM',
    'Posicion.Camion': 'X=173168.547 Y=157816.094 Z=-24471.604',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '238.5',
    X: '173168.547',
    Y: '157816.094',
    Z: '-24471.604'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:22 AM',
    'Posicion.Camion': 'X=173168.547 Y=157816.094 Z=-24471.602',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '239',
    X: '173168.547',
    Y: '157816.094',
    Z: '-24471.602'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:22 AM',
    'Posicion.Camion': 'X=173168.547 Y=157816.094 Z=-24471.600',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '239.5',
    X: '173168.547',
    Y: '157816.094',
    Z: '-24471.6'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:23 AM',
    'Posicion.Camion': 'X=173168.547 Y=157816.094 Z=-24471.596',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '240',
    X: '173168.547',
    Y: '157816.094',
    Z: '-24471.596'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:23 AM',
    'Posicion.Camion': 'X=173168.547 Y=157816.094 Z=-24471.596',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '240.5',
    X: '173168.547',
    Y: '157816.094',
    Z: '-24471.596'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:24 AM',
    'Posicion.Camion': 'X=173168.547 Y=157816.094 Z=-24471.596',
    'Posicio.Freno': '0.223529',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '241',
    X: '173168.547',
    Y: '157816.094',
    Z: '-24471.596'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:24 AM',
    'Posicion.Camion': 'X=173168.547 Y=157816.094 Z=-24471.596',
    'Posicio.Freno': '0.223529',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '241.5',
    X: '173168.547',
    Y: '157816.094',
    Z: '-24471.596'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:25 AM',
    'Posicion.Camion': 'X=173168.547 Y=157816.094 Z=-24471.596',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '242',
    X: '173168.547',
    Y: '157816.094',
    Z: '-24471.596'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:25 AM',
    'Posicion.Camion': 'X=173168.531 Y=157816.094 Z=-24471.596',
    'Posicio.Freno': '0.2',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '242.5',
    X: '173168.531',
    Y: '157816.094',
    Z: '-24471.596'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:26 AM',
    'Posicion.Camion': 'X=173168.531 Y=157816.094 Z=-24471.594',
    'Posicio.Freno': '0.196078',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '243',
    X: '173168.531',
    Y: '157816.094',
    Z: '-24471.594'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:26 AM',
    'Posicion.Camion': 'X=173168.531 Y=157816.094 Z=-24471.592',
    'Posicio.Freno': '0.196078',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '243.5',
    X: '173168.531',
    Y: '157816.094',
    Z: '-24471.592'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:27 AM',
    'Posicion.Camion': 'X=173168.531 Y=157816.094 Z=-24471.592',
    'Posicio.Freno': '0.196078',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '244',
    X: '173168.531',
    Y: '157816.094',
    Z: '-24471.592'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:27 AM',
    'Posicion.Camion': 'X=173168.531 Y=157816.094 Z=-24471.592',
    'Posicio.Freno': '0.196078',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '244.5',
    X: '173168.531',
    Y: '157816.094',
    Z: '-24471.592'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:28 AM',
    'Posicion.Camion': 'X=173168.531 Y=157816.094 Z=-24471.592',
    'Posicio.Freno': '0.196078',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '245',
    X: '173168.531',
    Y: '157816.094',
    Z: '-24471.592'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:28 AM',
    'Posicion.Camion': 'X=173168.531 Y=157816.094 Z=-24471.590',
    'Posicio.Freno': '0.14902',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '245.5',
    X: '173168.531',
    Y: '157816.094',
    Z: '-24471.59'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:29 AM',
    'Posicion.Camion': 'X=173168.531 Y=157816.094 Z=-24471.590',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '246',
    X: '173168.531',
    Y: '157816.094',
    Z: '-24471.59'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:29 AM',
    'Posicion.Camion': 'X=173168.531 Y=157816.094 Z=-24471.592',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '246.5',
    X: '173168.531',
    Y: '157816.094',
    Z: '-24471.592'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:30 AM',
    'Posicion.Camion': 'X=173170.781 Y=157813.000 Z=-24472.303',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.258824',
    Speed: '0.812663',
    'Refresh.Rate': '0.5',
    Time: '247',
    X: '173170.781',
    Y: '157813',
    Z: '-24472.303'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:30 AM',
    'Posicion.Camion': 'X=173180.484 Y=157791.750 Z=-24474.096',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.388235',
    Speed: '2.962321',
    'Refresh.Rate': '0.5',
    Time: '247.5',
    X: '173180.484',
    Y: '157791.75',
    Z: '-24474.096'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:31 AM',
    'Posicion.Camion': 'X=173202.438 Y=157741.250 Z=-24479.381',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.494118',
    Speed: '5.99951',
    'Refresh.Rate': '0.5',
    Time: '248',
    X: '173202.438',
    Y: '157741.25',
    Z: '-24479.381'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:31 AM',
    'Posicion.Camion': 'X=173245.969 Y=157658.938 Z=-24488.119',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.494118',
    Speed: '9.198184',
    'Refresh.Rate': '0.5',
    Time: '248.5',
    X: '173245.969',
    Y: '157658.938',
    Z: '-24488.119'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:32 AM',
    'Posicion.Camion': 'X=173316.656 Y=157549.656 Z=-24496.500',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.494118',
    Speed: '12.128989',
    'Refresh.Rate': '0.5',
    Time: '249',
    X: '173316.656',
    Y: '157549.656',
    Z: '-24496.5'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:32 AM',
    'Posicion.Camion': 'X=173410.000 Y=157407.156 Z=-24512.545',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.494118',
    Speed: '15.67097',
    'Refresh.Rate': '0.5',
    Time: '249.5',
    X: '173410',
    Y: '157407.156',
    Z: '-24512.545'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:33 AM',
    'Posicion.Camion': 'X=173520.047 Y=157229.578 Z=-24527.963',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.466667',
    Speed: '18.513071',
    'Refresh.Rate': '0.5',
    Time: '250',
    X: '173520.047',
    Y: '157229.578',
    Z: '-24527.963'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:34 AM',
    'Posicion.Camion': 'X=173652.312 Y=157022.531 Z=-24540.875',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.466667',
    Speed: '21.755066',
    'Refresh.Rate': '0.5',
    Time: '250.5',
    X: '173652.312',
    Y: '157022.531',
    Z: '-24540.875'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:34 AM',
    'Posicion.Camion': 'X=173806.234 Y=156789.250 Z=-24557.383',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.54902',
    Speed: '24.423172',
    'Refresh.Rate': '0.5',
    Time: '251',
    X: '173806.234',
    Y: '156789.25',
    Z: '-24557.383'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:35 AM',
    'Posicion.Camion': 'X=174001.531 Y=156509.469 Z=-24576.576',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.552941',
    Speed: '27.933475',
    'Refresh.Rate': '0.5',
    Time: '251.5',
    X: '174001.531',
    Y: '156509.469',
    Z: '-24576.576'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:35 AM',
    'Posicion.Camion': 'X=174231.781 Y=156198.547 Z=-24593.729',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.552941',
    Speed: '30.367893',
    'Refresh.Rate': '0.5',
    Time: '252',
    X: '174231.781',
    Y: '156198.547',
    Z: '-24593.729'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:36 AM',
    'Posicion.Camion': 'X=174474.219 Y=155886.234 Z=-24610.984',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.552941',
    Speed: '30.998459',
    'Refresh.Rate': '0.5',
    Time: '252.5',
    X: '174474.219',
    Y: '155886.234',
    Z: '-24610.984'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:36 AM',
    'Posicion.Camion': 'X=174706.625 Y=155589.281 Z=-24630.580',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.552941',
    Speed: '31.671633',
    'Refresh.Rate': '0.5',
    Time: '253',
    X: '174706.625',
    Y: '155589.281',
    Z: '-24630.58'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:37 AM',
    'Posicion.Camion': 'X=174937.281 Y=155282.359 Z=-24650.123',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.529412',
    Speed: '32.075703',
    'Refresh.Rate': '0.5',
    Time: '253.5',
    X: '174937.281',
    Y: '155282.359',
    Z: '-24650.123'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:37 AM',
    'Posicion.Camion': 'X=175160.250 Y=154962.016 Z=-24668.910',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.529412',
    Speed: '32.695492',
    'Refresh.Rate': '0.5',
    Time: '254',
    X: '175160.25',
    Y: '154962.016',
    Z: '-24668.91'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:38 AM',
    'Posicion.Camion': 'X=175380.391 Y=154633.172 Z=-24687.766',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.529412',
    Speed: '33.12727',
    'Refresh.Rate': '0.5',
    Time: '254.5',
    X: '175380.391',
    Y: '154633.172',
    Z: '-24687.766'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:38 AM',
    'Posicion.Camion': 'X=175618.922 Y=154310.750 Z=-24706.391',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.529412',
    Speed: '33.57745',
    'Refresh.Rate': '0.5',
    Time: '255',
    X: '175618.922',
    Y: '154310.75',
    Z: '-24706.391'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:39 AM',
    'Posicion.Camion': 'X=175895.562 Y=153972.109 Z=-24726.014',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.529412',
    Speed: '34.069946',
    'Refresh.Rate': '0.5',
    Time: '255.5',
    X: '175895.562',
    Y: '153972.109',
    Z: '-24726.014'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:39 AM',
    'Posicion.Camion': 'X=176165.516 Y=153620.359 Z=-24746.666',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.529412',
    Speed: '34.521141',
    'Refresh.Rate': '0.5',
    Time: '256',
    X: '176165.516',
    Y: '153620.359',
    Z: '-24746.666'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:40 AM',
    'Posicion.Camion': 'X=176409.109 Y=153281.547 Z=-24768.262',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.529412',
    Speed: '34.999542',
    'Refresh.Rate': '0.5',
    Time: '256.5',
    X: '176409.109',
    Y: '153281.547',
    Z: '-24768.262'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:40 AM',
    'Posicion.Camion': 'X=176661.844 Y=152940.109 Z=-24791.051',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.529412',
    Speed: '35.680347',
    'Refresh.Rate': '0.5',
    Time: '257',
    X: '176661.844',
    Y: '152940.109',
    Z: '-24791.051'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:41 AM',
    'Posicion.Camion': 'X=176916.906 Y=152591.812 Z=-24815.861',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.529412',
    Speed: '36.25288',
    'Refresh.Rate': '0.5',
    Time: '257.5',
    X: '176916.906',
    Y: '152591.812',
    Z: '-24815.861'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:41 AM',
    'Posicion.Camion': 'X=177165.234 Y=152229.094 Z=-24843.846',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.529412',
    Speed: '37.034462',
    'Refresh.Rate': '0.5',
    Time: '258',
    X: '177165.234',
    Y: '152229.094',
    Z: '-24843.846'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:42 AM',
    'Posicion.Camion': 'X=177409.578 Y=151852.250 Z=-24872.881',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.529412',
    Speed: '37.68351',
    'Refresh.Rate': '0.5',
    Time: '258.5',
    X: '177409.578',
    Y: '151852.25',
    Z: '-24872.881'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:42 AM',
    'Posicion.Camion': 'X=177657.141 Y=151466.906 Z=-24902.197',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.580392',
    Speed: '38.496445',
    'Refresh.Rate': '0.5',
    Time: '259',
    X: '177657.141',
    Y: '151466.906',
    Z: '-24902.197'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:43 AM',
    'Posicion.Camion': 'X=177910.203 Y=151074.953 Z=-24932.105',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.611765',
    Speed: '39.140419',
    'Refresh.Rate': '0.5',
    Time: '259.5',
    X: '177910.203',
    Y: '151074.953',
    Z: '-24932.105'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:43 AM',
    'Posicion.Camion': 'X=178167.203 Y=150672.453 Z=-24962.732',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.611765',
    Speed: '40.826809',
    'Refresh.Rate': '0.5',
    Time: '260',
    X: '178167.203',
    Y: '150672.453',
    Z: '-24962.732'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:44 AM',
    'Posicion.Camion': 'X=178437.641 Y=150240.312 Z=-24994.838',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.611765',
    Speed: '43.66835',
    'Refresh.Rate': '0.5',
    Time: '260.5',
    X: '178437.641',
    Y: '150240.312',
    Z: '-24994.838'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:44 AM',
    'Posicion.Camion': 'X=178723.906 Y=149777.672 Z=-25030.809',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.611765',
    Speed: '46.734898',
    'Refresh.Rate': '0.5',
    Time: '261',
    X: '178723.906',
    Y: '149777.672',
    Z: '-25030.809'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:45 AM',
    'Posicion.Camion': 'X=179026.953 Y=149286.922 Z=-25069.586',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.611765',
    Speed: '49.170761',
    'Refresh.Rate': '0.5',
    Time: '261.5',
    X: '179026.953',
    Y: '149286.922',
    Z: '-25069.586'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:45 AM',
    'Posicion.Camion': 'X=179348.469 Y=148771.031 Z=-25110.281',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.611765',
    Speed: '51.803089',
    'Refresh.Rate': '0.5',
    Time: '262',
    X: '179348.469',
    Y: '148771.031',
    Z: '-25110.281'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:46 AM',
    'Posicion.Camion': 'X=179717.578 Y=148189.891 Z=-25156.512',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.611765',
    Speed: '54.509399',
    'Refresh.Rate': '0.5',
    Time: '262.5',
    X: '179717.578',
    Y: '148189.891',
    Z: '-25156.512'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:46 AM',
    'Posicion.Camion': 'X=180108.719 Y=147578.391 Z=-25194.217',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.611765',
    Speed: '56.909191',
    'Refresh.Rate': '0.5',
    Time: '263',
    X: '180108.719',
    Y: '147578.391',
    Z: '-25194.217'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:47 AM',
    'Posicion.Camion': 'X=180481.062 Y=146987.766 Z=-25222.053',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.611765',
    Speed: '59.043545',
    'Refresh.Rate': '0.5',
    Time: '263.5',
    X: '180481.062',
    Y: '146987.766',
    Z: '-25222.053'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:47 AM',
    'Posicion.Camion': 'X=180902.812 Y=146331.844 Z=-25256.078',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.611765',
    Speed: '61.311153',
    'Refresh.Rate': '0.5',
    Time: '264',
    X: '180902.812',
    Y: '146331.844',
    Z: '-25256.078'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:48 AM',
    'Posicion.Camion': 'X=181357.031 Y=145661.281 Z=-25291.371',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.611765',
    Speed: '63.525715',
    'Refresh.Rate': '0.5',
    Time: '264.5',
    X: '181357.031',
    Y: '145661.281',
    Z: '-25291.371'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:48 AM',
    'Posicion.Camion': 'X=181830.641 Y=144965.609 Z=-25292.785',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.611765',
    Speed: '65.007149',
    'Refresh.Rate': '0.5',
    Time: '265',
    X: '181830.641',
    Y: '144965.609',
    Z: '-25292.785'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:49 AM',
    'Posicion.Camion': 'X=182315.875 Y=144266.531 Z=-25282.814',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.592157',
    Speed: '66.409645',
    'Refresh.Rate': '0.5',
    Time: '265.5',
    X: '182315.875',
    Y: '144266.531',
    Z: '-25282.814'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:50 AM',
    'Posicion.Camion': 'X=182832.406 Y=143567.172 Z=-25278.291',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.592157',
    Speed: '67.755051',
    'Refresh.Rate': '0.5',
    Time: '266',
    X: '182832.406',
    Y: '143567.172',
    Z: '-25278.291'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:50 AM',
    'Posicion.Camion': 'X=183369.484 Y=142855.969 Z=-25243.135',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.588235',
    Speed: '68.108589',
    'Refresh.Rate': '0.5',
    Time: '266.5',
    X: '183369.484',
    Y: '142855.969',
    Z: '-25243.135'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:51 AM',
    'Posicion.Camion': 'X=183897.844 Y=142151.438 Z=-25151.375',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.376471',
    Speed: '68.0009',
    'Refresh.Rate': '0.5',
    Time: '267',
    X: '183897.844',
    Y: '142151.438',
    Z: '-25151.375'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:51 AM',
    'Posicion.Camion': 'X=184430.500 Y=141466.453 Z=-25077.184',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.062745',
    Speed: '66.813507',
    'Refresh.Rate': '0.5',
    Time: '267.5',
    X: '184430.5',
    Y: '141466.453',
    Z: '-25077.184'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:52 AM',
    'Posicion.Camion': 'X=184979.625 Y=140815.516 Z=-25000.865',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.019608',
    Speed: '64.778198',
    'Refresh.Rate': '0.5',
    Time: '268',
    X: '184979.625',
    Y: '140815.516',
    Z: '-25000.865'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:52 AM',
    'Posicion.Camion': 'X=185516.734 Y=140195.219 Z=-24880.686',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.019608',
    Speed: '62.123333',
    'Refresh.Rate': '0.5',
    Time: '268.5',
    X: '185516.734',
    Y: '140195.219',
    Z: '-24880.686'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:53 AM',
    'Posicion.Camion': 'X=186016.109 Y=139601.734 Z=-24765.844',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.019608',
    Speed: '59.443233',
    'Refresh.Rate': '0.5',
    Time: '269',
    X: '186016.109',
    Y: '139601.734',
    Z: '-24765.844'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:53 AM',
    'Posicion.Camion': 'X=186493.500 Y=139030.625 Z=-24658.447',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.062745',
    Speed: '56.799759',
    'Refresh.Rate': '0.5',
    Time: '269.5',
    X: '186493.5',
    Y: '139030.625',
    Z: '-24658.447'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:54 AM',
    'Posicion.Camion': 'X=186950.172 Y=138484.406 Z=-24554.664',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.070588',
    Speed: '54.304653',
    'Refresh.Rate': '0.5',
    Time: '270',
    X: '186950.172',
    Y: '138484.406',
    Z: '-24554.664'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:54 AM',
    'Posicion.Camion': 'X=187380.891 Y=137959.156 Z=-24451.359',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.070588',
    Speed: '51.758663',
    'Refresh.Rate': '0.5',
    Time: '270.5',
    X: '187380.891',
    Y: '137959.156',
    Z: '-24451.359'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:55 AM',
    'Posicion.Camion': 'X=187789.766 Y=137459.531 Z=-24352.859',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.07451',
    Speed: '49.235241',
    'Refresh.Rate': '0.5',
    Time: '271',
    X: '187789.766',
    Y: '137459.531',
    Z: '-24352.859'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:55 AM',
    'Posicion.Camion': 'X=188179.109 Y=136984.938 Z=-24260.135',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.094118',
    Speed: '46.783615',
    'Refresh.Rate': '0.5',
    Time: '271.5',
    X: '188179.109',
    Y: '136984.938',
    Z: '-24260.135'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:56 AM',
    'Posicion.Camion': 'X=188541.719 Y=136527.953 Z=-24172.377',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.231372',
    Speed: '44.602905',
    'Refresh.Rate': '0.5',
    Time: '272',
    X: '188541.719',
    Y: '136527.953',
    Z: '-24172.377'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:56 AM',
    'Posicion.Camion': 'X=188878.234 Y=136084.812 Z=-24096.490',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.360784',
    Speed: '43.132271',
    'Refresh.Rate': '0.5',
    Time: '272.5',
    X: '188878.234',
    Y: '136084.812',
    Z: '-24096.49'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:57 AM',
    'Posicion.Camion': 'X=189204.234 Y=135646.203 Z=-24038.713',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.47451',
    Speed: '42.426029',
    'Refresh.Rate': '0.5',
    Time: '273',
    X: '189204.234',
    Y: '135646.203',
    Z: '-24038.713'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:57 AM',
    'Posicion.Camion': 'X=189523.266 Y=135205.266 Z=-23979.518',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.596078',
    Speed: '42.136436',
    'Refresh.Rate': '0.5',
    Time: '273.5',
    X: '189523.266',
    Y: '135205.266',
    Z: '-23979.518'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:58 AM',
    'Posicion.Camion': 'X=189831.500 Y=134760.500 Z=-23920.342',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.654902',
    Speed: '42.155102',
    'Refresh.Rate': '0.5',
    Time: '274',
    X: '189831.5',
    Y: '134760.5',
    Z: '-23920.342'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:58 AM',
    'Posicion.Camion': 'X=190131.953 Y=134308.469 Z=-23861.857',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.709804',
    Speed: '42.345192',
    'Refresh.Rate': '0.5',
    Time: '274.5',
    X: '190131.953',
    Y: '134308.469',
    Z: '-23861.857'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:59 AM',
    'Posicion.Camion': 'X=190426.656 Y=133847.594 Z=-23803.236',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.733333',
    Speed: '42.759861',
    'Refresh.Rate': '0.5',
    Time: '275',
    X: '190426.656',
    Y: '133847.594',
    Z: '-23803.236'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:43:59 AM',
    'Posicion.Camion': 'X=190715.031 Y=133378.219 Z=-23760.660',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.733333',
    Speed: '43.668083',
    'Refresh.Rate': '0.5',
    Time: '275.5',
    X: '190715.031',
    Y: '133378.219',
    Z: '-23760.66'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:00 AM',
    'Posicion.Camion': 'X=191005.047 Y=132885.641 Z=-23727.727',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.733333',
    Speed: '44.755421',
    'Refresh.Rate': '0.5',
    Time: '276',
    X: '191005.047',
    Y: '132885.641',
    Z: '-23727.727'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:00 AM',
    'Posicion.Camion': 'X=191292.938 Y=132374.719 Z=-23690.586',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.709804',
    Speed: '45.823753',
    'Refresh.Rate': '0.5',
    Time: '276.5',
    X: '191292.938',
    Y: '132374.719',
    Z: '-23690.586'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:01 AM',
    'Posicion.Camion': 'X=191577.250 Y=131848.781 Z=-23652.602',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.490196',
    Speed: '46.660431',
    'Refresh.Rate': '0.5',
    Time: '277',
    X: '191577.25',
    Y: '131848.781',
    Z: '-23652.602'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:01 AM',
    'Posicion.Camion': 'X=191858.422 Y=131315.125 Z=-23614.172',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.337255',
    Speed: '46.712013',
    'Refresh.Rate': '0.5',
    Time: '277.5',
    X: '191858.422',
    Y: '131315.125',
    Z: '-23614.172'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:02 AM',
    'Posicion.Camion': 'X=192135.047 Y=130782.055 Z=-23576.475',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.109804',
    Speed: '46.301556',
    'Refresh.Rate': '0.5',
    Time: '278',
    X: '192135.047',
    Y: '130782.055',
    Z: '-23576.475'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:02 AM',
    'Posicion.Camion': 'X=192399.141 Y=130254.594 Z=-23550.461',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.098039',
    Speed: '45.655064',
    'Refresh.Rate': '0.5',
    Time: '278.5',
    X: '192399.141',
    Y: '130254.594',
    Z: '-23550.461'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:03 AM',
    'Posicion.Camion': 'X=192651.672 Y=129726.172 Z=-23526.871',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.098039',
    Speed: '45.003319',
    'Refresh.Rate': '0.5',
    Time: '279',
    X: '192651.672',
    Y: '129726.172',
    Z: '-23526.871'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:04 AM',
    'Posicion.Camion': 'X=192895.828 Y=129203.984 Z=-23502.281',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.098039',
    Speed: '44.364563',
    'Refresh.Rate': '0.5',
    Time: '279.5',
    X: '192895.828',
    Y: '129203.984',
    Z: '-23502.281'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:04 AM',
    'Posicion.Camion': 'X=193117.703 Y=128725.688 Z=-23479.943',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.098039',
    Speed: '43.413471',
    'Refresh.Rate': '0.5',
    Time: '280',
    X: '193117.703',
    Y: '128725.688',
    Z: '-23479.943'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:05 AM',
    'Posicion.Camion': 'X=193348.391 Y=128215.383 Z=-23456.100',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.082353',
    Speed: '42.7444',
    'Refresh.Rate': '0.5',
    Time: '280.5',
    X: '193348.391',
    Y: '128215.383',
    Z: '-23456.1'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:05 AM',
    'Posicion.Camion': 'X=193568.250 Y=127711.742 Z=-23437.529',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.047059',
    Speed: '42.17271',
    'Refresh.Rate': '0.5',
    Time: '281',
    X: '193568.25',
    Y: '127711.742',
    Z: '-23437.529'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:06 AM',
    'Posicion.Camion': 'X=193768.172 Y=127247.727 Z=-23433.602',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.015686',
    Speed: '42.267937',
    'Refresh.Rate': '0.5',
    Time: '281.5',
    X: '193768.172',
    Y: '127247.727',
    Z: '-23433.602'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:06 AM',
    'Posicion.Camion': 'X=193982.938 Y=126748.125 Z=-23429.387',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '41.877148',
    'Refresh.Rate': '0.5',
    Time: '282',
    X: '193982.938',
    Y: '126748.125',
    Z: '-23429.387'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:07 AM',
    'Posicion.Camion': 'X=194176.484 Y=126288.688 Z=-23424.561',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '41.038754',
    'Refresh.Rate': '0.5',
    Time: '282.5',
    X: '194176.484',
    Y: '126288.688',
    Z: '-23424.561'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:07 AM',
    'Posicion.Camion': 'X=194363.641 Y=125831.328 Z=-23419.881',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '41.098557',
    'Refresh.Rate': '0.5',
    Time: '283',
    X: '194363.641',
    Y: '125831.328',
    Z: '-23419.881'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:08 AM',
    'Posicion.Camion': 'X=194547.016 Y=125377.836 Z=-23415.188',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '40.238506',
    'Refresh.Rate': '0.5',
    Time: '283.5',
    X: '194547.016',
    Y: '125377.836',
    Z: '-23415.188'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:08 AM',
    'Posicion.Camion': 'X=194730.562 Y=124929.148 Z=-23411.131',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '40.368542',
    'Refresh.Rate': '0.5',
    Time: '284',
    X: '194730.562',
    Y: '124929.148',
    Z: '-23411.131'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:09 AM',
    'Posicion.Camion': 'X=194912.844 Y=124488.539 Z=-23422.486',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '40.016689',
    'Refresh.Rate': '0.5',
    Time: '284.5',
    X: '194912.844',
    Y: '124488.539',
    Z: '-23422.486'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:09 AM',
    'Posicion.Camion': 'X=195096.844 Y=124038.445 Z=-23442.545',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '40.767498',
    'Refresh.Rate': '0.5',
    Time: '285',
    X: '195096.844',
    Y: '124038.445',
    Z: '-23442.545'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:10 AM',
    'Posicion.Camion': 'X=195292.547 Y=123547.734 Z=-23461.584',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '41.083813',
    'Refresh.Rate': '0.5',
    Time: '285.5',
    X: '195292.547',
    Y: '123547.734',
    Z: '-23461.584'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:10 AM',
    'Posicion.Camion': 'X=195488.875 Y=123054.359 Z=-23480.699',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '41.392914',
    'Refresh.Rate': '0.5',
    Time: '286',
    X: '195488.875',
    Y: '123054.359',
    Z: '-23480.699'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:11 AM',
    'Posicion.Camion': 'X=195689.906 Y=122558.102 Z=-23500.150',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '41.702282',
    'Refresh.Rate': '0.5',
    Time: '286.5',
    X: '195689.906',
    Y: '122558.102',
    Z: '-23500.15'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:11 AM',
    'Posicion.Camion': 'X=195877.109 Y=122093.812 Z=-23518.295',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '41.546349',
    'Refresh.Rate': '0.5',
    Time: '287',
    X: '195877.109',
    Y: '122093.812',
    Z: '-23518.295'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:12 AM',
    'Posicion.Camion': 'X=196059.969 Y=121628.891 Z=-23548.510',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '42.65604',
    'Refresh.Rate': '0.5',
    Time: '287.5',
    X: '196059.969',
    Y: '121628.891',
    Z: '-23548.51'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:12 AM',
    'Posicion.Camion': 'X=196246.297 Y=121154.141 Z=-23606.924',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '43.562874',
    'Refresh.Rate': '0.5',
    Time: '288',
    X: '196246.297',
    Y: '121154.141',
    Z: '-23606.924'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:13 AM',
    'Posicion.Camion': 'X=196441.734 Y=120654.688 Z=-23663.121',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '45.164295',
    'Refresh.Rate': '0.5',
    Time: '288.5',
    X: '196441.734',
    Y: '120654.688',
    Z: '-23663.121'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:13 AM',
    'Posicion.Camion': 'X=196636.562 Y=120146.117 Z=-23721.854',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '46.207664',
    'Refresh.Rate': '0.5',
    Time: '289',
    X: '196636.562',
    Y: '120146.117',
    Z: '-23721.854'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:14 AM',
    'Posicion.Camion': 'X=196834.016 Y=119620.039 Z=-23791.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '47.974915',
    'Refresh.Rate': '0.5',
    Time: '289.5',
    X: '196834.016',
    Y: '119620.039',
    Z: '-23791.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:14 AM',
    'Posicion.Camion': 'X=197037.109 Y=119072.984 Z=-23857.748',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '49.116016',
    'Refresh.Rate': '0.5',
    Time: '290',
    X: '197037.109',
    Y: '119072.984',
    Z: '-23857.748'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:15 AM',
    'Posicion.Camion': 'X=197239.203 Y=118518.125 Z=-23939.314',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '51.108078',
    'Refresh.Rate': '0.5',
    Time: '290.5',
    X: '197239.203',
    Y: '118518.125',
    Z: '-23939.314'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:15 AM',
    'Posicion.Camion': 'X=197444.375 Y=117937.461 Z=-24050.613',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '53.156906',
    'Refresh.Rate': '0.5',
    Time: '291',
    X: '197444.375',
    Y: '117937.461',
    Z: '-24050.613'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:16 AM',
    'Posicion.Camion': 'X=197655.797 Y=117325.531 Z=-24162.549',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '55.433418',
    'Refresh.Rate': '0.5',
    Time: '291.5',
    X: '197655.797',
    Y: '117325.531',
    Z: '-24162.549'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:16 AM',
    'Posicion.Camion': 'X=197873.938 Y=116691.055 Z=-24277.928',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '57.585571',
    'Refresh.Rate': '0.5',
    Time: '292',
    X: '197873.938',
    Y: '116691.055',
    Z: '-24277.928'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:17 AM',
    'Posicion.Camion': 'X=198102.219 Y=116032.719 Z=-24398.160',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '59.756889',
    'Refresh.Rate': '0.5',
    Time: '292.5',
    X: '198102.219',
    Y: '116032.719',
    Z: '-24398.16'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:17 AM',
    'Posicion.Camion': 'X=198340.375 Y=115350.914 Z=-24523.955',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '61.975243',
    'Refresh.Rate': '0.5',
    Time: '293',
    X: '198340.375',
    Y: '115350.914',
    Z: '-24523.955'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:18 AM',
    'Posicion.Camion': 'X=198578.766 Y=114641.898 Z=-24658.281',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '64.174385',
    'Refresh.Rate': '0.5',
    Time: '293.5',
    X: '198578.766',
    Y: '114641.898',
    Z: '-24658.281'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:18 AM',
    'Posicion.Camion': 'X=198808.922 Y=113902.328 Z=-24796.070',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '66.344795',
    'Refresh.Rate': '0.5',
    Time: '294',
    X: '198808.922',
    Y: '113902.328',
    Z: '-24796.07'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:19 AM',
    'Posicion.Camion': 'X=199033.562 Y=113134.469 Z=-24938.018',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '68.506073',
    'Refresh.Rate': '0.5',
    Time: '294.5',
    X: '199033.562',
    Y: '113134.469',
    Z: '-24938.018'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:19 AM',
    'Posicion.Camion': 'X=199274.125 Y=112337.453 Z=-25059.920',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '70.024643',
    'Refresh.Rate': '0.5',
    Time: '295',
    X: '199274.125',
    Y: '112337.453',
    Z: '-25059.92'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:20 AM',
    'Posicion.Camion': 'X=199526.000 Y=111529.750 Z=-25162.205',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '71.468781',
    'Refresh.Rate': '0.5',
    Time: '295.5',
    X: '199526',
    Y: '111529.75',
    Z: '-25162.205'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:20 AM',
    'Posicion.Camion': 'X=199772.453 Y=110704.906 Z=-25273.271',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '72.858475',
    'Refresh.Rate': '0.5',
    Time: '296',
    X: '199772.453',
    Y: '110704.906',
    Z: '-25273.271'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:21 AM',
    'Posicion.Camion': 'X=199996.250 Y=109883.148 Z=-25380.092',
    'Posicio.Freno': '0.082353',
    'Posicon.Acelerador': '0',
    Speed: '67.873894',
    'Refresh.Rate': '0.5',
    Time: '296.5',
    X: '199996.25',
    Y: '109883.148',
    Z: '-25380.092'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:21 AM',
    'Posicion.Camion': 'X=200176.562 Y=109169.750 Z=-25435.590',
    'Posicio.Freno': '0.156863',
    'Posicon.Acelerador': '0',
    Speed: '54.522709',
    'Refresh.Rate': '0.5',
    Time: '297',
    X: '200176.562',
    Y: '109169.75',
    Z: '-25435.59'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:22 AM',
    'Posicion.Camion': 'X=200307.328 Y=108603.477 Z=-25471.422',
    'Posicio.Freno': '0.156863',
    'Posicon.Acelerador': '0',
    Speed: '42.820255',
    'Refresh.Rate': '0.5',
    Time: '297.5',
    X: '200307.328',
    Y: '108603.477',
    Z: '-25471.422'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:22 AM',
    'Posicion.Camion': 'X=200400.125 Y=108179.398 Z=-25502.135',
    'Posicio.Freno': '0.129412',
    'Posicon.Acelerador': '0',
    Speed: '30.486017',
    'Refresh.Rate': '0.5',
    Time: '298',
    X: '200400.125',
    Y: '108179.398',
    Z: '-25502.135'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:23 AM',
    'Posicion.Camion': 'X=200468.156 Y=107874.844 Z=-25524.371',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '24.478914',
    'Refresh.Rate': '0.5',
    Time: '298.5',
    X: '200468.156',
    Y: '107874.844',
    Z: '-25524.371'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:23 AM',
    'Posicion.Camion': 'X=200538.656 Y=107576.039 Z=-25546.611',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '26.007145',
    'Refresh.Rate': '0.5',
    Time: '299',
    X: '200538.656',
    Y: '107576.039',
    Z: '-25546.611'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:24 AM',
    'Posicion.Camion': 'X=200613.922 Y=107271.906 Z=-25568.482',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.168627',
    Speed: '26.427649',
    'Refresh.Rate': '0.5',
    Time: '299.5',
    X: '200613.922',
    Y: '107271.906',
    Z: '-25568.482'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:25 AM',
    'Posicion.Camion': 'X=200694.125 Y=106948.484 Z=-25591.469',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.364706',
    Speed: '28.859825',
    'Refresh.Rate': '0.5',
    Time: '300',
    X: '200694.125',
    Y: '106948.484',
    Z: '-25591.469'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:25 AM',
    'Posicion.Camion': 'X=200786.594 Y=106599.180 Z=-25610.688',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.580392',
    Speed: '30.704269',
    'Refresh.Rate': '0.5',
    Time: '300.5',
    X: '200786.594',
    Y: '106599.18',
    Z: '-25610.688'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:26 AM',
    'Posicion.Camion': 'X=200892.609 Y=106222.305 Z=-25627.930',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.662745',
    Speed: '34.041801',
    'Refresh.Rate': '0.5',
    Time: '301',
    X: '200892.609',
    Y: '106222.305',
    Z: '-25627.93'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:26 AM',
    'Posicion.Camion': 'X=201009.031 Y=105811.984 Z=-25646.949',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.752941',
    Speed: '36.899887',
    'Refresh.Rate': '0.5',
    Time: '301.5',
    X: '201009.031',
    Y: '105811.984',
    Z: '-25646.949'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:27 AM',
    'Posicion.Camion': 'X=201133.516 Y=105361.633 Z=-25668.053',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.752941',
    Speed: '40.473564',
    'Refresh.Rate': '0.5',
    Time: '302',
    X: '201133.516',
    Y: '105361.633',
    Z: '-25668.053'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:27 AM',
    'Posicion.Camion': 'X=201265.359 Y=104873.297 Z=-25690.705',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '43.069481',
    'Refresh.Rate': '0.5',
    Time: '302.5',
    X: '201265.359',
    Y: '104873.297',
    Z: '-25690.705'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:28 AM',
    'Posicion.Camion': 'X=201403.703 Y=104359.773 Z=-25714.447',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.560784',
    Speed: '45.652264',
    'Refresh.Rate': '0.5',
    Time: '303',
    X: '201403.703',
    Y: '104359.773',
    Z: '-25714.447'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:28 AM',
    'Posicion.Camion': 'X=201546.406 Y=103818.641 Z=-25738.035',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '46.797523',
    'Refresh.Rate': '0.5',
    Time: '303.5',
    X: '201546.406',
    Y: '103818.641',
    Z: '-25738.035'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:29 AM',
    'Posicion.Camion': 'X=201685.219 Y=103268.102 Z=-25750.900',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '47.115479',
    'Refresh.Rate': '0.5',
    Time: '304',
    X: '201685.219',
    Y: '103268.102',
    Z: '-25750.9'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:29 AM',
    'Posicion.Camion': 'X=201809.984 Y=102755.859 Z=-25760.154',
    'Posicio.Freno': '0.247059',
    'Posicon.Acelerador': '0',
    Speed: '38.793362',
    'Refresh.Rate': '0.5',
    Time: '304.5',
    X: '201809.984',
    Y: '102755.859',
    Z: '-25760.154'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:30 AM',
    'Posicion.Camion': 'X=201898.250 Y=102382.000 Z=-25768.338',
    'Posicio.Freno': '0.313725',
    'Posicon.Acelerador': '0',
    Speed: '26.411053',
    'Refresh.Rate': '0.5',
    Time: '305',
    X: '201898.25',
    Y: '102382',
    Z: '-25768.338'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:30 AM',
    'Posicion.Camion': 'X=201947.922 Y=102152.984 Z=-25773.158',
    'Posicio.Freno': '0.172549',
    'Posicon.Acelerador': '0',
    Speed: '13.415384',
    'Refresh.Rate': '0.5',
    Time: '305.5',
    X: '201947.922',
    Y: '102152.984',
    Z: '-25773.158'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:31 AM',
    'Posicion.Camion': 'X=201964.469 Y=102065.906 Z=-25774.961',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '4.709143',
    'Refresh.Rate': '0.5',
    Time: '306',
    X: '201964.469',
    Y: '102065.906',
    Z: '-25774.961'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:31 AM',
    'Posicion.Camion': 'X=201974.922 Y=102005.406 Z=-25777.125',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '4.753086',
    'Refresh.Rate': '0.5',
    Time: '306.5',
    X: '201974.922',
    Y: '102005.406',
    Z: '-25777.125'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:32 AM',
    'Posicion.Camion': 'X=201986.219 Y=101941.047 Z=-25778.455',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.490196',
    Speed: '6.230039',
    'Refresh.Rate': '0.5',
    Time: '307',
    X: '201986.219',
    Y: '101941.047',
    Z: '-25778.455'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:32 AM',
    'Posicion.Camion': 'X=202001.938 Y=101852.414 Z=-25780.223',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.639216',
    Speed: '8.643473',
    'Refresh.Rate': '0.5',
    Time: '307.5',
    X: '202001.938',
    Y: '101852.414',
    Z: '-25780.223'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:33 AM',
    'Posicion.Camion': 'X=202023.719 Y=101731.922 Z=-25782.680',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.658823',
    Speed: '11.448953',
    'Refresh.Rate': '0.5',
    Time: '308',
    X: '202023.719',
    Y: '101731.922',
    Z: '-25782.68'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:33 AM',
    'Posicion.Camion': 'X=202051.719 Y=101577.938 Z=-25785.781',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.827451',
    Speed: '14.438576',
    'Refresh.Rate': '0.5',
    Time: '308.5',
    X: '202051.719',
    Y: '101577.938',
    Z: '-25785.781'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:34 AM',
    'Posicion.Camion': 'X=202088.062 Y=101384.641 Z=-25789.725',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.913725',
    Speed: '17.973715',
    'Refresh.Rate': '0.5',
    Time: '309',
    X: '202088.062',
    Y: '101384.641',
    Z: '-25789.725'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:34 AM',
    'Posicion.Camion': 'X=202135.453 Y=101149.953 Z=-25794.252',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.992157',
    Speed: '21.274111',
    'Refresh.Rate': '0.5',
    Time: '309.5',
    X: '202135.453',
    Y: '101149.953',
    Z: '-25794.252'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:35 AM',
    'Posicion.Camion': 'X=202196.906 Y=100869.891 Z=-25799.711',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '25.757544',
    'Refresh.Rate': '0.5',
    Time: '310',
    X: '202196.906',
    Y: '100869.891',
    Z: '-25799.711'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:35 AM',
    'Posicion.Camion': 'X=202274.281 Y=100544.586 Z=-25801.844',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '29.130724',
    'Refresh.Rate': '0.5',
    Time: '310.5',
    X: '202274.281',
    Y: '100544.586',
    Z: '-25801.844'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:36 AM',
    'Posicion.Camion': 'X=202362.984 Y=100178.023 Z=-25801.584',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '33.122513',
    'Refresh.Rate': '0.5',
    Time: '311',
    X: '202362.984',
    Y: '100178.023',
    Z: '-25801.584'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:36 AM',
    'Posicion.Camion': 'X=202453.484 Y=99768.812 Z=-25801.191',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '36.38068',
    'Refresh.Rate': '0.5',
    Time: '311.5',
    X: '202453.484',
    Y: '99768.812',
    Z: '-25801.191'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:37 AM',
    'Posicion.Camion': 'X=202538.094 Y=99313.922 Z=-25800.754',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '40.171082',
    'Refresh.Rate': '0.5',
    Time: '312',
    X: '202538.094',
    Y: '99313.922',
    Z: '-25800.754'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:37 AM',
    'Posicion.Camion': 'X=202616.625 Y=98815.180 Z=-25799.885',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '43.48494',
    'Refresh.Rate': '0.5',
    Time: '312.5',
    X: '202616.625',
    Y: '98815.18',
    Z: '-25799.885'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:38 AM',
    'Posicion.Camion': 'X=202707.703 Y=98275.680 Z=-25799.049',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '47.09922',
    'Refresh.Rate': '0.5',
    Time: '313',
    X: '202707.703',
    Y: '98275.68',
    Z: '-25799.049'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:38 AM',
    'Posicion.Camion': 'X=202816.828 Y=97696.922 Z=-25797.375',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.413879',
    'Refresh.Rate': '0.5',
    Time: '313.5',
    X: '202816.828',
    Y: '97696.922',
    Z: '-25797.375'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:39 AM',
    'Posicion.Camion': 'X=202917.156 Y=97100.094 Z=-25785.740',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.358936',
    'Refresh.Rate': '0.5',
    Time: '314',
    X: '202917.156',
    Y: '97100.094',
    Z: '-25785.74'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:39 AM',
    'Posicion.Camion': 'X=202998.203 Y=96502.258 Z=-25772.623',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.3325',
    'Refresh.Rate': '0.5',
    Time: '314.5',
    X: '202998.203',
    Y: '96502.258',
    Z: '-25772.623'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:40 AM',
    'Posicion.Camion': 'X=203067.984 Y=95903.734 Z=-25760.258',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '50.000973',
    'Refresh.Rate': '0.5',
    Time: '315',
    X: '203067.984',
    Y: '95903.734',
    Z: '-25760.258'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:40 AM',
    'Posicion.Camion': 'X=203134.141 Y=95309.797 Z=-25747.756',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '49.356785',
    'Refresh.Rate': '0.5',
    Time: '315.5',
    X: '203134.141',
    Y: '95309.797',
    Z: '-25747.756'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:41 AM',
    'Posicion.Camion': 'X=203197.531 Y=94709.867 Z=-25734.750',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '49.89505',
    'Refresh.Rate': '0.5',
    Time: '316',
    X: '203197.531',
    Y: '94709.867',
    Z: '-25734.75'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:41 AM',
    'Posicion.Camion': 'X=203255.703 Y=94103.711 Z=-25700.156',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.157597',
    'Refresh.Rate': '0.5',
    Time: '316.5',
    X: '203255.703',
    Y: '94103.711',
    Z: '-25700.156'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:42 AM',
    'Posicion.Camion': 'X=203305.969 Y=93505.336 Z=-25657.041',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.063015',
    'Refresh.Rate': '0.5',
    Time: '317',
    X: '203305.969',
    Y: '93505.336',
    Z: '-25657.041'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:43 AM',
    'Posicion.Camion': 'X=203350.297 Y=92906.109 Z=-25617.420',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.095798',
    'Refresh.Rate': '0.5',
    Time: '317.5',
    X: '203350.297',
    Y: '92906.109',
    Z: '-25617.42'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:43 AM',
    'Posicion.Camion': 'X=203386.766 Y=92305.742 Z=-25577.240',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.078781',
    'Refresh.Rate': '0.5',
    Time: '318',
    X: '203386.766',
    Y: '92305.742',
    Z: '-25577.24'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:44 AM',
    'Posicion.Camion': 'X=203411.344 Y=91704.984 Z=-25536.775',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.157066',
    'Refresh.Rate': '0.5',
    Time: '318.5',
    X: '203411.344',
    Y: '91704.984',
    Z: '-25536.775'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:44 AM',
    'Posicion.Camion': 'X=203421.359 Y=91101.008 Z=-25481.854',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '49.929405',
    'Refresh.Rate': '0.5',
    Time: '319',
    X: '203421.359',
    Y: '91101.008',
    Z: '-25481.854'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:45 AM',
    'Posicion.Camion': 'X=203419.406 Y=90501.578 Z=-25417.438',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.089722',
    'Refresh.Rate': '0.5',
    Time: '319.5',
    X: '203419.406',
    Y: '90501.578',
    Z: '-25417.438'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:45 AM',
    'Posicion.Camion': 'X=203415.219 Y=89904.047 Z=-25356.156',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.007557',
    'Refresh.Rate': '0.5',
    Time: '320',
    X: '203415.219',
    Y: '89904.047',
    Z: '-25356.156'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:46 AM',
    'Posicion.Camion': 'X=203399.266 Y=89305.914 Z=-25295.158',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '49.916',
    'Refresh.Rate': '0.5',
    Time: '320.5',
    X: '203399.266',
    Y: '89305.914',
    Z: '-25295.158'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:46 AM',
    'Posicion.Camion': 'X=203360.188 Y=88708.883 Z=-25234.557',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '49.786278',
    'Refresh.Rate': '0.5',
    Time: '321',
    X: '203360.188',
    Y: '88708.883',
    Z: '-25234.557'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:47 AM',
    'Posicion.Camion': 'X=203303.125 Y=88114.352 Z=-25180.461',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '49.903114',
    'Refresh.Rate': '0.5',
    Time: '321.5',
    X: '203303.125',
    Y: '88114.352',
    Z: '-25180.461'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:47 AM',
    'Posicion.Camion': 'X=203238.125 Y=87518.305 Z=-25135.660',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.282291',
    'Refresh.Rate': '0.5',
    Time: '322',
    X: '203238.125',
    Y: '87518.305',
    Z: '-25135.66'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:48 AM',
    'Posicion.Camion': 'X=203160.891 Y=86922.125 Z=-25088.742',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.183937',
    'Refresh.Rate': '0.5',
    Time: '322.5',
    X: '203160.891',
    Y: '86922.125',
    Z: '-25088.742'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:48 AM',
    'Posicion.Camion': 'X=203053.562 Y=86332.250 Z=-25042.088',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '49.841267',
    'Refresh.Rate': '0.5',
    Time: '323',
    X: '203053.562',
    Y: '86332.25',
    Z: '-25042.088'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:49 AM',
    'Posicion.Camion': 'X=202918.625 Y=85747.805 Z=-24996.328',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.038151',
    'Refresh.Rate': '0.5',
    Time: '323.5',
    X: '202918.625',
    Y: '85747.805',
    Z: '-24996.328'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:49 AM',
    'Posicion.Camion': 'X=202773.797 Y=85167.523 Z=-24958.574',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.424374',
    'Refresh.Rate': '0.5',
    Time: '324',
    X: '202773.797',
    Y: '85167.523',
    Z: '-24958.574'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:50 AM',
    'Posicion.Camion': 'X=202618.328 Y=84589.414 Z=-24946.924',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.192009',
    'Refresh.Rate': '0.5',
    Time: '324.5',
    X: '202618.328',
    Y: '84589.414',
    Z: '-24946.924'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:51 AM',
    'Posicion.Camion': 'X=202441.391 Y=84010.805 Z=-24933.768',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '1',
    Speed: '50.463207',
    'Refresh.Rate': '0.5',
    Time: '325',
    X: '202441.391',
    Y: '84010.805',
    Z: '-24933.768'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:51 AM',
    'Posicion.Camion': 'X=202241.016 Y=83441.945 Z=-24918.350',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.968627',
    Speed: '50.006847',
    'Refresh.Rate': '0.5',
    Time: '325.5',
    X: '202241.016',
    Y: '83441.945',
    Z: '-24918.35'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:52 AM',
    'Posicion.Camion': 'X=202019.906 Y=82881.602 Z=-24902.891',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.937255',
    Speed: '50.186302',
    'Refresh.Rate': '0.5',
    Time: '326',
    X: '202019.906',
    Y: '82881.602',
    Z: '-24902.891'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:52 AM',
    'Posicion.Camion': 'X=201783.391 Y=82328.953 Z=-24892.830',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.701961',
    Speed: '50.363056',
    'Refresh.Rate': '0.5',
    Time: '326.5',
    X: '201783.391',
    Y: '82328.953',
    Z: '-24892.83'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:53 AM',
    'Posicion.Camion': 'X=201531.859 Y=81782.383 Z=-24894.752',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.698039',
    Speed: '49.964733',
    'Refresh.Rate': '0.5',
    Time: '327',
    X: '201531.859',
    Y: '81782.383',
    Z: '-24894.752'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:53 AM',
    'Posicion.Camion': 'X=201257.797 Y=81243.328 Z=-24894.643',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.698039',
    Speed: '50.061924',
    'Refresh.Rate': '0.5',
    Time: '327.5',
    X: '201257.797',
    Y: '81243.328',
    Z: '-24894.643'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:54 AM',
    'Posicion.Camion': 'X=200965.188 Y=80716.414 Z=-24893.578',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.698039',
    Speed: '50.070667',
    'Refresh.Rate': '0.5',
    Time: '328',
    X: '200965.188',
    Y: '80716.414',
    Z: '-24893.578'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:54 AM',
    'Posicion.Camion': 'X=200667.422 Y=80190.984 Z=-24892.541',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.733333',
    Speed: '50.209583',
    'Refresh.Rate': '0.5',
    Time: '328.5',
    X: '200667.422',
    Y: '80190.984',
    Z: '-24892.541'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:55 AM',
    'Posicion.Camion': 'X=200368.969 Y=79667.742 Z=-24894.225',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.733333',
    Speed: '50.316662',
    'Refresh.Rate': '0.5',
    Time: '329',
    X: '200368.969',
    Y: '79667.742',
    Z: '-24894.225'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:55 AM',
    'Posicion.Camion': 'X=200054.172 Y=79152.195 Z=-24898.057',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.733333',
    Speed: '50.089718',
    'Refresh.Rate': '0.5',
    Time: '329.5',
    X: '200054.172',
    Y: '79152.195',
    Z: '-24898.057'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:56 AM',
    'Posicion.Camion': 'X=199713.875 Y=78653.125 Z=-24900.654',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.705882',
    Speed: '50.164288',
    'Refresh.Rate': '0.5',
    Time: '330',
    X: '199713.875',
    Y: '78653.125',
    Z: '-24900.654'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:56 AM',
    'Posicion.Camion': 'X=199352.172 Y=78170.953 Z=-24902.545',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.670588',
    Speed: '50.07172',
    'Refresh.Rate': '0.5',
    Time: '330.5',
    X: '199352.172',
    Y: '78170.953',
    Z: '-24902.545'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:57 AM',
    'Posicion.Camion': 'X=198977.516 Y=77698.469 Z=-24904.051',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.670588',
    Speed: '49.983128',
    'Refresh.Rate': '0.5',
    Time: '331',
    X: '198977.516',
    Y: '77698.469',
    Z: '-24904.051'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:57 AM',
    'Posicion.Camion': 'X=198594.812 Y=77230.500 Z=-24900.293',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.670588',
    Speed: '50.071671',
    'Refresh.Rate': '0.5',
    Time: '331.5',
    X: '198594.812',
    Y: '77230.5',
    Z: '-24900.293'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:58 AM',
    'Posicion.Camion': 'X=198202.453 Y=76772.188 Z=-24893.861',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.670588',
    Speed: '50.173313',
    'Refresh.Rate': '0.5',
    Time: '332',
    X: '198202.453',
    Y: '76772.188',
    Z: '-24893.861'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:59 AM',
    'Posicion.Camion': 'X=197792.953 Y=76331.141 Z=-24887.793',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.576471',
    Speed: '49.944748',
    'Refresh.Rate': '0.5',
    Time: '332.5',
    X: '197792.953',
    Y: '76331.141',
    Z: '-24887.793'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:44:59 AM',
    'Posicion.Camion': 'X=197365.266 Y=75906.328 Z=-24881.158',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.576471',
    Speed: '49.958832',
    'Refresh.Rate': '0.5',
    Time: '333',
    X: '197365.266',
    Y: '75906.328',
    Z: '-24881.158'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:00 AM',
    'Posicion.Camion': 'X=196925.891 Y=75493.203 Z=-24872.971',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.576471',
    Speed: '49.921333',
    'Refresh.Rate': '0.5',
    Time: '333.5',
    X: '196925.891',
    Y: '75493.203',
    Z: '-24872.971'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:00 AM',
    'Posicion.Camion': 'X=196479.562 Y=75084.914 Z=-24855.348',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.572549',
    Speed: '50.050461',
    'Refresh.Rate': '0.5',
    Time: '334',
    X: '196479.562',
    Y: '75084.914',
    Z: '-24855.348'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:01 AM',
    'Posicion.Camion': 'X=196030.891 Y=74684.133 Z=-24836.768',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.572549',
    Speed: '50.079884',
    'Refresh.Rate': '0.5',
    Time: '334.5',
    X: '196030.891',
    Y: '74684.133',
    Z: '-24836.768'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:01 AM',
    'Posicion.Camion': 'X=195569.922 Y=74293.984 Z=-24818.756',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.545098',
    Speed: '50.555984',
    'Refresh.Rate': '0.5',
    Time: '335',
    X: '195569.922',
    Y: '74293.984',
    Z: '-24818.756'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:02 AM',
    'Posicion.Camion': 'X=195088.469 Y=73913.172 Z=-24799.836',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.509804',
    Speed: '51.365627',
    'Refresh.Rate': '0.5',
    Time: '335.5',
    X: '195088.469',
    Y: '73913.172',
    Z: '-24799.836'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:02 AM',
    'Posicion.Camion': 'X=194592.938 Y=73535.938 Z=-24780.314',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.541176',
    Speed: '52.134674',
    'Refresh.Rate': '0.5',
    Time: '336',
    X: '194592.938',
    Y: '73535.938',
    Z: '-24780.314'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:03 AM',
    'Posicion.Camion': 'X=194088.750 Y=73151.570 Z=-24755.828',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.592157',
    Speed: '52.97979',
    'Refresh.Rate': '0.5',
    Time: '336.5',
    X: '194088.75',
    Y: '73151.57',
    Z: '-24755.828'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:03 AM',
    'Posicion.Camion': 'X=193569.828 Y=72773.305 Z=-24730.715',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.607843',
    Speed: '53.862106',
    'Refresh.Rate': '0.5',
    Time: '337',
    X: '193569.828',
    Y: '72773.305',
    Z: '-24730.715'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:04 AM',
    'Posicion.Camion': 'X=193023.906 Y=72415.219 Z=-24705.553',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.607843',
    Speed: '54.709846',
    'Refresh.Rate': '0.5',
    Time: '337.5',
    X: '193023.906',
    Y: '72415.219',
    Z: '-24705.553'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:04 AM',
    'Posicion.Camion': 'X=192450.109 Y=72082.508 Z=-24678.895',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.607843',
    Speed: '55.578953',
    'Refresh.Rate': '0.5',
    Time: '338',
    X: '192450.109',
    Y: '72082.508',
    Z: '-24678.895'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:05 AM',
    'Posicion.Camion': 'X=191860.266 Y=71755.836 Z=-24651.494',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.627451',
    Speed: '56.476635',
    'Refresh.Rate': '0.5',
    Time: '338.5',
    X: '191860.266',
    Y: '71755.836',
    Z: '-24651.494'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:05 AM',
    'Posicion.Camion': 'X=191262.312 Y=71419.125 Z=-24621.258',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.65098',
    Speed: '57.425983',
    'Refresh.Rate': '0.5',
    Time: '339',
    X: '191262.312',
    Y: '71419.125',
    Z: '-24621.258'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:06 AM',
    'Posicion.Camion': 'X=190649.406 Y=71089.398 Z=-24589.684',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.654902',
    Speed: '58.333576',
    'Refresh.Rate': '0.5',
    Time: '339.5',
    X: '190649.406',
    Y: '71089.398',
    Z: '-24589.684'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:06 AM',
    'Posicion.Camion': 'X=190012.828 Y=70782.555 Z=-24558.055',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.67451',
    Speed: '59.221287',
    'Refresh.Rate': '0.5',
    Time: '340',
    X: '190012.828',
    Y: '70782.555',
    Z: '-24558.055'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:07 AM',
    'Posicion.Camion': 'X=189350.953 Y=70504.445 Z=-24525.584',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.686275',
    Speed: '60.196655',
    'Refresh.Rate': '0.5',
    Time: '340.5',
    X: '189350.953',
    Y: '70504.445',
    Z: '-24525.584'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:07 AM',
    'Posicion.Camion': 'X=188665.516 Y=70253.742 Z=-24492.562',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.686275',
    Speed: '61.183163',
    'Refresh.Rate': '0.5',
    Time: '341',
    X: '188665.516',
    Y: '70253.742',
    Z: '-24492.562'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:08 AM',
    'Posicion.Camion': 'X=187958.172 Y=70031.922 Z=-24459.354',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.635294',
    Speed: '62.102669',
    'Refresh.Rate': '0.5',
    Time: '341.5',
    X: '187958.172',
    Y: '70031.922',
    Z: '-24459.354'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:08 AM',
    'Posicion.Camion': 'X=187231.359 Y=69845.250 Z=-24425.615',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.4',
    Speed: '62.605556',
    'Refresh.Rate': '0.5',
    Time: '342',
    X: '187231.359',
    Y: '69845.25',
    Z: '-24425.615'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:09 AM',
    'Posicion.Camion': 'X=186493.375 Y=69699.328 Z=-24391.074',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.384314',
    Speed: '62.639973',
    'Refresh.Rate': '0.5',
    Time: '342.5',
    X: '186493.375',
    Y: '69699.328',
    Z: '-24391.074'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:09 AM',
    'Posicion.Camion': 'X=185747.969 Y=69593.492 Z=-24356.748',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.384314',
    Speed: '62.671806',
    'Refresh.Rate': '0.5',
    Time: '343',
    X: '185747.969',
    Y: '69593.492',
    Z: '-24356.748'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:10 AM',
    'Posicion.Camion': 'X=184997.219 Y=69526.117 Z=-24320.303',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.384314',
    Speed: '62.625984',
    'Refresh.Rate': '0.5',
    Time: '343.5',
    X: '184997.219',
    Y: '69526.117',
    Z: '-24320.303'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:10 AM',
    'Posicion.Camion': 'X=184245.531 Y=69497.883 Z=-24274.807',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.384314',
    Speed: '62.464886',
    'Refresh.Rate': '0.5',
    Time: '344',
    X: '184245.531',
    Y: '69497.883',
    Z: '-24274.807'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:11 AM',
    'Posicion.Camion': 'X=183497.156 Y=69509.734 Z=-24229.609',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.384314',
    Speed: '62.291367',
    'Refresh.Rate': '0.5',
    Time: '344.5',
    X: '183497.156',
    Y: '69509.734',
    Z: '-24229.609'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:11 AM',
    'Posicion.Camion': 'X=182752.312 Y=69562.625 Z=-24185.672',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.384314',
    Speed: '62.122459',
    'Refresh.Rate': '0.5',
    Time: '345',
    X: '182752.312',
    Y: '69562.625',
    Z: '-24185.672'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:12 AM',
    'Posicion.Camion': 'X=182013.109 Y=69656.875 Z=-24140.207',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.384314',
    Speed: '61.875614',
    'Refresh.Rate': '0.5',
    Time: '345.5',
    X: '182013.109',
    Y: '69656.875',
    Z: '-24140.207'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:12 AM',
    'Posicion.Camion': 'X=181283.531 Y=69789.117 Z=-24087.531',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.384314',
    Speed: '61.581158',
    'Refresh.Rate': '0.5',
    Time: '346',
    X: '181283.531',
    Y: '69789.117',
    Z: '-24087.531'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:13 AM',
    'Posicion.Camion': 'X=180563.375 Y=69946.047 Z=-24035.488',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.243137',
    Speed: '61.278652',
    'Refresh.Rate': '0.5',
    Time: '346.5',
    X: '180563.375',
    Y: '69946.047',
    Z: '-24035.488'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:13 AM',
    'Posicion.Camion': 'X=179856.797 Y=70123.453 Z=-23985.172',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '60.11314',
    'Refresh.Rate': '0.5',
    Time: '347',
    X: '179856.797',
    Y: '70123.453',
    Z: '-23985.172'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:14 AM',
    'Posicion.Camion': 'X=179173.906 Y=70322.508 Z=-23939.744',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '58.770561',
    'Refresh.Rate': '0.5',
    Time: '347.5',
    X: '179173.906',
    Y: '70322.508',
    Z: '-23939.744'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:15 AM',
    'Posicion.Camion': 'X=178513.641 Y=70546.188 Z=-23907.031',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '57.618046',
    'Refresh.Rate': '0.5',
    Time: '348',
    X: '178513.641',
    Y: '70546.188',
    Z: '-23907.031'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:15 AM',
    'Posicion.Camion': 'X=177873.016 Y=70791.797 Z=-23873.268',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '56.497978',
    'Refresh.Rate': '0.5',
    Time: '348.5',
    X: '177873.016',
    Y: '70791.797',
    Z: '-23873.268'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:16 AM',
    'Posicion.Camion': 'X=177251.719 Y=71047.633 Z=-23839.791',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '55.406574',
    'Refresh.Rate': '0.5',
    Time: '349',
    X: '177251.719',
    Y: '71047.633',
    Z: '-23839.791'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:16 AM',
    'Posicion.Camion': 'X=176646.766 Y=71308.039 Z=-23808.729',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '54.380768',
    'Refresh.Rate': '0.5',
    Time: '349.5',
    X: '176646.766',
    Y: '71308.039',
    Z: '-23808.729'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:17 AM',
    'Posicion.Camion': 'X=176062.844 Y=71578.102 Z=-23804.602',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '53.891579',
    'Refresh.Rate': '0.5',
    Time: '350',
    X: '176062.844',
    Y: '71578.102',
    Z: '-23804.602'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:17 AM',
    'Posicion.Camion': 'X=175484.656 Y=71869.398 Z=-23808.777',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '53.531071',
    'Refresh.Rate': '0.5',
    Time: '350.5',
    X: '175484.656',
    Y: '71869.398',
    Z: '-23808.777'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:18 AM',
    'Posicion.Camion': 'X=174917.578 Y=72172.461 Z=-23808.340',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '53.200264',
    'Refresh.Rate': '0.5',
    Time: '351',
    X: '174917.578',
    Y: '72172.461',
    Z: '-23808.34'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:18 AM',
    'Posicion.Camion': 'X=174358.453 Y=72479.336 Z=-23808.281',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '52.862797',
    'Refresh.Rate': '0.5',
    Time: '351.5',
    X: '174358.453',
    Y: '72479.336',
    Z: '-23808.281'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:19 AM',
    'Posicion.Camion': 'X=173815.312 Y=72795.188 Z=-23821.564',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '52.792328',
    'Refresh.Rate': '0.5',
    Time: '352',
    X: '173815.312',
    Y: '72795.188',
    Z: '-23821.564'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:19 AM',
    'Posicion.Camion': 'X=173284.984 Y=73135.742 Z=-23880.412',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '53.532082',
    'Refresh.Rate': '0.5',
    Time: '352.5',
    X: '173284.984',
    Y: '73135.742',
    Z: '-23880.412'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:20 AM',
    'Posicion.Camion': 'X=172757.359 Y=73517.828 Z=-23933.352',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '54.409534',
    'Refresh.Rate': '0.5',
    Time: '353',
    X: '172757.359',
    Y: '73517.828',
    Z: '-23933.352'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:20 AM',
    'Posicion.Camion': 'X=172239.250 Y=73918.547 Z=-23998.232',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '55.528507',
    'Refresh.Rate': '0.5',
    Time: '353.5',
    X: '172239.25',
    Y: '73918.547',
    Z: '-23998.232'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:21 AM',
    'Posicion.Camion': 'X=171710.234 Y=74335.703 Z=-24063.975',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '56.533428',
    'Refresh.Rate': '0.5',
    Time: '354',
    X: '171710.234',
    Y: '74335.703',
    Z: '-24063.975'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:21 AM',
    'Posicion.Camion': 'X=171191.312 Y=74769.289 Z=-24145.225',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '57.924351',
    'Refresh.Rate': '0.5',
    Time: '354.5',
    X: '171191.312',
    Y: '74769.289',
    Z: '-24145.225'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:22 AM',
    'Posicion.Camion': 'X=170679.969 Y=75243.445 Z=-24257.275',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '59.678474',
    'Refresh.Rate': '0.5',
    Time: '355',
    X: '170679.969',
    Y: '75243.445',
    Z: '-24257.275'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:22 AM',
    'Posicion.Camion': 'X=170174.281 Y=75760.188 Z=-24368.211',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '61.542854',
    'Refresh.Rate': '0.5',
    Time: '355.5',
    X: '170174.281',
    Y: '75760.188',
    Z: '-24368.211'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:23 AM',
    'Posicion.Camion': 'X=169663.109 Y=76300.414 Z=-24483.531',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '63.423744',
    'Refresh.Rate': '0.5',
    Time: '356',
    X: '169663.109',
    Y: '76300.414',
    Z: '-24483.531'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:23 AM',
    'Posicion.Camion': 'X=169142.062 Y=76863.273 Z=-24600.535',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '65.216179',
    'Refresh.Rate': '0.5',
    Time: '356.5',
    X: '169142.062',
    Y: '76863.273',
    Z: '-24600.535'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:24 AM',
    'Posicion.Camion': 'X=168624.516 Y=77457.164 Z=-24715.783',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '66.919861',
    'Refresh.Rate': '0.5',
    Time: '357',
    X: '168624.516',
    Y: '77457.164',
    Z: '-24715.783'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:24 AM',
    'Posicion.Camion': 'X=168116.000 Y=78083.672 Z=-24836.521',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '68.664894',
    'Refresh.Rate': '0.5',
    Time: '357.5',
    X: '168116',
    Y: '78083.672',
    Z: '-24836.521'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:25 AM',
    'Posicion.Camion': 'X=167604.688 Y=78735.867 Z=-24960.936',
    'Posicio.Freno': '0.011765',
    'Posicon.Acelerador': '0',
    Speed: '70.416649',
    'Refresh.Rate': '0.5',
    Time: '358',
    X: '167604.688',
    Y: '78735.867',
    Z: '-24960.936'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:25 AM',
    'Posicion.Camion': 'X=167107.375 Y=79383.820 Z=-25051.332',
    'Posicio.Freno': '0.05098',
    'Posicon.Acelerador': '0',
    Speed: '64.035629',
    'Refresh.Rate': '0.5',
    Time: '358.5',
    X: '167107.375',
    Y: '79383.82',
    Z: '-25051.332'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:26 AM',
    'Posicion.Camion': 'X=166691.859 Y=79960.961 Z=-25094.391',
    'Posicio.Freno': '0.054902',
    'Posicon.Acelerador': '0',
    Speed: '55.170216',
    'Refresh.Rate': '0.5',
    Time: '359',
    X: '166691.859',
    Y: '79960.961',
    Z: '-25094.391'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:26 AM',
    'Posicion.Camion': 'X=166369.359 Y=80474.547 Z=-25142.947',
    'Posicio.Freno': '0.054902',
    'Posicon.Acelerador': '0',
    Speed: '46.696411',
    'Refresh.Rate': '0.5',
    Time: '359.5',
    X: '166369.359',
    Y: '80474.547',
    Z: '-25142.947'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:27 AM',
    'Posicion.Camion': 'X=166140.391 Y=80923.070 Z=-25180.336',
    'Posicio.Freno': '0.054902',
    'Posicon.Acelerador': '0',
    Speed: '37.818363',
    'Refresh.Rate': '0.5',
    Time: '360',
    X: '166140.391',
    Y: '80923.07',
    Z: '-25180.336'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:27 AM',
    'Posicion.Camion': 'X=165987.062 Y=81291.734 Z=-25208.865',
    'Posicio.Freno': '0.047059',
    'Posicon.Acelerador': '0',
    Speed: '29.160917',
    'Refresh.Rate': '0.5',
    Time: '360.5',
    X: '165987.062',
    Y: '81291.734',
    Z: '-25208.865'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:28 AM',
    'Posicion.Camion': 'X=165886.750 Y=81553.391 Z=-25221.732',
    'Posicio.Freno': '0.047059',
    'Posicon.Acelerador': '0',
    Speed: '16.914654',
    'Refresh.Rate': '0.5',
    Time: '361',
    X: '165886.75',
    Y: '81553.391',
    Z: '-25221.732'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:28 AM',
    'Posicion.Camion': 'X=165835.141 Y=81685.312 Z=-25221.373',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '8.58772',
    'Refresh.Rate': '0.5',
    Time: '361.5',
    X: '165835.141',
    Y: '81685.312',
    Z: '-25221.373'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:29 AM',
    'Posicion.Camion': 'X=165794.922 Y=81788.578 Z=-25220.395',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '8.522447',
    'Refresh.Rate': '0.5',
    Time: '362',
    X: '165794.922',
    Y: '81788.578',
    Z: '-25220.395'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:29 AM',
    'Posicion.Camion': 'X=165758.594 Y=81879.703 Z=-25217.766',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '7.884146',
    'Refresh.Rate': '0.5',
    Time: '362.5',
    X: '165758.594',
    Y: '81879.703',
    Z: '-25217.766'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:30 AM',
    'Posicion.Camion': 'X=165725.906 Y=81965.664 Z=-25214.355',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.070588',
    Speed: '7.5978',
    'Refresh.Rate': '0.5',
    Time: '363',
    X: '165725.906',
    Y: '81965.664',
    Z: '-25214.355'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:30 AM',
    'Posicion.Camion': 'X=165693.828 Y=82048.008 Z=-25211.590',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.086274',
    Speed: '7.083398',
    'Refresh.Rate': '0.5',
    Time: '363.5',
    X: '165693.828',
    Y: '82048.008',
    Z: '-25211.59'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:31 AM',
    'Posicion.Camion': 'X=165663.125 Y=82127.555 Z=-25208.893',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0.086274',
    Speed: '7.075284',
    'Refresh.Rate': '0.5',
    Time: '364',
    X: '165663.125',
    Y: '82127.555',
    Z: '-25208.893'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:31 AM',
    'Posicion.Camion': 'X=165634.469 Y=82204.094 Z=-25206.246',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '6.54436',
    'Refresh.Rate': '0.5',
    Time: '364.5',
    X: '165634.469',
    Y: '82204.094',
    Z: '-25206.246'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:32 AM',
    'Posicion.Camion': 'X=165607.391 Y=82276.477 Z=-25203.752',
    'Posicio.Freno': '0',
    'Posicon.Acelerador': '0',
    Speed: '6.294775',
    'Refresh.Rate': '0.5',
    Time: '365',
    X: '165607.391',
    Y: '82276.477',
    Z: '-25203.752'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:32 AM',
    'Posicion.Camion': 'X=165599.078 Y=82299.227 Z=-25202.430',
    'Posicio.Freno': '0.156863',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '365.5',
    X: '165599.078',
    Y: '82299.227',
    Z: '-25202.43'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:33 AM',
    'Posicion.Camion': 'X=165598.625 Y=82300.500 Z=-25202.896',
    'Posicio.Freno': '0.172549',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '366',
    X: '165598.625',
    Y: '82300.5',
    Z: '-25202.896'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:34 AM',
    'Posicion.Camion': 'X=165598.562 Y=82300.766 Z=-25203.025',
    'Posicio.Freno': '0.176471',
    'Posicon.Acelerador': '0',
    Speed: '0.017455',
    'Refresh.Rate': '0.5',
    Time: '366.5',
    X: '165598.562',
    Y: '82300.766',
    Z: '-25203.025'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:34 AM',
    'Posicion.Camion': 'X=165598.656 Y=82300.609 Z=-25202.980',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0.00943',
    'Refresh.Rate': '0.5',
    Time: '367',
    X: '165598.656',
    Y: '82300.609',
    Z: '-25202.98'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:35 AM',
    'Posicion.Camion': 'X=165598.688 Y=82300.578 Z=-25202.967',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0.000312',
    'Refresh.Rate': '0.5',
    Time: '367.5',
    X: '165598.688',
    Y: '82300.578',
    Z: '-25202.967'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:35 AM',
    'Posicion.Camion': 'X=165598.688 Y=82300.578 Z=-25202.967',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0',
    'Refresh.Rate': '0.5',
    Time: '368',
    X: '165598.688',
    Y: '82300.578',
    Z: '-25202.967'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:36 AM',
    'Posicion.Camion': 'X=165598.688 Y=82300.578 Z=-25202.967',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0.000007',
    'Refresh.Rate': '0.5',
    Time: '368.5',
    X: '165598.688',
    Y: '82300.578',
    Z: '-25202.967'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:36 AM',
    'Posicion.Camion': 'X=165598.688 Y=82300.578 Z=-25202.965',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0.000015',
    'Refresh.Rate': '0.5',
    Time: '369',
    X: '165598.688',
    Y: '82300.578',
    Z: '-25202.965'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:37 AM',
    'Posicion.Camion': 'X=165598.688 Y=82300.578 Z=-25202.965',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0.000012',
    'Refresh.Rate': '0.5',
    Time: '369.5',
    X: '165598.688',
    Y: '82300.578',
    Z: '-25202.965'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:37 AM',
    'Posicion.Camion': 'X=165598.688 Y=82300.578 Z=-25202.965',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0.000015',
    'Refresh.Rate': '0.5',
    Time: '370',
    X: '165598.688',
    Y: '82300.578',
    Z: '-25202.965'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:38 AM',
    'Posicion.Camion': 'X=165598.688 Y=82300.578 Z=-25202.965',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0.000014',
    'Refresh.Rate': '0.5',
    Time: '370.5',
    X: '165598.688',
    Y: '82300.578',
    Z: '-25202.965'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:38 AM',
    'Posicion.Camion': 'X=165598.688 Y=82300.578 Z=-25202.965',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0.000018',
    'Refresh.Rate': '0.5',
    Time: '371',
    X: '165598.688',
    Y: '82300.578',
    Z: '-25202.965'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:39 AM',
    'Posicion.Camion': 'X=165598.688 Y=82300.578 Z=-25202.965',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0.000005',
    'Refresh.Rate': '0.5',
    Time: '371.5',
    X: '165598.688',
    Y: '82300.578',
    Z: '-25202.965'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:39 AM',
    'Posicion.Camion': 'X=165598.688 Y=82300.578 Z=-25202.965',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0.000017',
    'Refresh.Rate': '0.5',
    Time: '372',
    X: '165598.688',
    Y: '82300.578',
    Z: '-25202.965'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:40 AM',
    'Posicion.Camion': 'X=165598.688 Y=82300.578 Z=-25202.965',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0.000004',
    'Refresh.Rate': '0.5',
    Time: '372.5',
    X: '165598.688',
    Y: '82300.578',
    Z: '-25202.965'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:40 AM',
    'Posicion.Camion': 'X=165598.688 Y=82300.578 Z=-25202.965',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0.000017',
    'Refresh.Rate': '0.5',
    Time: '373',
    X: '165598.688',
    Y: '82300.578',
    Z: '-25202.965'
  },
  {
    Fecha: 'Apr 9- 2021',
    Hora: '11:45:41 AM',
    'Posicion.Camion': 'X=165598.688 Y=82300.578 Z=-25202.965',
    'Posicio.Freno': '0.180392',
    'Posicon.Acelerador': '0',
    Speed: '0.000005',
    'Refresh.Rate': '0.5',
    Time: '373.5',
    X: '165598.688',
    Y: '82300.578',
    Z: '-25202.965'
  }
];
