import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
// import { getDimensionFullName, getAreaFullName } from '../constants';

const CarrerasTopDescPage = ({ user, top, careers, background, number }) => {
  // const { TOP_DIM_1, TOP_DIM_2, TOP_DIM_3, TOP_DIM_4 } = user;
  // console.log({ TOP_DIM_1, TOP_DIM_2 });
  // const firstDimName = getDimensionFullName(TOP_DIM_1);
  // const secondDimName = getDimensionFullName(TOP_DIM_2);
  // const thirdDimName = getDimensionFullName(TOP_DIM_3);
  // const fourthDimName = getDimensionFullName(TOP_DIM_4);
  // const dimensions = [
  //   ...new Set([TOP_DIM_1, TOP_DIM_2, TOP_DIM_3, TOP_DIM_4].filter(Boolean))
  // ];
  // console.log({top})
  // console.log({ user });
  // const areaName = getAreaFullName(top);

  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h2>
            3.1. Carreras asociadas a las áreas de conocimiento de interés
          </h2>

          <p className='justified'>
            En el siguiente apartado te presentamos las carreras que mejor se
            ajustan a las áreas de conocimiento en las que mostraste un mayor
            interés.
          </p>
          {/* <p className='justified'>
            A continuación encontrarás un resumen de carreras en relación a tus
            preferencias:{'  '}
            {dimensions.map((dim, i) => {
              const isLast = i + 1 === dimensions.length;
              const isSemiLast = i + 1 === dimensions.length - 1;
              const separator = isSemiLast ? ' y ' : ', ';
              return (
                <span key={dim}>
                  <strong>{getDimensionFullName(dim)}</strong>
                  {isLast ? '.' : separator}
                </span>
              );
            })}
          </p> */}
          {/* <p className='justified'>
            En tu caso las {careers.length} áreas de conocimiento que más te
            atraen son:{'  '}
            {careers.map((career, i) => {
              const isLast = i + 1 === careers.length;
              const isSemiLast = i + 1 === careers.length - 1;
              const separator = isSemiLast ? ' y ' : ', ';
              return (
                <span key={career}>
                  <strong>{getAreaFullName(career)}</strong>
                  {isLast ? '.' : separator}
                </span>
              );
            })}
          </p> */}
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default CarrerasTopDescPage;
