import React from 'react';
import { DataProvider, useDataContext } from './campaign-context';
import AppEquisOneCollege from '../AppEquisOneCollege';
import AppEquisJuniorCollege from '../AppEquisJuniorCollege';

const SwitchPage = props => {
  const { campaign, responses, isLoading } = useDataContext();
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!campaign && !isLoading) {
    console.log('not found, redirect');
    return <div>Not found</div>;
  }

  // console.log({ response });
  if (campaign.SKU === 'EQUIS_ONE') {
    return <AppEquisOneCollege campaign={campaign} responses={responses} />;
  }
  if (campaign.SKU === 'EQUIS_ONE_PILOTO') {
    return <AppEquisOneCollege campaign={campaign} responses={responses} />;
  }

  if (campaign.SKU === 'EQUIS_JUNIOR') {
    return <AppEquisJuniorCollege campaign={campaign} responses={responses} />;
  }
  if (campaign.SKU === 'EQUIS_JUNIOR_PILOTO') {
    return <AppEquisJuniorCollege campaign={campaign} responses={responses} />;
  }

  return (
    <div>
      <div>id</div>
    </div>
  );
};

const DataWrapper = props => {
  // console.log({ props });
  return (
    <DataProvider
      id={props.match.params.id}
      campaignAccessKey={props.match.params.key}
    >
      <SwitchPage {...props} />
    </DataProvider>
  );
};
export default DataWrapper;
