import React from 'react';
import { DataProvider, useDataContext } from './response-context';
import EquisOneReport from '../AppEquisOne';
import AppEquisJuniorReport from '../AppEquisJunior';

const SwitchPage = props => {
  const { response, isLoading } = useDataContext();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!response && !isLoading) {
    console.log('not found, redirect');
    return <div>Not found</div>;
  }
  // console.log({ response });
  const { campaign, data } = response;
  if (campaign.SKU === 'EQUIS_ONE') {
    return (
      <EquisOneReport campaign={campaign} response={response} data={data} />
    );
  }
  if (campaign.SKU === 'EQUIS_ONE_PILOTO') {
    return (
      <EquisOneReport campaign={campaign} response={response} data={data} />
    );
  }

  if (campaign.SKU === 'EQUIS_JUNIOR') {
    return (
      <AppEquisJuniorReport
        campaign={campaign}
        response={response}
        data={data}
      />
    );
  }
  if (campaign.SKU === 'EQUIS_JUNIOR_PILOTO') {
    return (
      <AppEquisJuniorReport
        campaign={campaign}
        response={response}
        data={data}
      />
    );
  }

  return (
    <div>
      <div>{response.id}</div>
    </div>
  );
};

const DataWrapper = props => {
  // console.log({ props });
  return (
    <DataProvider id={props.match.params.id}>
      <SwitchPage {...props} />
    </DataProvider>
  );
};
export default DataWrapper;
