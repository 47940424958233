import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer, ProgressBox, ProgressStat } from '../styles';
import { dimColors } from '../constants';

// const getColor = value => {
//   if (value < 40) {
//     return polarColors[0];
//   }
//   return polarColors[4];
// };

const DecisionMakingFirstPage = ({ user, background, number }) => {
  // const { TOP_DECISION_STYLE } = user;
  const afrontamientoProductivo = parseFloat(user['Afrontamiento Productivo']);

  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>5. Estilo personal de toma de decisión</h1>
          <h2>5.1. Estilo de Decisión Predominante</h2>
          <p className='justified'>
            Las personas pueden afrontar las dificultades asociadas al proceso
            de toma de decisión sobre su carrera a futuro de distintas formas,
            siendo algunas formas más beneficiosas para el proceso de elegir una
            carrera profesional. <br /> <br />
            Tu estilo predominante de decisión es aquel en el que tienes{' '}
            <strong>mayor puntaje</strong>.
          </p>
          <h3>Afrontamiento Productivo</h3>
          <p className='justified'>
            Las personas que obtienen puntajes más altos en esta escala tienden
            a utilizar estrategias que facilitan el afrontar positivamente el
            proceso de toma de decisiones respecto de la carrera profesional,
            cómo buscar activamente información respecto de las carreras;
            planificar y analizar sistemáticamente la información disponible;
            comparar alternativas, estar dispuesto a transar respecto de
            distintas preferencias e intereses que entran en conflicto; tener
            pensamientos positivos frente al desafío de la elección de carrera.
            El uso de este tipo de estrategias ayuda a afrontar de forma más
            exitosa el proceso de toma de decisión sobre la elección de una
            carrera profesional.
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details-single'>
                <div className='progress-name'>Afrontamiento Productivo</div>
                <ProgressBox
                  progress={afrontamientoProductivo}
                  backColor={() => dimColors[2]}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{afrontamientoProductivo}</ProgressStat>
              </div>
            </div>
          </div>
          <br />
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default DecisionMakingFirstPage;
