import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import { getDimensionFullName } from '../constants';

// "Influencia y Personas"
const InfluenciaYPersonas = () => (
  <p className='justified'>
    Personas que les gusta la vida social y tienen habilidades organizativas y
    de trabajo en equipo. Por lo general gustan de los grupos y suelen ser muy
    sociables y amistosos. Lo suyo es la persuasión, logrando convencer a otros
    de proyectos que tienen valor. Esto se expresa en interés en trabajar con
    otros vendiendo, asistiendo, entregando información o administrando esos
    servicios (e.g., director de servicios sociales, director de personal,
    director de publicidad, vendedor, agente de viajes, instructor de gimnasio).
  </p>
);

// "Fabricación y Mecánica"
const FabricacionYMecanica = () => (
  <p className='justified'>
    Personas orientadas por los hechos concretos y las cosas. Les interesa lo
    que se pueda manipular y son diestros en el uso de diferentes herramientas.
    Interés por la tecnología y son hábiles en la utilización de éstas para el
    desarrollo de sus tareas y proyectos. La física, suele ser una ciencia que
    se les da bien a estas personas y la tarea de construir, inventar, y crear
    nuevas soluciones es un interés de estas personas. Interés por entender y
    por diseñar, instalar y mantener maquinaria (e.g., mecánico automotriz,
    mecánico de aviones, ingeniero químico).
  </p>
);

// "Liderazgo y Management"
const LiderazgoYManagement = () => (
  <p className='justified'>
    Personas prácticas, organizadas y con capacidad de mando. Capaz de movilizar
    a la gente a su cargo en torno a tareas o metas. Les suele caracterizar
    también una pasión por los resultados concretos que sus acciones o las de
    los demás logran. Interés en administrar y planificar las actividades
    principales de un negocio u organización (e.g., gerente de un hotel, gerente
    de ventas, gerente de oficina, empleado de ventas).
  </p>
);

// "Ciencias y Naturaleza"
const CienciaYNaturaleza = () => (
  <p className='justified'>
    Suelen ser personas que explican el mundo en base a principios o reglas
    generales y suelen buscar evidencias para comprobar lo que dicen y saben en
    relación a las cosas. Las personas con una alta orientación en esta escala
    les interesan la naturaleza tanto como las ciencias que explican los
    fenómenos naturales. Son personas que suelen haber participado en ferias
    científicas y cursos del área y tienen pasión por lograr una comprensión del
    mundo en base a su conocimiento. Interés por aplicar conocimientos de
    ciencias naturales a plantas y animales, gusto por trabajar y estar al aire
    libre (e.g., ecologista, guardabosque, veterinario, oceanógrafo).
  </p>
);
// "Administración y Proyectos"
const AdministracionYProyectos = () => (
  <p className='justified'>
    Personas orientadas al detalle y la meticulosidad en el desarrollo de
    proyectos de emprendimiento. Suelen ser personas realistas y prácticas por
    lo que son capaces de perseverar cuando enfrentan dificultades y les
    interesa un mundo ordenado, lógico y estructurado. Su inclinación suele
    llevarles a ser curiosos y a tener una orientación a la resolución de
    problemas de la vida real. Interés en los detalles y actividades de oficina
    de los negocios: La contabilidad, la evaluación, la estimación, el
    asesoramiento y el desarrollo de presupuestos (e.g., analista financiero,
    contador).
  </p>
);

// "Artes y Literatura"
const ArtesYLiteratura = () => (
  <p className='justified'>
    Habilidades e intereses en las artes o en las ocupaciones que estimulan el
    pensamiento divergente, lo original y lo creativo. Suelen explicarse el
    mundo desde principios estéticos y les atrae lo diferente y novedoso. Su
    pasión les lleva a ser algo inconformistas y suelen ser críticos de la
    realidad o de lo tradicional. Pueden o no tener habilidades artísticas, sin
    embargo, por lo general gustan de la literatura, la música, las artes
    plásticas, el cine o cualquier expresión que permita canalizar su espíritu
    inquieto. Interés en artes visuales, escénicas y literarias, se centra en
    actividades creativas y expresivas (e.g., músico, poeta, actor, escultor,
    compositor, dramaturgo).
  </p>
);

// "Soporte Social y Educación"
const SoporteSocialYEducacion = () => (
  <p className='justified'>
    Sensibles a problemáticas sociales y humanas, y tienen habilidades para
    relacionarse con las personas. Les resulta fácil escuchar y/o ayudar a los
    demás. Pueden ser personas que se pueden caracterizar como reflexivas y
    maduras por el tipo de cuestiones que abordan, y habitualmente se hacen
    preguntas por el sentido de la existencia humana o por el significado de las
    conductas de las personas con las que se relacionan. Su orientación puede
    llevarles a ser muy observadores de las situaciones que afectan a las
    personas y, con facilidad, pueden ponerse en el lugar de los demás. Interés
    por ayudar a otros de múltiples maneras. Gusto por enseñar, proveer, apoyar
    y aconsejar (e.g., fonoaudiólogo, trabajador social, terapeuta familiar,
    orientador vocacional, psicólogo educacional, educador de párvulos).
  </p>
);

// "Ciencia de Datos"
const CienciaYDatos = () => (
  <p className='justified'>
    Interés en los procesos a través de los cuales se logran los objetivos.
    Suelen ser personas perfeccionistas y muy lógicas en la estrategia que
    utilizan para resolver problemas. Lo suyo son los datos concretos, reales y
    prácticos, que les permiten alcanzar metas específicas. Les interesa el
    orden y la lógica, por lo que suelen ser personas que desarrollan
    procedimientos y rutinas para alcanzar sus objetivos y proyectos. Interés en
    aspectos de gestión de detalles y de información. Interés en el uso de las
    matemáticas y sistemas para analizar e interpretar datos y resolver
    problemas técnicos (e.g., programador computacional, ingeniero eléctrico).
  </p>
);

// const CN = 'Ciencias y Naturaleza';
// const FM = 'Fabricación y Mecánica';
// const CD = 'Ciencia de Datos';
// const AP = 'Administración y Proyectos';
// const AL = 'Artes y Literatura';
// const SE = 'Soporte Social y Educación';
// const IP = 'Influencia y Personas';
// const LM = 'Liderazgo y Management';

const EquisOneAreasDescriptionPage = ({ user, background, number }) => {
  const { TOP_DIM_1, TOP_DIM_2, TOP_DIM_3, TOP_DIM_4 } = user;
  // console.log({ TOP_DIM_1, TOP_DIM_2 });
  const firstDimName = getDimensionFullName(TOP_DIM_1);
  const secondDimName = getDimensionFullName(TOP_DIM_2);
  const thirdDimName = getDimensionFullName(TOP_DIM_3);
  const fourthDimName = getDimensionFullName(TOP_DIM_4);
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h2>{`2.1.1. ${firstDimName}`}</h2>
          {TOP_DIM_1 === 'IP' && <InfluenciaYPersonas />}
          {TOP_DIM_1 === 'FM' && <FabricacionYMecanica />}
          {TOP_DIM_1 === 'LM' && <LiderazgoYManagement />}
          {TOP_DIM_1 === 'CN' && <CienciaYNaturaleza />}
          {TOP_DIM_1 === 'AP' && <AdministracionYProyectos />}
          {TOP_DIM_1 === 'AL' && <ArtesYLiteratura />}
          {TOP_DIM_1 === 'SE' && <SoporteSocialYEducacion />}
          {TOP_DIM_1 === 'CD' && <CienciaYDatos />}

          <h2>{`2.1.2. ${secondDimName}`}</h2>
          {TOP_DIM_2 === 'IP' && <InfluenciaYPersonas />}
          {TOP_DIM_2 === 'FM' && <FabricacionYMecanica />}
          {TOP_DIM_2 === 'LM' && <LiderazgoYManagement />}
          {TOP_DIM_2 === 'CN' && <CienciaYNaturaleza />}
          {TOP_DIM_2 === 'AP' && <AdministracionYProyectos />}
          {TOP_DIM_2 === 'AL' && <ArtesYLiteratura />}
          {TOP_DIM_2 === 'SE' && <SoporteSocialYEducacion />}
          {TOP_DIM_2 === 'CD' && <CienciaYDatos />}

          {TOP_DIM_3 && (
            <>
              <h2>{`2.1.3. ${thirdDimName}`}</h2>
              {TOP_DIM_3 === 'IP' && <InfluenciaYPersonas />}
              {TOP_DIM_3 === 'FM' && <FabricacionYMecanica />}
              {TOP_DIM_3 === 'LM' && <LiderazgoYManagement />}
              {TOP_DIM_3 === 'CN' && <CienciaYNaturaleza />}
              {TOP_DIM_3 === 'AP' && <AdministracionYProyectos />}
              {TOP_DIM_3 === 'AL' && <ArtesYLiteratura />}
              {TOP_DIM_3 === 'SE' && <SoporteSocialYEducacion />}
              {TOP_DIM_3 === 'CD' && <CienciaYDatos />}
            </>
          )}
          {TOP_DIM_4 && (
            <>
              <h2>{`2.1.4. ${fourthDimName}`}</h2>
              {TOP_DIM_4 === 'IP' && <InfluenciaYPersonas />}
              {TOP_DIM_4 === 'FM' && <FabricacionYMecanica />}
              {TOP_DIM_4 === 'LM' && <LiderazgoYManagement />}
              {TOP_DIM_4 === 'CN' && <CienciaYNaturaleza />}
              {TOP_DIM_4 === 'AP' && <AdministracionYProyectos />}
              {TOP_DIM_4 === 'AL' && <ArtesYLiteratura />}
              {TOP_DIM_4 === 'SE' && <SoporteSocialYEducacion />}
              {TOP_DIM_4 === 'CD' && <CienciaYDatos />}
            </>
          )}

        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOneAreasDescriptionPage;
