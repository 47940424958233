import React, { useState, useEffect, Fragment } from 'react';
import { Deck, Slide } from 'spectacle';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import theme from './theme';
import { mergeTheme, oneTheme } from './deckTheme';
import topBackground from './imgs/02-top_equis_1.png';
import backBackground from './imgs/02-back_m.png';
import innerPresentationBack from './imgs/02-inner_equis1.png';
import innerPlainBack from './imgs/02-inner_equis1_plain.png';

import { ResetCSS, GlobalStyle, PageWrapper, PageNumber } from './styles';
// pages
import EquisOneTopPage from './Pages/Top';
import EquisOneClosePage from './Pages/Close';
import PresentationPage from './Pages/Presentation';
import InfoDataPage from './Pages/InfoData';
import OrientationDescriptionPage from './Pages/OrientationDescription';
import AreasGraphPage from './Pages/AreasGraph';
import AreasDescriptionPage from './Pages/AreasDescription';
import PreferencesGraphPage from './Pages/PreferencesGraph';
import PreferencesClarityPage from './Pages/PreferencesClarity';
import KnowledgeAreasGraphPage from './Pages/KnowledgeAreasGraph';
import CarrerasTop1FirstPage from './Pages/CarrerasTop1First';
import CarrerasTop1SecondPage from './Pages/CarrerasTop1Second';
import CarrerasTop2FirstPage from './Pages/CarrerasTop2First';
import CarrerasTop2SecondPage from './Pages/CarrerasTop2Second';
import DysfunctionalBeliefsFirstPage from './Pages/DysfunctionalBeliefsFirst';
import DysfunctionalBeliefsSecondPage from './Pages/DysfunctionalBeliefsSecond';
import DysfunctionalBeliefsThirdPage from './Pages/DysfunctionalBeliefsThird';
import DysfunctionalBeliefsForthPage from './Pages/DysfunctionalBeliefsForth';
import DecisionMakingFirstPage from './Pages/DecisionMakingFirst';
import DecisionMakingSecondPage from './Pages/DecisionMakingSecond';
import DecisionMakingThirdPage from './Pages/DecisionMakingThird';
import VocationalMaturityFirstPage from './Pages/VocationalMaturityFirst';
import VocationalMaturitySecondPage from './Pages/VocationalMaturitySecond';
import AboutUsPage from './Pages/AboutUs';

// Slider Deck theme
const deckTheme = mergeTheme(oneTheme);

// https://github.com/FormidableLabs/spectacle/blob/master/docs/content/advanced-concepts.md#query-parameters
// add to url
// &exportMode=true
export const SlidePage = ({ number, children, background }) => {
  const isEven = number % 2 === 0;
  console.log({ number, isEven });
  return (
    <PageWrapper backgroundImage={background}>
      {children}
      {number && <PageNumber even={isEven}>{number}</PageNumber>}
    </PageWrapper>
  );
};

// /equis-one/u/1xPPbmQNdcpbMHNOoE16Vz5Nx_QiBZHgfYIlISUt1Ku4/padre-hurtado-y-juanita-de-los-andes/21.293.144-6

// equis-junior
// /equis-junior/u/1CGuBUZ1v6Tw4YIV_RKvIZJG1Wp70rlmaXFficOIpWW0/liceo-siete-providencia/21.673.962-0

const EquisOne = props => {
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(false);
  const [user, setUser] = useState(equisOneDummy);
  console.log({ props });
  const {
    match: { params }
  } = props;

  useEffect(() => {
    console.log({ params });
    const fetchUser = async () => {
      // const url = `/api/get-user`;
      // const body = {
      //   rut: params.rut,
      //   SHEET_ID: params.sid,
      //   SHEET_TITLE: params.stitle
      // };
      // const response = await fetch(url, {
      //   method: 'POST',
      //   body: JSON.stringify(body)
      // });
      // const userData = await response.json();
      // console.log({ userData });
      // setUser(userData.user);

      const userData = equisOneDummy;
      const fields = Object.keys(userData);
      const sortedFirstAreaSums = fields
        .filter(field => field.includes('_FIRST_SUM'))
        .map(fieldKey => ({
          area: fieldKey.replace('perc_', '').replace('_FIRST_SUM', ''),
          value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
        }))
        .sort((a, b) => b.value - a.value);
      const sortedSecondAreaSums = fields
        .filter(field => field.includes('_SECOND_SUM'))
        .map(fieldKey => ({
          area: fieldKey.replace('perc_', '').replace('_SECOND_SUM', ''),
          value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
        }))
        .sort((a, b) => b.value - a.value);
      const decisionFields = [
        'Afrontamiento Productivo',
        'Búsqueda de Apoyo',
        'Afrontamiento No-Producivo'
      ];
      const sortedDecisionStyle = decisionFields
        .map(fieldKey => ({
          field: fieldKey,
          value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
        }))
        .sort((a, b) => b.value - a.value);
      // console.log({ sortedFirstAreaSums, sortedSecondAreaSums });
      // console.log({ sortedDecisionStyle });
      const parsedUser = {
        ...equisOneDummy,
        TOP_FIRST_1: sortedFirstAreaSums[0].area,
        TOP_FIRST_2: sortedFirstAreaSums[1].area,
        TOP_SECOND_1: sortedSecondAreaSums[0].area,
        TOP_SECOND_2: sortedSecondAreaSums[1].area,
        TOP_DECISION_STYLE: sortedDecisionStyle[0].field
      };
      setUser(parsedUser);
      setLoading(false);
    };
    fetchUser();
  }, [params]);

  console.log({ user });
  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Helmet>
          <title>{`${user.RUT}_EquisOne`}</title>
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <Deck theme={deckTheme} backgroundColor='#fff'>
          <Slide backgroundColor='#fff'>
            <EquisOneTopPage user={user} background={topBackground} />
          </Slide>

          {/** BLANK PAGE */}
          <Slide backgroundColor='#fff'>
            <SlidePage></SlidePage>
          </Slide>

          <Slide backgroundColor='#fff'>
            <PresentationPage
              user={user}
              number={1}
              background={innerPresentationBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <InfoDataPage user={user} number={2} background={innerPlainBack} />
          </Slide>

          <Slide backgroundColor='#fff'>
            <OrientationDescriptionPage
              number={3}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <AreasGraphPage
              user={user}
              number={4}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <AreasDescriptionPage
              user={user}
              number={5}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <PreferencesGraphPage
              user={user}
              number={6}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <PreferencesClarityPage
              user={user}
              number={7}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <KnowledgeAreasGraphPage
              user={user}
              number={8}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <CarrerasTop1FirstPage
              user={user}
              number={9}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <CarrerasTop1SecondPage
              user={user}
              number={10}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <CarrerasTop2FirstPage
              user={user}
              number={11}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <CarrerasTop2SecondPage
              user={user}
              number={12}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <DysfunctionalBeliefsFirstPage
              user={user}
              number={13}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <DysfunctionalBeliefsSecondPage
              user={user}
              number={14}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <DysfunctionalBeliefsThirdPage
              user={user}
              number={15}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <DysfunctionalBeliefsForthPage
              user={user}
              number={16}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <DecisionMakingFirstPage
              user={user}
              number={17}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <DecisionMakingSecondPage
              user={user}
              number={18}
              background={innerPlainBack}
            />
          </Slide>
          <Slide backgroundColor='#fff'>
            <DecisionMakingThirdPage
              user={user}
              number={19}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <VocationalMaturityFirstPage
              user={user}
              number={20}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <VocationalMaturitySecondPage
              user={user}
              number={21}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <AboutUsPage number={22} background={innerPlainBack} />
          </Slide>

          <Slide backgroundColor='#fff'>
            <EquisOneClosePage background={backBackground} />
          </Slide>
        </Deck>
      </Fragment>
    </ThemeProvider>
  );
};

export default EquisOne;

const equisOneDummy = {
  school: 'Padre Hurtado y Juanita de los Andes',
  grade: '3EM-D',
  name: 'DIEGO ALEJANDRO',
  lastname: 'CORTEZ LOPEZ',
  email: '',
  RUT: '18.117.565-6',
  BIRTH: '2003/09/09 0:00:00',
  SEX: 'Masculino',
  age: '17',
  updatedat: 'Mon, 09 Nov 2020 14:27:26 GMT',
  TOP_DIM_1: 'AL',
  TOP_DIM_2: 'SE',
  CONS_AL: '62.5',
  CONS_SE: '25',
  CONS_IP: '15.625',
  CONS_LM: '0',
  CONS_AP: '0',
  CONS_CD: '0',
  CONS_FM: '0',
  CONS_CN: '15.625',
  perc_AL_SUM: '96.875',
  perc_AP_SUM: '34.375',
  perc_CD_SUM: '31.25',
  perc_CN_SUM: '53.125',
  perc_FM_SUM: '37.5',
  perc_IP_SUM: '53.125',
  perc_LM_SUM: '37.5',
  perc_SE_SUM: '56.25',
  perc_AC_FIRST_SUM: '',
  perc_AG_FIRST_SUM: '',
  perc_AR_FIRST_SUM: '87.5',
  perc_CN_FIRST_SUM: '',
  perc_CS_FIRST_SUM: '',
  perc_D_FIRST_SUM: '',
  perc_E_FIRST_SUM: '37.5',
  perc_H_FIRST_SUM: '25',
  perc_S_FIRST_SUM: '',
  perc_T_FIRST_SUM: '50',
  perc_AC_SECOND_SUM: '',
  perc_AG_SECOND_SUM: '',
  perc_AR_SECOND_SUM: '',
  perc_CN_SECOND_SUM: '',
  perc_CS_SECOND_SUM: '75',
  perc_D_SECOND_SUM: '37.5',
  perc_E_SECOND_SUM: '75',
  perc_H_SECOND_SUM: '',
  perc_S_SECOND_SUM: '12.5',
  perc_T_SECOND_SUM: '',
  'Afrontamiento Productivo': '56',
  'Búsqueda de Apoyo': '61',
  'Afrontamiento No-Producivo': '17',
  'Rol de la casualidad': '1',
  'Implicancias de la Decisión': '100',
  'Rol de otros significativos': '3',
  'Rol de la ayuda profesional': '16',
  Género: '1',
  'Información sobre el proceso': '49',
  'Información sobre el Self': '9',
  'Información sobre Ocupaciones': '32',
  'Conflictos internos': '76',
  'Conflictos externos': '44',
  'Indecisión General': '41'
};
