import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import mapaOrientacionCarreras from '../imgs/mapa-orientacion-carreras.png';

const EquisOneOrientacionDescriptionPage = ({ background, number }) => {
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>2. Orientación Vocacional y Carreras</h1>
          <p className='justified'>
            El Test Equis One identiﬁca tus preferencias en base a un modelo
            circular de 8 preferencias principales. Generalmente las personas
            tienden a preferir cierto tipo de actividades por sobre otras, y
            cada una de las dimensiones del test describe estos diferentes
            intereses y orientaciones.
          </p>
          <div className='image-wrapper'>
            <img
              src={mapaOrientacionCarreras}
              alt='mapa Orientación Vocacional y Carreras'
              style={{ marginLeft: -20 }}
            />
          </div>
          <p className='justified'>
            En este modelo, cada una de las 8 preferencias tiene un polo
            opuesto. Por ejemplo, la dimensión <em>Inﬂuencia y Personas</em> es
            el opuesto a <em>Fabricación y Mecánica</em>, mientras que la
            dimensión de <em> Artes y Literatura</em> es el opuesto de
            <em> Administración y Proyectos</em>. De acuerdo a este modelo, las
            personas que tienden a tener mayor preferencia por un área, deberían
            tener al mismo tiempo poco interés por el polo opuesto.
          </p>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOneOrientacionDescriptionPage;
