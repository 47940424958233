const colors = {
  red: '#dc3545',
  green: '#28a745',
  blue: '#009fe3',
  yellow: '#ffc107',
  orange: '#ff8300',
  dark: '#343a40',
  grey: '#6c757d',
  extraLight: '#fefefe',
  light: '#f1f1f1',
  disabled: '#adb5bd',
  headings: '#060F1E'
};

export default colors;
