import React, { useState, useEffect, Fragment } from 'react';
import { Deck, Slide } from 'spectacle';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import theme from './theme';
import { mergeTheme, oneTheme } from './deckTheme';
import topBackground from './imgs/02-top.png';
import backBackground from './imgs/02-back_m.png';
import innerPresentationBack from './imgs/02-inner_presentation.png';
import innerPlainBack from './imgs/02-inner_plain.png';

import { ResetCSS, GlobalStyle, PageWrapper, PageNumber } from './styles';
// pages
import EquisJuniorTopPage from './Pages/Top';
import EquisJuniorClosePage from './Pages/Close';
import PresentationPage from './Pages/Presentation';
import InfoDataPage from './Pages/InfoData';
import OrientationDescriptionPage from './Pages/OrientationDescription';
import AreasGraphPage from './Pages/AreasGraph';

import AreasDescriptionPage from './Pages/AreasDescription';
import PreferencesGraphPage from './Pages/PreferencesGraph';
// import PreferencesClarityPage from './Pages/PreferencesClarity';
import InterestDescriptionPage from './Pages/InterestDescription';
import InterestGraphPage from './Pages/InterestGraph';
import InterestAreaDescriptionPage from './Pages/InterestAreaDescription';
import DisciplinesGraphPage from './Pages/DisciplinesGraph';
import TopDisciplinesPage from './Pages/TopDisciplines';
import EnfoqueGraphPage from './Pages/EnfoqueGraph';
import MotivationScalesDescriptionPage from './Pages/MotivationScalesDescription';
import StrategyScalesDescriptionPage from './Pages/StrategyScalesDescription';

// import KnowledgeAreasGraphPage from './Pages/KnowledgeAreasGraph';
// import CarrerasTop1FirstPage from './Pages/CarrerasTop1First';
// import CarrerasTop1SecondPage from './Pages/CarrerasTop1Second';
// import CarrerasTop2FirstPage from './Pages/CarrerasTop2First';
// import CarrerasTop2SecondPage from './Pages/CarrerasTop2Second';
// import DysfunctionalBeliefsFirstPage from './Pages/DysfunctionalBeliefsFirst';
// import DysfunctionalBeliefsSecondPage from './Pages/DysfunctionalBeliefsSecond';
// import DysfunctionalBeliefsThirdPage from './Pages/DysfunctionalBeliefsThird';
// import DysfunctionalBeliefsForthPage from './Pages/DysfunctionalBeliefsForth';
// import DecisionMakingFirstPage from './Pages/DecisionMakingFirst';
// import DecisionMakingSecondPage from './Pages/DecisionMakingSecond';
// import DecisionMakingThirdPage from './Pages/DecisionMakingThird';
// import VocationalMaturityFirstPage from './Pages/VocationalMaturityFirst';
// import VocationalMaturitySecondPage from './Pages/VocationalMaturitySecond';
import AboutUsPage from './Pages/AboutUs';

// Slider Deck theme
const deckTheme = mergeTheme(oneTheme);

// https://github.com/FormidableLabs/spectacle/blob/master/docs/content/advanced-concepts.md#query-parameters
// add to url
// &exportMode=true
export const SlidePage = ({ number, children, background }) => {
  const isEven = number % 2 === 0;
  // console.log({ number, isEven });
  return (
    <PageWrapper backgroundImage={background}>
      {children}
      {number && <PageNumber even={isEven}>{number}</PageNumber>}
    </PageWrapper>
  );
};

// /equis-one/u/1xPPbmQNdcpbMHNOoE16Vz5Nx_QiBZHgfYIlISUt1Ku4/padre-hurtado-y-juanita-de-los-andes/21.293.144-6

// equis-junior
// /equis-junior/u/1CGuBUZ1v6Tw4YIV_RKvIZJG1Wp70rlmaXFficOIpWW0/liceo-siete-providencia/21.673.962-0

// /r/84af0b5d-5ced-44c5-acda-bd71ae233972

const EquisJunior = ({ campaign, response, data }) => {
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  // console.log({ props });

  useEffect(() => {
    // console.log({ params });
    const parseUser = async () => {
      const userData = data;
      // // –––– for local  dummy
      // const userData = equisJuniorDummy;
      // // –––––––––––––––––––––
      const fields = Object.keys(userData);
      const interestAreaFields = ['A', 'B', 'C'];
      const sortedInterestArea = interestAreaFields
        .map(fieldKey => ({
          field: fieldKey,
          value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
        }))
        .sort((a, b) => b.value - a.value);
      const sorted_A = fields
        .filter(field => field.includes('perc_A_'))
        .map(fieldKey => ({
          field: fieldKey,
          value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
        }))
        .sort((a, b) => b.value - a.value);

      const sorted_B = fields
        .filter(field => field.includes('perc_B_'))
        .map(fieldKey => ({
          field: fieldKey,
          value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
        }))
        .sort((a, b) => b.value - a.value);
      const sorted_C = fields
        .filter(field => field.includes('perc_C_'))
        .map(fieldKey => ({
          field: fieldKey,
          value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
        }))
        .sort((a, b) => b.value - a.value);
      const sortedDisp = [...sorted_A, ...sorted_B, ...sorted_C].sort(
        (a, b) => b.value - a.value
      );
      // console.log({ sortedDisp });
      const baseEquisInfo = {
        RUT: userData.rut,
        name: '',
        lastname: '',
        grade: '',
        school: campaign.organization || campaign.title
      };
      const school = userData.school || baseEquisInfo.school;
      const parsedUser = {
        ...baseEquisInfo,
        ...userData,
        school,
        sorted_A,
        sorted_B,
        sorted_C,
        sortedDisp,
        TOP_AREA: sortedInterestArea[0].field,
        createdAt: response.createdAt,
        updatedAt: response.updatedAt
      };
      setUser(parsedUser);
      setLoading(false);
    };
    parseUser();
  }, [data]);

  // console.log({ user });
  if (loading) {
    return <div>loading...</div>;
  }
  const userSchool = user.school ? `_${user.school.split(' ').join('_')}` : '';
  const userGrade = user.grade ? `${user.grade.split(' ').join('_')}_` : '';
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Helmet>
          <title>{`${userGrade}${user.RUT}${userSchool}_EquisJunior`}</title>
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <Deck theme={deckTheme} backgroundColor='#fff'>
          <Slide backgroundColor='#fff'>
            <EquisJuniorTopPage user={user} background={topBackground} />
          </Slide>

          {/** BLANK PAGE */}
          <Slide backgroundColor='#fff'>
            <SlidePage></SlidePage>
          </Slide>

          <Slide backgroundColor='#fff'>
            <PresentationPage
              user={user}
              number={1}
              background={innerPresentationBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <InfoDataPage user={user} number={2} background={innerPlainBack} />
          </Slide>

          <Slide backgroundColor='#fff'>
            <OrientationDescriptionPage
              number={3}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <AreasGraphPage
              user={user}
              number={4}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <AreasDescriptionPage
              user={user}
              number={5}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <PreferencesGraphPage
              user={user}
              number={6}
              background={innerPlainBack}
            />
          </Slide>

          {/* <Slide backgroundColor='#fff'>
            <PreferencesClarityPage
              user={user}
              number={7}
              background={innerPlainBack}
            />
          </Slide> */}

          <Slide backgroundColor='#fff'>
            <InterestDescriptionPage
              user={user}
              number={7}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <InterestGraphPage
              user={user}
              number={8}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <InterestAreaDescriptionPage
              user={user}
              number={9}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <DisciplinesGraphPage
              user={user}
              number={10}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <TopDisciplinesPage
              user={user}
              number={11}
              background={innerPlainBack}
            />
          </Slide>

          {/* <Slide backgroundColor='#fff'>
            <EnfoqueGraphPage
              user={user}
              number={12}
              background={innerPlainBack}
            />
          </Slide> */}
          <Slide backgroundColor='#fff'>
            <MotivationScalesDescriptionPage
              user={user}
              number={12}
              background={innerPlainBack}
            />
          </Slide>
          <Slide backgroundColor='#fff'>
            <StrategyScalesDescriptionPage
              user={user}
              number={13}
              background={innerPlainBack}
            />
          </Slide>

          {/*
          <Slide backgroundColor='#fff'>
            <KnowledgeAreasGraphPage
              user={user}
              number={8}
              background={innerPlainBack}
            />
          </Slide> */}

          {/* <Slide backgroundColor='#fff'>
            <CarrerasTop1FirstPage
              user={user}
              number={9}
              background={innerPlainBack}
            />
          </Slide> */}

          {/* <Slide backgroundColor='#fff'>
            <CarrerasTop1SecondPage
              user={user}
              number={10}
              background={innerPlainBack}
            />
          </Slide> */}

          {/* <Slide backgroundColor='#fff'>
            <CarrerasTop2FirstPage
              user={user}
              number={11}
              background={innerPlainBack}
            />
          </Slide> */}

          {/* <Slide backgroundColor='#fff'>
            <CarrerasTop2SecondPage
              user={user}
              number={12}
              background={innerPlainBack}
            />
          </Slide> */}

          {/* <Slide backgroundColor='#fff'>
            <DysfunctionalBeliefsFirstPage
              user={user}
              number={13}
              background={innerPlainBack}
            />
          </Slide> */}

          {/* <Slide backgroundColor='#fff'>
            <DysfunctionalBeliefsSecondPage
              user={user}
              number={14}
              background={innerPlainBack}
            />
          </Slide> */}

          {/* <Slide backgroundColor='#fff'>
            <DysfunctionalBeliefsThirdPage
              user={user}
              number={15}
              background={innerPlainBack}
            />
          </Slide> */}

          {/* <Slide backgroundColor='#fff'>
            <DysfunctionalBeliefsForthPage
              user={user}
              number={16}
              background={innerPlainBack}
            />
          </Slide> */}

          {/* <Slide backgroundColor='#fff'>
            <DecisionMakingFirstPage
              user={user}
              number={17}
              background={innerPlainBack}
            />
          </Slide> */}

          {/* <Slide backgroundColor='#fff'>
            <DecisionMakingSecondPage
              user={user}
              number={18}
              background={innerPlainBack}
            />
          </Slide> */}

          {/* <Slide backgroundColor='#fff'>
            <DecisionMakingThirdPage
              user={user}
              number={19}
              background={innerPlainBack}
            />
          </Slide> */}

          {/*
          <Slide backgroundColor='#fff'>
            <VocationalMaturityFirstPage
              user={user}
              number={20}
              background={innerPlainBack}
            />
          </Slide> */}

          {/* <Slide backgroundColor='#fff'>
            <VocationalMaturitySecondPage
              user={user}
              number={21}
              background={innerPlainBack}
            />
          </Slide> */}

          <Slide backgroundColor='#fff'>
            <AboutUsPage number={14} background={innerPlainBack} />
          </Slide>

          <Slide backgroundColor='#fff'>
            <EquisJuniorClosePage background={backBackground} />
          </Slide>
        </Deck>
      </Fragment>
    </ThemeProvider>
  );
};

export default EquisJunior;

// const equisJuniorDummy = {
//   school: 'Colegio Equis Junior',
//   grade: '2°A',
//   name: 'Nombre(s)',
//   lastname: 'Apellido(s)',
//   email: '',
//   RUT: '99.999.999-9',
//   BIRTH: '2004/06/09 0:00:00',
//   SEX: 'Femenino',
//   age: '16',
//   updatedat: 'Wed, 18 Nov 2020 18:34:26 GMT',
//   TOP_DIM_1: 'CN',
//   TOP_DIM_2: 'LM',
//   CONS_AL: '-25',
//   CONS_SE: '21.875',
//   CONS_IP: '12.5',
//   CONS_LM: '-15.625',
//   CONS_AP: '25',
//   CONS_CD: '-21.875',
//   CONS_FM: '-12.5',
//   CONS_CN: '15.625',
//   perc_AL_SUM: '18.75',
//   perc_AP_SUM: '43.75',
//   perc_CD_SUM: '37.5',
//   perc_CN_SUM: '87.5',
//   perc_FM_SUM: '34.375',
//   perc_IP_SUM: '46.875',
//   perc_LM_SUM: '71.875',
//   perc_SE_SUM: '59.375',
//   perc_A_F: '50',
//   perc_A_HGC: '44.44444444',
//   perc_A_LL: '50',
//   perc_B_CBS: '100',
//   perc_B_CD: '100',
//   perc_B_M: '61.11111111',
//   perc_C_AI: '0',
//   perc_C_AV: '22.22222222',
//   perc_C_D: '22.22222222',
//   A: '48.14814815',
//   B: '87.03703704',
//   C: '14.81481481'
// };
