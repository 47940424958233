import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer, Spacer } from '../styles';

const EquisOnePresentationPage = ({ user, background, number }) => {
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='right-content'>
          <h1>
            ¡Hola <span className='capitalized'>{user.name.toLowerCase()}</span>
            !
          </h1>
          <p className='justified'>
            El presente informe resume los resultados que obtuviste tras
            responder el Test Equis Junior. Este test está orientado al proceso
            de toma de decisiones en relación a tu futuro proyecto de vida,
            entregándote información respecto de tus preferencias e intereses
            vocacionales, y orientaciones para la elección de asignaturas de
            profundización en los últimos años de tu etapa escolar. Además, te
            entrega información sobre la forma en que actualmente enfrentas tus
            estudios.
          </p>

          <h2>Orientación Vocacional</h2>
          <p className='justified'>
            El Test Equis Junior entrega información acerca de tus{' '}
            <strong>principales intereses o preferencias</strong>. Conocer esta
            información sobre ti te permite identificar aquellas actividades que
            probablemente te gustaría más hacer o con las que te sentirías más
            cómodo en un futuro laboral.
          </p>

          <h2>Asignaturas de profundización</h2>
          <p className='justified'>
            En tu última etapa escolar debes elegir tres asignaturas de
            profundización de acuerdo a tus intereses personales y/o proyecto de
            vida. El Test Equis Junior te entrega información sobre las{' '}
            <strong>áreas disciplinares </strong> en las que te interesaría más
            desarrollar tus conocimientos o habilidades para que puedas realizar
            tu elección de asignaturas de profundización de manera informada.
          </p>

          <h2>Aproximaciones al estudio</h2>
          <p className='justified'>
            El Test Equis Junior te entrega, además, información respecto de la
            forma en que te enfrentas a tus procesos de aprendizaje,
            identificando las principales orientaciones que tienes para estudiar,
            y las principales estrategias o formas en que estudias. Las
            orientaciones para estudiar o aprender y la forma en que normalmente
            estudias pueden influir positiva o negativamente en el logro de los
            objetivos que te propongas para tu etapa escolar, por lo que es
            importante identificar qué es lo que te motiva más para aprender, y
            si estás utilizando las estrategias de estudio apropiadas.
          </p>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOnePresentationPage;
