import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { getAreaShortName, dimColors } from '../constants';

const colors = [
  '#46df34',
  '#7dcc2b',
  '#9db821',
  '#b4a218',
  '#c78b0e',
  '#d77005',
  '#e44f01',
  '#ef0000'
];

const groups = ['> 0', '> 10', '> 20', '> 30', '> 40', '> 50', '> 60', '> 70'];

const getColor = value => {
  if (value > 70) {
    return colors[7];
  }
  if (value > 60) {
    return colors[6];
  }
  if (value > 50) {
    return colors[5];
  }
  if (value > 40) {
    return colors[4];
  }
  if (value > 30) {
    return colors[3];
  }
  if (value > 20) {
    return colors[2];
  }
  if (value > 10) {
    return colors[1];
  }
  if (value > 0) {
    return colors[0];
  }
  return '#dadada';
};
const getMapData = ({ results }) => {
  // console.log({ results });
  const positions = results.map(res => {
    const color = getColor(parseFloat(res.Speed));
    const dot = {
      // dar vuelta x e y
      x: parseFloat(res.Y),
      y: parseFloat(res.X),
      z: parseFloat(res.Z),
      speed: parseFloat(res.Speed),
      color
    };
    return dot;
  });
  return positions;
};

const CustomNode = props => {
  const {
    node,
    x,
    y,
    size,
    // color,
    blendMode,
    onMouseEnter,
    onMouseMove,
    onMouseLeave,
    onClick
  } = props;
  // console.log({ props });
  return (
    <g transform={`translate(${x},${y})`}>
      <circle
        r={size / 2}
        // width={size}
        // height={size}
        // fill={color}
        fill={node.data.color}
        style={{ mixBlendMode: blendMode }}
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      />
    </g>
  );

  // if (node.data.serieId === 'A') {
  //   return (
  //     <g transform={`translate(${x},${y})`}>
  //       <circle
  //         r={size / 2}
  //         fill={color}
  //         style={{ mixBlendMode: blendMode }}
  //         onMouseEnter={onMouseEnter}
  //         onMouseMove={onMouseMove}
  //         onMouseLeave={onMouseLeave}
  //         onClick={onClick}
  //       />
  //     </g>
  //   );
  // }

  // if (node.data.serieId === 'B') {
  //   return (
  //     <g transform={`translate(${x},${y}) rotate(45)`}>
  //       <rect
  //         x={size * -0.5}
  //         y={size * -0.5}
  //         width={size}
  //         height={size}
  //         fill={color}
  //         style={{ mixBlendMode: blendMode }}
  //         onMouseEnter={onMouseEnter}
  //         onMouseMove={onMouseMove}
  //         onMouseLeave={onMouseLeave}
  //         onClick={onClick}
  //       />
  //     </g>
  //   );
  // }

  // return (
  //   <g transform={`translate(${x},${y})`}>
  //     <rect
  //       x={size * -0.5}
  //       y={size * -0.5}
  //       width={size}
  //       height={size}
  //       fill={color}
  //       style={{ mixBlendMode: blendMode }}
  //       onMouseEnter={onMouseEnter}
  //       onMouseMove={onMouseMove}
  //       onMouseLeave={onMouseLeave}
  //       onClick={onClick}
  //     />
  //   </g>
  // );
};

const MyResponsiveBar = ({ user }) => {
  // console.log({ user });
  // const areasData = getAreaData({ user, first, second });
  const mapData = getMapData({ results: user.results });
  console.log({ mapData });

  // return null;
  return (
    <>
      <ResponsiveScatterPlot
        data={[{ id: 'positions', data: mapData }]}
        margin={{ top: 10, right: 80, bottom: 10, left: 10 }}
        xScale={{ type: 'linear', min: -50000, max: 300000 }}
        // xScale={{ type: 'linear', min: -220000, max: 300000 }}
        // xFormat={function (e) {
        //   return e + ' kg';
        // }}
        yScale={{ type: 'linear', min: -50000, max: 300000 }}
        // yScale={{ type: 'linear', min: -200000, max: 300000 }}
        // yFormat={function (e) {
        //   return e + ' cm';
        // }}
        blendMode='normal'
        axisTop={null}
        axisRight={null}
        axisLeft={null}
        axisBottom={null}
        // axisBottom={{
        //   orient: 'bottom',
        //   tickSize: 5,
        //   tickPadding: 5,
        //   tickRotation: 0,
        //   legend: 'weight',
        //   legendPosition: 'middle',
        //   legendOffset: 46
        // }}
        // axisLeft={{
        //   orient: 'left',
        //   tickSize: 5,
        //   tickPadding: 5,
        //   tickRotation: 0,
        //   legend: 'size',
        //   legendPosition: 'middle',
        //   legendOffset: -60
        // }}
        annotations={[
          // {
          //   type: 'circle',
          //   match: { index: mapData.length - 1 },
          //   noteX: -20,
          //   noteY: -40,
          //   offset: 3,
          //   noteTextOffset: -3,
          //   noteWidth: 65,
          //   note: 'Finalizado'
          // },
          {
            type: 'circle',
            match: { index: mapData.length - 1 },
            noteX: 20,
            noteY: 40,
            offset: 3,
            noteTextOffset: -3,
            noteWidth: 45,
            note: 'Finalizado'
          }
        ]}
        // legends={[
        //   {
        //     data: [
        //       { id: 'hola', label: 'label', color: '#666666', fill: '#666666' }
        //     ],
        //     anchor: 'bottom-right',
        //     direction: 'column',
        //     justify: false,
        //     translateX: 130,
        //     translateY: 0,
        //     itemWidth: 100,
        //     itemHeight: 22,
        //     itemsSpacing: 0,
        //     itemDirection: 'left-to-right',
        //     symbolSize: 22,
        //     symbolShape: 'square',
        //     effects: [
        //       {
        //         on: 'hover',
        //         style: {
        //           itemOpacity: 1
        //         }
        //       }
        //     ]
        //   }
        // ]}
        // legends={props = () => {
        //   console.log({ props });
        //   return [
        //     {
        //       anchor: 'bottom-right',
        //       direction: 'column',
        //       justify: false,
        //       translateX: 130,
        //       translateY: 0,
        //       itemWidth: 100,
        //       itemHeight: 12,
        //       itemsSpacing: 5,
        //       itemDirection: 'left-to-right',
        //       symbolSize: 12,
        //       symbolShape: 'circle',
        //       effects: [
        //         {
        //           on: 'hover',
        //           style: {
        //             itemOpacity: 1
        //           }
        //         }
        //       ]
        //     }
        //   ]
        // }}
        renderNode={CustomNode}
        colors={colors}
        // keys={groups}
        animate={false}
        motionStiffness={90}
        motionDamping={15}
        isInteractive={false}
      />
      <div className='legend-right'>
        <div className='legend-title'>Velocidad (Km/h)</div>
        {[...groups].reverse().map((group, index) => (
          <div className='legend-row' key={group}>
            <div
              className='legend-shape'
              style={{ backgroundColor: colors[colors.length - index] }}
            ></div>
            <div className='legend-label'>{group}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MyResponsiveBar;
