import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';

const EstiloConduccionPage = ({ user, background, number }) => {
  // console.log({ user });
  const ITEM_NOT_OBSERVED = ITEMS.filter(it => user[it.code] === '2');
  const firstHalf = ITEM_NOT_OBSERVED.slice(0, 25);
  const secondHalf = ITEM_NOT_OBSERVED.slice(25);
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>5. Evaluación Instructor</h1>
          <p className='justified'>
            A continuación, verá una lista de comportamientos{' '}
            <strong>NO observados </strong>
            durante el ejercicio y que REQUIEREN SU ATENCIÓN.
          </p>

          <div
            className={`open-list-wrapper${
              secondHalf.length > 0 ? ' muticolumn' : ''
            }`}
          >
            <ul>
              {firstHalf.map(it => {
                return <li key={it.code}>{it.statement}</li>;
              })}
            </ul>
            <ul>
              {secondHalf.map(it => {
                return <li key={it.code}>{it.statement}</li>;
              })}
            </ul>
          </div>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EstiloConduccionPage;

const ITEMS = [
  { code: 'DPE_01', group: 'CAR_MOVEMENT', statement: 'Chequea los espejos' },
  {
    code: 'DPE_02',
    group: 'CAR_MOVEMENT',
    statement: 'Pone la transmisión en neutro'
  },
  {
    code: 'DPE_03',
    group: 'CAR_MOVEMENT',
    statement: 'Pasa los cambios suavemente'
  },
  {
    code: 'DPE_04',
    group: 'CAR_MOVEMENT',
    statement: 'Arranca el motor sin dificultad'
  },
  {
    code: 'DPE_05',
    group: 'CAR_MOVEMENT',
    statement: 'Espera que el motor se caliente'
  },
  {
    code: 'DPE_06',
    group: 'CAR_MOVEMENT',
    statement: 'Deja que se forme presión de aire antes de salir'
  },
  {
    code: 'DPE_07',
    group: 'CAR_MOVEMENT',
    statement: 'Chequea los frenos a 30 metros de la partida'
  },
  {
    code: 'DPE_08',
    group: 'CAR_MOVEMENT',
    statement: 'Chequea y entiende los instrumentos'
  },
  {
    code: 'DPE_09',
    group: 'CAR_MOVEMENT',
    statement: 'Usa la palanca de cambio de forma apropiada'
  },
  {
    code: 'DPE_10',
    group: 'CAR_MOVEMENT',
    statement: 'Mantiene un RPM adecuado'
  },
  {
    code: 'DPE_11',
    group: 'CAR_MOVEMENT',
    statement: 'Pasa los cambios cuando corresponde'
  },
  {
    code: 'DPE_12',
    group: 'CAR_MOVEMENT',
    statement: 'Usa los cambios en la secuencia adecuada'
  },
  {
    code: 'DPE_13',
    group: 'CAR_MOVEMENT',
    statement: 'Usa el cinturón de seguridad'
  },
  //
  {
    code: 'DPE_14',
    group: 'BREAK_REDUCTION',
    statement: 'Frena tan pronto cuando surge la necesidad'
  },
  {
    code: 'DPE_15',
    group: 'BREAK_REDUCTION',
    statement: 'Evita frenar de manera repentina'
  },
  {
    code: 'DPE_16',
    group: 'BREAK_REDUCTION',
    statement: 'Se detiene con suavidad, sin patinar'
  },
  {
    code: 'DPE_17',
    group: 'BREAK_REDUCTION',
    statement: 'Chequea los frenos en la cima de una subida'
  },
  {
    code: 'DPE_18',
    group: 'BREAK_REDUCTION',
    statement: 'Baja los cambios para descender'
  },
  {
    code: 'DPE_19',
    group: 'BREAK_REDUCTION',
    statement: 'Usa el freno de manera adecuada al descender'
  },
  {
    code: 'DPE_20',
    group: 'BREAK_REDUCTION',
    statement: 'Chequea las válvulas de aire'
  },
  //
  {
    code: 'DPE_21',
    group: 'DRIVE_TRAFFIC',
    statement: 'Entra a las intersecciones listo para frenar'
  },
  {
    code: 'DPE_22',
    group: 'DRIVE_TRAFFIC',
    statement: 'Chequea el tráfico en el cruce'
  },
  { code: 'DPE_23', group: 'DRIVE_TRAFFIC', statement: 'Deja pasar a otros' },
  {
    code: 'DPE_24',
    group: 'DRIVE_TRAFFIC',
    statement: 'Señaliza a tiempo el viraje'
  },
  {
    code: 'DPE_25',
    group: 'DRIVE_TRAFFIC',
    statement: 'Se ubica en la pista de viraje con anticipación'
  },
  {
    code: 'DPE_26',
    group: 'DRIVE_TRAFFIC',
    statement: 'Vira sólo cuando está despejado'
  },
  {
    code: 'DPE_27',
    group: 'DRIVE_TRAFFIC',
    statement: 'Bloquea el tráfico del lado de la curva de viraje'
  },
  //
  { code: 'DPE_28', group: 'SIGNS', statement: 'Arranca de manera pausada' },
  {
    code: 'DPE_29',
    group: 'SIGNS',
    statement: 'Mira hacia adelante buscando signos y señalética'
  },
  {
    code: 'DPE_30',
    group: 'SIGNS',
    statement: 'En un disco pare se detiene por completo'
  },
  {
    code: 'DPE_31',
    group: 'SIGNS',
    statement:
      'Se aproxima a las intersecciones con señalética preparado para frenar'
  },
  {
    code: 'DPE_32',
    group: 'SIGNS',
    statement: 'Desacelera de forma paulatina'
  },
  //
  {
    code: 'DPE_33',
    group: 'GRADE_CROSS',
    statement: 'Ajusta la velocidad a las condiciones para cruzar'
  },
  {
    code: 'DPE_34',
    group: 'GRADE_CROSS',
    statement: 'Si es necesario se detiene completamente'
  },
  {
    code: 'DPE_35',
    group: 'GRADE_CROSS',
    statement: 'Se detiene a una distancia adecuada de un cruce'
  },
  {
    code: 'DPE_36',
    group: 'GRADE_CROSS',
    statement: 'Evita pasar cambios al cruzar'
  },
  //
  {
    code: 'DPE_37',
    group: 'ADELANTAMIENTO',
    statement: 'Deja suficiente espacio para adelantar'
  },
  {
    code: 'DPE_38',
    group: 'ADELANTAMIENTO',
    statement: 'Adelanta sólo cuando hay un amplio espacio'
  },
  {
    code: 'DPE_39',
    group: 'ADELANTAMIENTO',
    statement: 'Adelanta sólo en un lugar seguro'
  },
  {
    code: 'DPE_40',
    group: 'ADELANTAMIENTO',
    statement: 'Señaliza para cambiarse de pista'
  },
  {
    code: 'DPE_41',
    group: 'ADELANTAMIENTO',
    statement: 'Señaliza antes de adelantar'
  },
  {
    code: 'DPE_42',
    group: 'ADELANTAMIENTO',
    statement: 'Adelanta por la pista correcta'
  },
  //
  {
    code: 'DPE_43',
    group: 'VELOCIDAD',
    statement: 'Se mantiene dentro del límite de velocidad'
  },
  {
    code: 'DPE_44',
    group: 'VELOCIDAD',
    statement: 'Mantiene un intervalo respecto de los vehículos adelante'
  },
  {
    code: 'DPE_45',
    group: 'VELOCIDAD',
    statement: 'Adecúa su velocidad a las condiciones de manejo'
  },
  {
    code: 'DPE_46',
    group: 'VELOCIDAD',
    statement: 'Mantiene una velocidad constante en carretera'
  },
  {
    code: 'DPE_47',
    group: 'VELOCIDAD',
    statement: 'Ajusta su velocidad en las curvas o zonas peligrosas'
  },
  //
  {
    code: 'DPE_48',
    group: 'GENERAL',
    statement: 'Respeta las normas de seguridad al conducir'
  },
  {
    code: 'DPE_49',
    group: 'GENERAL',
    statement: 'Toma el volante de forma correcta'
  },
  {
    code: 'DPE_50',
    group: 'GENERAL',
    statement: 'Tiene una buena postura para conducir'
  },
  {
    code: 'DPE_51',
    group: 'GENERAL',
    statement: 'Mantiene el vehículo centrado en la pista'
  },
  { code: 'DPE_52', group: 'GENERAL', statement: 'Está atento al tráfico' },
  {
    code: 'DPE_53',
    group: 'GENERAL',
    statement: 'Usa los espejos de manera correcta'
  },
  {
    code: 'DPE_54',
    group: 'GENERAL',
    statement: 'Evita quedar acorralado por otros vehículos'
  },
  {
    code: 'DPE_55',
    group: 'GENERAL',
    statement: 'Toma buenas decisiones al conducir'
  },
  //
  {
    code: 'DPE_56',
    group: 'SIMULATOR',
    statement: 'Fue capaz de dominar sus emociones'
  },
  {
    code: 'DPE_57',
    group: 'SIMULATOR',
    statement: 'Prestó atención a las instrucciones del instructor'
  },
  {
    code: 'DPE_58',
    group: 'SIMULATOR',
    statement: 'Siguió las recomendaciones del instructor'
  },
  {
    code: 'DPE_59',
    group: 'SIMULATOR',
    statement: 'Hizo preguntas que indican interés y curiosidad'
  },
  { code: 'DPE_60', group: 'SIMULATOR', statement: 'Llegó puntualmente' },
  {
    code: 'DPE_61',
    group: 'SIMULATOR',
    statement: 'Completó todas las actividades planificadas'
  },
  {
    code: 'DPE_62',
    group: 'SIMULATOR',
    statement: 'Estuvo atento, presente durante todo el ejercicio'
  }
];
