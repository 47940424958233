import React from 'react';
import styled from 'styled-components';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
// import { getDimensionFullName } from '../constants';
import mapaIntereses from '../imgs/junior_model.png';

export const ChartWrapper = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
  position: relative;
  ${'' /* bug with polar lines on radar pdf print */}
  @media print {
    svg {
      g {
        line {
          opacity: 0;
        }
      }
    }
  }
`;

const EquisJuniorInterestDescriptionPage = ({ user, background, number }) => {
  // const { TOP_AREA, sorted_A, sorted_B, sorted_C } = user;
  // const firstDimName = getDimensionFullName(TOP_DIM_1);
  // const secondDimName = getDimensionFullName(TOP_DIM_2);
  // console.log({ TOP_AREA, sorted_A, sorted_B, sorted_C });
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>3. Áreas Disciplinares de Interés</h1>
          <p className='justified'>
            El Equis Junior te ayuda a identificar las áreas en las que más te
            gustaría profundizar en los próximos años. Los resultados están
            organizados en nueve disciplinas que, a su vez, son parte de tres
            grandes áreas (A, B, y C).
          </p>
          <br />
          <br />
          <div className='image-wrapper'>
            <img src={mapaIntereses} alt='mapa intereses disciplinas' />
          </div>
          {/* <ChartWrapper>
            <RadarChart user={user} />
          </ChartWrapper> */}
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisJuniorInterestDescriptionPage;
