import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
// import dummData from './junior_data.json';
// import dummData from './one_data.json';

const INITIAL_VALUES = {};

const DataContext = createContext(INITIAL_VALUES);

export const DataProvider = props => {
  const { children, id, campaignAccessKey, initial } = props;
  const [state, setState] = useState(initial || INITIAL_VALUES);
  const [campaign, setCampaign] = useState(null);
  const [responses, setResponses] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // console.log('on data context results');
    const getRespData = async () => {
      const POST_API = `/api/campaign`;
      const body = {
        params: {
          id,
          accessKey: campaignAccessKey
        }
      };
      // console.log('prev calling');
      // const options = { ...opts, headers };
      // console.log({ body, options });
      const { data } = await axios.get(POST_API, body);
      // const data = dummData;
      // console.log({ data });
      if (data) {
        const campaignData = {
          ...data.campaign,
          participants:
            typeof data.campaign.participants === 'string'
              ? JSON.parse(data.campaign.participants)
              : data.campaign.participants
        };
        try {
          // const parsed = JSON.parse(data);
          const finishedResponses = data.responses.filter(
            resp => resp.submitted
          );
          const campaignResponses = finishedResponses.map(resp => {
            return {
              ...resp,
              data:
                typeof resp.data === 'string'
                  ? JSON.parse(resp.data)
                  : resp.data
            };
          });
          await setCampaign(campaignData);
          await setResponses(campaignResponses);
        } catch (err) {
          console.log(err);
          await setCampaign(null);
        }
      }
      await setLoading(false);
    };
    getRespData();
  }, [id, campaignAccessKey]);

  return (
    <DataContext.Provider
      value={{
        ...state,
        updateContext: setState,
        campaign,
        responses,
        isLoading: loading
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const DataConsumer = DataContext.Consumer;
export const useDataContext = () => useContext(DataContext);
