import React from 'react';
import styled from 'styled-components';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import PieChart from '../PieChart';
// import { getDimensionFullName } from '../constants';

export const ChartWrapper = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
  position: relative;
  ${'' /* bug with polar lines on radar pdf print */}
  @media print {
    svg {
      g {
        line {
          opacity: 0;
        }
      }
    }
  }
`;

const EquisJuniorInterestGraphPage = ({ user, background, number }) => {
  // const { TOP_AREA, sorted_A, sorted_B, sorted_C } = user;
  // const firstDimName = getDimensionFullName(TOP_DIM_1);
  // const secondDimName = getDimensionFullName(TOP_DIM_2);
  // console.log({ TOP_AREA, sorted_A, sorted_B, sorted_C });
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h2>3.1. Grandes Áreas de Interés</h2>
          <p className='justified'>
            En la figura de más abajo puedes ver cuál de las tres grandes áreas
            te interesa más:
          </p>
          <ChartWrapper>
            <PieChart user={user} />
          </ChartWrapper>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisJuniorInterestGraphPage;
