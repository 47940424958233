import { ResponsiveWaffle } from '@nivo/waffle';
import { getDimensionFullName, dimColors } from '../constants';

// const groups = ['Muy Bajo', 'Bajo', 'Neutro', 'Alto', 'Muy Alto'];

// const getDimData = responses => {
//   // // // scales
//   // // const {
//   // //   EP_ESC_SUM,
//   // //   ES_ESC_SUM,
//   // //   EL_ESC_SUM,
//   // //   EP_ESC_MEAN,
//   // //   ES_ESC_MEAN,
//   // //   EL_ESC_MEAN
//   // // } = user;

//   // // sub-scales
//   // const {
//   //   MA_SUB_SUM,
//   //   ML_SUB_SUM,
//   //   ES_SUB_SUM,
//   //   AP_SUB_SUM,
//   //   MS_SUB_SUM,
//   //   EL_SUB_SUM
//   //   // MA_SUB_MEAN,
//   //   // ML_SUB_MEAN,
//   //   // ES_SUB_MEAN,
//   //   // AP_SUB_MEAN,
//   //   // MS_SUB_MEAN,
//   //   // EL_SUB_MEAN
//   // } = user;

//   // const dims = [
//   //   {
//   //     dimension: 'Profundo',
//   //     'Motivación por aprender': MA_SUB_SUM,
//   //     // "Estrategias de Estudio Limitado": 84,
//   //     // "Motivación de logro": 90,
//   //     'Estrategias de Aprendizaje Profundo': AP_SUB_SUM
//   //     // "Motivación superficial por el estudio": 79,
//   //     // "Estrategias de Organización": 143,
//   //   },
//   //   {
//   //     dimension: 'Superficial',
//   //     // "Motivación por aprender": 87,
//   //     'Estrategias de Estudio Limitado': ES_SUB_SUM,
//   //     // "Motivación de logro": 90,
//   //     // "Estrategias de Aprendizaje Profundo": 82,
//   //     'Motivación superficial por el estudio': MS_SUB_SUM
//   //     // "Estrategias de Organización": 143,
//   //   },
//   //   {
//   //     dimension: 'De logro',
//   //     // "Motivación por aprender": 87,
//   //     // "Estrategias de Estudio Limitado": 84,
//   //     'Motivación de logro': ML_SUB_SUM,
//   //     // "Estrategias de Aprendizaje Profundo": 82,
//   //     // "Motivación superficial por el estudio": 79,
//   //     'Estrategias de Organización': EL_SUB_SUM
//   //   }
//   // ];

//   return [];
// };

const MyResponsiveBar = ({ responses }) => {
  // const dimData = getDimData(responses);
  const hombres = responses.filter(resp => resp.data.SEX === 'Masculino');
  const mujeres = responses.filter(resp => resp.data.SEX === 'Femenino');

  const data = [
    {
      id: 'Mujeres',
      label: 'Mujeres',
      value: mujeres.length / responses.length,
      color: '#ff7f0f'
    },
    {
      id: 'Hombres',
      label: 'Hombres',
      value: hombres.length / responses.length,
      color: '#1f77b4'
    },
    // {
    //   "id": "children",
    //   "label": "children",
    //   "value": 25.733878354318797,
    //   "color": "#a1cfff"
    // }
  ];
  const total = data.reduce((acc, curr) => acc + curr.value, 0);
  return (
    <ResponsiveWaffle
      theme={{
        legends: {
          text: {
            fontSize: 22
          }
        }
      }}
      data={data}
      total={total}
      rows={20}
      columns={30}
      margin={{ top: 10, right: 10, bottom: 10, left: 150 }}
      // colors={{ scheme: 'category10' }}
      colors={['#ff7f0f','#1f77b4']}
      borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
      animate={false}
      isInteractive={false}
      motionStiffness={90}
      motionDamping={11}
      fillDirection="top"
      legends={[
        {
          anchor: 'top-left',
          direction: 'column',
          justify: false,
          translateX: -150,
          translateY: 0,
          itemsSpacing: 4,
          itemWidth: 100,
          itemHeight: 60,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          itemTextColor: '#777',
          symbolSize: 40,
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
                itemBackground: '#f7fafb'
              }
            }
          ]
        }
      ]}
    />
  );
};

export default MyResponsiveBar;
