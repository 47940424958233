import React from 'react';
import styled from 'styled-components';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import KnowledgeAreasChart from '../KnowledgeAreasChart';
import { getDimensionFullName } from '../constants';

export const ChartWrapper = styled.div`
  margin-bottom: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
`;

const EquisOneKnowledgeAreasGraphPage = ({ user, background, number }) => {
  const { TOP_DIM_1, TOP_DIM_2 } = user;
  // console.log({ TOP_DIM_1, TOP_DIM_2 });
  const firstDimName = getDimensionFullName(TOP_DIM_1);
  const secondDimName = getDimensionFullName(TOP_DIM_2);
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>3. Áreas de conocimiento</h1>
          <p className='justified'>
            En la figura de más abajo verás las áreas de conocimiento que se
            relacionan con tu primera preferencia{' '}
            <strong>{firstDimName}</strong>
          </p>

          <ChartWrapper>
            <KnowledgeAreasChart user={user} first />
          </ChartWrapper>

          <p className='justified'>
            En la figura de más abajo verás las áreas de conocimiento que se
            relacionan con tu segunda preferencia{' '}
            <strong>{secondDimName}</strong>
          </p>

          <ChartWrapper>
            <KnowledgeAreasChart user={user} second />
          </ChartWrapper>

          <p className='justified small'>
            <em>Nota. </em>
            Los resultados anteriores muestran en qué área académica te
            resultaría más atractivo trabajar para desplegar cada una de tus
            preferencias vocacionales prioritarias. Por esto, si bien un área
            académica puede resultarte poco interesante al relacionarla con una
            de tus preferencias prioritarias, esta misma área puede ser más
            interesante al relacionarla con la segunda de tus preferencias (o
            viceversa).
          </p>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOneKnowledgeAreasGraphPage;
