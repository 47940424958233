import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import EquisLogo from '../imgs/equis_junior_logo.png';

const EquisJuniorAboutUsPage = ({ background, number }) => {
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>4. Quiénes somos</h1>
          <p className='justified'>
            UAI Metrics es un centro de medición de la Escuela de Psicología de
            la Universidad Adolfo Ibáñez que se especializa en el desarrollo y
            aplicación de test para la toma de decisiones en contextos
            organizacionales y educacionales.
          </p>
          <p className='justified'>
            El equipo de UAI Metrics está compuesto por profesionales de
            excelencia con una amplia experiencia en el desarrollo de
            instrumentos de medición.
          </p>
          <p className='justified'>
            Para mayor información favor comunicarse con:
            <br />
            <br />
            <strong>UAI Metrics</strong>
            <br />
            Escuela de Psicología | Universidad Adolfo Ibáñez
            <br />
            Diagonal Las Torres 2640, Peñalolén <br />
            Santiago de Chile <br />
            uaimetrics@uai.cl <br />
          </p>
          <br />
          <br />
          <br />
          <p className='justified'>
            Para saber más y continuar trabajando sobre los resultados de este
            test ponerse en contacto con Jorge Sanhueza al mail
            jorge.sanhueza@uai.cl o con Sergio Gómez al mail sergio.gomez@uai.cl
            de Albert Bridge Project. Para más detalles sobre el test{' '}
            <em>Equis Junior </em>
            comunicarse con UAIMETRICS al mail uaimetrics@uai.cl.
          </p>
          <br />
          <br />
          <br />
          <br />
          <div className='logo-wrapper'>
            <img src={EquisLogo} alt='uaimetrics equis junior logo' />
          </div>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisJuniorAboutUsPage;
