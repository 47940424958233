import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer, ProgressBox, ProgressStat, Span } from '../styles';
import { polarColors } from '../constants';

const getColor = value => {
  if (value < 25) {
    return polarColors[4];
  }
  if (value < 50) {
    return polarColors[3];
  }
  if (value < 75) {
    return polarColors[1];
  }
  return polarColors[0];
};

const VocationalMaturityFirstPage = ({ user, background, number }) => {
  const confictosExternos = parseFloat(user['Conflictos externos']);
  const confictosInternos = parseFloat(user['Conflictos internos']);
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>6. Madurez vocacional</h1>
          <p className='justified'>
            La madurez vocacional se relaciona con el nivel de conflicto
            (externo e interno) asociado a la toma de decisión de carrera, así
            como a la calidad y cantidad de información disponible sobre ti
            mismo (Self), las ocupaciones o carreras, y el proceso de toma de
            decisión.
          </p>
          <p className='justified'>
            Resultados en
            <Span strong color={polarColors[0]}>
              {' '}
              ROJO{' '}
            </Span>
            sugieren un alto nivel de conflicto o falta de información. <br />
            Resultados en{' '}
            <Span strong color={polarColors[4]}>
              VERDE
            </Span>{' '}
            sugieren un bajo nivel de conflicto o falta de información.
          </p>
          <br />
          <br />
          <p className='justified'>
            <strong>Conflictos externos. </strong>
            <br />
            Falta de correspondencia entre las cosas que te gustaría estudiar y
            lo que piensas que otras personas esperan o quieren para ti
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details-single'>
                <div className='progress-name'>Conflictos externos</div>
                <ProgressBox
                  progress={confictosExternos}
                  backColor={() => getColor(confictosExternos)}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{confictosExternos}</ProgressStat>
              </div>
            </div>
          </div>
          <br />
          <p className='justified'>
            <strong>Conflictos internos. </strong>
            <br />
            Falta de correspondencia entre las cosas que te gustaría estudiar y
            tus habilidades; o problemas con algunos aspectos de una carrera de
            tu interés (aspectos de la carrera que son poco atractivos y te
            hacen dudar).
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details-single'>
                <div className='progress-name'>Conflictos internos</div>
                <ProgressBox
                  progress={confictosInternos}
                  backColor={() => getColor(confictosInternos)}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{confictosInternos}</ProgressStat>
              </div>
            </div>
          </div>
          <br />
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default VocationalMaturityFirstPage;
