import React from 'react';
import { SlidePage } from '../index';
import BHPLogo from '../imgs/BHP_orange.png';
import HolosLogo from '../imgs/logo_holos.png';
import BottomLeft from '../imgs/dots-frame-blue.png';
import TopRight from '../imgs/dots-frame-orange.png';

const EquisOneTopPage = ({ user, ...rest }) => {
  return (
    <SlidePage {...rest}>
      <div className='top-left-icon'>
        <img src={BHPLogo} alt='bhp' />
      </div>
      <div className='bottom-left-accent'>
        <img src={TopRight} alt='dots' />
      </div>
      <div className='top-right-accent'>
        <img src={BottomLeft} alt='dots' />
      </div>

      <div className='title-box'>
        <div className='title-box-icon'>
          <img src={HolosLogo} alt='holos' />
        </div>
        <div className='top-title'>
          <h1>Simulador Cambio Conductual de Conductores</h1>
        </div>
        <div className='info-row'>
          <div className='title-label'>Reporte para:</div>
          <div className='title-value'>{`${user.LASTNAME}, ${user.NAME}`}</div>
        </div>
        <div className='info-row'>
          <div className='title-label'>RUT:</div>
          <div className='title-value'>{user.RUT}</div>
        </div>
        <br />
        <div className='info-row'>
          <div className='title-label'>Realizado:</div>
          <div className='title-value'>
            {user.updatedat
              ? new Date(user.updatedat).toLocaleDateString()
              : ''}
          </div>
        </div>
      </div>
      <div className='top-footer'>
        Este reporte es confidencial y no debe distribuirse sin autorización{' '}
        <br />· Holos Technology
      </div>
    </SlidePage>
  );
};

export default EquisOneTopPage;
