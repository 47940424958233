import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer, ProgressBox, ProgressStat, Span } from '../styles';
import { polarColors } from '../constants';

const getColor = value => {
  if (value < 25) {
    return polarColors[4];
  }
  if (value < 50) {
    return polarColors[3];
  }
  if (value < 75) {
    return polarColors[1];
  }
  return polarColors[0];
};

const DysfunctionalBeliefsThirdPage = ({ user, background, number }) => {
  const indecisionGeneral = parseFloat(user['Indecisión General']);

  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h2>4.2. Indecisión como rasgo personal</h2>
          <p className='justified'>
            Es importante diferenciar si estas pasando por un momento específico
            de indecisión en relación a la elección de carrera o tiendes a ser
            una persona indecisa en general.
          </p>
          {/* <p className='justified'>
            Valores <strong>bajo 40</strong> en esta dimensión sugieren una
            dificultad para tomar decisiones en general que puede estar
            afectando tu capacidad para decidir sobre una carrera.
          </p> */}
          <p className='justified'>
            Resultados en
            <Span strong color={polarColors[0]}>
              {' '}
              ROJO{' '}
            </Span>
            sugieren un alto nivel de indecisión general. <br />
            Resultados en{' '}
            <Span strong color={polarColors[4]}>
              VERDE
            </Span>{' '}
            sugieren un bajo nivel de indecisión general.
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details-single'>
                <div className='progress-name'>Indecisión General</div>
                <ProgressBox
                  progress={indecisionGeneral}
                  backColor={() => getColor(indecisionGeneral)}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{indecisionGeneral}</ProgressStat>
              </div>
            </div>
          </div>
          <br />
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default DysfunctionalBeliefsThirdPage;
