import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const INITIAL_VALUES = {};

const DataContext = createContext(INITIAL_VALUES);

export const DataProvider = props => {
  const { children, id, initial } = props;
  const [state, setState] = useState(initial || INITIAL_VALUES);
  const [userData, setUserData] = useState(null);
  // const [responses, setResponses] = useState([]);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // console.log('on data context results');
    const getRespData = async () => {
      const POST_API = `/api/response`;
      const body = {
        params: {
          id
        }
      };
      // console.log('prev calling');
      // const options = { ...opts, headers };
      // console.log({ body, options });
      const { data } = await axios.get(POST_API, body);
      // console.log({ data });
      if (data) {
        try {
          const parsed = JSON.parse(data);
          let withParsedResponse = parsed.data;
          if (parsed.data.data) {
            withParsedResponse = {
              ...parsed.data,
              data: JSON.parse(parsed.data.data)
            };
          }
          await setResponse(withParsedResponse);
        } catch (err) {
          await setResponse(null);
        }
      }
      await setLoading(false);
    };
    getRespData();
  }, [id]);

  return (
    <DataContext.Provider
      value={{
        ...state,
        updateContext: setState,
        response,
        isLoading: loading,
        userData,
        setUserData
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const DataConsumer = DataContext.Consumer;
export const useDataContext = () => useContext(DataContext);
