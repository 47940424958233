import { ResponsiveBar } from '@nivo/bar';
import { getDimensionFullName, dimColors } from '../constants';

const groups = ['Muy Bajo', 'Bajo', 'Neutro', 'Alto', 'Muy Alto'];

// perc_AL_SUM: '96.875',
// perc_AP_SUM: '34.375',
// perc_CD_SUM: '31.25',
// perc_CN_SUM: '53.125',
// perc_FM_SUM: '37.5',
// perc_IP_SUM: '53.125',
// perc_LM_SUM: '37.5',
// perc_SE_SUM: '56.25',

const getDimData = user => {
  const {
    perc_AL_SUM,
    perc_AP_SUM,
    perc_CD_SUM,
    perc_CN_SUM,
    perc_FM_SUM,
    perc_IP_SUM,
    perc_LM_SUM,
    perc_SE_SUM
  } = user;

  const data = [
    {
      dimension: 'CN',
      value: parseFloat(perc_CN_SUM)
    },
    {
      dimension: 'FM',
      value: parseFloat(perc_FM_SUM)
    },
    {
      dimension: 'CD',
      value: parseFloat(perc_CD_SUM)
    },
    {
      dimension: 'AP',
      value: parseFloat(perc_AP_SUM)
    },
    {
      dimension: 'LM',
      value: parseFloat(perc_LM_SUM)
    },
    {
      dimension: 'IP',
      value: parseFloat(perc_IP_SUM)
    },
    {
      dimension: 'SE',
      value: parseFloat(perc_SE_SUM)
    },
    {
      dimension: 'AL',
      value: parseFloat(perc_AL_SUM)
    }
  ];
  const getValueGroup = val => {
    const value = parseFloat(val);
    if (value < 30) {
      return 'Muy Bajo';
    }
    if (value < 40) {
      return 'Bajo';
    }
    if (value < 60) {
      return 'Neutro';
    }
    if (value < 70) {
      return 'Alto';
    }
    return 'Muy Alto';
  };
  const groupColor = group => {
    switch (group) {
      case 'Muy Bajo':
        return dimColors[0];
      case 'Bajo':
        return dimColors[1];
      case 'Neutro':
        return dimColors[2];
      case 'Alto':
        return dimColors[3];
      case 'Muy Alto':
        return dimColors[4];
      default:
        return dimColors[0];
    }
  };
  const dataWithDetails = data.map(el => {
    const group = getValueGroup(el.value);
    const color = groupColor(group);
    return { ...el, group, color };
  });

  const groupedData = dataWithDetails.map(dp => {
    const groupsvals = groups.reduce((acc, curr) => {
      let val = 0;
      if (curr === dp.group) {
        val = dp.value;
      }
      return { ...acc, [curr]: val };
    }, {});

    return {
      ...dp,
      dimension: dp.dimension,
      ...groupsvals
    };
  });
  return groupedData;
};

const MyResponsiveBar = ({ user }) => {
  const dimData = getDimData(user);
  // console.log({ dimData });
  return (
    <ResponsiveBar
      data={dimData}
      keys={groups}
      indexBy='dimension'
      layout='horizontal'
      // groupMode='grouped'
      isInteractive={false}
      margin={{ top: 20, right: 90, bottom: 40, left: 155 }}
      padding={0.3}
      maxValue={100}
      valueScale={{ type: 'linear' }}
      // colors={({ data }) => {
      //   console.log({ data });
      //   return data.color;
      // }}
      colors={dimColors}
      // colorBy={({ data }) => data.group}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      enableGridX
      enableGridY={false}
      axisLeft={{
        // tickRotation: 25,
        format: v => getDimensionFullName(v)
      }}
      label={d => `${parseFloat(d.value).toFixed(0)}`}
      // axisBottom={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: 'country',
      //   legendPosition: 'middle',
      //   legendOffset: 32
      // }}
      // axisLeft={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: 'Dimension',
      //   legendPosition: 'middle',
      //   legendOffset: -40
      // }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      // labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      legends={[
        {
          // data: 'data.group',
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 110,
          translateY: 0,
          itemsSpacing: 2,
          // itemTextColor: '#fff',
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20
          // symbolShape: 'square',
          // symbolBorderColor: 'rgba(0, 0, 0, 0)'
        }
        // {
        //   dataFrom: 'group',
        //   anchor: 'bottom-right',
        //   direction: 'column',
        //   justify: false,
        //   translateX: 120,
        //   translateY: 0,
        //   itemsSpacing: 2,
        //   itemWidth: 100,
        //   itemHeight: 20,
        //   itemDirection: 'left-to-right',
        //   itemOpacity: 0.85,
        //   symbolSize: 20,
        //   effects: [
        //     {
        //       on: 'hover',
        //       style: {
        //         itemOpacity: 1
        //       }
        //     }
        //   ]
        // }
      ]}
      animate={false}
      motionStiffness={90}
      motionDamping={15}
    />
  );
};

export default MyResponsiveBar;
