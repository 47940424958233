import styled, { createGlobalStyle, css } from 'styled-components';

export const ResetCSS = createGlobalStyle`
  ::selection {
    background: #333333;
    color: #ffffff;
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *:focus {
    outline: none;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ${'' /* text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004); */}
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  .reuseModalHolder {
    padding: 0 !important;
    &.demo_switcher_modal {
      border: 0 !important;
      background-color: rgba(16, 30, 77, 0.9) !important;
      .innerRndComponent {
        border-radius: 8px !important;
      }
    }
  }

  button.modalCloseBtn {
    position: fixed !important;
    z-index: 999991 !important;
    background-color: transparent !important;
    top: 10px !important;
    right: 10px !important;
    min-width: 34px !important;
    min-height: 34px !important;
    padding: 0 !important;
    span.btn-icon {
      font-size: 22px !important;
      transform: rotate(45deg) !important;
    }

    &.alt {
      border-radius: 50% !important;
      z-index: 999999 !important;
      padding: 0 !important;
      transition: all 0.3s ease !important;
      top: 25px !important;
      right: 30px !important;
      min-width: 40px !important;
      min-height: 40px !important;

      span.btn-icon {
        font-size: 20px !important;
      }

      &:hover {
        opacity: 0.88 !important;
      }
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

  body {
    ${'' /* font-family: 'Open Sans', sans-serif; */}
    font-family: 'Montserrat', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.colors.headings};
    ${'' /* font-family: 'Open Sans', sans-serif; */}
    font-family: 'Montserrat', sans-serif;
  }

  section {
    position: relative;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  ul, table, p {
    color: ${({ theme }) => theme.colors.headings};
  }

  a:hover {
    text-decoration: none !important;
    color: inherit;
  }

  /* Drawer menu style */
  .drawer {
    .drawer-content-wrapper {
      @media only screen and (max-width: 480px) {
        width: 320px !important;
      }

      .reusecore-drawer__close {
        position: absolute;
        top: 20px;
        right: 30px;

        > button {
          box-shadow: 0px 8px 38px 0px rgba(16, 172, 132, 0.5);
          transition: all 0.3s ease;

          svg {
            width: 22px;
            height: 22px;
          }

          &:hover {
            opacity: 0.9;
          }
        }
      }

      .scrollspy__menu {
        padding: 50px 40px;
        max-height: 505px;
        overflow-x: auto;

        @media only screen and (max-width: 480px) {
          padding: 30px 10px;
        }

        @media only screen and (max-width: 320px) {
          max-height: 380px;
        }

        li {
          margin: 35px 0;
          @media only screen and (max-width: 480px) {
            margin: 25px 0;
          }

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }

          a {
            display: block;
            color: ${({ theme }) => theme.colors.headings};
            font-size: 18px;
            font-weight: 600;
            transition: all 0.3s ease;
            @media only screen and (max-width: 480px) {
              font-size: 16px;
            }
            &:hover {
              color: #D50032;
            }
          }

          &.active {
            a {
              color: #D50032;
              position: relative;
              &:before {
                content: '';
                display: block;
                width: 20px;
                height: 2px;
                border-radius: 5px;
                background-color: #D50032;
                position: absolute;
                top: calc(50% - 1px);
                left: -30px;
              }
            }
          }
        }
      }
    }
  }
`;

export const Spacer = styled.div`
  content: '';
  margin-bottom: ${({ space }) => (space ? space : '0px')};
`;

export const Span = styled.span`
  font-weight: ${({ strong }) => (strong ? 600 : 400)};
  color: ${({ theme, color }) => (color ? color : theme.colors.headings)};
`;

export const ProgressBox = styled.div`
  background-color: #f1f1f1;
  height: 40px;
  ${'' /* width: 200px; */}
  position: relative;
  margin-bottom: 0.5rem;
  width: 100%;
  .progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${({ progress }) => (progress ? progress : 0)}%;
    background-color: ${({ backColor }) => (backColor ? backColor : 'green')};
  }
  .progress-icon-left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -36px;
    height: 100%;
    align-items: center;
    display: flex;
  }
  .progress-icon-right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -36px;
    height: 100%;
    align-items: center;
    display: flex;
  }
`;

export const ProgressStat = styled.span`
  font-size: 2.5rem;
  letter-spacing: 2px;
  font-weight: 700;
`;

export const PageWrapper = styled.div`
  ${
    '' /* position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; */
  }
  position: relative;
  margin: 0;
  padding: 0;
  height: 1123px;
  ${'' /* width: 100%; */}
  width: 794px;
  ${'' /* width: 1240px; */}
  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${backgroundImage});
      background-size: 100%;
      background-size: contain;
    `}
  .fill-page {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .title-box {
    position: absolute;
    top: 730px;
    right: 60px;
    font-size: 18px;
    color: #fff;
    line-height: 1.4;
    font-weight: 500;
    .info-row {
      width: 300px;
      display: flex;
      gap: 15px;
      ${'' /* justify-content: space-between; */}
      .title-label {
        width: 85px;
      }
      .title-value {
        width: 200px;
      }
    }
  }
  .capitalized {
    text-transform: capitalize;
  }

  table {
    text-align: left;
    width: 100%;
    th {
      border-bottom: 1px solid ${({ theme }) => theme.colors.headings};
      border-top: 1px solid ${({ theme }) => theme.colors.headings};
      padding: 0.5rem 0;
    }
    td {
      line-height: 1.4rem;
      font-size: 0.85rem;
    }
    tr:first-child > td {
      padding-top: 0.5rem;
    }
    tr:last-child > td {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid ${({ theme }) => theme.colors.headings};
    }
  }
  .career-table-wrapper {
    table {
      padding: 0 1rem;
      margin-top: 1.5rem;
    }
    color: ${({ theme }) => theme.colors.headings};
    padding: 0 0.2rem;
    .table-head {
      display: grid;
      grid-template-columns: 480px 30px 30px 30px;
      grid-gap: 5px;
      font-weight: 600;
    }
    .table-body {
      font-size: 0.6rem;
      display: grid;
      grid-gap: 5px;
      margin: 10px 0;
      line-height: 14px;
      .career-row {
        display: grid;
        grid-template-columns: 480px 30px 30px 30px;
        grid-gap: 5px;
        padding: 0.2rem 0;
        &:nth-child(even) {
          background-color: #f2f2f2;
        }
        .text-col {
          margin-left: 0.15rem;
        }
      }
    }
    .table-footer {
      font-size: 0.65rem;
      font-weight: 600;
    }
    .dot-col {
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .dot {
        height: 5px;
        width: 5px;
        background: ${({ theme }) => theme.colors.orange};
        border-radius: 50%;
      }
    }
  }
  .logo-wrapper {
    text-align: center;
    img {
      width: 250px;
    }
  }
`;

export const ChartWrapper = styled.div`
  height: 300px;
  width: 100%;
`;

export const BodyContainer = styled.div`
  margin-top: 165px;
  .content {
    padding: 0 100px;
  }
  .right-content {
    padding: 0 80px 0 360px;
    h1 {
      font-size: 1.875rem;
      margin: 0;
      ${'' /* margin-bottom: 2.5rem; */}
    }
    h2 {
      font-size: 1.25rem;
      font-weight: 600;
      letter-spacing: -0.5px;
      margin: 0;
    }
    p {
      line-height: 1.45;
      font-size: 0.8rem;
      margin-block-start: 0.5rem;
      &.justified {
        text-align: justify;
      }
      &.small {
        line-height: 1.2;
        font-size: 0.75rem;
      }
    }
  }
  h1 {
    font-size: 2.25rem;
    ${'' /* margin-bottom: 2.5rem; */}
  }
  h2 {
    ${'' /* font-size: 1.25rem; */}
    font-weight: 600;
    letter-spacing: -0.5px;
  }
  p {
    line-height: 1.5;
    font-size: 0.8rem;
    &.justified {
      text-align: justify;
    }
    &.small {
      line-height: 1.2;
      font-size: 0.75rem;
    }
  }
  ul {
    line-height: 1.8;
    margin-left: 1rem;
    li {
      display: flex;
    }
    .list-item-label {
      width: 160px;
    }
  }
  .progress-info-group {
    display: flex;
    justify-content: space-around;
  }
  .progress-info {
    text-align: center;
    justify-content: center;
    display: flex;
    color: ${({ theme }) => theme.colors.headings};
    .progress-name {
      width: 250px;
      font-weight: 600;
      font-size: 1.15rem;
      padding-bottom: 1rem;
      min-height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .progress-details-single {
    width: 350px;
    margin-top: 0.5rem;
    .progress-name {
      width: 100%;
    }
    &.inline-grid {
      display: grid;
      grid-template-columns: 350px 1fr;
      grid-gap: 1.5rem;
      align-items: center;
      width: 100%;
      div {
        margin: 0;
      }
    }
  }
  .graph-in-text {
    display: grid;
    grid-template-columns: 20% auto;
    grid-gap: 1rem;
    .chart-col {
      ${'' /* background: grey; */}
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const PageNumber = styled.div`
  position: absolute;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.orange};
  color: #fff;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 38px;
  font-weight: 600;
  ${({ even }) =>
    even
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}
`;
