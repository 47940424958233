import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer, ProgressBox, ProgressStat } from '../styles';
import { getDimensionFullName, polarColors } from '../constants';

const EquisOnePreferencesClarityPage = ({ user, background, number }) => {
  const { TOP_DIM_1, TOP_DIM_2 } = user;
  // console.log({ TOP_DIM_1, TOP_DIM_2 });
  const firstDimName = getDimensionFullName(TOP_DIM_1);
  const secondDimName = getDimensionFullName(TOP_DIM_2);
  const firstDimConsistency = parseFloat(user[`CONS_${TOP_DIM_1}`]).toFixed(0);
  const secondDimConsistency = parseFloat(user[`CONS_${TOP_DIM_2}`]).toFixed(0);

  const getColor = value => {
    if (value < 30) {
      return polarColors[0];
    }
    if (value < 40) {
      return polarColors[1];
    }
    if (value < 60) {
      return polarColors[2];
    }
    if (value < 70) {
      return polarColors[3];
    }
    return polarColors[4];
  };

  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h2>2.3. Claridad preferencias</h2>
          <p className='justified'>
            El puntaje de claridad de tus preferencias te indica cuán
            consistentes son tus intereses. Personas con preferencias bien
            definidas y específicas tienen puntajes más altos en sus áreas de
            interés principal y muy bajos en el área del polo opuesto. Cada vez
            que se elige un área opuesta a las de mayor interés, el puntaje en
            claridad se reduce proporcionalmente. Los siguientes son los
            puntajes de claridad para cada una de tus preferencias prioritarias
          </p>
          <p className='justified'>
            El máximo puntaje en claridad es 100 y el mínimo es 0. Valores sobre
            70 sugieren que tienes intereses que van a coincidir con carreras
            bien definidas. Valores bajo 70 indican que te costará más trabajo
            encontrar una única carrera que te permita desarrollar todos tus
            intereses.
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details'>
                <div className='progress-name'>{firstDimName}</div>
                <ProgressBox
                  progress={firstDimConsistency}
                  backColor={() => getColor(firstDimConsistency)}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{firstDimConsistency}</ProgressStat>
              </div>
            </div>
            <div className='progress-info'>
              <div className='progress-details'>
                <div className='progress-name'>{secondDimName}</div>
                <ProgressBox
                  progress={secondDimConsistency}
                  backColor={() => getColor(secondDimConsistency)}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{secondDimConsistency}</ProgressStat>
              </div>
            </div>
          </div>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOnePreferencesClarityPage;
