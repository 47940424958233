import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer, ProgressBox, ProgressStat } from '../styles';
import { polarColors } from '../constants';

const getColor = value => {
  if (value < 25) {
    return polarColors[4];
  }
  if (value < 50) {
    return polarColors[3];
  }
  if (value < 75) {
    return polarColors[1];
  }
  return polarColors[0];
};

const VocationalMaturitySecondPage = ({ user, background, number }) => {
  const infoSobreOcupaciones = parseFloat(
    user['Información sobre Ocupaciones']
  );
  const infoSobreSiMismo = parseFloat(user['Información sobre el Self']);
  const infoSobreProceso = parseFloat(user['Información sobre el proceso']);
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <p className='justified'>
            <strong>Falta de información sobre ocupaciones. </strong>
            <br />
            Falta de claridad sobre la oferta de carreras y/o el mundo del
            trabajo: ¿Cuáles son las características de los programas de estudio
            de las carreras que me interesan? ¿Cuál es el campo laboral de la
            carrera que me gusta?
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details-single'>
                <div className='progress-name'>
                  Falta de información sobre ocupaciones
                </div>
                <ProgressBox
                  progress={infoSobreOcupaciones}
                  backColor={() => getColor(infoSobreOcupaciones)}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{infoSobreOcupaciones}</ProgressStat>
              </div>
            </div>
          </div>
          <br />
          <p className='justified'>
            <strong>Falta de información sobre Sí mismo. </strong>
            <br />
            Poca claridad sobre algunas características personales: ¿Cuáles son
            mis habilidades e intereses? ¿Qué me gusta?
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details-single'>
                <div className='progress-name'>
                  Falta de información sobre Sí mismo
                </div>
                <ProgressBox
                  progress={infoSobreSiMismo}
                  backColor={() => getColor(infoSobreSiMismo)}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{infoSobreSiMismo}</ProgressStat>
              </div>
            </div>
          </div>
          <br />
          <p className='justified'>
            <strong>Falta de información sobre el proceso. </strong>
            <br />
            Poca claridad sobré qué pasos seguir en el proceso de toma de
            decisión: ¿Cómo se hace para tomar esta decisión? ¿Qué puedo esperar
            de este proceso? ¿Es normal lo que siento?
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details-single'>
                <div className='progress-name'>
                  Falta de información sobre el proceso
                </div>
                <ProgressBox
                  progress={infoSobreProceso}
                  backColor={() => getColor(infoSobreProceso)}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{infoSobreProceso}</ProgressStat>
              </div>
            </div>
          </div>
          <br />
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default VocationalMaturitySecondPage;
