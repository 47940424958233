import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// application
import BlankPage from './pages/Blank';
// complete pages
import EquisOne from './pages/EquisOne';
import EquisOneMock from './pages/EquisOneMock';
// import EquisJuniorMock from './pages/EquisJuniorMock';
import EquisJunior from './pages/EquisJunior';
// import EquisJuniorCollege from './pages/AppEquisJuniorCollege';
// Equis One-Junior App
import SwitchPage from './pages/SwitchPage';
// Campaign reporting
import SwitchCampaignPage from './pages/SwitchCampaignPage'
// holos bhp
import HolosBHP from './pages/HolosBHP';

function App() {
  return (
    <Router>
      <>
        <Route path='/' exact component={BlankPage} />
        <Route path='/r/:id' exact component={SwitchPage} />
        <Route path='/c/:id/:key' exact component={SwitchCampaignPage} />
        {/* <Route path='/equis-college' exact component={EquisJuniorCollege} /> */}
        <Route path='/equis-one' exact component={EquisOneMock} />
        <Route
          path='/equis-one/u/:sid/:stitle/:rut'
          exact
          component={EquisOne}
        />
        <Route path='/equis-junior' exact component={EquisJunior} />
        <Route
          path='/equis-junior/u/:sid/:stitle/:rut'
          exact
          component={EquisJunior}
        />
        <Route
          path='/holos-bhp/u/:sid/:stitle/:rut'
          exact
          component={HolosBHP}
        />
      </>
    </Router>
  );
}

export default App;
