import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer, Span } from '../styles';
import { polarColors } from '../constants';

const DysfunctionalBeliefsFirstPage = ({ background, number }) => {
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>4. Características del proceso de toma de decisión de carrera</h1>
          <p className='justified'>
            En esta sección podrás identificar alguna de tus características al
            tomar decisiones y algunas dificultades asociadas a tu proceso de
            toma de decisión de carrera. Esta información te puede ayudar a
            decidir qué aspectos debes revisar o qué estrategias debes
            implementar para enfrentar de mejor manera este proceso y sentirte
            más seguro/a con tu decisión.
          </p>
          <h2>4.1. Creencias Disfuncionales</h2>
          <p className='justified'>
            El tener ideas o creencias equivocadas sobre las implicancias del
            proceso de elección de carrera es una de las dificultades más
            importantes y más comunes que enfrentan las personas al momento de
            tomar este tipo de decisiones.
          </p>
          <p className='justified'>
            Es importante identificar estas creencias erróneas y cuestionarlas,
            ya que pueden estar obstaculizando el proceso de toma de decisión.
          </p>
          <p className='justified'>
            Resultados en
            <Span strong color={polarColors[0]}>
              {' '}
              ROJO{' '}
            </Span>
            sugieren la alta presencia de creencias disfuncionales en estas
            áreas. Resultados en{' '}
            <Span strong color={polarColors[4]}>
              VERDE
            </Span>{' '}
            sugieren la baja presencia o ausencia de creencias disfuncionales.
          </p>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default DysfunctionalBeliefsFirstPage;
