const A4_96 = {
  width: 794,
  height: 1123
};
const A4_150 = {
  width: 1240,
  height: 1754
};
const defaultTheme = {
  size: {
    ...A4_96
    // maxCodePaneHeight: 200
  },
  colors: {
    // primary: '#ebe5da',
    // secondary: '#fc6986',
    // // tertiary: '#1e2852',
    // quaternary: '#ffc951',
    // quinary: '#8bddfd'
  },
  // fonts: {
  //   header: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  //   text: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  //   monospace: '"Consolas", "Menlo", monospace'
  // },
  fontSizes: {
    h1: '72px',
    h2: '64px',
    h3: '56px',
    text: '44px',
    monospace: '20px'
  },
  // space: [16, 24, 32]
  space: [0, 0, 0]
};

export default defaultTheme;
