import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';

const EquisOnePresentationPage = ({ user, background, number }) => {
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='right-content'>
          <h1>
            ¡Hola <span className='capitalized'>{user.name.toLowerCase()}</span>
            !
          </h1>
          <p className='justified'>
            El presente informe resume los resultados que obtuviste en el Test
            Equis One. Este test está orientado al proceso de toma de decisiones
            en relación a tu carrera futura, por lo que junto con entregarte
            información respecto de tus preferencias e intereses, te entrega
            información sobre la forma en que estás llevando el proceso de
            decisión.
          </p>
          {/* <Spacer space='32px' /> */}
          <h2>Orientación Vocacional y Carreras</h2>
          <p className='justified'>
            El Test Equis One entrega información acerca de tus principales
            intereses o preferencias. Conocer esta información te permite
            identiﬁcar aquellas actividades que te gustaría más hacer o con las
            que te sentirías más cómodo, y descartar aquellas que no te gustaría
            realizar en un futuro.
          </p>
          <p className='justified'>
            A partir de la identificación de tus principales preferencias
            vocacionales, el test Equis One además sugiere aquellas áreas de
            conocimiento profesional que podrían relacionarse en mayor medida
            con dichas preferencias, y te ayuda a identiﬁcar cuáles áreas son de
            mayor interés para ti.
          </p>
          <h2>Proceso de Toma de Decisión</h2>
          <p className='justified'>
            La información sobre preferencias o intereses no siempre es
            suﬁciente, a pesar de tener claro qué cosas te gustaría hacer,
            puedes estar enfrentando diﬁcultades para tomar la decisión ﬁnal. El
            test Equis One te ayuda a analizar la forma en que generalmente
            tomas decisiones e identiﬁcar posibles diﬁcultades en relación a la
            toma de decisiones sobre tu carrera a futuro. Esta información
            contribuirá a identiﬁcar fortalezas y/o debilidades de tu proceso de
            decisión, o al acompañamiento por parte de un(a) orientador(a)
            vocacional.
          </p>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOnePresentationPage;
