import React from 'react';
import styled from 'styled-components';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import RadarChart from '../RadarChart';

export const ChartWrapper = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
  position: relative;
  ${'' /* bug with polar lines on radar pdf print */}
  @media print {
    svg {
      g {
        line {
          opacity: 0;
        }
      }
    }
  }
`;

const EquisOnePreferencesGraphPage = ({ user, background, number }) => {
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h2>2.2. Mapa de preferencias</h2>
          <p className='justified'>
            El siguiente mapa te muestra como se expresan tus preferencias en
            relación al resto de las preferencias del modelo:
          </p>
          <ChartWrapper>
            <RadarChart user={user} />
          </ChartWrapper>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOnePreferencesGraphPage;
