import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer, ProgressBox, ProgressStat } from '../styles';
import { polarColors } from '../constants';

const getColor = value => {
  if (value < 25) {
    return polarColors[4];
  }
  if (value < 50) {
    return polarColors[3];
  }
  if (value < 75) {
    return polarColors[1];
  }
  return polarColors[0];
};

const DysfunctionalBeliefsThirdPage = ({ user, background, number }) => {
  const rolAyudaProfesional = parseFloat(user['Rol de la ayuda profesional']);
  const creenciasGenero = parseFloat(user['Género']);
  // console.log({ user });
  // console.log({ rolAyudaProfesional, creenciasGenero });
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h3>4.1.4. Rol de la ayuda profesional</h3>
          <p className='justified'>
            Confianza excesiva en pruebas psicológicas o actividades de
            orientación vocacional, creyendo que proveerán una solución mágica a
            nuestras dudas. Este tipo de creencias puede llevarnos a invertir
            menos esfuerzos en el proceso de toma de decisión, dejando a un
            tercero que tome la decisión por nosotros.
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details-single'>
                <ProgressBox
                  progress={rolAyudaProfesional}
                  backColor={() => getColor(rolAyudaProfesional)}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{rolAyudaProfesional}</ProgressStat>
              </div>
            </div>
          </div>
          <br />
          <h3>4.1.5. Creencias en relación al género</h3>
          <p className='justified'>
            Creer que existen carreras que son plenamente de dominio femenino o
            masculino puede llevarnos a descartar posibles opciones de carreras
            que pueden resultar interesantes por este sesgo de género.
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details-single'>
                <ProgressBox
                  progress={creenciasGenero}
                  backColor={() => getColor(creenciasGenero)}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{creenciasGenero}</ProgressStat>
              </div>
            </div>
          </div>
          <br />
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default DysfunctionalBeliefsThirdPage;
