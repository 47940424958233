import { ResponsiveBar } from '@nivo/bar';
import { getDimensionFullName, dimColors } from '../constants';

// const groups = ['Muy Bajo', 'Bajo', 'Neutro', 'Alto', 'Muy Alto'];
const groups = ['A', 'B', 'C'];

const getDimData = user => {
  const { A, B, C } = user;
  const data = [
    {
      dimension: 'A',
      value: parseFloat(A)
    },
    {
      dimension: 'B',
      value: parseFloat(B)
    },
    {
      dimension: 'C',
      value: parseFloat(C)
    }
  ];
  return data;
};

const getGroupData = gradeResponses => {
  const groupedGrades = gradeResponses.map(grade => {
    // console.log('get averages');
    // console.log({ grade });
    const total = grade.responses.length;
    const allCases = grade.responses.map(resp => getDimData(resp.data)).flat();
    // const groups = ['', 'B', 'C'];
    const ACases = allCases.filter(dp => dp.dimension === 'A');
    const BCases = allCases.filter(dp => dp.dimension === 'B');
    const CCases = allCases.filter(dp => dp.dimension === 'C');
    // console.log({ total, allCases, groups, ACases, BCases, CCases });
    // const groupCases = allCases.filter(
    //   resPoint => resPoint.dimension === group
    // );
    // console.log({ groupCases, group });
    const averages = {
      A: ACases.reduce((acc, curr) => acc + curr.value, 0) / ACases.length,
      B: BCases.reduce((acc, curr) => acc + curr.value, 0) / BCases.length,
      C: CCases.reduce((acc, curr) => acc + curr.value, 0) / CCases.length
    };
    const totAvSum = Object.keys(averages).reduce(
      (acc, curr) => acc + averages[curr],
      0
    );
    // console.log({ totAvSum });
    const groupBar = {
      grade: grade.grade,
      ...averages
    };
    // console.log({ allCases, total, groupedAverages });
    return groupBar;
  });
  return groupedGrades;
};

const data = [
  {
    grade: '2do A',
    A: 213,
    B: 213,
    C: 213
  },
  {
    grade: '2do B',
    A: 213,
    B: 213,
    C: 213
  },
  {
    grade: '2do C',
    A: 213,
    B: 213,
    C: 213
  }
];

const MyResponsiveBar = ({
  responses,
  gradesResponses,
  groupMode = 'stacked'
}) => {
  // const dimData = getDimData(responses[0].data);
  const groupData = getGroupData(gradesResponses).reverse();
  // console.log({ dimData });
  // console.log({ responses });
  // console.log({ gradesResponses });
  // console.log({ groupData });
  const averageA =
    groupData.reduce((acc, curr) => acc + curr.A, 0) / groupData.length;
  const averageB =
    groupData.reduce((acc, curr) => acc + curr.B, 0) / groupData.length;
  const averageC =
    groupData.reduce((acc, curr) => acc + curr.C, 0) / groupData.length;

  return (
    <ResponsiveBar
      theme={{
        fontSize: 20,
        labels: {
          text: {
            fontSize: 22
          }
        },
        legends: {
          text: {
            fontSize: 20
          }
        }
      }}
      data={[
        ...groupData,
        { grade: 'General', A: averageA, B: averageB, C: averageC }
      ]}
      keys={[...groups]}
      indexBy='grade'
      layout='horizontal'
      groupMode={groupMode}
      isInteractive={false}
      margin={{ top: 0, right: 100, bottom: 40, left: 100 }}
      padding={0.2}
      // innerPadding={3}
      maxValue={100}
      valueScale={{ type: 'linear' }}
      // colors={({ data }) => {
      //   console.log({ data });
      //   return data.color;
      // }}
      // colors={dimColors}
      colors={['#f7c033', '#70ad48', '#4472c4']}
      // colorBy={({ data }) => data.group}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      enableGridX
      enableGridY={false}
      // axisLeft={{
      //   // tickRotation: 25,
      //   format: v => getDimensionFullName(v)
      // }}
      label={d => `${parseFloat(d.value).toFixed(0)}%`}
      // axisBottom={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: 'country',
      //   legendPosition: 'middle',
      //   legendOffset: 32
      // }}
      // axisLeft={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: 'Dimension',
      //   legendPosition: 'middle',
      //   legendOffset: -40
      // }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      // labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      legends={[
        {
          // data: 'data.group',
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 130,
          translateY: 0,
          itemsSpacing: 10,
          // itemTextColor: '#fff',
          itemWidth: 100,
          itemHeight: 40,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 40
          // symbolShape: 'square',
          // symbolBorderColor: 'rgba(0, 0, 0, 0)'
        }
        // {
        //   dataFrom: 'group',
        //   anchor: 'bottom-right',
        //   direction: 'column',
        //   justify: false,
        //   translateX: 120,
        //   translateY: 0,
        //   itemsSpacing: 2,
        //   itemWidth: 100,
        //   itemHeight: 20,
        //   itemDirection: 'left-to-right',
        //   itemOpacity: 0.85,
        //   symbolSize: 20,
        //   effects: [
        //     {
        //       on: 'hover',
        //       style: {
        //         itemOpacity: 1
        //       }
        //     }
        //   ]
        // }
      ]}
      animate={false}
      motionStiffness={90}
      motionDamping={15}
    />
  );
};

export default MyResponsiveBar;
