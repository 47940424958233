import React from 'react';
import { SlidePage } from '../index';

const EquisOneClosePage = ({ user, background }) => {
  return (
    <SlidePage background={background}>
      <div className='fill-page'> </div>
      <div className='title-box'></div>
    </SlidePage>
  );
};

export default EquisOneClosePage;
