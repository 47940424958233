import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer, ProgressBox, ProgressStat } from '../styles';
import { dimColors } from '../constants';

// const getColor = value => {
//   if (value < 40) {
//     return polarColors[0];
//   }
//   return polarColors[4];
// };

const DecisionMakingSecondPage = ({ user, background, number }) => {
  // const { TOP_DECISION_STYLE } = user;

  const busquedaApoyo = parseFloat(user['Búsqueda de Apoyo']);

  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h3>Búsqueda de Apoyo</h3>
          <p className='justified'>
            Las personas que obtienen puntajes más altos en esta escala utilizan
            principalmente estrategias orientadas a obtener ayuda y/o apoyo de
            otros para afrontar el proceso de toma de decisiones, por ejemplo
            acudir a otros para obtener consejos y recomendaciones sobre la
            forma en que debería tomar la decisión; o buscar apoyo emocional que
            les ayude a enfrentar las preocupaciones relacionadas con este
            proceso. También pueden buscar que otros tomen la decisión o busquen
            respuestas que permitan facilitar el proceso de decisión. La
            búsqueda de apoyo en otros es una estrategia relevante para
            enfrentar el estrés y las dificultades del proceso de elegir una
            carrera profesional. Sin embargo, es relevante que estas estrategias
            sean acompañadas de estrategias de afrontamiento productivo, que te
            ayuden a tomar tu decisión personalmente.
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details-single'>
                <div className='progress-name'>Búsqueda de Apoyo</div>
                <ProgressBox
                  progress={busquedaApoyo}
                  backColor={() => dimColors[2]}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{busquedaApoyo}</ProgressStat>
              </div>
            </div>
          </div>
          <br />
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default DecisionMakingSecondPage;
