import { ResponsiveBar } from '@nivo/bar';
import { getAreaShortName, colorsFourth } from '../constants';

// const groups = ['Muy Bajo', 'Bajo', 'Neutro', 'Alto', 'Muy Alto'];
const groupsTop = ['1ra Prior.', '2da Prior.', '3ra Prior.', '4ta Prior.'];

const getTopAreaData = user => {
  const {
    perc_A_F,
    perc_A_HGC,
    perc_A_LL,
    perc_B_CBS,
    perc_B_CD,
    perc_B_M,
    perc_C_AI,
    perc_C_AV,
    perc_C_D
  } = user;

  const data = [
    {
      dimension: 'F',
      value: parseFloat(perc_A_F)
    },
    {
      dimension: 'HGC',
      value: parseFloat(perc_A_HGC)
    },
    {
      dimension: 'LL',
      value: parseFloat(perc_A_LL)
    },
    {
      dimension: 'CBS',
      value: parseFloat(perc_B_CBS)
    },
    {
      dimension: 'CD',
      value: parseFloat(perc_B_CD)
    },
    {
      dimension: 'M',
      value: parseFloat(perc_B_M)
    },
    {
      dimension: 'AV',
      value: parseFloat(perc_C_AV)
    },
    {
      dimension: 'AI',
      value: parseFloat(perc_C_AI)
    },
    {
      dimension: 'D',
      value: parseFloat(perc_C_D)
    }
  ];

  const {
    0: TOP1,
    1: TOP2,
    2: TOP3,
    3: TOP4
  } = [...data].sort((a, b) => b.value - a.value);
  // console.log({ TOP1, TOP2, TOP3, TOP4 });
  const topArr = [
    { TOP: 1, ...TOP1 },
    { TOP: 2, ...TOP2 },
    { TOP: 3, ...TOP3 },
    { TOP: 4, ...TOP4 }
  ];
  // console.log({ topArr });
  return topArr;
};

const getTopGroupData = (responses = []) => {
  // const total = responses.length;
  const allTopCases = responses.map(resp => getTopAreaData(resp.data)).flat();
  const groups = [
    'F',
    'HGC',
    'LL',
    'CBS',
    'CD',
    'M',
    'AV',
    'AI',
    'D'
  ].reverse();
  const groupedCases = groups.map(group => {
    const groupCases = allTopCases.filter(
      resPoint => resPoint.dimension === group
    );
    // console.log({ groupCases, group });
    const filters = {
      '1ra Prior.': groupCases.filter(dp => dp.TOP === 1).length,
      '2da Prior.': groupCases.filter(dp => dp.TOP === 2).length,
      '3ra Prior.': groupCases.filter(dp => dp.TOP === 3).length,
      '4ta Prior.': groupCases.filter(dp => dp.TOP === 4).length
    };
    // console.log({ group, groupCases, filters });
    // const filteredSum = Object.keys(filters).reduce(
    //   (acc, curr) => acc + filters[curr],
    //   0
    // );
    // console.log({ filteredSum });
    const groupBar = {
      dimension: group,
      ...filters
    };
    return groupBar;
  });
  return groupedCases;
};

const MyResponsiveBar = ({ responses, prioridades, dimensions }) => {
  // console.log({ responses });
  const topData = getTopGroupData(responses);
  const filteredData = topData.filter(dim =>
    dimensions ? dimensions.includes(dim.dimension) : true
  );
  // console.log({ filteredData });
  // console.log({ topData });
  // console.log({areasData})
  // console.log({groupData})
  return (
    <ResponsiveBar
      theme={{
        fontSize: 20,
        labels: {
          text: {
            fontSize: 22
          }
        },
        legends: {
          text: {
            fontSize: 20
          }
        }
      }}
      data={filteredData}
      keys={prioridades || groupsTop}
      indexBy='dimension'
      layout='horizontal'
      // groupMode='grouped'
      isInteractive={false}
      margin={{ top: 0, right: 145, bottom: 40, left: 240 }}
      padding={0.3}
      // maxValue={100}
      valueScale={{ type: 'linear' }}
      colors={[...colorsFourth]}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      enableGridX
      enableGridY={false}
      axisLeft={{
        format: v => getAreaShortName(v)
      }}
      label={d => `${parseFloat(d.value).toFixed(0)}`}
      labelSkipWidth={12}
      labelSkipHeight={12}
      legends={[
        {
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 110,
          translateY: 0,
          itemsSpacing: 10,
          // itemTextColor: '#fff',
          itemWidth: 100,
          itemHeight: 40,
          itemDirection: 'left-to-right',
          // itemOpacity: 0.85,
          symbolSize: 40
        }
      ]}
      animate={false}
      motionStiffness={90}
      motionDamping={15}
    />
  );
};

export default MyResponsiveBar;
