import { ProgressBox, ProgressStat } from '../styles';
import { polarColors, colorsFive } from '../constants';

const getPolarColor = value => {
  if (value < 25) {
    return polarColors[4];
  }
  if (value < 50) {
    return polarColors[3];
  }
  if (value < 75) {
    return polarColors[1];
  }
  return polarColors[0];
};

const HorizontalRange = ({ scale, responses, plainColor }) => {
  // console.log({ scale, responses });
  const scaleCases = responses.map(resp => resp.data[scale]);
  // console.log({ scaleCases });
  const scaleAverage =
    scaleCases.reduce((acc, curr) => acc + curr, 0) / responses.length;
  // console.log({ scaleAverage });
  return (
    <div className='range'>
      <div className='progress-info-group'>
        <div className='progress-info'>
          <div className='progress-details-single'>
            <ProgressBox
              progress={scaleAverage}
              backColor={() =>
                plainColor ? colorsFive[0] : getPolarColor(scaleAverage)
              }
            >
              <div className='progress'></div>
            </ProgressBox>
          </div>
        </div>
      </div>
      <ProgressStat>{scaleAverage.toFixed(0)}</ProgressStat>
    </div>
  );
};

export default HorizontalRange;
