import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import { polarColors } from '../constants';

const mergeItemsData = user => {
  const parsed = ITEMS.map(item => {
    return { ...item, value: parseInt(user[item.code]) };
  });
  return parsed;
};

const ResumenEstilosConduccion = ({ user, background, number }) => {
  // console.log({ user });
  const merged = mergeItemsData(user);
  console.log({ merged });
  const problematic = merged.filter(it => it.value > it.mean + it.sd);
  const probCodes = problematic.map(it => it.code);
  const aboveAv = merged
    .filter(it => it.value > it.mean)
    .filter(it => !probCodes.includes(it.code));
  // console.log({ aboveAv, problematic });
  // console.log({ polarColors });
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>4. Resumen Estilos Conducción</h1>
          <p className='justified'>
            A continuación, verá una lista de comportamientos en base a sus
            propias respuestas sobre su estilo predominante de conducción, que
            están <strong>por sobre lo normal</strong> y, por tanto, debe pensar
            seriamente en reducir o eliminar dichos comportamientos.
          </p>

          {problematic.length > 0 && (
            <div className='open-list-wrapper'>
              <h3 style={{ color: polarColors[0] }}>Sobre la norma:</h3>
              <ul>
                {problematic.map(it => {
                  return <li key={it.code}>{it.statement}</li>;
                })}
              </ul>
            </div>
          )}
          {aboveAv.length > 0 && (
            <div className='open-list-wrapper'>
              <h3>Oportunidades de mejora:</h3>
              <ul>
                {aboveAv.map(it => {
                  return <li key={it.code}>{it.statement}</li>;
                })}
              </ul>
            </div>
          )}
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default ResumenEstilosConduccion;

const ITEMS = [
  {
    code: 'DBQ_01',
    group: 'ViolO',
    mean: 1.45,
    sd: 1.26,
    statement: 'Ir sobre el límite de velocidad en carretera'
  },
  {
    code: 'DBQ_02',
    group: 'ViolO',
    mean: 0.8,
    sd: 0.88,
    statement: 'Ir sobre el límite de velocidad en una zona residencial'
  },
  {
    code: 'DBQ_03',
    group: 'ViolO',
    mean: 0.69,
    sd: 0.77,
    statement:
      'Conducir demasiado cerca de un vehículo enfrente que haría difícil detenerse en caso de emergencia'
  },
  {
    code: 'DBQ_04',
    group: 'ViolO',
    mean: 0.54,
    sd: 0.69,
    statement: 'Cruzar una intersección sabiendo que la luz ya está cambiando'
  },
  {
    code: 'DBQ_05',
    group: 'ViolO',
    mean: 0.24,
    sd: 0.59,
    statement: 'Adelantar por la derecha'
  },
  {
    code: 'DBQ_06',
    group: 'ViolO',
    mean: 0.24,
    sd: 0.54,
    statement:
      'Conducir con la duda de si está o no sobre el límite de velocidad permitido'
  },
  {
    code: 'DBQ_07',
    group: 'ViolA',
    mean: 1.29,
    sd: 1.02,
    statement:
      'Tocar la bocina para demostrar su molestia hacia otros conductores'
  },
  {
    code: 'DBQ_08',
    group: 'ViolA',
    mean: 1.07,
    sd: 1.0,
    statement: 'Enojarse con otro conductor y mostrarse hostil'
  },
  {
    code: 'DBQ_09',
    group: 'ViolA',
    mean: 0.5,
    sd: 0.79,
    statement: 'Salir rápido de un semáforo para ganarle a otro conductor'
  },
  {
    code: 'DBQ_10',
    group: 'ViolA',
    mean: 0.45,
    sd: 0.68,
    statement:
      'Asomar el camión en una salida lo suficiente como para forzar a otros vehículos a ceder el paso'
  },
  {
    code: 'DBQ_11',
    group: 'ViolA',
    mean: 0.28,
    sd: 0.69,
    statement: 'Enojarse con otro conductor y perseguirlo'
  },
  {
    code: 'DBQ_12',
    group: 'ViolA',
    mean: 0.14,
    sd: 0.44,
    statement:
      'Mantenerse hasta el último segundo en una pista que se va cerrando y entonces cambiarse súbitamente'
  },
  {
    code: 'DBQ_13',
    group: 'Lapse',
    mean: 0.99,
    sd: 0.81,
    statement:
      'Ir por la pista equivocada al salir de una rotonda o al doblar en una intersección'
  },
  {
    code: 'DBQ_14',
    group: 'Lapse',
    mean: 0.97,
    sd: 0.86,
    statement: 'No recordar bien la ruta que acaba de hacer'
  },
  {
    code: 'DBQ_15',
    group: 'Lapse',
    mean: 0.94,
    sd: 0.88,
    statement: 'Apretar o prender algo cuando quería hacer otra cosa'
  },
  {
    code: 'DBQ_16',
    group: 'Lapse',
    mean: 0.73,
    sd: 0.98,
    statement: 'Salir de una luz en una marcha equivocada'
  },
  {
    code: 'DBQ_17',
    group: 'Lapse',
    mean: 0.59,
    sd: 0.64,
    statement: 'Al retroceder, pasar a llevar algo que no había visto'
  },
  {
    code: 'DBQ_18',
    group: 'Lapse',
    mean: 0.43,
    sd: 0.67,
    statement: 'Leer mal una indicación y tomar una salida equivocada'
  },
  {
    code: 'DBQ_19',
    group: 'Lapse',
    mean: 0.32,
    sd: 0.56,
    statement:
      '“Despertarse” y darse cuenta que está en una ruta distinta de la planificada'
  },
  {
    code: 'DBQ_20',
    group: 'Lapse',
    mean: 0.1,
    sd: 0.33,
    statement: 'Olvidarse donde dejó estacionado el camión'
  },
  {
    code: 'DBQ_21',
    group: 'Error',
    mean: 0.78,
    sd: 0.67,
    statement:
      'Al adelantar, subestimar la velocidad de un vehículo que viene por la pista contraria'
  },
  {
    code: 'DBQ_22',
    group: 'Error',
    mean: 0.43,
    sd: 0.62,
    statement:
      'Por ir buscando un espacio para cambiarse de pista, estar cerca de chocar un vehículo que está adelante suyo (choque por alcance)'
  },
  {
    code: 'DBQ_23',
    group: 'Error',
    mean: 0.43,
    sd: 0.67,
    statement:
      'Olvidar mirar por el espejo retrovisor antes de hacer una maniobra'
  },
  {
    code: 'DBQ_24',
    group: 'Error',
    mean: 0.41,
    sd: 0.6,
    statement: 'Patinar por frenar muy fuerte en una ruta resbalosa o al doblar'
  },
  {
    code: 'DBQ_25',
    group: 'Error',
    mean: 0.4,
    sd: 0.65,
    statement: 'Al doblar, casi pasar a llevar un ciclista que viene por dentro'
  },
  {
    code: 'DBQ_26',
    group: 'Error',
    mean: 0.36,
    sd: 0.57,
    statement: 'Al hacer un viraje darse cuenta que hay peatones cruzando'
  },
  {
    code: 'DBQ_27',
    group: 'Error',
    mean: 0.23,
    sd: 0.47,
    statement:
      'Intentar adelantar un vehículo que está señalando un viraje a la izquierda'
  },
  {
    code: 'DBQ_28',
    group: 'Error',
    mean: 0.19,
    sd: 0.43,
    statement: 'Pasarse un ceda el paso y apenas evitar chocar otro vehículo'
  }
];
