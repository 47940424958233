import React from 'react';
import styled from 'styled-components';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import LinearChart from '../LinearChart';
import ScatterplotMapChart from '../ScatterplotMapChart';
// import { getDimensionFullName } from '../constants';

export const ChartWrapper = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 385px;
  width: 100%;
  position: relative;
  .legend-right {
    position: absolute;
    right: 0;
    color: #43464d;
    font-size: 0.75rem;
    width: 65px;
    display: grid;
    grid-template-columns: 1fr;
    .legend-title {
      font-weight: 600;
      padding-bottom: 0.5rem;
    }
    .legend-row {
      display: grid;
      grid-template-columns: 26px auto;
      align-items: center;
      margin-bottom: -1px;
      .legend-shape {
        width: 22px;
        height: 22px;
        background-color: red;
      }
    }
  }
`;

const EquisOneKnowledgeAreasGraphPage = ({ user, background, number }) => {
  // console.log({ user });
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>3. Resultados Simulador</h1>

          <ChartWrapper>
            <LinearChart user={user} />
          </ChartWrapper>

          <ChartWrapper>
            <ScatterplotMapChart user={user} />
          </ChartWrapper>

          {/* <p className='justified small'>
            <em>Nota. </em>
            Los resultados anteriores muestran en qué área académica te
            resultaría más atractivo trabajar para desplegar cada una de tus
            preferencias vocacionales prioritarias. Por esto, si bien un área
            académica puede resultarte poco interesante al relacionarla con una
            de tus preferencias prioritarias, esta misma área puede ser más
            interesante al relacionarla con la segunda de tus preferencias (o
            viceversa).
          </p> */}
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOneKnowledgeAreasGraphPage;
