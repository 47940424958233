import React from 'react';
import { SlidePage } from '../index';

const EquisOneTopPage = ({ user, background }) => {
  return (
    <SlidePage background={background}>
      <div className='title-box'>
        <div className='info-row'>
          <div className='title-label'>RUT</div>
          <div className='title-value'>{user.RUT}</div>
        </div>
        <div className='info-row'>
          <div className='title-label'>Nombre</div>
          <div className='title-value'>{`${user.lastname}, ${user.name}`}</div>
        </div>
        <div className='info-row'>
          <div className='title-label'>Curso</div>
          <div className='title-value'>{user.grade}</div>
        </div>
        <div className='info-row'>
          <div className='title-label'>Colegio</div>
          <div className='title-value'>{user.school}</div>
        </div>
      </div>
    </SlidePage>
  );
};

export default EquisOneTopPage;
