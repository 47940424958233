import React from 'react';
import styled from 'styled-components';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import DisciplinesChart from '../DisciplinesChart';
// import { getDimensionFullName } from '../constants';

export const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 100%;
`;

const DisciplinesGraphPage = ({ user, background, number }) => {
  // const { TOP_AREA } = user;
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h2>3.2. Disciplinas de Interés</h2>
          {/* <p className='justified'>
            En la figura de más abajo verás en cuáles disciplinas te interesaría
            profundizar más el próximo año:
          </p> */}

          {/* <p className='justified'>
            En tu caso la disciplina que más te atrae es{' '}
            <strong>{TOP_AREA}</strong>
          </p> */}
          <ChartWrapper>
            <DisciplinesChart user={user} />
          </ChartWrapper>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default DisciplinesGraphPage;
