import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';

const calculateAgeFromBirthday = birthDate => {
  // date comes as "2001-01-02" (year-month-day) from date input
  return Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);
  // https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd
};

const validateDateString = dateString => {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString);
  const isValidDate = date.toString() !== 'Invalid Date';
  if (isValidDate) {
    return `${dateString}T12:00:00.000Z`; // add hours to set mid of day and not 00 and sometimes parse ad previous day
  }
  const [rawYear, rawMonth, rawDay] = dateString.split('-');
  // console.log('invalid datee!!');
  let year = rawYear;
  let month = rawMonth;
  let day = rawDay;
  // if includes empty spaces
  if (rawYear.includes('_')) {
    let innerYear = rawYear;
    // console.log('year includes empty spaces');
    let yearStartWithEmpty = innerYear.startsWith('__');
    let yearEndsWithEmpty = innerYear.endsWith('__');
    if (yearStartWithEmpty) {
      innerYear = `${innerYear.replace('__', '20')}`;
    }
    if (yearEndsWithEmpty) {
      // meaning something like "03__"
      let [finalYear, _] = innerYear.split('__');
      innerYear = `20${finalYear}`;
    }
    innerYear = innerYear.replace(/_/g, '0'); // replace empty with 0 if any (it should not)
    year = innerYear;
  }
  if (rawMonth.includes('_')) {
    // console.log('rawMonth includes empty spaces');
    let innerMonth = rawMonth;
    const monthStartWithEmpty = rawMonth.startsWith('_');
    const monthEndsWithEmpty = rawMonth.endsWith('_');
    if (monthStartWithEmpty) {
      // replace first empty with 0
      innerMonth.replace('_', '0');
    }
    if (monthEndsWithEmpty) {
      // for instance 1_ should be 01
      innerMonth.replace('_', '0');
      innerMonth = `0${innerMonth}`;
    }
    month = innerMonth;
  }
  if (rawDay.includes('_')) {
    let innerDay = rawDay;
    // console.log('rawDay includes empty spaces');
    const dayStartWithEmpty = rawDay.startsWith('_');
    const dayEndsWithEmpty = rawDay.endsWith('_');
    if (dayStartWithEmpty) {
      // replace first empty with 0
      innerDay.replace('_', '0');
    }
    if (dayEndsWithEmpty) {
      // for instance 1_ should be 01
      innerDay.replace('_', '0');
      innerDay = `0${innerDay}`;
    }
    day = innerDay;
  }
  return `${year}-${month}-${day}T12:00:00.000Z`;
};

const EquisOneInfoDataPage = ({ user, background, number }) => {
  const hasDisplayName = user.lastname || user.name;
  const displayName = `${user.lastname}, ${user.name}`;

  const birthDateString = validateDateString(user.BIRTH);
  const dateIsValid = new Date(birthDateString).toString() !== 'Invalid Date';
  const rawAge = calculateAgeFromBirthday(birthDateString);

  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>1. Datos de identificación</h1>
          <div>
            <ul>
              {hasDisplayName && (
                <li>
                  <div className='list-item-label'>Nombre:</div>
                  <span>{displayName}</span>
                </li>
              )}
              <li>
                <div className='list-item-label'>RUT:</div>
                <span>{user.RUT}</span>
              </li>
              <li>
                <div className='list-item-label'>Sexo: </div>
                <span>{user.SEX}</span>
              </li>
              <li>
                <div className='list-item-label'>Fecha nacimiento:</div>
                <span>
                  {user.BIRTH && dateIsValid
                    ? new Date(birthDateString).toLocaleDateString()
                    : ''}
                </span>
              </li>
              <li>
                <div className='list-item-label'>Edad:</div>
                <span>{rawAge || user.age}</span>
              </li>
              {user.grade && (
                <li>
                  <div className='list-item-label'>Curso:</div>
                  <span>{user.grade}</span>
                </li>
              )}
              <li>
                <div className='list-item-label'>Colegio: </div>
                <span>{user.school}</span>
              </li>
              <li>
                <div className='list-item-label'>Fecha evaluación: </div>
                <span>
                  {user.updatedAt
                    ? new Date(user.updatedAt).toLocaleDateString()
                    : ''}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOneInfoDataPage;
