import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import { getAreaFullName } from '../constants';

const cleanDisciplinesName = fieldName => {
  return fieldName
    .replace('perc_A_', '')
    .replace('perc_B_', '')
    .replace('perc_C_', '');
};

const Filosofía = () => (
  <p className='justified'>
    Interés en profundizar en grandes temáticas filosóficas, formular preguntas
    significativas a usando conceptos filosóficos o discutir con compañeros/as
    grandes problemas de la filosofía, de la política, o sobre conceptos como
    libertad, justicia o verdad. Asignaturas relacionadas: Estética, Filosofía
    Política, Seminario de Filosofía
  </p>
);

const HGCSOC = () => (
  <p className='justified'>
    Interés en profundizar en el estudio de la historia, la sociedad y su
    relación con la geografía que habita, así como en analizar problemas
    actuales usando conceptos y teorías de la historia, la geografía y la
    economía. Asignaturas relacionadas: Comprensión Histórica del Presente,
    Economía y Sociedad, Geografía, Territorio y Desafíos Socio-ambientales.
  </p>
);

const LenguaLiteratura = () => (
  <p className='justified'>
    Interés en el estudio del lenguaje, la comunicación y la creación literaria,
    en adquirir técnicas para escribir textos literarios o especializados,
    aprender a desarrollar buenos argumentos o participar en debates.
    Asignaturas relacionadas: Literatura y Escritura Especializada,
    Participación y Argumentación en Democracia, Taller de Literatura.
  </p>
);

const CienciasBio = () => (
  <p className='justified'>
    Interés en profundizar en conocimientos relacionados con la biología, la
    genética, el medioambiente, las ciencias de la salud, o la biotecnología,
    así como adquirir habilidades para el desarrollo de investigaciones
    científicas. Asignaturas relacionadas: Biología de los Ecosistemas, Biología
    Celular y Molecular, Ciencias de la Salud
  </p>
);

const CienciasBasicas = () => (
  <p className='justified'>
    Interés en profundizar en conocimientos relacionados con la física y/o la
    química, aprender principios de la mecánica, termodinámica, el universo y la
    organización de la naturaleza, así como adquirir habilidades para el
    desarrollo de investigaciones científicas. Asignaturas relacionadas: Química
    y Física
  </p>
);

const Matematicas = () => (
  <p className='justified'>
    Interés en profundizar en el estudio de las matemáticas, aprendiendo
    conceptos relacionados con el uso de funciones, análisis estadísticos,
    geometría analítica y 3D, así como desarrollar el pensamiento computacional,
    o aprender a trabajar con límites, derivadas o integrales. Asignaturas
    relacionadas: Geometría 3D, Límites, Derivadas e Integrales, Pensamiento
    Computacional y Programación, Probabilidades y Estadística Descriptiva.
  </p>
);

const ArtesInterpretativas = () => (
  <p className='justified'>
    Interés en adquirir técnicas y conocimientos para la interpretación en
    distintas disciplinas artísticas, como la música, el teatro o la danza, así
    como para la creación artística en estas áreas. Asignaturas relacionadas:
    Creación y Composición Musical, Interpretación Musical, Interpretación y
    Creación, Interpretación y Creación en Teatro.
  </p>
);

const ArtesVisuales = () => (
  <p className='justified'>
    Interés en adquirir conocimientos y desarrollar habilidades relacionadas con
    las artes visuales, audiovisuales o multimedia, el diseño o la arquitectura,
    tanto para crear proyectos propios, como para analizar y reflexionar sobre
    proyecto y obras creadas por otros. Asignaturas relacionadas: Artes
    Visuales, Audiovisuales y Multimediales, Diseño y Arquitectura.
  </p>
);

const Deportes = () => (
  <p className='justified'>
    Interés en la expresión corporal, la promoción de estilos de vida saludable
    y la práctica de la actividad física, así como en aprender sobre las
    ciencias del deporte, el diseño de programas de entrenamiento y los efectos
    del ejercicio físico en la salud. Asignaturas relacionadas: Ciencias de
    Ejercicio Físico y Deportivo, Expresión Corporal, Promoción de Estilos de
    Vida Activos y Saludables.
  </p>
);

const TopDisciplinesPage = ({ user, background, number }) => {
  const TOP_1 = cleanDisciplinesName(user.sortedDisp[0].field);
  const TOP_2 = cleanDisciplinesName(user.sortedDisp[1].field);
  const TOP_3 = cleanDisciplinesName(user.sortedDisp[2].field);
  // console.log({ TOP_1, TOP_2, TOP_3 });
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h3>{`3.2.1. ${getAreaFullName(TOP_1)}`}</h3>
          {TOP_1 === 'F' && <Filosofía />}
          {TOP_1 === 'HGC' && <HGCSOC />}
          {TOP_1 === 'LL' && <LenguaLiteratura />}
          {TOP_1 === 'CBS' && <CienciasBio />}
          {TOP_1 === 'CD' && <CienciasBasicas />}
          {TOP_1 === 'M' && <Matematicas />}
          {TOP_1 === 'AI' && <ArtesInterpretativas />}
          {TOP_1 === 'AV' && <ArtesVisuales />}
          {TOP_1 === 'D' && <Deportes />}

          <h3>{`3.2.2. ${getAreaFullName(TOP_2)}`}</h3>
          {TOP_2 === 'F' && <Filosofía />}
          {TOP_2 === 'HGC' && <HGCSOC />}
          {TOP_2 === 'LL' && <LenguaLiteratura />}
          {TOP_2 === 'CBS' && <CienciasBio />}
          {TOP_2 === 'CD' && <CienciasBasicas />}
          {TOP_2 === 'M' && <Matematicas />}
          {TOP_2 === 'AI' && <ArtesInterpretativas />}
          {TOP_2 === 'AV' && <ArtesVisuales />}
          {TOP_2 === 'D' && <Deportes />}

          <h3>{`3.2.3. ${getAreaFullName(TOP_3)}`}</h3>
          {TOP_3 === 'F' && <Filosofía />}
          {TOP_3 === 'HGC' && <HGCSOC />}
          {TOP_3 === 'LL' && <LenguaLiteratura />}
          {TOP_3 === 'CBS' && <CienciasBio />}
          {TOP_3 === 'CD' && <CienciasBasicas />}
          {TOP_3 === 'M' && <Matematicas />}
          {TOP_3 === 'AI' && <ArtesInterpretativas />}
          {TOP_3 === 'AV' && <ArtesVisuales />}
          {TOP_3 === 'D' && <Deportes />}
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default TopDisciplinesPage;
