import React from 'react';
import styled from 'styled-components';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import AreasBarChart from '../AreasBarChart';
import { getDimensionFullName } from '../constants';

export const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 100%;
`;

const EquisOneAreasGraphPage = ({ user, background, number }) => {
  const { TOP_DIM_1, TOP_DIM_2, TOP_DIM_3, TOP_DIM_4 } = user;
  const filtered = [TOP_DIM_1, TOP_DIM_2, TOP_DIM_3, TOP_DIM_4].filter(Boolean);
  const dimensions = filtered.map(dim => ({
    dimension: dim,
    label: getDimensionFullName(dim)
  }));
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h2>2.1. Áreas Prioritarias</h2>
          <p className='justified'>
            En la figura de más abajo verás tus preferencias principales, que
            dan cuenta de aquellos aspectos del mundo del trabajo que, de
            acuerdo a tus respuestas al test, te resultan más atractivos y menos
            atractivos en la actualidad.
          </p>

          <p className='justified'>
            En tu caso las {dimensions.length} dimensiones que más te atraen
            son:{'  '}
            {dimensions.map((dim, i) => {
              const isLast = i + 1 === dimensions.length;
              const isSemiLast = i + 1 === dimensions.length - 1;
              const separator = isSemiLast ? ' y ' : ', ';
              return (
                <span key={dim.dimension}>
                  <strong>{dim.label}</strong>
                  {isLast ? '.' : separator}
                </span>
              );
            })}
          </p>
          <ChartWrapper>
            <AreasBarChart user={user} />
          </ChartWrapper>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {dimensions.length > 2 && (
            <p className='justified small'>
              <em>Nota. </em>
              En los siguientes resultados solo se utilizarán las 2 dimensiones
              más altas. En caso de existir empate de alguna de estas, se
              continuará solo con una de ellas seleccionada al azar.
            </p>
          )}
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOneAreasGraphPage;
