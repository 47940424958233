import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import {
  getDimensionFullName,
  getAreaFullName,
  getCareersTable
} from '../constants';

// "Agropecuaria"
const Agropecuaria = () => (
  <p className='justified'>
    Disciplinas que correspondan a las ciencias agropecuarias y que se
    relacionan con este ámbito, excluyendo la administración y planificación de
    la misma.
  </p>
);
// "Arte y Arquitectura"
const ArteYArquitectura = () => (
  <p className='justified'>
    Incluye la expresión artística en todas sus formas (incluyendo el Comics) y
    el desarrollo del entorno y su hermosamiento. Incluye además el desarrollo
    de las artes en los medios audiovisuales (radio, televisión y medios
    escritos en todas sus formas, exceptuando periodismo y publicidad).
  </p>
);
// "Ciencias Exactas y Naturales"
const CienciasExactasYNaturales = () => (
  <p className='justified'>
    Incluye las ciencias de la vida y físicas puras, exceptuando medicina y
    vespertina.
  </p>
);
// "Ciencias Sociales"
const CienciasSociales = () => (
  <p className='justified'>
    Incluye todo lo que se relaciona con el quehacer político y comunicacional
    (información); el desarrollo sociocultural y psicológico en todos sus
    ámbitos y el desarrollo de aptitudes de apoyo al área (se excluye el Derecho
    y todas sus ramas).
  </p>
);
// "Derecho"
const Derecho = () => (
  <p className='justified'>
    Incluye el estudio y desarrollo de la Teoría e Historia del Derecho y todo
    lo que implique apoyo o asesoría a esta área.
  </p>
);
// "Humanidades"
const Humanidades = () => (
  <p className='justified'>
    Incluye el estudio de materias concernientes al desarrollo humano interno y
    su interactuar con el medio y el crecimiento del alma, además del cultivo de
    las artes, letras y lenguas extranjeras.
  </p>
);
// "Educación"
const Educacion = () => (
  <p className='justified'>
    Incluye la formación de personal docente en los distintos niveles
    educativos, la formación de especialidades en deportes y recreación y las
    disciplinas de apoyo, desarrollo y administración del área.
  </p>
);
// "Tecnología"
const Tecnologia = () => (
  <p className='justified'>
    Incluye el conocimiento y formación de especialistas orientado al estudio y
    desarrollo de las técnicas de las distintas ramas de la ingeniería y la
    utilización y aplicación de las tecnologías de la información como
    herramientas de apoyo.
  </p>
);
// "Salud"
const Salud = () => (
  <p className='justified'>
    Incluye el estudio de materias concernientes al cuidado de la persona y del
    cuerpo humano y todo lo relacionado con el bienestar durante toda su vida,
    además de técnicas y disciplinas de apoyo al área.
  </p>
);
// "Administración y Comercio"
const AdministracionYComercio = () => (
  <p className='justified'>
    Incluye todo el quehacer comercial y el mercado de capitales, nacional e
    internacional y la administración del sector, su desarrollo, gestión y el
    apoyo a la misma.
  </p>
);
const CarrerasTopPage = ({ user, top, index, background, number }) => {
  // console.log({ TOP_DIM_1, TOP_DIM_2 });
  const areaName = getAreaFullName(top);
  const carreras = getCareersTable(top);

  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h3>
            3.1.{index + 1} {areaName}
          </h3>
          {top === 'AG' && <Agropecuaria />}
          {top === 'AR' && <ArteYArquitectura />}
          {top === 'CN' && <CienciasExactasYNaturales />}
          {top === 'CS' && <CienciasSociales />}
          {top === 'AC' && <AdministracionYComercio />}
          {top === 'D' && <Derecho />}
          {top === 'E' && <Educacion />}
          {top === 'H' && <Humanidades />}
          {top === 'S' && <Salud />}
          {top === 'T' && <Tecnologia />}

          <div className='career-table-wrapper'>
            <div className='table-head'>
              <div className='text-col'>Carreras</div>
              <div className='dot-col'>U</div>
              <div className='dot-col'>IP</div>
              <div className='dot-col'>CFT</div>
            </div>
            <div className='table-body'>
              {carreras.map(({ title, UNI, IP, CFT }) => (
                <div key={title} className='career-row'>
                  <div className='text-col'>{title}</div>
                  <div className='dot-col'>
                    {UNI ? <span className='dot'></span> : ''}
                  </div>
                  <div className='dot-col'>
                    {IP ? <span className='dot'></span> : ''}
                  </div>
                  <div className='dot-col'>
                    {CFT ? <span className='dot'></span> : ''}
                  </div>
                </div>
              ))}
            </div>
            <div className='table-footer'>
              <div className='footer-text'>
                * U = <em>UNIVERSIDAD</em>; IP = <em>INSTITUTO PROFESIONAL</em>;
                CFT = <em>CENTRO FORMACIÓN TÉCNICA</em>
              </div>
            </div>
          </div>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default CarrerasTopPage;
