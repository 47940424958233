import React, { useState, useEffect, Fragment } from 'react';
import { Deck, Slide } from 'spectacle';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import theme from './theme';
import { mergeTheme, oneTheme } from './deckTheme';
import topBackground from './imgs/02-top_equis_1.png';
import backBackground from './imgs/02-back_m.png';
import innerPresentationBack from './imgs/02-inner_equis1.png';
import innerPlainBack from './imgs/02-inner_equis1_plain.png';

import { ResetCSS, GlobalStyle, PageWrapper, PageNumber } from './styles';
// pages
import EquisOneTopPage from './Pages/Top';
import EquisOneClosePage from './Pages/Close';
import PresentationPage from './Pages/Presentation';
import InfoDataPage from './Pages/InfoData';
import OrientationDescriptionPage from './Pages/OrientationDescription';
import AreasGraphPage from './Pages/AreasGraph';
import AreasDescriptionPage from './Pages/AreasDescription';
import PreferencesGraphPage from './Pages/PreferencesGraph';
import PreferencesClarityPage from './Pages/PreferencesClarity';
import KnowledgeAreasGraphPage from './Pages/KnowledgeAreasGraph';
import KnowledgeAreasGraphContPage from './Pages/KnowledgeAreasGraphCont';
import CarrerasTopPage from './Pages/CarrerasTop';
import CarrerasTopDescPage from './Pages/CarrerasTopDesc';
import CarrerasTop1FirstPage from './Pages/CarrerasTop1First';
import CarrerasTop1SecondPage from './Pages/CarrerasTop1Second';
import CarrerasTop2FirstPage from './Pages/CarrerasTop2First';
import CarrerasTop2SecondPage from './Pages/CarrerasTop2Second';
import DysfunctionalBeliefsFirstPage from './Pages/DysfunctionalBeliefsFirst';
import DysfunctionalBeliefsSecondPage from './Pages/DysfunctionalBeliefsSecond';
import DysfunctionalBeliefsThirdPage from './Pages/DysfunctionalBeliefsThird';
import DysfunctionalBeliefsForthPage from './Pages/DysfunctionalBeliefsForth';
import DecisionMakingFirstPage from './Pages/DecisionMakingFirst';
import DecisionMakingSecondPage from './Pages/DecisionMakingSecond';
import DecisionMakingThirdPage from './Pages/DecisionMakingThird';
import VocationalMaturityFirstPage from './Pages/VocationalMaturityFirst';
import VocationalMaturitySecondPage from './Pages/VocationalMaturitySecond';
import AboutUsPage from './Pages/AboutUs';

// Slider Deck theme
const deckTheme = mergeTheme(oneTheme);

// https://github.com/FormidableLabs/spectacle/blob/master/docs/content/advanced-concepts.md#query-parameters
// add to url
// &exportMode=true
export const SlidePage = ({ number, children, background }) => {
  const isEven = number % 2 === 0;
  // console.log({ number, isEven });
  return (
    <PageWrapper backgroundImage={background}>
      {children}
      {number && <PageNumber even={isEven}>{number}</PageNumber>}
    </PageWrapper>
  );
};

// /r/3041903f-b7f3-47ba-bbef-3f6995586f9c?exportMode=true

const EquisOne = ({ campaign, response, data }) => {
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // console.log({ params });
    const parseUser = async () => {
      const userData = data;
      // console.log({ userData });
      const fields = Object.keys(userData);
      const FIRST_AREA_ITEMS = fields.filter(
        field => field.includes('_FIRST_SUM') && !field.includes('perc_')
      );
      const SECOND_AREA_ITEMS = fields.filter(
        field => field.includes('_SECOND_SUM') && !field.includes('perc_')
      );
      const THIRD_AREA_ITEMS = fields.filter(
        field => field.includes('_THIRD_SUM') && !field.includes('perc_')
      );
      const FOURTH_AREA_ITEMS = fields.filter(
        field => field.includes('_FOURTH_SUM') && !field.includes('perc_')
      );
      const sortedFirstAreaSums = FIRST_AREA_ITEMS.map(fieldKey => ({
        area: fieldKey.replace('_FIRST_SUM', ''),
        value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
      })).sort((a, b) => b.value - a.value);
      const sortedSecondAreaSums = SECOND_AREA_ITEMS.map(fieldKey => ({
        area: fieldKey.replace('_SECOND_SUM', ''),
        value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
      })).sort((a, b) => b.value - a.value);
      const sortedThirdAreaSums = THIRD_AREA_ITEMS.map(fieldKey => ({
        area: fieldKey.replace('_THIRD_SUM', ''),
        value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
      })).sort((a, b) => b.value - a.value);
      const sortedFourthAreaSums = FOURTH_AREA_ITEMS.map(fieldKey => ({
        area: fieldKey.replace('_FOURTH_SUM', ''),
        value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
      })).sort((a, b) => b.value - a.value);
      const decisionFields = [
        'Afrontamiento Productivo',
        'Búsqueda de Apoyo',
        'Afrontamiento No-Producivo'
      ];
      const sortedDecisionStyle = decisionFields
        .map(fieldKey => ({
          field: fieldKey,
          value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
        }))
        .sort((a, b) => b.value - a.value);
      // console.log({ sortedFirstAreaSums, sortedSecondAreaSums });
      // console.log({ sortedDecisionStyle });
      const baseEquisInfo = {
        RUT: userData.rut,
        name: '',
        lastname: '',
        grade: '',
        school: campaign.organization || campaign.title
      };
      const school = userData.school || baseEquisInfo.school;
      // console.log({ FIRST_AREA_ITEMS, sortedFirstAreaSums });
      // console.log({ SECOND_AREA_ITEMS, sortedSecondAreaSums });
      // console.log({ THIRD_AREA_ITEMS, sortedThirdAreaSums });
      // console.log({ userData });
      const parsedUser = {
        ...baseEquisInfo,
        ...userData,
        school,
        FIRST_AREA_ITEMS,
        SECOND_AREA_ITEMS,
        THIRD_AREA_ITEMS,
        FOURTH_AREA_ITEMS,
        TOP_FIRST_1: sortedFirstAreaSums[0].area,
        TOP_FIRST_2: sortedFirstAreaSums[1].area,
        TOP_SECOND_1: sortedSecondAreaSums[0].area,
        TOP_SECOND_2: sortedSecondAreaSums[1].area,
        TOP_THIRD_1: sortedThirdAreaSums[0]?.area,
        TOP_THIRD_2: sortedThirdAreaSums[1]?.area,
        TOP_FOURTH_1: sortedFourthAreaSums[0]?.area,
        TOP_FOURTH_2: sortedFourthAreaSums[1]?.area,
        TOP_DECISION_STYLE: sortedDecisionStyle[0].field,
        createdAt: response.createdAt,
        updatedAt: response.updatedAt
      };
      setUser(parsedUser);
      setLoading(false);
    };
    parseUser();
  }, [data]);

  // console.log({ user });
  if (loading) {
    return <div>loading...</div>;
  }
  // console.log({ user });

  const {
    TOP_FIRST_AREA_1,
    TOP_FIRST_AREA_2,
    TOP_FIRST_AREA_3,
    TOP_FIRST_AREA_4,
    TOP_SECOND_AREA_1,
    TOP_SECOND_AREA_2,
    TOP_SECOND_AREA_3,
    TOP_SECOND_AREA_4,
    TOP_THIRD_AREA_1,
    TOP_THIRD_AREA_2,
    TOP_THIRD_AREA_3,
    TOP_THIRD_AREA_4,
    TOP_FOURTH_AREA_1,
    TOP_FOURTH_AREA_2,
    TOP_FOURTH_AREA_3,
    TOP_FOURTH_AREA_4
  } = user;
  const allTopCareers = [
    TOP_FIRST_AREA_1,
    TOP_FIRST_AREA_2,
    TOP_FIRST_AREA_3,
    TOP_FIRST_AREA_4,
    TOP_SECOND_AREA_1,
    TOP_SECOND_AREA_2,
    TOP_SECOND_AREA_3,
    TOP_SECOND_AREA_4,
    TOP_THIRD_AREA_1,
    TOP_THIRD_AREA_2,
    TOP_THIRD_AREA_3,
    TOP_THIRD_AREA_4,
    TOP_FOURTH_AREA_1,
    TOP_FOURTH_AREA_2,
    TOP_FOURTH_AREA_3,
    TOP_FOURTH_AREA_4
  ].filter(Boolean);
  const topCareers = [...new Set(allTopCareers)];
  // console.log({ allTopCareers });

  const hasExtraAreaGraphPage = user.TOP_DIM_3;
  const halfLength = hasExtraAreaGraphPage ? 9 : 8;
  const lastLength = topCareers.length + 1 + halfLength;

  // console.log({ topCareers });

  const userSchool = user.school ? `_${user.school.split(' ').join('_')}` : '';
  const userGrade = user.grade ? `${user.grade.split(' ').join('_')}_` : '';
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Helmet>
          <title>{`${userGrade}${user.RUT}${userSchool}_EquisOne`}</title>
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <Deck theme={deckTheme} backgroundColor='#fff'>
          <Slide backgroundColor='#fff'>
            <EquisOneTopPage user={user} background={topBackground} />
          </Slide>

          {/** BLANK PAGE */}
          <Slide backgroundColor='#fff'>
            <SlidePage></SlidePage>
          </Slide>

          <Slide backgroundColor='#fff'>
            <PresentationPage
              user={user}
              number={1}
              background={innerPresentationBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <InfoDataPage user={user} number={2} background={innerPlainBack} />
          </Slide>

          <Slide backgroundColor='#fff'>
            <OrientationDescriptionPage
              number={3}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <AreasGraphPage
              user={user}
              number={4}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <AreasDescriptionPage
              user={user}
              number={5}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <PreferencesGraphPage
              user={user}
              number={6}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <PreferencesClarityPage
              user={user}
              number={7}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <KnowledgeAreasGraphPage
              user={user}
              number={8}
              background={innerPlainBack}
            />
          </Slide>

          {user.TOP_DIM_3 ? (
            <Slide backgroundColor='#fff'>
              <KnowledgeAreasGraphContPage
                user={user}
                number={halfLength}
                background={innerPlainBack}
              />
            </Slide>
          ) : (
            <div></div>
          )}

          <Slide backgroundColor='#fff'>
            <CarrerasTopDescPage
              user={user}
              // top={topCareers[0]}
              careers={topCareers}
              number={halfLength + 1}
              background={innerPlainBack}
            />
          </Slide>

          {topCareers.map((top, index) => (
            <Slide backgroundColor='#fff' key={top}>
              <CarrerasTopPage
                user={user}
                number={halfLength + index + 2}
                top={top}
                index={index}
                background={innerPlainBack}
              />
            </Slide>
          ))}

          {/* <Slide backgroundColor='#fff'>
            <CarrerasTop1FirstPage
              user={user}
              number={halfLength + 1}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <CarrerasTop1SecondPage
              user={user}
              number={halfLength + 2}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <CarrerasTop2FirstPage
              user={user}
              number={halfLength + 3}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <CarrerasTop2SecondPage
              user={user}
              number={halfLength + 4}
              background={innerPlainBack}
            />
          </Slide> */}

          <Slide backgroundColor='#fff'>
            <DysfunctionalBeliefsFirstPage
              user={user}
              number={lastLength + 1}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <DysfunctionalBeliefsSecondPage
              user={user}
              number={lastLength + 2}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <DysfunctionalBeliefsThirdPage
              user={user}
              number={lastLength + 3}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <DysfunctionalBeliefsForthPage
              user={user}
              number={lastLength + 4}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <DecisionMakingFirstPage
              user={user}
              number={lastLength + 5}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <DecisionMakingSecondPage
              user={user}
              number={lastLength + 6}
              background={innerPlainBack}
            />
          </Slide>
          <Slide backgroundColor='#fff'>
            <DecisionMakingThirdPage
              user={user}
              number={lastLength + 7}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <VocationalMaturityFirstPage
              user={user}
              number={lastLength + 8}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <VocationalMaturitySecondPage
              user={user}
              number={lastLength + 9}
              background={innerPlainBack}
            />
          </Slide>

          <Slide backgroundColor='#fff'>
            <AboutUsPage number={lastLength + 10} background={innerPlainBack} />
          </Slide>

          <Slide backgroundColor='#fff'>
            <EquisOneClosePage background={backBackground} />
          </Slide>
        </Deck>
      </Fragment>
    </ThemeProvider>
  );
};

export default EquisOne;
