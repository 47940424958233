import colors from './colors';

const theme = {
  baseName: 'quanta',
  colors: {
    ...colors,
    primary: colors.blue,
    secondary: colors.grey,
    success: colors.green,
    danger: colors.red,
    warning: colors.yellow,
    textColor: '#43464d',
    darkBackground: '#1d1e20',
    greyBackground: '#2d2d2d',
    brandColor: '#009fe3',
    brandSolid: '#0076e3',
    itemBackground: 'rgba(0, 0, 0, 0.06)',
    itemBackgroundHover: 'rgba(0, 0, 0, 0.11)'
  }
};

export default theme;
