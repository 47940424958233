import React from 'react';
import styled from 'styled-components';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
// import AreasBarChart from '../AreasBarChart';
import EnfoqueDimsBarChart from '../EnfoqueDimsBarChart';
import EnfoqueTreemapChart from '../EnfoqueTreemapChart';
import EnfoqueSubChart from '../EnfoqueSubChart';
// import { getDimensionFullName } from '../constants';

export const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => (height ? height : 400)}px;
  width: 100%;
`;

const EquisOneAreasGraphPage = ({ user, background, number }) => {
  // console.log({ user });
  // const { TOP_DIM_1, TOP_DIM_2, TOP_DIM_3, TOP_DIM_4 } = user;
  // const filtered = [TOP_DIM_1, TOP_DIM_2, TOP_DIM_3, TOP_DIM_4].filter(Boolean);
  // const dimensions = filtered.map(dim => ({
  //   dimension: dim,
  //   label: getDimensionFullName(dim)
  // }));

  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>4. Aprendizaje y Estrategias de Estudio</h1>
          <p className='justified'>
            El siguiente gráfico identifica tus principales motivaciones para
            estudiar y las estrategias que más usas a la hora de enfrentar tus
            estudios:
          </p>
          <br />
          <br />
          <ChartWrapper height={450}>
            <EnfoqueTreemapChart user={user} />
          </ChartWrapper>

          {/**
                    <div>
            <ChartWrapper height={180}>
              <EnfoqueSubChart user={user} fields={['MA', 'MS', 'ML']} />
            </ChartWrapper>
            <ChartWrapper height={180}>
              <EnfoqueSubChart user={user} fields={['ES', 'AP', 'EL']} />
            </ChartWrapper>
          </div>

           */}

        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOneAreasGraphPage;

// const dumm = {
//   "RUT": "18.117.565-6",
//   "name": "",
//   "lastname": "",
//   "grade": "",
//   "school": "prueba Diego",
//   "C4_1_SE": -1,
//   "perc_CN_SUM": 62.5,
//   "metadata": {
//       "referer": "http://localhost:3000/c/a499adf2-1560-49f2-ba51-450e5f639bee",
//       "sec-ch-ua": "\" Not A;Brand\";v=\"99\", \"Chromium\";v=\"90\", \"Google Chrome\";v=\"90\"",
//       "sec-ch-ua-mobile": "?0",
//       "accept-language": "es-ES,es;q=0.9,en;q=0.8,gl;q=0.7",
//       "origin": "http://localhost:3000",
//       "host": "localhost:57581",
//       "x-uaimetrics-api-ver": "v1",
//       "user-agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.93 Safari/537.36"
//   },
//   "ES_ESC_MEAN": 3.4166666666666665,
//   "C5_4_SE": -1,
//   "C1_3_CD": 1,
//   "C2_1_AL": 0,
//   "C4_4_IP": 0,
//   "caseId": "ae683689-9722-43de-a63b-247e4796a5db",
//   "C5_1_LM": 1,
//   "C3_2_FM": 1,
//   "C8_2_CD": 0,
//   "C8_1_FM": 0,
//   "C3_3_CN": 0,
//   "C5_2_IP": -1,
//   "C8_2_AP": -1,
//   "C4_1_CN": 1,
//   "EL_EL_1": "5",
//   "EL_EL_3": "5",
//   "EL_EL_2": "1",
//   "EL_EL_5": "5",
//   "EL_EL_4": "3",
//   "EL_EL_6": "4",
//   "C7_2_IP": -1,
//   "A_LL_9": -1,
//   "C8_4_LM": -1,
//   "C7_1_LM": 0,
//   "C6_1_SE": -1,
//   "IP_SUM": -3,
//   "C7_4_CD": 1,
//   "A_LL_2": -1,
//   "C3_4_AL": -1,
//   "A_LL_1": 0,
//   "A_LL_4": 0,
//   "A_LL_3": 0,
//   "A_LL_6": 0,
//   "A_LL_5": 0,
//   "C1_3_AP": -1,
//   "A_LL_8": 1,
//   "A_LL_7": 0,
//   "TUTORIAL_2": "1",
//   "A_F_7": 1,
//   "C8_3_AP": -1,
//   "A_F_8": 0,
//   "A": 46.296296296296305,
//   "A_F_9": 0,
//   "B_CD_9": 1,
//   "B": 90.74074074074075,
//   "C": 12.962962962962962,
//   "C2_4_FM": 0,
//   "SEX": "Masculino",
//   "B_CD_3": 1,
//   "B_CD_4": 1,
//   "C7_4_AP": -1,
//   "B_CD_1": 1,
//   "B_CD_2": 1,
//   "B_CD_7": 0,
//   "B_CD_8": 1,
//   "B_CD_5": 1,
//   "B_CD_6": 1,
//   "AP_SUB_MEAN": 3.3333333333333335,
//   "C3_4_SE": 0,
//   "C6_2_SE": -1,
//   "EL_SUB_MEAN": 3.8333333333333335,
//   "C1_4_AP": -1,
//   "C6_4_LM": 1,
//   "STAGE": 6,
//   "MA_SUB_SUM": 18,
//   "C4_1_AL": 0,
//   "C7_1_IP": 0,
//   "TOP_DIM_1": "CD",
//   "TOP_DIM_2": "FM",
//   "C6_2_AP": 1,
//   "C1_2_FM": 0,
//   "LM_SUM": 5,
//   "C3_4_CN": 0,
//   "C3_3_FM": 1,
//   "C1_3_CN": 0,
//   "CONS_AL": 9.375,
//   "C5_2_LM": 1,
//   "CONS_AP": 0,
//   "B_M_9": 1,
//   "C_AV_8": -1,
//   "C5_3_IP": 0,
//   "C_AV_9": -1,
//   "B_M_7": 1,
//   "C_AV_6": -1,
//   "B_M_8": 0,
//   "C_AV_7": -1,
//   "B_M_5": 1,
//   "C_AV_4": -1,
//   "B_M_6": 1,
//   "C_AV_5": -1,
//   "C_AV_2": -1,
//   "B_M_3": 1,
//   "B_M_4": 1,
//   "C_AV_3": -1,
//   "B_M_1": 1,
//   "B_M_2": 1,
//   "C_AV_1": 0,
//   "MS_SUB_MEAN": 3.5,
//   "rut": "18.117.565-6",
//   "EL_ESC_SUM": 43,
//   "C1_4_CN": 1,
//   "C3_2_SE": -1,
//   "C4_2_CN": 1,
//   "C1_3_FM": 0,
//   "perc_SE_SUM": 15.625,
//   "CN_SUM": 4,
//   "ML_SUB_SUM": 20,
//   "C6_2_LM": 0,
//   "C2_4_CN": 0,
//   "C2_3_FM": -1,
//   "C6_3_IP": 0,
//   "perc_IP_SUM": 40.625,
//   "EP_MA_1": "2",
//   "EP_MA_2": "4",
//   "EP_MA_3": "2",
//   "EP_MA_4": "4",
//   "EP_MA_5": "2",
//   "C1_4_CD": 0,
//   "EP_MA_6": "4",
//   "C8_3_CD": 0,
//   "C4_3_AL": -1,
//   "A_F_1": 0,
//   "A_F_2": -1,
//   "C3_2_CN": 0,
//   "A_F_3": -1,
//   "C8_2_FM": 1,
//   "A_F_4": -1,
//   "A_F_5": 0,
//   "C3_1_FM": 1,
//   "A_F_6": 0,
//   "C5_1_AL": -1,
//   "C6_3_LM": 0,
//   "C6_4_IP": 0,
//   "A_HGC_9": 0,
//   "CONS_LM": 3.125,
//   "perc_FM_SUM": 68.75,
//   "C6_1_AP": 1,
//   "C7_3_IP": -1,
//   "EP_AP_6": "1",
//   "C8_4_CD": 0,
//   "EP_AP_5": "5",
//   "EP_AP_4": "3",
//   "C4_2_AL": 0,
//   "C7_2_LM": 1,
//   "ES_ES_3": "3",
//   "C5_4_IP": 0,
//   "C8_3_FM": 1,
//   "ES_ES_2": "3",
//   "C8_1_LM": 0,
//   "ES_ES_1": "3",
//   "C2_4_CD": 1,
//   "C_D_7": -1,
//   "EP_AP_3": "3",
//   "C3_3_AL": 0,
//   "C_D_8": -1,
//   "EP_AP_2": "3",
//   "ES_ES_6": "3",
//   "C_D_9": -1,
//   "EP_AP_1": "5",
//   "ES_ES_5": "3",
//   "C5_3_LM": 1,
//   "ES_ES_4": "5",
//   "C8_4_AP": 0,
//   "C2_4_AL": -1,
//   "A_STUDY_SUM": -2,
//   "C5_1_SE": 0,
//   "A_HGC_2": 0,
//   "C3_3_SE": -1,
//   "A_HGC_1": -1,
//   "ES_SUB_SUM": 20,
//   "A_HGC_4": 0,
//   "A_HGC_3": 0,
//   "A_HGC_6": 0,
//   "A_HGC_5": 0,
//   "A_HGC_8": 1,
//   "A_HGC_7": 1,
//   "perc_LM_SUM": 65.625,
//   "C4_2_SE": -1,
//   "age": 29,
//   "C2_3_CN": 0,
//   "C2_2_FM": -1,
//   "C6_1_LM": 0,
//   "B_CBS_7": 0,
//   "C7_2_AP": 0,
//   "B_CBS_6": 1,
//   "B_CBS_5": 1,
//   "B_CBS_4": 1,
//   "B_CBS_3": 1,
//   "B_CBS_2": 1,
//   "B_CBS_1": 1,
//   "B_STUDY_SUM": 22,
//   "perc_AP_SUM": 31.25,
//   "C3_1_AL": 0,
//   "C6_4_SE": -1,
//   "C4_4_AL": 1,
//   "B_CBS_9": 1,
//   "B_CBS_8": -1,
//   "CONS_FM": 28.125,
//   "C7_4_LM": 0,
//   "C5_2_AL": 0,
//   "C_D_3": -1,
//   "C_D_4": 0,
//   "C_D_5": -1,
//   "C_D_6": -1,
//   "SE_SUM": -11,
//   "C_STUDY_SUM": -20,
//   "C_D_1": -1,
//   "C_D_2": 0,
//   "EL_ML_5": "4",
//   "C1_4_FM": 0,
//   "EL_ML_4": "4",
//   "EL_ML_6": "2",
//   "C3_1_SE": -1,
//   "EL_ML_1": "4",
//   "EL_ML_3": "4",
//   "EL_ML_2": "2",
//   "perc_B_M": 94.44444444444444,
//   "C4_4_SE": 0,
//   "C2_3_CD": 1,
//   "C6_4_AP": 0,
//   "C5_4_LM": 1,
//   "C2_3_AL": 0,
//   "C5_2_SE": 0,
//   "ML_SUB_MEAN": 3.3333333333333335,
//   "AL_SUM": -3,
//   "C3_1_CN": 0,
//   "C7_1_CD": 1,
//   "BIRTH": "1992-02-25",
//   "C4_3_SE": 0,
//   "perc_A_LL": 44.44444444444444,
//   "C2_1_FM": 1,
//   "AP_SUM": -6,
//   "C7_1_AP": -1,
//   "AP_SUB_SUM": 20,
//   "C4_1_IP": 0,
//   "C2_2_CN": 0,
//   "FM_SUM": 6,
//   "perc_A_F": 38.88888888888889,
//   "C7_4_IP": 0,
//   "CD_SUM": 8,
//   "TUTORIAL": "1",
//   "C4_3_CN": 1,
//   "C7_3_LM": 0,
//   "CONS_IP": 0,
//   "C8_4_FM": 1,
//   "CHAT_NAME": "Diego",
//   "C6_2_IP": 0,
//   "C8_2_LM": 0,
//   "C3_2_AL": 0,
//   "C1_1_AP": -1,
//   "C5_3_AL": 0,
//   "perc_CD_SUM": 75,
//   "C8_1_AP": -1,
//   "EP_ESC_MEAN": 3.1666666666666665,
//   "MA_SUB_MEAN": 3,
//   "C8_3_LM": 0,
//   "C7_3_CD": 1,
//   "C2_2_CD": 1,
//   "C1_2_AP": -1,
//   "C6_3_AP": 1,
//   "perc_B_CD": 94.44444444444444,
//   "CONS_SE": 0,
//   "MS_SUB_SUM": 21,
//   "ES_ESC_SUM": 41,
//   "C7_3_AP": 0,
//   "finished": 1,
//   "CONS_CD": 59.375,
//   "C2_2_AL": 0,
//   "C5_3_SE": -1,
//   "C1_1_CD": 0,
//   "C4_3_IP": 0,
//   "C1_1_CN": 1,
//   "CONS_CN": 0,
//   "ES_SUB_MEAN": 3.3333333333333335,
//   "ES_MS_6": "3",
//   "perc_A_HGC": 55.55555555555556,
//   "ES_MS_5": "4",
//   "ES_MS_4": "4",
//   "ES_MS_3": "4",
//   "ES_MS_2": "2",
//   "perc_C_AI": 22.22222222222222,
//   "C5_1_IP": 0,
//   "ES_MS_1": "4",
//   "C2_1_CN": 0,
//   "C4_2_IP": 0,
//   "C4_4_CN": -1,
//   "C1_1_FM": 0,
//   "C1_2_CN": 0,
//   "C6_1_IP": 0,
//   "TOP_STUDY_AREA_2": "A",
//   "perc_AL_SUM": 40.625,
//   "C3_4_FM": 1,
//   "EL_SUB_SUM": 23,
//   "TOP_STUDY_AREA_1": "B",
//   "perc_B_CBS": 83.33333333333334,
//   "C5_4_AL": 0,
//   "EP_ESC_SUM": 38,
//   "EL_ESC_MEAN": 3.5833333333333335,
//   "C1_2_CD": 1,
//   "perc_C_D": 11.11111111111111,
//   "C6_3_SE": -1,
//   "C_AI_9": 0,
//   "C_AI_7": -1,
//   "perc_C_AV": 5.555555555555555,
//   "C_AI_8": 0,
//   "C_AI_5": -1,
//   "C_AI_6": -1,
//   "C_AI_3": 0,
//   "C7_2_CD": 0,
//   "C_AI_4": -1,
//   "C_AI_1": -1,
//   "C2_1_CD": -1,
//   "C_AI_2": 0,
//   "C8_1_CD": 1,
//   "sorted_A": [
//       {
//           "field": "perc_A_HGC",
//           "value": 55.55555555555556
//       },
//       {
//           "field": "perc_A_LL",
//           "value": 44.44444444444444
//       },
//       {
//           "field": "perc_A_F",
//           "value": 38.88888888888889
//       }
//   ],
//   "sorted_B": [
//       {
//           "field": "perc_B_M",
//           "value": 94.44444444444444
//       },
//       {
//           "field": "perc_B_CD",
//           "value": 94.44444444444444
//       },
//       {
//           "field": "perc_B_CBS",
//           "value": 83.33333333333334
//       }
//   ],
//   "sorted_C": [
//       {
//           "field": "perc_C_AI",
//           "value": 22.22222222222222
//       },
//       {
//           "field": "perc_C_D",
//           "value": 11.11111111111111
//       },
//       {
//           "field": "perc_C_AV",
//           "value": 5.555555555555555
//       }
//   ],
//   "sortedDisp": [
//       {
//           "field": "perc_B_M",
//           "value": 94.44444444444444
//       },
//       {
//           "field": "perc_B_CD",
//           "value": 94.44444444444444
//       },
//       {
//           "field": "perc_B_CBS",
//           "value": 83.33333333333334
//       },
//       {
//           "field": "perc_A_HGC",
//           "value": 55.55555555555556
//       },
//       {
//           "field": "perc_A_LL",
//           "value": 44.44444444444444
//       },
//       {
//           "field": "perc_A_F",
//           "value": 38.88888888888889
//       },
//       {
//           "field": "perc_C_AI",
//           "value": 22.22222222222222
//       },
//       {
//           "field": "perc_C_D",
//           "value": 11.11111111111111
//       },
//       {
//           "field": "perc_C_AV",
//           "value": 5.555555555555555
//       }
//   ],
//   "TOP_AREA": "B",
//   "createdAt": "2021-05-07T00:47:35.017Z",
//   "updatedAt": "2021-05-07T01:02:07.884Z"
// }
