import React from 'react';
import styled from 'styled-components';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import AreasBarChart from '../AreasBarChart';
import { getDimensionFullName } from '../constants';

export const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 100%;
`;

const EquisOneAreasGraphPage = ({ user, background, number }) => {
  const { TOP_DIM_1, TOP_DIM_2 } = user;
  const firstDimName = getDimensionFullName(TOP_DIM_1);
  const secondDimName = getDimensionFullName(TOP_DIM_2);
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h2>2.1. Áreas Prioritarias</h2>
          <p className='justified'>
            En la figura de más abajo verás tus preferencias principales, que
            dan cuenta de aquellos aspectos del mundo del trabajo que, de
            acuerdo a tus respuestas al test, te resultan más atractivos y menos
            atractivos en la actualidad.
          </p>

          <p className='justified'>
            En tu caso las dos dimensiones que más te atraen son{' '}
            <strong>{firstDimName}</strong> y <strong>{secondDimName}</strong>
          </p>
          <ChartWrapper>
            <AreasBarChart user={user} />
          </ChartWrapper>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOneAreasGraphPage;
