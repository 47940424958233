import React from 'react';
import styled from 'styled-components';
import { SlidePage } from '../index';
import { BodyContainer, ProgressBox, ProgressStat } from '../styles';
// import { getDimensionFullName, polarColors } from '../constants';
// import SingleHorizontalBarChart from '../SingleHorizontalBarChart';

export const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: 100%;
`;

const EstiloConduccionPage = ({ user, background, number }) => {
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>6. Recomendaciones Instructor</h1>
          <p className='justified'>
            En base a lo observado durante la simulación, ¿el conductor está
            recomendado para conducir camiones para esta compañía?
          </p>
          <br />
          <div className='qualified-results'>
            <div className={`option${user.FEV_01 === '1' ? ' marked' : ''}`}>
              Recomendado
            </div>
            <div className={`option${user.FEV_01 === '2' ? ' marked warning' : ''}`}>
              Recomendado con observaciones
            </div>
            <div className={`option${user.FEV_01 === '3' ? ' marked danger' : ''}`}>
              No recomendado
            </div>
          </div>
          <br />
          <br />
          <br />
          <h3>Observaciones y/o sugerencias adicionales</h3>

          <p className='justified'>{user.FEV_02}</p>
          <p className='justified'>{user.FEV_03}</p>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EstiloConduccionPage;
