import React, { useState, useEffect, Fragment } from 'react';
import { Deck, Slide } from 'spectacle';
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import theme from './theme';
import { mergeTheme, oneTheme } from './deckTheme';
// import topBackground from './imgs/02-top.png';
// import backBackground from './imgs/02-back_m.png';
// import innerPresentationBack from './imgs/02-inner_presentation.png';
// import innerPlainBack from './imgs/02-inner_plain.png';
import EquisJuniorLogoWhite from './imgs/equis_junior_logo_white.png';
import UaimetricsLogo from './imgs/uaimetrics_logo.png';
import ABLogo from './imgs/ABP_logo.png';
import MapaOrientacion from './imgs/mapa-orientacion-carreras.png';
import InteresesJunior from './imgs/junior_model.png';
import EnfoqueImg from './imgs/enfoque_estudios.png';

import {
  ResetCSS,
  GlobalStyle,
  PageWrapper,
  PageNumber,
  ArasDetailsBackLayer
} from './styles';
// pages
// import EquisJuniorTopPage from './Pages/Top';
// import EquisJuniorClosePage from './Pages/Close';
// import PresentationPage from './Pages/Presentation';
// import InfoDataPage from './Pages/InfoData';
// import OrientationDescriptionPage from './Pages/OrientationDescription';
// import AreasGraphPage from './Pages/AreasGraph';

// import AreasDescriptionPage from './Pages/AreasDescription';
// import PreferencesGraphPage from './Pages/PreferencesGraph';
// import PreferencesClarityPage from './Pages/PreferencesClarity';
// import InterestDescriptionPage from './Pages/InterestDescription';
// import InterestGraphPage from './Pages/InterestGraph';
// import InterestAreaDescriptionPage from './Pages/InterestAreaDescription';
// import DisciplinesGraphPage from './Pages/DisciplinesGraph';
// import TopDisciplinesPage from './Pages/TopDisciplines';
// import EnfoqueGraphPage from './Pages/EnfoqueGraph';
// import MotivationScalesDescriptionPage from './Pages/MotivationScalesDescription';
// import StrategyScalesDescriptionPage from './Pages/StrategyScalesDescription';
import WaffleChart from './WaffleChart';
import GradeDistributionChart from './GradeDistributionChart';
import AreasStackedChart from './AreasStackedChart';
// import AreasBarChart from './AreasBarChart';
// import PieChart from './PieChart';
import ABCStackedChart from './ABCStackedChart';
// import ABCDivergentChart from './ABCDivergentChart';
// import DisciplinesChart from './DisciplinesChart';
// import DisciplinesStackedChart from './DisciplinesStackedChart';
import DisciplinesTopStackedChart from './DisciplinesTopStackedChart';
// import SmallPieChart from './SmallPieChart';
import ProgressPieChart from './ProgressPieChart';
// import KnowledgeAreasGraphPage from './Pages/KnowledgeAreasGraph';
// import CarrerasTop1FirstPage from './Pages/CarrerasTop1First';
// import CarrerasTop1SecondPage from './Pages/CarrerasTop1Second';
// import CarrerasTop2FirstPage from './Pages/CarrerasTop2First';
// import CarrerasTop2SecondPage from './Pages/CarrerasTop2Second';
// import DysfunctionalBeliefsFirstPage from './Pages/DysfunctionalBeliefsFirst';
// import DysfunctionalBeliefsSecondPage from './Pages/DysfunctionalBeliefsSecond';
// import DysfunctionalBeliefsThirdPage from './Pages/DysfunctionalBeliefsThird';
// import DysfunctionalBeliefsForthPage from './Pages/DysfunctionalBeliefsForth';
// import DecisionMakingFirstPage from './Pages/DecisionMakingFirst';
// import DecisionMakingSecondPage from './Pages/DecisionMakingSecond';
// import DecisionMakingThirdPage from './Pages/DecisionMakingThird';
// import VocationalMaturityFirstPage from './Pages/VocationalMaturityFirst';
// import VocationalMaturitySecondPage from './Pages/VocationalMaturitySecond';
// import AboutUsPage from './Pages/AboutUs';

export const ChartWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => (height ? height : 400)}px;
  width: 100%;
`;

// Slider Deck theme
const deckTheme = mergeTheme(oneTheme);

// https://github.com/FormidableLabs/spectacle/blob/master/docs/content/advanced-concepts.md#query-parameters
// add to url
// &exportMode=true
export const SlidePage = ({ number, children, background, ...rest }) => {
  // const isEven = number % 2 === 0;
  // console.log({ number, isEven });
  return (
    <PageWrapper {...rest} backgroundImage={background}>
      {children}
      {number && <PageNumber>{number}</PageNumber>}
    </PageWrapper>
  );
};
// caso real
// /c/9f01895a-1701-445d-bfe4-673b80a8932d/Ab7JsNgMspCwrJv-yYv5m
// /r/a14c8d54-4bf9-4a27-bfe3-892c3d6ac6e3?exportMode=true

// diego Prueba
// /c/a499adf2-1560-49f2-ba51-450e5f639bee
// /r/84af0b5d-5ced-44c5-acda-bd71ae233972?exportMode=true

const SlideHeader = () => (
  <div className='slide-header'>
    <div className='logo'>
      <img src={UaimetricsLogo} alt='uaimetrics logo' />
    </div>
    <div className='logo'>
      <img src={ABLogo} alt='Albert Bridge logo' />
      {/* <img src={UaimetricsLogo}/> */}
    </div>
  </div>
);

const PlainHeader = () => (
  <div className='plain-header right'>
    <div className='logo-big'>
      <img src={EquisJuniorLogoWhite} alt='equis-junior logo' />
    </div>
  </div>
);

const EquisJunior = ({ campaign, responses }) => {
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(false);
  // const [user, setUser] = useState(null);
  const [campaignData, setCampaignData] = useState(null);
  // console.log({ props });
  // console.log({ campaign, responses });

  useEffect(() => {
    // console.log({ params });
    const parseCampaign = async () => {
      const male = responses.filter(resp => resp.data.SEX === 'Masculino');
      const female = responses.filter(resp => resp.data.SEX === 'Femenino');
      const grades = [...new Set(responses.map(resp => resp.data.grade))]
        .sort((a, b) => a.localeCompare(b))
        .filter(Boolean); // clean undefined and falsy grades (with no list)
      const gradeResponses = grades.map(grade => {
        return {
          grade,
          responses: responses.filter(resp => resp.data.grade === grade)
        };
      });
      console.log({ gradeResponses });
      const infoData = { grades, male, female, gradeResponses };
      setCampaignData(infoData);
      setLoading(false);
    };
    parseCampaign();
  }, [campaign, responses]);

  // console.log({ user });
  if (loading) {
    return <div>loading...</div>;
  }
  console.log({ campaignData });

  const firstStep = 7;
  const gradesLength = campaignData?.grades.length;
  const hasGrades = gradesLength > 1;
  const secondStep = hasGrades
    ? firstStep + gradesLength
    : firstStep - gradesLength;
  const thirdStep = hasGrades ? secondStep + gradesLength + 7 : secondStep + 7;
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Helmet>
          <title>{`${(campaign.organization || campaign.title)
            .split(' ')
            .join('_')}_EquisJunior_${campaign.id}`}</title>
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <Deck theme={deckTheme} backgroundColor='#ff8300'>
          <Slide backgroundColor='#ff8300'>
            <SlidePage>
              <div className='title-page'>
                <div className='logo-big'>
                  <img src={EquisJuniorLogoWhite} alt='equis-junior logo' />
                </div>
              </div>
              <div className='top-body'>
                <h1>Presentación de Resultados</h1>
                <h2>{campaign.organization || campaign.title}</h2>
              </div>
            </SlidePage>
          </Slide>

          {/** BLANK PAGE */}
          <Slide backgroundColor='#ff8300'>
            <SlidePage></SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={1}>
              <SlideHeader />
              <div className='half-left'>
                <div>
                  <h1>
                    <span>¿</span>Qué es{' '}
                    <span>
                      <img src={EquisJuniorLogoWhite} alt='equis-junior logo' />
                    </span>
                    <span>?</span>
                  </h1>
                </div>
              </div>
              <div className='slide-body right'>
                <div className='slide-content'>
                  <div className='flex-center'>
                    <p>
                      Test de orientación sobre preferencias e intereses
                      vocacionales a profundizar en los últimos años de la etapa
                      escolar para estudiantes de enseñanza media, diseñado por
                      UAI Metrics de la Escuela de Psicología de la Universidad
                      Adolfo Ibáñez.
                    </p>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={2}>
              <SlideHeader />
              <div className='half-left'>
                <div>
                  <h1>
                    <span>¿</span>Qué es{' '}
                    <span>
                      <img src={EquisJuniorLogoWhite} alt='equis-junior logo' />
                    </span>
                    <span>?</span>
                  </h1>
                </div>
              </div>
              <div className='slide-body right'>
                <div className='slide-content'>
                  <h1>Objetivo</h1>
                  <div>
                    <ul>
                      <li>
                        Orientar a los estudiantes respecto de sus preferencias
                        para seleccionar las asignaturas de profundización que
                        deberán cursar en 3ero y 4to medio.
                      </li>
                      <li>
                        Entregar información a los estudiantes sobre sus
                        principales motivaciones y estrategias de estudio.
                      </li>
                      <li>
                        Entregar información a los establecimientos sobre las
                        preferencias de sus estudiantes, para decidir qué cursos
                        optativos ofrecer cada año.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={3}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Distribución de estudiantes participantes</h1>
                </div>
                <div className='footnote'>
                  <div>Número de hombres y mujeres</div>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución General</h1>
                  <p>
                    Total = {responses.length} (Mujeres ={' '}
                    {campaignData?.female.length}; Hombres ={' '}
                    {campaignData?.male.length})
                  </p>
                  <div>
                    <br />
                    <ChartWrapper height={600}>
                      <WaffleChart responses={responses} />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          {hasGrades ? (
            <Slide backgroundColor='#ff8300'>
              <SlidePage number={4}>
                <SlideHeader />
                <div className='third-left'>
                  <div>
                    <h1>Distribución de estudiantes participantes</h1>
                  </div>
                  <div className='footnote'>
                    <div>Número de hombres y mujeres por curso</div>
                  </div>
                </div>
                <div className='slide-body big'>
                  <div className='slide-content'>
                    <h1>Distribución Detallada</h1>
                    <p>
                      {campaignData?.gradeResponses
                        .map(g => `${g.grade} = ${g.responses.length}`)
                        .join('; ')}
                    </p>
                    <div>
                      <br />
                      <ChartWrapper height={600}>
                        <GradeDistributionChart
                          grades={campaignData?.gradeResponses}
                        />
                      </ChartWrapper>
                    </div>
                  </div>
                </div>
              </SlidePage>
            </Slide>
          ) : (
            <div></div>
          )}

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={hasGrades ? 5 : 4}>
              <PlainHeader />
              <div className='plain-body right'>
                <h1>Orientación Vocacional</h1>
                <h2>Áreas Prioritarias</h2>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={hasGrades ? 6 : 5}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Áreas Prioritarias</h1>
                  <h2>Presentación</h2>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <div>
                    <p>
                      El Test Equis Junior identifica las preferencias de los
                      estudiantes en base a un modelo circular de 8 preferencias
                      principales.
                    </p>
                  </div>
                  <div className='image-wrapper'>
                    <img
                      style={{ height: 700, marginLeft: -50 }}
                      src={MapaOrientacion}
                      alt='dsa'
                    />
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={hasGrades ? 7 : 6}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Áreas Prioritarias</h1>
                  <h2>Distribución General</h2>
                </div>
                <div className='footnote'>
                  <div>
                    Número de estudiantes agrupados según su nivel de interés en
                    las distintas áreas prioritarias
                  </div>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución General</h1>
                  <div>
                    <ChartWrapper height={700}>
                      <AreasStackedChart responses={responses} />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          {hasGrades ? (
            campaignData?.gradeResponses.map((grade, index) => (
              <Slide backgroundColor='#ff8300' key={`areas${grade.grade}`}>
                <SlidePage number={firstStep + index + 1}>
                  <SlideHeader />
                  <div className='third-left'>
                    <div>
                      <h1>Áreas Prioritarias</h1>
                      <h2>Distribución {grade.grade}</h2>
                    </div>
                    <div className='footnote'>
                      <div>
                        Número de estudiantes de curso {grade.grade} agrupados
                        según su nivel de interés en las distintas áreas
                        prioritarias
                      </div>
                    </div>
                  </div>
                  <div className='slide-body big'>
                    <div className='slide-content'>
                      <h1>Distribución {grade.grade}</h1>
                      <div>
                        <ChartWrapper height={700}>
                          <AreasStackedChart responses={grade.responses} />
                        </ChartWrapper>
                      </div>
                    </div>
                  </div>
                </SlidePage>
              </Slide>
            ))
          ) : (
            <div></div>
          )}

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={secondStep + 1}>
              <PlainHeader />
              <div className='plain-body right'>
                <h1>Orientación Vocacional</h1>
                <h2>Áreas Disciplinares</h2>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={secondStep + 2}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Áreas Disciplinares</h1>
                  <h2>Presentación</h2>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <div>
                    <p>
                      El Test Equis Junior identifica aquellas áreas
                      disciplinares de interés de los estudiantes, aquellas en
                      que les interesaría profundizar sus conocimientos en los
                      próximos años. El test Equis Junior identifica tres
                      grandes áreas de interés, A, B y C, y nueve disciplinas:
                    </p>
                  </div>
                  <div className='image-wrapper'>
                    <img
                      style={{ height: 700 }}
                      src={InteresesJunior}
                      alt='dsa'
                    />
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          {/* <Slide backgroundColor='#ff8300'>
            <SlidePage number={secondStep + 3}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Áreas Disciplinares</h1>
                  <h2>Distribución General</h2>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución General</h1>
                  <div>
                    <ChartWrapper height={700}>
                      <ABCStackedChart
                        gradesResponses={campaignData?.gradeResponses}
                        responses={responses}
                        user={user}
                      />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide> */}

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={secondStep + 3}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Áreas Disciplinares</h1>
                  <h2>Distribución General</h2>
                </div>
                <div className='footnote'>
                  <div>
                    Grado de interés promedio por area A, B y C (0% = ningún
                    interés; 100% = máximo interés)
                  </div>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución General</h1>
                  <div>
                    <ChartWrapper height={700}>
                      <ABCStackedChart
                        gradesResponses={campaignData?.gradeResponses}
                        responses={responses}
                        // user={user}
                        groupMode='grouped'
                      />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>
          {/*
          <Slide backgroundColor='#ff8300'>
            <SlidePage number={secondStep + 3}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Áreas Disciplinares</h1>
                  <h2>Distribución General (3)</h2>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución General (3)</h1>
                  <div>
                    <ChartWrapper height={700}>
                      <ABCDivergentChart
                        gradesResponses={campaignData?.gradeResponses}
                        responses={responses}
                        user={user}
                      />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide> */}

          {/*
          <Slide backgroundColor='#ff8300'>
            <SlidePage number={4}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Áreas Disciplinares</h1>
                  <h2>Distribución 2do A</h2>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución 2do A</h1>
                  <div>
                    <ChartWrapper height={700}>
                      <PieChart user={user} />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide> */}

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={secondStep + 4}>
              <PlainHeader />
              <div className='plain-body right'>
                <h1>Orientación Vocacional</h1>
                <h2>Disciplinas de Interés</h2>
              </div>
            </SlidePage>
          </Slide>

          {/**
          <Slide backgroundColor='#ff8300'>
            <SlidePage number={secondStep + 5}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Disciplinas de Interés</h1>
                  <h2>Distribución General</h2>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución General</h1>
                  <div>
                    <ChartWrapper height={700}>
                      <ArasDetailsBackLayer>
                        <div className="area-back area-a"><div className="label">A</div></div>
                        <div className="area-back area-b"><div className="label">B</div></div>
                        <div className="area-back area-c"><div className="label">C</div></div>
                      </ArasDetailsBackLayer>
                      <DisciplinesStackedChart
                        responses={responses}
                        user={user}
                      />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>
 */}

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={secondStep + 5}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Disciplinas de Interés</h1>
                  <h2>Distribución General</h2>
                </div>
                <div className='footnote'>
                  <div>
                    Número de estudiantes que seleccionan cada disciplina de
                    interés en primera prioridad
                  </div>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución General (1ra prioridad)</h1>
                  <div>
                    <ChartWrapper height={700}>
                      <ArasDetailsBackLayer>
                        <div className='area-back area-a'>
                          <div className='label'>A</div>
                        </div>
                        <div className='area-back area-b'>
                          <div className='label'>B</div>
                        </div>
                        <div className='area-back area-c'>
                          <div className='label'>C</div>
                        </div>
                      </ArasDetailsBackLayer>
                      <DisciplinesTopStackedChart
                        responses={responses}
                        // user={user}
                        // dimensions={['F', 'HGC', 'LL']}
                        prioridades={['1ra Prior.']}
                      />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={secondStep + 6}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Disciplinas de Interés</h1>
                  <h2>Distribución General</h2>
                </div>
                <div className='footnote'>
                  <div>
                    Número de estudiantes que seleccionan cada disciplina de
                    interés en primera, segunda, tercera y cuarta prioridad
                  </div>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución General (Prioridades)</h1>
                  <div>
                    <ChartWrapper height={700}>
                      <ArasDetailsBackLayer>
                        <div className='area-back area-a'>
                          <div className='label'>A</div>
                        </div>
                        <div className='area-back area-b'>
                          <div className='label'>B</div>
                        </div>
                        <div className='area-back area-c'>
                          <div className='label'>C</div>
                        </div>
                      </ArasDetailsBackLayer>
                      <DisciplinesTopStackedChart responses={responses} />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          {/**
          <Slide backgroundColor='#ff8300'>
            <SlidePage number={secondStep + 5}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Disciplinas de Interés</h1>
                  <h2>Distribución General</h2>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución General A</h1>
                  <div>
                    <ChartWrapper height={700}>
                      <DisciplinesTopStackedChart
                        responses={responses}
                        user={user}
                        dimensions={['F', 'HGC', 'LL']}
                        prioridades={['1ra Prior.']}
                      />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>
 */}

          {/**
          <Slide backgroundColor='#ff8300'>
            <SlidePage number={secondStep + 5}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Disciplinas de Interés</h1>
                  <h2>Distribución General</h2>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución General B</h1>
                  <div>
                    <ChartWrapper height={700}>
                      <DisciplinesTopStackedChart
                        responses={responses}
                        user={user}
                        dimensions={['CBS', 'CD', 'M']}
                        prioridades={['1ra Prior.']}
                      />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>
 */}

          {/**

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={secondStep + 5}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Disciplinas de Interés</h1>
                  <h2>Distribución General</h2>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución General C</h1>
                  <div>
                    <ChartWrapper height={700}>
                      <DisciplinesTopStackedChart
                        responses={responses}
                        user={user}
                        dimensions={['AV', 'AI', 'D']}
                        prioridades={['1ra Prior.']}
                      />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>
 */}

          {hasGrades ? (
            campaignData?.gradeResponses.map((grade, index) => (
              <Slide backgroundColor='#ff8300' key={`disc${grade.grade}`}>
                <SlidePage number={secondStep + index + 7}>
                  <SlideHeader />
                  <div className='third-left'>
                    <div>
                      <h1>Disciplinas de Interés</h1>
                      <h2>Prioridades {grade.grade}</h2>
                    </div>
                    <div className='footnote'>
                      <div>
                        Número de estudiantes {grade.grade} que seleccionan cada
                        disciplina de interés en primera, segunda, tercera y
                        cuarta prioridad
                      </div>
                    </div>
                  </div>
                  <div className='slide-body big'>
                    <div className='slide-content'>
                      <h1>Distribución {grade.grade} (Prioridades)</h1>
                      {/**
                    <div>
                      <ChartWrapper height={700}>
                        <DisciplinesStackedChart responses={grade.responses} />
                      </ChartWrapper>
                    </div>
                     */}

                      <div>
                        <ChartWrapper height={700}>
                          <ArasDetailsBackLayer>
                            <div className='area-back area-a'>
                              <div className='label'>A</div>
                            </div>
                            <div className='area-back area-b'>
                              <div className='label'>B</div>
                            </div>
                            <div className='area-back area-c'>
                              <div className='label'>C</div>
                            </div>
                          </ArasDetailsBackLayer>
                          <DisciplinesTopStackedChart
                            responses={grade.responses}
                          />
                        </ChartWrapper>
                      </div>
                    </div>
                  </div>
                </SlidePage>
              </Slide>
            ))
          ) : (
            <div></div>
          )}

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={thirdStep}>
              <PlainHeader />
              <div className='plain-body right'>
                <h1>Orientación Vocacional</h1>
                <h2>Aproximación al estudio</h2>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={thirdStep + 1}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Aproximación al estudio</h1>
                  <h2>Presentación</h2>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <div>
                    <p>
                      El Test Equis Junior describe el enfoque de estudio de
                      los/las estudiantes, identificando sus principales
                      motivaciones para estudiar y aprender, así como las
                      principales estrategias que implementan los estudiantes
                      para estudiar
                    </p>
                  </div>
                  <div className='image-wrapper'>
                    <img style={{ height: 625 }} src={EnfoqueImg} alt='dsa' />
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={thirdStep + 2}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Aproximación al estudio</h1>
                  <h2>Distribución General</h2>
                </div>
                <div className='footnote'>
                  <div>
                    Porcentaje de estudiantes que tienen un puntaje ALTO y BAJO
                    en cada una de las Orientaciones al Aprendizaje
                  </div>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Orientaciones al Aprendizaje</h1>
                  <div className='scale-grid cols-2'>
                    <div className='scale-wrap'>
                      <h3>Orientación al aprendizaje profundo</h3>
                      <ChartWrapper height={225}>
                        <ProgressPieChart scale='MA' responses={responses} />
                      </ChartWrapper>
                    </div>
                    <div className='scale-wrap'>
                      <h3>Orientación al aprendizaje instrumental</h3>
                      <ChartWrapper height={225}>
                        <ProgressPieChart scale='MS' responses={responses} />
                      </ChartWrapper>
                    </div>
                  </div>
                  <div className='scale-grid'>
                    <div className='scale-wrap'>
                      <h3>Orientación de logro académico</h3>
                      <ChartWrapper height={225}>
                        <ProgressPieChart scale='ML' responses={responses} />
                      </ChartWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={thirdStep + 3}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Aproximación al estudio</h1>
                  <h2>Distribución General</h2>
                </div>
                <div className='footnote'>
                  <div>
                    Porcentaje de estudiantes que tienen un puntaje ALTO y BAJO
                    en cada una de las Estrategias de Estudio
                  </div>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Estrategias de Estudio</h1>
                  <div className='scale-grid cols-2'>
                    <div className='scale-wrap'>
                      <h3>Estrategias de aprendizaje profundo</h3>
                      <ChartWrapper height={225}>
                        <ProgressPieChart scale='AP' responses={responses} />
                      </ChartWrapper>
                    </div>
                    <div className='scale-wrap'>
                      <h3>Estrategias de aprendizaje instrumental</h3>
                      <ChartWrapper height={225}>
                        <ProgressPieChart scale='ES' responses={responses} />
                      </ChartWrapper>
                    </div>
                  </div>
                  <div className='scale-grid'>
                    <div className='scale-wrap'>
                      <h3>Estrategias de logro académico</h3>
                      <ChartWrapper height={225}>
                        <ProgressPieChart scale='EL' responses={responses} />
                      </ChartWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          {hasGrades ? (
            campaignData?.grades
              .map(grade => [grade, grade])
              .flat()
              .map((grade, index) => {
                const gradeData = campaignData.gradeResponses.find(
                  g => g.grade === grade
                );
                const isEven = index % 2 === 0;
                return (
                  <Slide
                    backgroundColor='#ff8300'
                    key={`study${grade.grade}_${index}`}
                  >
                    <SlidePage number={thirdStep + 4 + index}>
                      <SlideHeader />
                      <div className='third-left'>
                        <div>
                          <h1>Aproximación al estudio</h1>
                          <h2>Distribución {gradeData.grade}</h2>
                        </div>
                        <div className='footnote'>
                          <div>
                            Porcentaje de estudiantes {gradeData.grade} que
                            tienen un puntaje ALTO y BAJO en cada una de las{' '}
                            {isEven
                              ? 'Orientaciones al Aprendizaje'
                              : 'Estrategias de Estudio'}
                          </div>
                        </div>
                      </div>
                      {isEven ? (
                        <div className='slide-body big'>
                          <div className='slide-content'>
                            <h1>
                              Orientaciones al Aprendizaje {gradeData.grade}
                            </h1>
                            <div className='scale-grid cols-2'>
                              <div className='scale-wrap'>
                                <h3>Orientación al aprendizaje profundo</h3>
                                <ChartWrapper height={225}>
                                  <ProgressPieChart
                                    scale='MA'
                                    responses={gradeData.responses}
                                  />
                                </ChartWrapper>
                              </div>
                              <div className='scale-wrap'>
                                <h3>Orientación al aprendizaje instrumental</h3>
                                <ChartWrapper height={225}>
                                  <ProgressPieChart
                                    scale='MS'
                                    responses={gradeData.responses}
                                  />
                                </ChartWrapper>
                              </div>
                            </div>
                            <div className='scale-grid'>
                              <div className='scale-wrap'>
                                <h3>Orientación de logro académico</h3>
                                <ChartWrapper height={225}>
                                  <ProgressPieChart
                                    scale='ML'
                                    responses={gradeData.responses}
                                  />
                                </ChartWrapper>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='slide-body big'>
                          <div className='slide-content'>
                            <h1>Estrategias de Estudio {gradeData.grade}</h1>
                            <div className='scale-grid cols-2'>
                              <div className='scale-wrap'>
                                <h3>Estrategias de aprendizaje profundo</h3>
                                <ChartWrapper height={225}>
                                  <ProgressPieChart
                                    scale='AP'
                                    responses={gradeData.responses}
                                  />
                                </ChartWrapper>
                              </div>
                              <div className='scale-wrap'>
                                <h3>Estrategias de aprendizaje instrumental</h3>
                                <ChartWrapper height={225}>
                                  <ProgressPieChart
                                    scale='ES'
                                    responses={gradeData.responses}
                                  />
                                </ChartWrapper>
                              </div>
                            </div>
                            <div className='scale-grid'>
                              <div className='scale-wrap'>
                                <h3>Estrategias de logro académico</h3>
                                <ChartWrapper height={225}>
                                  <ProgressPieChart
                                    scale='EL'
                                    responses={gradeData.responses}
                                  />
                                </ChartWrapper>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </SlidePage>
                  </Slide>
                );
              })
          ) : (
            <div></div>
          )}

          <Slide backgroundColor='#ff8300'>
            <SlidePage>
              <div className='title-page'>
                <div className='logo-big'>
                  <img
                    style={{ marginTop: 150 }}
                    src={EquisJuniorLogoWhite}
                    alt='equis-junior logo'
                  />
                </div>
              </div>
            </SlidePage>
          </Slide>

          {/**
          <Slide backgroundColor='#ff8300'>
            <SlidePage></SlidePage>
          </Slide>
           */}
        </Deck>
      </Fragment>
    </ThemeProvider>
  );
};

export default EquisJunior;

// const equisJuniorDummy = {
//   school: 'Colegio Equis Junior',
//   grade: '2°A',
//   name: 'Nombre(s)',
//   lastname: 'Apellido(s)',
//   email: '',
//   RUT: '99.999.999-9',
//   BIRTH: '2004/06/09 0:00:00',
//   SEX: 'Femenino',
//   age: '16',
//   updatedat: 'Wed, 18 Nov 2020 18:34:26 GMT',
//   TOP_DIM_1: 'CN',
//   TOP_DIM_2: 'LM',
//   CONS_AL: '-25',
//   CONS_SE: '21.875',
//   CONS_IP: '12.5',
//   CONS_LM: '-15.625',
//   CONS_AP: '25',
//   CONS_CD: '-21.875',
//   CONS_FM: '-12.5',
//   CONS_CN: '15.625',
//   perc_AL_SUM: '18.75',
//   perc_AP_SUM: '43.75',
//   perc_CD_SUM: '37.5',
//   perc_CN_SUM: '87.5',
//   perc_FM_SUM: '34.375',
//   perc_IP_SUM: '46.875',
//   perc_LM_SUM: '71.875',
//   perc_SE_SUM: '59.375',
//   perc_A_F: '50',
//   perc_A_HGC: '44.44444444',
//   perc_A_LL: '50',
//   perc_B_CBS: '100',
//   perc_B_CD: '100',
//   perc_B_M: '61.11111111',
//   perc_C_AI: '0',
//   perc_C_AV: '22.22222222',
//   perc_C_D: '22.22222222',
//   A: '48.14814815',
//   B: '87.03703704',
//   C: '14.81481481'
// };
