import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer, ProgressBox, ProgressStat } from '../styles';
import { dimColors } from '../constants';

// const getColor = value => {
//   if (value < 40) {
//     return polarColors[0];
//   }
//   return polarColors[4];
// };

const DecisionMakingThirdPage = ({ user, background, number }) => {
  // const { TOP_DECISION_STYLE } = user;
  const afrontNOProductivo = parseFloat(user['Afrontamiento No-Producivo']);

  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h3>Afrontamiento No Productivo</h3>
          <p className='justified'>
            Las personas que obtienen puntajes más altos en esta escala utilizan
            mayoritariamente estrategias que pueden dificultar el proceso de
            elección de carrera, como retrasar o evitar el tomar la decisión;
            ocultar a otros sus preocupaciones; enfocarse en los aspectos
            negativos del proceso, etc. Es recomendable trabajar en identificar
            los factores que están dificultando el proceso de decisión y cuál
            sería la mejor estrategia para afrontarlos de forma productiva.
            También puede ser relevante implementar estrategias de búsqueda de
            apoyo para enfrentar los procesos (como pedir consejos y
            recomendaciones a personas significativas o profesionales).
          </p>
          <div className='progress-info-group'>
            <div className='progress-info'>
              <div className='progress-details-single'>
                <div className='progress-name'>Afrontamiento No Productivo</div>
                <ProgressBox
                  progress={afrontNOProductivo}
                  backColor={() => dimColors[2]}
                >
                  <div className='progress'></div>
                </ProgressBox>
                <ProgressStat>{afrontNOProductivo}</ProgressStat>
              </div>
            </div>
          </div>
          <br />
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default DecisionMakingThirdPage;
