import React, { useState, useEffect, Fragment } from 'react';
import { Deck, Slide } from 'spectacle';
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import theme from './theme';
import { mergeTheme, oneTheme } from './deckTheme';
// import topBackground from './imgs/02-top.png';
// import backBackground from './imgs/02-back_m.png';
// import innerPresentationBack from './imgs/02-inner_presentation.png';
// import innerPlainBack from './imgs/02-inner_plain.png';
import EquisOneLogoWhite from './imgs/equis_one_logo_white.png';
import UaimetricsLogo from './imgs/uaimetrics_logo.png';
import ABLogo from './imgs/ABP_logo.png';
import MapaOrientacion from './imgs/mapa-orientacion-carreras.png';
import InteresesJunior from './imgs/junior_model.png';
import EnfoqueImg from './imgs/enfoque_estudios.png';

import {
  ResetCSS,
  GlobalStyle,
  PageWrapper,
  PageNumber,
  ArasDetailsBackLayer
} from './styles';

// pages
import EquisJuniorTopPage from './Pages/Top';
import EquisJuniorClosePage from './Pages/Close';
import PresentationPage from './Pages/Presentation';
import InfoDataPage from './Pages/InfoData';
import OrientationDescriptionPage from './Pages/OrientationDescription';
import AreasGraphPage from './Pages/AreasGraph';

import AreasDescriptionPage from './Pages/AreasDescription';
import PreferencesGraphPage from './Pages/PreferencesGraph';
// import PreferencesClarityPage from './Pages/PreferencesClarity';
import InterestDescriptionPage from './Pages/InterestDescription';
import InterestGraphPage from './Pages/InterestGraph';
import InterestAreaDescriptionPage from './Pages/InterestAreaDescription';
import DisciplinesGraphPage from './Pages/DisciplinesGraph';
import TopDisciplinesPage from './Pages/TopDisciplines';
import EnfoqueGraphPage from './Pages/EnfoqueGraph';
import MotivationScalesDescriptionPage from './Pages/MotivationScalesDescription';
import StrategyScalesDescriptionPage from './Pages/StrategyScalesDescription';
import WaffleChart from './WaffleChart';
import GradeDistributionChart from './GradeDistributionChart';
import AreasStackedChart from './AreasStackedChart';
import KnowledgeAreasStackedChart from './KnowledgeAreasStackedChart';
import AreasBarChart from './AreasBarChart';
import PieChart from './PieChart';
import ABCStackedChart from './ABCStackedChart';
import ABCDivergentChart from './ABCDivergentChart';
import DisciplinesChart from './DisciplinesChart';
import DisciplinesStackedChart from './DisciplinesStackedChart';
import DisciplinesTopStackedChart from './DisciplinesTopStackedChart';
import SmallPieChart from './SmallPieChart';
import ProgressPieChart from './ProgressPieChart';
import HorizontalRangeScale from './HorizontalRangeScale';
// import KnowledgeAreasGraphPage from './Pages/KnowledgeAreasGraph';
// import CarrerasTop1FirstPage from './Pages/CarrerasTop1First';
// import CarrerasTop1SecondPage from './Pages/CarrerasTop1Second';
// import CarrerasTop2FirstPage from './Pages/CarrerasTop2First';
// import CarrerasTop2SecondPage from './Pages/CarrerasTop2Second';
// import DysfunctionalBeliefsFirstPage from './Pages/DysfunctionalBeliefsFirst';
// import DysfunctionalBeliefsSecondPage from './Pages/DysfunctionalBeliefsSecond';
// import DysfunctionalBeliefsThirdPage from './Pages/DysfunctionalBeliefsThird';
// import DysfunctionalBeliefsForthPage from './Pages/DysfunctionalBeliefsForth';
// import DecisionMakingFirstPage from './Pages/DecisionMakingFirst';
// import DecisionMakingSecondPage from './Pages/DecisionMakingSecond';
// import DecisionMakingThirdPage from './Pages/DecisionMakingThird';
// import VocationalMaturityFirstPage from './Pages/VocationalMaturityFirst';
// import VocationalMaturitySecondPage from './Pages/VocationalMaturitySecond';
import AboutUsPage from './Pages/AboutUs';

export const ChartWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => (height ? height : 400)}px;
  width: 100%;
`;

// Slider Deck theme
const deckTheme = mergeTheme(oneTheme);

// https://github.com/FormidableLabs/spectacle/blob/master/docs/content/advanced-concepts.md#query-parameters
// add to url
// &exportMode=true
export const SlidePage = ({ number, children, background, ...rest }) => {
  // const isEven = number % 2 === 0;
  // console.log({ number, isEven });
  return (
    <PageWrapper {...rest} backgroundImage={background}>
      {children}
      {number && <PageNumber>{number}</PageNumber>}
    </PageWrapper>
  );
};
// caso real
// /c/4e11a22e-fc10-486f-b2ba-b33c079e1af2/k4l_cGUhF8LbRe0vn2_aE?exportMode=true

const SlideHeader = () => (
  <div className='slide-header'>
    <div className='logo'>
      <img src={UaimetricsLogo} alt='uaimetrics logo' />
    </div>
    <div className='logo'>
      <img src={ABLogo} alt='Albert Bridge logo' />
      {/* <img src={UaimetricsLogo}/> */}
    </div>
  </div>
);

const PlainHeader = () => (
  <div className='plain-header right'>
    <div className='logo-big'>
      <img src={EquisOneLogoWhite} alt='equis-junior logo' />
    </div>
  </div>
);

const EquisJunior = ({ campaign, responses: dataResponses }) => {
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(false);
  // const [user, setUser] = useState(null);
  const [campaignData, setCampaignData] = useState({});
  const [responses, setResponses] = useState([]);
  // console.log({ props });

  useEffect(() => {
    // console.log({ params });
    const parseInitialData = async () => {
      const parseUser = response => {
        const userData = response.data;
        // console.log({ userData });
        const fields = Object.keys(userData);
        const FIRST_AREA_ITEMS = fields.filter(
          field => field.includes('_FIRST_SUM') && !field.includes('perc_')
        );
        const SECOND_AREA_ITEMS = fields.filter(
          field => field.includes('_SECOND_SUM') && !field.includes('perc_')
        );
        const THIRD_AREA_ITEMS = fields.filter(
          field => field.includes('_THIRD_SUM') && !field.includes('perc_')
        );
        const FOURTH_AREA_ITEMS = fields.filter(
          field => field.includes('_FOURTH_SUM') && !field.includes('perc_')
        );
        const sortedFirstAreaSums = FIRST_AREA_ITEMS.map(fieldKey => ({
          area: fieldKey.replace('_FIRST_SUM', ''),
          value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
        })).sort((a, b) => b.value - a.value);
        const sortedSecondAreaSums = SECOND_AREA_ITEMS.map(fieldKey => ({
          area: fieldKey.replace('_SECOND_SUM', ''),
          value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
        })).sort((a, b) => b.value - a.value);
        const sortedThirdAreaSums = THIRD_AREA_ITEMS.map(fieldKey => ({
          area: fieldKey.replace('_THIRD_SUM', ''),
          value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
        })).sort((a, b) => b.value - a.value);
        const sortedFourthAreaSums = FOURTH_AREA_ITEMS.map(fieldKey => ({
          area: fieldKey.replace('_FOURTH_SUM', ''),
          value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
        })).sort((a, b) => b.value - a.value);
        const decisionFields = [
          'Afrontamiento Productivo',
          'Búsqueda de Apoyo',
          'Afrontamiento No-Producivo'
        ];
        const sortedDecisionStyle = decisionFields
          .map(fieldKey => ({
            field: fieldKey,
            value: userData[fieldKey] ? parseFloat(userData[fieldKey]) : 0
          }))
          .sort((a, b) => b.value - a.value);
        // console.log({ sortedFirstAreaSums, sortedSecondAreaSums });
        // console.log({ sortedDecisionStyle });

        const parsedUser = {
          ...userData,
          FIRST_AREA_ITEMS,
          SECOND_AREA_ITEMS,
          THIRD_AREA_ITEMS,
          FOURTH_AREA_ITEMS,
          TOP_FIRST_1: sortedFirstAreaSums[0].area,
          TOP_FIRST_2: sortedFirstAreaSums[1].area,
          TOP_SECOND_1: sortedSecondAreaSums[0].area,
          TOP_SECOND_2: sortedSecondAreaSums[1].area,
          TOP_THIRD_1: sortedThirdAreaSums[0]?.area,
          TOP_THIRD_2: sortedThirdAreaSums[1]?.area,
          TOP_FOURTH_1: sortedFourthAreaSums[0]?.area,
          TOP_FOURTH_2: sortedFourthAreaSums[1]?.area,
          TOP_DECISION_STYLE: sortedDecisionStyle[0].field,
          createdAt: response.createdAt,
          updatedAt: response.updatedAt
        };
        return { ...response, data: parsedUser };
      };
      const parseResponses = () => {
        return dataResponses.map(resp => parseUser(resp));
      };

      const parseCampaign = resps => {
        const male = resps.filter(resp => resp.data.SEX === 'Masculino');
        const female = resps.filter(resp => resp.data.SEX === 'Femenino');
        const grades = [...new Set(resps.map(resp => resp.data.grade))]
          .sort((a, b) => a.localeCompare(b))
          .filter(Boolean); // clean undefined and falsy grades (with no list)
        const gradeResponses = grades.map(grade => {
          return {
            grade,
            responses: resps.filter(resp => resp.data.grade === grade)
          };
        });
        console.log({ gradeResponses });
        const infoData = { grades, male, female, gradeResponses };
        return infoData;
      };
      const campaignResponses = parseResponses();
      const campaignInfoData = parseCampaign(campaignResponses);
      console.log({ campaignInfoData });
      console.log({ campaignResponses });

      await setCampaignData(campaignInfoData);
      await setResponses(campaignResponses);
      await setLoading(false);
    };
    parseInitialData();
  }, [campaign, dataResponses]);

  // console.log({ campaign, responses });

  // console.log({ user });
  if (loading) {
    return <div>loading...</div>;
  }
  if (!campaign || !responses) {
    return <div>loading...</div>;
  }
  if (!campaignData) {
    return <div>loading...</div>;
  }
  if (!campaignData.gradeResponses) {
    return <div>loading...</div>;
  }
  // console.log({ campaignData });

  const firstStep = 7;
  const gradesLength = campaignData.grades.length;
  // console.log({ gradesLength });
  const hasGrades = gradesLength > 1;
  const secondStep = hasGrades
    ? firstStep + gradesLength
    : firstStep - gradesLength;
  const thirdStep = hasGrades ? secondStep + gradesLength + 3 : secondStep + 3;
  const fourthStep = hasGrades ? thirdStep + gradesLength + 3 : thirdStep + 3;
  const fifthStep = hasGrades ? fourthStep + gradesLength + 1 : fourthStep + 1;
  const sixthStep = hasGrades ? fifthStep + gradesLength + 3 : fifthStep + 3;

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Helmet>
          <title>{`${(campaign.organization || campaign.title)
            .split(' ')
            .join('_')}_EquisOne_${campaign.id}`}</title>
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <Deck theme={deckTheme} backgroundColor='#ff8300'>
          <Slide backgroundColor='#ff8300'>
            <SlidePage>
              <div className='title-page'>
                <div className='logo-big'>
                  <img src={EquisOneLogoWhite} alt='equis-one logo' />
                </div>
              </div>
              <div className='top-body'>
                <h1>Presentación de Resultados</h1>
                <h2>{campaign.organization || campaign.title}</h2>
              </div>
            </SlidePage>
          </Slide>

          {/** BLANK PAGE */}
          <Slide backgroundColor='#ff8300'>
            <SlidePage></SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={1}>
              <SlideHeader />
              <div className='half-left'>
                <div>
                  <h1>
                    <span>¿</span>Qué es{' '}
                    <span>
                      <img src={EquisOneLogoWhite} alt='equis-one logo' />
                    </span>
                    <span>?</span>
                  </h1>
                </div>
              </div>
              <div className='slide-body right'>
                <div className='slide-content'>
                  <div className='flex-center'>
                    <p>
                      Test de orientación vocacional para estudiantes de
                      enseñanza media, diseñado por UAI Metrics de la Escuela de
                      Psicología de la Universidad Adolfo Ibáñez.
                      <br />
                      <br />
                      Basado en un modelo que incluye{' '}
                      <strong>8 orientaciones vocacionales</strong> y{' '}
                      <strong>10 áreas de conocimiento</strong>, donde se
                      encuentran las carreras idóneas para el perﬁl del
                      estudiante. El objetivo de este test es poder visualizar y
                      aclarar aquellas áreas que al estudiante{' '}
                      <strong>le gustan o que prefiere</strong>.
                    </p>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={2}>
              <SlideHeader />
              <div className='half-left'>
                <div>
                  <h1>
                    <span>¿</span>Qué es{' '}
                    <span>
                      <img src={EquisOneLogoWhite} alt='equis-one logo' />
                    </span>
                    <span>?</span>
                  </h1>
                </div>
              </div>
              <div className='slide-body right'>
                <div className='slide-content'>
                  <h1>Objetivo</h1>
                  <div>
                    <ul>
                      <li>
                        Entregar información relevante a estudiantes para el
                        proceso de toma de decisiones de carrera,
                        específicamente en relación a preferencias vocacionales
                        y características personales sobre el proceso de toma de
                        decisión.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={3}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Distribución de estudiantes participantes</h1>
                </div>
                <div className='footnote'>
                  <div>Número de hombres y mujeres</div>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución General</h1>
                  <p>
                    Total = {responses.length} (Mujeres ={' '}
                    {campaignData.female.length}; Hombres ={' '}
                    {campaignData.male.length})
                  </p>
                  <div>
                    <br />
                    <ChartWrapper height={600}>
                      <WaffleChart responses={responses} />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          {hasGrades ? (
            <Slide backgroundColor='#ff8300'>
              <SlidePage number={4}>
                <SlideHeader />
                <div className='third-left'>
                  <div>
                    <h1>Distribución de estudiantes participantes</h1>
                  </div>
                  <div className='footnote'>
                    <div>Número de hombres y mujeres por curso</div>
                  </div>
                </div>
                <div className='slide-body big'>
                  <div className='slide-content'>
                    <h1>Distribución Detallada</h1>
                    <p>
                      {campaignData.gradeResponses
                        .map(g => `${g.grade} = ${g.responses.length}`)
                        .join('; ')}
                    </p>
                    <div>
                      <br />
                      <ChartWrapper height={600}>
                        <GradeDistributionChart
                          grades={campaignData.gradeResponses}
                        />
                      </ChartWrapper>
                    </div>
                  </div>
                </div>
              </SlidePage>
            </Slide>
          ) : (
            <div></div>
          )}

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={hasGrades ? 5 : 4}>
              <PlainHeader />
              <div className='plain-body right'>
                <h1>Orientación Vocacional</h1>
                <h2>Áreas Prioritarias</h2>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={hasGrades ? 6 : 5}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Áreas Prioritarias</h1>
                  <h2>Presentación</h2>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <div>
                    <p>
                      El Test Equis Junior identifica las preferencias de los
                      estudiantes en base a un modelo circular de 8 preferencias
                      principales.
                    </p>
                  </div>
                  <div className='image-wrapper'>
                    <img
                      style={{ height: 700, marginLeft: -50 }}
                      src={MapaOrientacion}
                      alt='dsa'
                    />
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={hasGrades ? 7 : 6}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Áreas Prioritarias</h1>
                  <h2>Distribución General</h2>
                </div>
                <div className='footnote'>
                  <div>
                    Número de estudiantes agrupados según su nivel de interés en
                    las distintas áreas prioritarias
                  </div>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución General</h1>
                  <div>
                    <ChartWrapper height={700}>
                      <AreasStackedChart responses={responses} />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          {hasGrades ? (
            campaignData.gradeResponses.map((grade, index) => (
              <Slide backgroundColor='#ff8300' key={`areas${grade.grade}`}>
                <SlidePage number={firstStep + index + 1}>
                  <SlideHeader />
                  <div className='third-left'>
                    <div>
                      <h1>Áreas Prioritarias</h1>
                      <h2>Distribución {grade.grade}</h2>
                    </div>
                    <div className='footnote'>
                      <div>
                        Número de estudiantes de curso {grade.grade} agrupados
                        según su nivel de interés en las distintas áreas
                        prioritarias
                      </div>
                    </div>
                  </div>
                  <div className='slide-body big'>
                    <div className='slide-content'>
                      <h1>Distribución {grade.grade}</h1>
                      <div>
                        <ChartWrapper height={700}>
                          <AreasStackedChart responses={grade.responses} />
                        </ChartWrapper>
                      </div>
                    </div>
                  </div>
                </SlidePage>
              </Slide>
            ))
          ) : (
            <div></div>
          )}

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={secondStep + 1}>
              <PlainHeader />
              <div className='plain-body right'>
                <h1>Orientación Vocacional</h1>
                <h2>Áreas De Conocimiento</h2>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={secondStep + 2}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Áreas De Conocimiento</h1>
                  <h2>Distribución General</h2>
                </div>
                <div className='footnote'>
                  <div>
                    Número de estudiantes que seleccionan cada área de
                    conocimiento como primera prioridad
                  </div>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Distribución General (1º Prioridad)</h1>
                  <div>
                    <ChartWrapper height={700}>
                      <KnowledgeAreasStackedChart
                        order='FIRST'
                        responses={responses}
                      />
                    </ChartWrapper>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          {hasGrades ? (
            campaignData.gradeResponses.map((grade, index) => (
              <Slide backgroundColor='#ff8300' key={`disc${grade.grade}`}>
                <SlidePage number={secondStep + index + 3}>
                  <SlideHeader />
                  <div className='third-left'>
                    <div>
                      <h1>Disciplinas de Interés</h1>
                      <h2>Prioridades {grade.grade}</h2>
                    </div>
                    <div className='footnote'>
                      <div>
                        Número de estudiantes {grade.grade} que seleccionan cada
                        área de conocimiento como primera prioridad
                      </div>
                    </div>
                  </div>
                  <div className='slide-body big'>
                    <div className='slide-content'>
                      <h1>Distribución {grade.grade} (1º Prioridad)</h1>
                      <div>
                        <ChartWrapper height={700}>
                          <KnowledgeAreasStackedChart
                            order='FIRST'
                            responses={grade.responses}
                          />
                        </ChartWrapper>
                      </div>
                    </div>
                  </div>
                </SlidePage>
              </Slide>
            ))
          ) : (
            <div></div>
          )}

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={thirdStep}>
              <PlainHeader />
              <div className='plain-body right'>
                <h1>Orientación Vocacional</h1>
                <h2>Toma de Decisiones</h2>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={thirdStep + 1}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Toma de Decisiones</h1>
                  <h2>Presentación</h2>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <div>
                    <h1>Toma de decisión de carrera</h1>
                    <p>
                      En esta sección se podrán identificar alguna de las
                      características al tomar decisiones y algunas dificultades
                      asociadas al proceso de toma de decisión de carrera. Esta
                      información puede ayudar a decidir qué aspectos se deben
                      revisar o qué estrategias se pueden implementar para
                      enfrentar de mejor manera este proceso y sentirse más
                      seguro/a con una decisión.
                    </p>
                    <h2>Creencias Disfuncionales</h2>
                    <p>
                      El tener ideas o creencias equivocadas sobre las
                      implicancias del proceso de elección de carrera es una de
                      las dificultades más importantes y más comunes que
                      enfrentan las personas al momento de tomar este tipo de
                      decisiones.
                    </p>
                    <p>
                      Es importante identificar estas creencias erróneas y
                      cuestionarlas, ya que pueden estar obstaculizando el
                      proceso de toma de decisión.
                    </p>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={thirdStep + 2}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Toma de Decisiones</h1>
                  <h2>Creencias Disfuncionales</h2>
                </div>
                <div className='footnote'>
                  <div>
                    Resultados en ROJO sugieren la alta presencia de creencias
                    disfuncionales en estas áreas. Resultados en VERDE sugieren
                    la baja presencia o ausencia de creencias disfuncionales.
                  </div>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Creencias Disfuncionales</h1>
                  <div className='scales-range' style={{ height: 650 }}>
                    <div className='range-row'>
                      <div className='label'>
                        El rol de la casualidad y el destino
                      </div>
                      <HorizontalRangeScale
                        scale='Rol de la casualidad'
                        responses={responses}
                      />
                    </div>
                    <div className='range-row'>
                      <div className='label'>Implicancias de la Decisión</div>
                      <HorizontalRangeScale
                        scale='Implicancias de la Decisión'
                        responses={responses}
                      />
                    </div>
                    <div className='range-row'>
                      <div className='label'>
                        Creencias sobre el rol de otros en la decisión
                      </div>
                      <HorizontalRangeScale
                        scale='Rol de otros significativos'
                        responses={responses}
                      />
                    </div>
                    <div className='range-row'>
                      <div className='label'>Rol de la ayuda profesional</div>
                      <HorizontalRangeScale
                        scale='Rol de la ayuda profesional'
                        responses={responses}
                      />
                    </div>
                    <div className='range-row'>
                      <div className='label'>
                        Creencias en relación al género
                      </div>
                      <HorizontalRangeScale
                        scale='Género'
                        responses={responses}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          {hasGrades ? (
            campaignData.gradeResponses.map((grade, index) => {
              return (
                <Slide
                  backgroundColor='#ff8300'
                  key={`study__${grade.grade}_${index}`}
                >
                  <SlidePage number={thirdStep + 3 + index}>
                    <SlideHeader />
                    <div className='third-left'>
                      <div>
                        <h1>Toma de Decisiones</h1>
                        <h2>Creencias Disfuncionales {grade.grade}</h2>
                      </div>
                      <div className='footnote'>
                        <div>
                          Resultados en ROJO sugieren la alta presencia de
                          creencias disfuncionales en estas áreas. Resultados en
                          VERDE sugieren la baja presencia o ausencia de
                          creencias disfuncionales.
                        </div>
                      </div>
                    </div>
                    <div className='slide-body big'>
                      <div className='slide-content'>
                        <h1>Creencias Disfuncionales {grade.grade}</h1>
                        <div className='scales-range' style={{ height: 650 }}>
                          <div className='range-row'>
                            <div className='label'>
                              El rol de la casualidad y el destino
                            </div>
                            <HorizontalRangeScale
                              scale='Rol de la casualidad'
                              responses={grade.responses}
                            />
                          </div>
                          <div className='range-row'>
                            <div className='label'>
                              Implicancias de la Decisión
                            </div>
                            <HorizontalRangeScale
                              scale='Implicancias de la Decisión'
                              responses={grade.responses}
                            />
                          </div>
                          <div className='range-row'>
                            <div className='label'>
                              Creencias sobre el rol de otros en la decisión
                            </div>
                            <HorizontalRangeScale
                              scale='Rol de otros significativos'
                              responses={grade.responses}
                            />
                          </div>
                          <div className='range-row'>
                            <div className='label'>
                              Rol de la ayuda profesional
                            </div>
                            <HorizontalRangeScale
                              scale='Rol de la ayuda profesional'
                              responses={grade.responses}
                            />
                          </div>
                          <div className='range-row'>
                            <div className='label'>
                              Creencias en relación al género
                            </div>
                            <HorizontalRangeScale
                              scale='Género'
                              responses={grade.responses}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SlidePage>
                </Slide>
              );
            })
          ) : (
            <div></div>
          )}

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={fourthStep}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Toma de Decisiones</h1>
                  <h2>Indecisión como rasgo personal</h2>
                </div>
                <div className='footnote'>
                  <div>
                    Resultados en ROJO sugieren la alta presencia de creencias
                    disfuncionales en estas áreas. Resultados en VERDE sugieren
                    la baja presencia o ausencia de creencias disfuncionales.
                  </div>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <div>
                    <h1>Indecisión como rasgo personal</h1>
                    <p>
                      Es importante diferenciar si la indecisión en relación a
                      la elección de carrera es por este momento o situación en
                      específico, o si existe una tendencia a ser una persona
                      indecisa en general.
                    </p>
                    <div className='scales-range' style={{ height: 300 }}>
                      <div className='range-single'>
                        <div className='label'>General</div>
                        <HorizontalRangeScale
                          scale='Indecisión General'
                          responses={responses}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          {hasGrades ? (
            campaignData.gradeResponses.map((grade, index) => {
              return (
                <Slide
                  backgroundColor='#ff8300'
                  key={`ind_gral_${grade.grade}_${index}`}
                >
                  <SlidePage number={fourthStep + 1 + index}>
                    <SlideHeader />
                    <div className='third-left'>
                      <div>
                        <h1>Toma de Decisiones</h1>
                        <h2>Indecisión como rasgo personal {grade.grade}</h2>
                      </div>
                      <div className='footnote'>
                        <div>
                          Resultados en ROJO sugieren la alta presencia de
                          creencias disfuncionales en estas áreas. Resultados en
                          VERDE sugieren la baja presencia o ausencia de
                          creencias disfuncionales.
                        </div>
                      </div>
                    </div>

                    <div className='slide-body big'>
                      <div className='slide-content'>
                        <div>
                          <h1>Indecisión como rasgo personal</h1>
                          <p>
                            Es importante diferenciar si la indecisión en
                            relación a la elección de carrera es por este
                            momento o situación en específico, o si existe una
                            tendencia a ser una persona indecisa en general.
                          </p>
                          <div className='scales-range' style={{ height: 300 }}>
                            <div className='range-single'>
                              <div className='label'>{grade.grade}</div>
                              <HorizontalRangeScale
                                scale='Indecisión General'
                                responses={grade.responses}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SlidePage>
                </Slide>
              );
            })
          ) : (
            <div></div>
          )}

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={fifthStep}>
              <PlainHeader />
              <div className='plain-body right'>
                <h1>Toma de Decisiones</h1>
                <h2>Estilo de Decisión Predominante</h2>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={fifthStep + 1}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Toma de Decisiones</h1>
                  <h2>Estilo de Decisión Predominante</h2>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <div>
                    <h1>Estilo de Decisión Predominante</h1>
                    <p>
                      Las personas pueden afrontar las dificultades asociadas al
                      proceso de toma de decisión sobre su carrera a futuro de
                      distintas formas, siendo algunas formas más beneficiosas
                      para el proceso de elegir una carrera profesional.
                    </p>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={fifthStep + 2}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Toma de Decisiones</h1>
                  <h2>Estilo de Decisión Predominante</h2>
                </div>
                <div className='footnote'>
                  <div>
                    El estilo predominante de decisión es aquel en el que tiene{' '}
                    <strong>MAYOR</strong> puntaje.
                  </div>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Estilo de Decisión Predominante</h1>
                  <div className='scales-range' style={{ height: 650 }}>
                    <div className='range-row'>
                      <div className='label'>Afrontamiento Productivo</div>
                      <HorizontalRangeScale
                        scale='Afrontamiento Productivo'
                        responses={responses}
                        plainColor
                      />
                    </div>
                    <div className='range-row'>
                      <div className='label'>Búsqueda de Apoyo</div>
                      <HorizontalRangeScale
                        scale='Búsqueda de Apoyo'
                        responses={responses}
                        plainColor
                      />
                    </div>
                    <div className='range-row'>
                      <div className='label'>Afrontamiento No Productivo</div>
                      <HorizontalRangeScale
                        scale='Afrontamiento No-Producivo'
                        responses={responses}
                        plainColor
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          {hasGrades ? (
            campaignData.gradeResponses.map((grade, index) => {
              return (
                <Slide
                  backgroundColor='#ff8300'
                  key={`study${grade.grade}_${index}`}
                >
                  <SlidePage number={fifthStep + 3 + index}>
                    <SlideHeader />
                    <div className='third-left'>
                      <div>
                        <h1>Toma de Decisiones</h1>
                        <h2>Estilo de Decisión Predominante {grade.grade}</h2>
                      </div>
                      <div className='footnote'>
                        <div>
                          El estilo predominante de decisión es aquel en el que
                          tiene <strong>MAYOR</strong> puntaje.
                        </div>
                      </div>
                    </div>
                    <div className='slide-body big'>
                      <div className='slide-content'>
                        <h1>Estilo de Decisión Predominante {grade.grade}</h1>
                        <div className='scales-range' style={{ height: 650 }}>
                          <div className='range-row'>
                            <div className='label'>
                              Afrontamiento Productivo
                            </div>
                            <HorizontalRangeScale
                              scale='Afrontamiento Productivo'
                              responses={grade.responses}
                              plainColor
                            />
                          </div>
                          <div className='range-row'>
                            <div className='label'>Búsqueda de Apoyo</div>
                            <HorizontalRangeScale
                              scale='Búsqueda de Apoyo'
                              responses={grade.responses}
                              plainColor
                            />
                          </div>
                          <div className='range-row'>
                            <div className='label'>
                              Afrontamiento No Productivo
                            </div>
                            <HorizontalRangeScale
                              scale='Afrontamiento No-Producivo'
                              responses={grade.responses}
                              plainColor
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SlidePage>
                </Slide>
              );
            })
          ) : (
            <div></div>
          )}

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={sixthStep}>
              <PlainHeader />
              <div className='plain-body right'>
                <h1>Toma de Decisiones</h1>
                <h2>Madurez vocacional</h2>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={sixthStep + 1}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Toma de Decisiones</h1>
                  <h2>Madurez vocacional</h2>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <div>
                    <h1>Madurez vocacional</h1>
                    <p>
                      La madurez vocacional se relaciona con el nivel de
                      conflicto (externo e interno) asociado a la toma de
                      decisión de carrera, así como a la calidad y cantidad de
                      información disponible sobre ti mismo (Self), las
                      ocupaciones o carreras, y el proceso de toma de decisión.
                    </p>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          <Slide backgroundColor='#ff8300'>
            <SlidePage number={sixthStep + 2}>
              <SlideHeader />
              <div className='third-left'>
                <div>
                  <h1>Toma de Decisiones</h1>
                  <h2>Madurez vocacional</h2>
                </div>
                <div className='footnote'>
                  <div>
                    Resultados en ROJO sugieren un alto nivel de conflicto o
                    falta de información. Resultados en VERDE sugieren un bajo
                    nivel de conflicto o falta de información.
                  </div>
                </div>
              </div>
              <div className='slide-body big'>
                <div className='slide-content'>
                  <h1>Madurez vocacional</h1>
                  <div className='scales-range' style={{ height: 650 }}>
                    <div className='range-row'>
                      <div className='label'>Conflictos externos</div>
                      <HorizontalRangeScale
                        scale='Conflictos externos'
                        responses={responses}
                      />
                    </div>
                    <div className='range-row'>
                      <div className='label'>Conflictos internos</div>
                      <HorizontalRangeScale
                        scale='Conflictos internos'
                        responses={responses}
                      />
                    </div>
                    <div className='range-row'>
                      <div className='label'>
                        Falta de información sobre ocupaciones
                      </div>
                      <HorizontalRangeScale
                        scale='Información sobre Ocupaciones'
                        responses={responses}
                      />
                    </div>
                    <div className='range-row'>
                      <div className='label'>
                        Falta de información sobre Sí mismo
                      </div>
                      <HorizontalRangeScale
                        scale='Información sobre el Self'
                        responses={responses}
                      />
                    </div>
                    <div className='range-row'>
                      <div className='label'>
                        Falta de información sobre el proceso
                      </div>
                      <HorizontalRangeScale
                        scale='Información sobre el proceso'
                        responses={responses}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SlidePage>
          </Slide>

          {hasGrades ? (
            campaignData.gradeResponses.map((grade, index) => {
              return (
                <Slide
                  backgroundColor='#ff8300'
                  key={`study${grade.grade}_${index}`}
                >
                  <SlidePage number={sixthStep + 3 + index}>
                    <SlideHeader />
                    <div className='third-left'>
                      <div>
                        <h1>Toma de Decisiones</h1>
                        <h2>Madurez vocacional {grade.grade}</h2>
                      </div>
                      <div className='footnote'>
                        <div>
                          Resultados en ROJO sugieren un alto nivel de conflicto
                          o falta de información. Resultados en VERDE sugieren
                          un bajo nivel de conflicto o falta de información.
                        </div>
                      </div>
                    </div>
                    <div className='slide-body big'>
                      <div className='slide-content'>
                        <h1>Madurez vocacional {grade.grade}</h1>
                        <div className='scales-range' style={{ height: 650 }}>
                          <div className='range-row'>
                            <div className='label'>Conflictos externos</div>
                            <HorizontalRangeScale
                              scale='Conflictos externos'
                              responses={grade.responses}
                            />
                          </div>
                          <div className='range-row'>
                            <div className='label'>Conflictos internos</div>
                            <HorizontalRangeScale
                              scale='Conflictos internos'
                              responses={grade.responses}
                            />
                          </div>
                          <div className='range-row'>
                            <div className='label'>
                              Falta de información sobre ocupaciones
                            </div>
                            <HorizontalRangeScale
                              scale='Información sobre Ocupaciones'
                              responses={grade.responses}
                            />
                          </div>
                          <div className='range-row'>
                            <div className='label'>
                              Falta de información sobre Sí mismo
                            </div>
                            <HorizontalRangeScale
                              scale='Información sobre el Self'
                              responses={grade.responses}
                            />
                          </div>
                          <div className='range-row'>
                            <div className='label'>
                              Falta de información sobre el proceso
                            </div>
                            <HorizontalRangeScale
                              scale='Información sobre el proceso'
                              responses={grade.responses}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SlidePage>
                </Slide>
              );
            })
          ) : (
            <div></div>
          )}

          <Slide backgroundColor='#ff8300'>
            <SlidePage>
              <div className='title-page'>
                <div className='logo-big'>
                  <img
                    style={{ marginTop: 150 }}
                    src={EquisOneLogoWhite}
                    alt='equis-one logo'
                  />
                </div>
              </div>
            </SlidePage>
          </Slide>
        </Deck>
      </Fragment>
    </ThemeProvider>
  );
};

export default EquisJunior;
