import React from 'react';
import styled from 'styled-components';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import SmallPieChart from '../SmallPieChart';

export const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => (height ? height : 400)}px;
  width: 100%;
`;

const EquisJuniorInterestAreaDescriptionPage = ({
  user,
  background,
  number
}) => {
  // sub-scales
  const { ES_SUB_SUM, AP_SUB_SUM, EL_SUB_SUM } = user;

  const AP_SUB = (parseInt(AP_SUB_SUM) / 30) * 100;
  const ES_SUB = (parseInt(ES_SUB_SUM) / 30) * 100;
  const EL_SUB = (parseInt(EL_SUB_SUM) / 30) * 100;
  // console.log({ AP_SUB, ES_SUB, EL_SUB });

  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h2>4.2. Escalas de estrategias de estudio</h2>

          <h3>Estrategias de aprendizaje profundo</h3>
          <div className='graph-in-text'>
            <div className='chart-col'>
              <ChartWrapper height={150}>
                <SmallPieChart
                  user={user}
                  data={[
                    {
                      id: 'Total',
                      label: 'Total',
                      value: 100 - AP_SUB
                    },
                    {
                      id: 'AP',
                      label: 'AP',
                      value: AP_SUB
                    }
                  ]}
                />
              </ChartWrapper>
            </div>
            <p className='justified'>
              Estudiantes con un puntaje alto en esta escala usan estrategias de
              estudio que aseguran un aprendizaje significativo y duradero.
              Durante el estudio usan estrategias para ordenar sus ideas (como
              hacer resúmenes o mapas conceptuales) e intentan relacionar los
              contenidos de distintas temáticas o asignaturas. Cuando hay algo
              que no entienden bien, o cuando algo les parece simplemente
              interesante, se preocupan de buscar más información. Para alcanzar
              buenos resultados académicos es indispensable que se acompañe de
              una alta orientación al logro y de una buena planificación y
              organización del estudio.
            </p>
          </div>

          <h3>Estrategias de aprendizaje instrumental</h3>
          <div className='graph-in-text'>
            <div className='chart-col'>
              <ChartWrapper height={150}>
                <SmallPieChart
                  user={user}
                  data={[
                    {
                      id: 'Total',
                      label: 'Total',
                      value: 100 - ES_SUB
                    },
                    {
                      id: 'ES',
                      label: 'ES',
                      value: ES_SUB
                    }
                  ]}
                />
              </ChartWrapper>
            </div>
            <p className='justified'>
              Estudiantes con puntajes altos en esta escala tienen una
              aproximación superficial al estudio. Se focalizan sólo en lo que
              creen que será evaluado, tienden a aprender las cosas “de
              memoria”, y les incomodan las actividades académicas que son
              intelectualmente demandantes, como ensayos o trabajos que
              requieren experimentar con distintas ideas. Pueden ser muy
              efectivos en algunas áreas, pero su aprendizaje suele ser frágil
              lo que les puede generar mucha ansiedad ante la evaluación. Por su
              forma de aproximarse al estudio, les puede parecer muy difícil
              resolver tareas que requieren análisis, reflexión o creatividad.
            </p>
          </div>

          <h3>Estrategias de logro académico</h3>
          <div className='graph-in-text'>
            <div className='chart-col'>
              <ChartWrapper height={150}>
                <SmallPieChart
                  user={user}
                  data={[
                    {
                      id: 'Total',
                      label: 'Total',
                      value: 100 - EL_SUB
                    },
                    {
                      id: 'EL',
                      label: 'EL',
                      value: EL_SUB
                    }
                  ]}
                />
              </ChartWrapper>
            </div>
            <p className='justified'>
              Estudiantes con un puntaje alto en esta escala se preocupan de
              organizar el estudio para asegurar el logro de sus objetivos
              académicos de excelencia. Buscan un lugar de estudio que les
              permite concentrarse en sus tareas, planifican los tiempos que
              dedicarán a las distintas tareas y, sobre todo, cumplen con lo que
              se proponen. Hacen sus tareas con tiempo para que no se les
              acumule el trabajo, disminuyendo la ansiedad relacionada con las
              exigencias académicas. Las estrategias de aprendizaje productivo
              son un fundamentales para el logro académico y es deseable que
              todas y todos los estudiantes incorporen estas estrategias en su
              repertorio.
            </p>
          </div>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisJuniorInterestAreaDescriptionPage;
