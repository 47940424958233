// dimensiones prioritarias
const CN = 'Ciencias y Naturaleza';
const FM = 'Fabricación y Mecánica';
const CD = 'Ciencia de Datos';
const AP = 'Administración y Proyectos';
const AL = 'Artes y Literatura';
const SE = 'Soporte Social y Educación';
const IP = 'Influencia y Personas';
const LM = 'Liderazgo y Management';

const CN_Short = 'Ciencias y Naturaleza';
const FM_Short = 'Fabricación y Mecánica';
const CD_Short = 'Ciencia de Datos';
const AP_Short = 'Administración y Proyectos';
const AL_Short = 'Artes y Literatura';
const SE_Short = 'Sop. Social y Ed.';
const IP_Short = 'Influencia y Personas';
const LM_Short = 'Liderazgo y Management';

// areas de interés
const A_F = 'Filosofía';
const A_HGC = 'Historia, Geografía y Ciencias Sociales';
const A_LL = 'Lengua y Literatura';
const B_CBS = 'Ciencias Biológicas';
const B_CD = 'Ciencias Básicas';
const B_M = 'Matemáticas';
const C_AI = 'Artes Interpretativas y creativas';
const C_AV = 'Artes Visuales';
const C_D = 'Deportes';

// perc_A_F	perc_A_HGC	perc_A_LL
// perc_B_CBS	perc_B_CD	perc_B_M
// perc_C_AI	perc_C_AV	perc_C_D

// areas de interés short
const A_F_Short = 'Filosofía';
const A_HGC_Short = 'Hist., Geografía y C. Soc.';
const A_LL_Short = 'Lengua y Literatura';
const B_CBS_Short = 'Ciencias Biológicas';
const B_CD_Short = 'Ciencias Básicas';
const B_M_Short = 'Matemáticas';
const C_AI_Short = 'Artes Interp. y Creativas';
const C_AV_Short = 'Artes Visuales';
const C_D_Short = 'Deportes';

export const colors = ['#ffffb2', '#fecc5c', '#fd8d3c', '#f03b20', '#bd0026'];
export const dimColors = [
  '#f6eff7',
  '#bdc9e1',
  '#67a9cf',
  '#1c9099',
  '#016c59'
];
// https://github.com/d3/d3-scale-chromatic/blob/master/src/diverging/RdYlGn.js
export const polarColors = [
  '#d7191c',
  '#fdae61',
  '#ffffbf',
  '#a6d96a',
  '#1a9641'
];

// export const colorsFive = [
//   '#ff8300',
//   '#fca436',
//   '#fbc061',
//   '#fbd98d',
//   '#fff0bc'
// ];

// export const colorsFourth = ['#ff8300', '#fcad45', '#fbd17e', '#fff0bc'];

export const colorsFive = ['#ff8300', '#ffa03d', '#ffba72', '#ffd2a3', '#ffe9d2']

export const colorsFourth = ['#ff8300', '#ffa74a', '#ffc68b', '#ffe3c6']


export const getDimensionFullName = dim => {
  let name = '';
  switch (dim) {
    case 'CN':
      name = CN;
      break;
    case 'FM':
      name = FM;
      break;
    case 'CD':
      name = CD;
      break;
    case 'AP':
      name = AP;
      break;
    case 'AL':
      name = AL;
      break;
    case 'SE':
      name = SE;
      break;
    case 'IP':
      name = IP;
      break;
    case 'LM':
      name = LM;
      break;
    default:
      name = CN;
      break;
  }
  return name;
};

export const getDimensionShortName = dim => {
  let name = '';
  switch (dim) {
    case 'CN':
      name = CN_Short;
      break;
    case 'FM':
      name = FM_Short;
      break;
    case 'CD':
      name = CD_Short;
      break;
    case 'AP':
      name = AP_Short;
      break;
    case 'AL':
      name = AL_Short;
      break;
    case 'SE':
      name = SE_Short;
      break;
    case 'IP':
      name = IP_Short;
      break;
    case 'LM':
      name = LM_Short;
      break;
    default:
      name = CN_Short;
      break;
  }
  return name;
};

// perc_A_F	perc_A_HGC	perc_A_LL
// perc_B_CBS	perc_B_CD	perc_B_M
// perc_C_AI	perc_C_AV	perc_C_D

export const getAreaFullName = area => {
  let name = '';
  switch (area) {
    case 'F':
      name = A_F;
      break;
    case 'HGC':
      name = A_HGC;
      break;
    case 'LL':
      name = A_LL;
      break;
    case 'CBS':
      name = B_CBS;
      break;
    case 'CD':
      name = B_CD;
      break;
    case 'M':
      name = B_M;
      break;
    case 'AI':
      name = C_AI;
      break;
    case 'AV':
      name = C_AV;
      break;
    case 'D':
      name = C_D;
      break;
    default:
      name = '';
      break;
  }
  return name;
};

export const getAreaShortName = area => {
  let name = '';
  switch (area) {
    case 'F':
      name = A_F_Short;
      break;
    case 'HGC':
      name = A_HGC_Short;
      break;
    case 'LL':
      name = A_LL_Short;
      break;
    case 'CBS':
      name = B_CBS_Short;
      break;
    case 'CD':
      name = B_CD_Short;
      break;
    case 'M':
      name = B_M_Short;
      break;
    case 'AI':
      name = C_AI_Short;
      break;
    case 'AV':
      name = C_AV_Short;
      break;
    case 'D':
      name = C_D_Short;
      break;
    default:
      name = '';
      break;
  }
  return name;
};
