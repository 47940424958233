import React from 'react';
import styled from 'styled-components';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import KnowledgeAreasChart from '../KnowledgeAreasChart';
import { getDimensionFullName } from '../constants';

export const ChartWrapper = styled.div`
  margin-bottom: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
`;

const EquisOneKnowledgeAreasGraphPage = ({ user, background, number }) => {
  const { TOP_DIM_3, TOP_DIM_4, THIRD_AREA_ITEMS, FOURTH_AREA_ITEMS } = user;
  // console.log({ TOP_DIM_1, TOP_DIM_2 });
  const thirdDimName = getDimensionFullName(TOP_DIM_3);
  const fourthDimName = getDimensionFullName(TOP_DIM_4);
  // console.log({ TOP_DIM_3, TOP_DIM_4 });
  // console.log({FIRST_AREA_ITEMS, THIRD_AREA_ITEMS})
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          {TOP_DIM_3 && (
            <>
              <p className='justified'>
                En la figura de más abajo verás las áreas de conocimiento que se
                relacionan con tu tercera preferencia{' '}
                <strong>{thirdDimName}</strong>
              </p>

              <ChartWrapper>
                <KnowledgeAreasChart
                  user={user}
                  order="THIRD"
                  areas={THIRD_AREA_ITEMS.map(area =>
                    area.replace('_THIRD_SUM', '')
                  )}
                />
              </ChartWrapper>
            </>
          )}

          {TOP_DIM_4 && (
            <>
              <p className='justified'>
                En la figura de más abajo verás las áreas de conocimiento que se
                relacionan con tu cuarta preferencia{' '}
                <strong>{fourthDimName}</strong>
              </p>

              <ChartWrapper>
                <KnowledgeAreasChart
                  user={user}
                  order="FOURTH"
                  areas={FOURTH_AREA_ITEMS.map(area =>
                    area.replace('_FOURTH_SUM', '')
                  )}
                />
              </ChartWrapper>
            </>
          )}

          <p className='justified small'>
            <em>Nota. </em>
            Los resultados anteriores muestran en qué área académica te
            resultaría más atractivo trabajar para desplegar cada una de tus
            preferencias vocacionales prioritarias. Por esto, si bien un área
            académica puede resultarte poco interesante al relacionarla con una
            de tus preferencias prioritarias, esta misma área puede ser más
            interesante al relacionarla con otra de tus áreas de preferencia (o
            viceversa).
          </p>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOneKnowledgeAreasGraphPage;
