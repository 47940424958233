import { ResponsivePie } from '@nivo/pie';
import { animated } from 'react-spring';
// import { getDimensionShortName } from '../constants';

// const colors = ['#ffffb2', '#fecc5c', '#fd8d3c', '#f03b20', '#bd0026'];

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

// perc_AL_SUM: '96.875',
// perc_AP_SUM: '34.375',
// perc_CD_SUM: '31.25',
// perc_CN_SUM: '53.125',
// perc_FM_SUM: '37.5',
// perc_IP_SUM: '53.125',
// perc_LM_SUM: '37.5',
// perc_SE_SUM: '56.25',

const MyResponsiveRadar = ({ user }) => {
  // const {
  //   A,
  // } = user;
  const A = parseFloat(user.A);
  const B = parseFloat(user.B);
  const C = parseFloat(user.C);

  // const data = [
  //   {
  //     dimension: getDimensionShortName('CN'),
  //     percent: parseFloat(perc_CN_SUM)
  //   },
  //   {
  //     dimension: getDimensionShortName('AL'),
  //     percent: parseFloat(perc_AL_SUM)
  //   },
  //   {
  //     dimension: getDimensionShortName('SE'),
  //     percent: parseFloat(perc_SE_SUM)
  //   },
  //   {
  //     dimension: getDimensionShortName('IP'),
  //     percent: parseFloat(perc_IP_SUM)
  //   },
  //   {
  //     dimension: getDimensionShortName('LM'),
  //     percent: parseFloat(perc_LM_SUM)
  //   },
  //   {
  //     dimension: getDimensionShortName('AP'),
  //     percent: parseFloat(perc_AP_SUM)
  //   },
  //   {
  //     dimension: getDimensionShortName('CD'),
  //     percent: parseFloat(perc_CD_SUM)
  //   },
  //   {
  //     dimension: getDimensionShortName('FM'),
  //     percent: parseFloat(perc_FM_SUM)
  //   }
  // ];
  const theme = {
    background: '#ffffff',
    textColor: '#333333',
    fontSize: 18
    // axis: {
    //   domain: {
    //     line: {
    //       stroke: '#777777',
    //       strokeWidth: 1
    //     }
    //   },
    //   ticks: {
    //     line: {
    //       stroke: '#777777',
    //       strokeWidth: 1
    //     }
    //   }
    // }
  };

  const data = [
    {
      id: 'a',
      label: 'A',
      value: A,
      color: '#f7c033'
    },
    {
      id: 'b',
      label: 'B',
      value: B,
      color: '#70ad48'
    },
    {
      id: 'c',
      label: 'C',
      value: C,
      color: '#4472c4'
    }
  ];
  // console.log({ data });

  const TOT_SUM = A + B + C;
  return (
    <ResponsivePie
      data={data}
      theme={theme}
      margin={{ top: 20, right: 80, bottom: 20, left: 20 }}
      innerRadius={0.5}
      padAngle={0.8}
      cornerRadius={2}
      colors={['#f7c033', '#70ad48', '#4472c4']}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextColor='#333333'
      radialLabelsLinkColor={{ from: 'color' }}
      sliceLabelsSkipAngle={10}
      sliceLabelsTextColor='#333333'
      enableArcLinkLabels={false}
      radialLabel={dp => dp.label}
      // arcLabel={dp => dp.value.toFixed(0)}
      arcLabel={dp => `${((dp.value / TOT_SUM) * 100).toFixed(0)}%`}
      arcLabelsSkipAngle={20}
      arcLabelsRadiusOffset={0.55}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 0.6]]
      }}
      arcLinkLabelsOffset={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLinkLabelsThickness={2}
      arcLabelsComponent={({ datum, label, style }) => (
        <animated.g
          transform={style.transform}
          style={{ pointerEvents: 'none' }}
        >
          <circle fill={style.textColor} cy={6} r={24} />
          <circle fill='#ffffff' stroke={datum.color} strokeWidth={2} r={24} />
          <text
            textAnchor='middle'
            dominantBaseline='central'
            fill={style.textColor}
            style={{
              fontSize: 16,
              fontWeight: 800
            }}
          >
            {label}
          </text>
        </animated.g>
      )}
      // defs={[
      //   {
      //     id: 'dots',
      //     type: 'patternDots',
      //     background: 'inherit',
      //     color: 'rgba(255, 255, 255, 0.3)',
      //     size: 4,
      //     padding: 1,
      //     stagger: true
      //   },
      //   {
      //     id: 'lines',
      //     type: 'patternLines',
      //     background: 'inherit',
      //     color: 'rgba(255, 255, 255, 0.3)',
      //     rotation: -45,
      //     lineWidth: 6,
      //     spacing: 10
      //   }
      // ]}
      // fill={[
      //   {
      //     match: {
      //       id: 'ruby'
      //     },
      //     id: 'dots'
      //   },
      //   {
      //     match: {
      //       id: 'c'
      //     },
      //     id: 'dots'
      //   },
      //   {
      //     match: {
      //       id: 'go'
      //     },
      //     id: 'dots'
      //   },
      //   {
      //     match: {
      //       id: 'python'
      //     },
      //     id: 'dots'
      //   },
      //   {
      //     match: {
      //       id: 'scala'
      //     },
      //     id: 'lines'
      //   },
      //   {
      //     match: {
      //       id: 'lisp'
      //     },
      //     id: 'lines'
      //   },
      //   {
      //     match: {
      //       id: 'elixir'
      //     },
      //     id: 'lines'
      //   },
      //   {
      //     match: {
      //       id: 'javascript'
      //     },
      //     id: 'lines'
      //   }
      // ]}
      legends={[
        {
          anchor: 'top-right',
          direction: 'column',
          justify: false,
          translateX: 80,
          translateY: 0,
          itemsSpacing: 5,
          itemWidth: 80,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ]
        }
      ]}
    />
    // <ResponsiveRadar
    //   data={data}
    //   keys={['percent']}
    //   indexBy='dimension'
    //   // maxValue='auto'
    //   maxValue={100}
    //   margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
    //   curve='cardinalClosed' // 'linearClosed'
    //   borderWidth={2}
    //   borderColor={colors[2]}
    //   gridLevels={5}
    //   gridShape='circular'
    //   gridLabelOffset={16}
    //   enableDots={true}
    //   dotSize={10}
    //   // dotColor={{ theme: 'background' }}
    //   dotBorderWidth={2}
    //   dotBorderColor={colors[2]}
    //   enableDotLabel={true}
    //   dotLabel={v => {
    //     return parseFloat(v.value).toFixed(0);
    //   }}
    //   dotLabelYOffset={-12} // -12
    //   colors={[colors[1]]}
    //   fillOpacity={0.25}
    //   blendMode='multiply'
    //   animate={false}
    //   motionConfig='wobbly'
    //   isInteractive={false}
    //   // markers={[
    //   //   {
    //   //     axis: 'y',
    //   //     value: 0,
    //   //     lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
    //   //     legend: 'y marker',
    //   //     legendOrientation: 'vertical'
    //   //   },
    //   //   {
    //   //     axis: 'x',
    //   //     value: 0,
    //   //     lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
    //   //     legend: 'x marker'
    //   //   }
    //   // ]}
    // />
  );
};

export default MyResponsiveRadar;
