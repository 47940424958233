import React from 'react';

const Application = () => {
  return (
    <div className='App'>
      <header className='App-header'>
        <p>
          UAIMETRICS reports engine <span role='img'>🤘</span>
        </p>
        <p>Nada que hacer por acá ;)</p>
      </header>
    </div>
  );
};

export default Application;
