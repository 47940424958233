const printTheme = {
  colors: {
    primary: '#00000096',
    secondary: '#00000069',
    // tertiary: '#00000085',
    quaternary: '#000000',
    quinary: '#000000'
  }
};
export default printTheme;
