import { ResponsiveBar } from '@nivo/bar';
import { getDimensionFullName, dimColors } from '../constants';

// const groups = ['Muy Bajo', 'Bajo', 'Neutro', 'Alto', 'Muy Alto'];

// const getDimData = user => {
//   // // scales
//   // const {
//   //   EP_ESC_SUM,
//   //   ES_ESC_SUM,
//   //   EL_ESC_SUM,
//   //   EP_ESC_MEAN,
//   //   ES_ESC_MEAN,
//   //   EL_ESC_MEAN
//   // } = user;

//   // sub-scales
//   const {
//     MA_SUB_SUM,
//     ML_SUB_SUM,
//     ES_SUB_SUM,
//     AP_SUB_SUM,
//     MS_SUB_SUM,
//     EL_SUB_SUM
//     // MA_SUB_MEAN,
//     // ML_SUB_MEAN,
//     // ES_SUB_MEAN,
//     // AP_SUB_MEAN,
//     // MS_SUB_MEAN,
//     // EL_SUB_MEAN
//   } = user;

//   const dims = [
//     {
//       dimension: 'Profundo',
//       'Motivación por aprender': MA_SUB_SUM,
//       // "Estrategias de Estudio Limitado": 84,
//       // "Motivación de logro": 90,
//       'Estrategias de Aprendizaje Profundo': AP_SUB_SUM
//       // "Motivación superficial por el estudio": 79,
//       // "Estrategias de Organización": 143,
//     },
//     {
//       dimension: 'Superficial',
//       // "Motivación por aprender": 87,
//       'Estrategias de Estudio Limitado': ES_SUB_SUM,
//       // "Motivación de logro": 90,
//       // "Estrategias de Aprendizaje Profundo": 82,
//       'Motivación superficial por el estudio': MS_SUB_SUM
//       // "Estrategias de Organización": 143,
//     },
//     {
//       dimension: 'De logro',
//       // "Motivación por aprender": 87,
//       // "Estrategias de Estudio Limitado": 84,
//       'Motivación de logro': ML_SUB_SUM,
//       // "Estrategias de Aprendizaje Profundo": 82,
//       // "Motivación superficial por el estudio": 79,
//       'Estrategias de Organización': EL_SUB_SUM
//     }
//   ];

//   return dims;
// };

const MyResponsiveBar = ({ grades }) => {
  // console.log({ grades });

  const gradesData = grades.map(grade => {
    const male = grade.responses.filter(resp => resp.data.SEX === 'Masculino');
    const female = grade.responses.filter(resp => resp.data.SEX === 'Femenino');
    return { grade: grade.grade, Mujeres: female.length, Hombres: male.length };
  });

  return (
    <ResponsiveBar
      theme={{
        fontSize: 20,
        labels: {
          text: {
            fontSize: 26
          }
        },
        legends: {
          text: {
            fontSize: 20
          }
        }
      }}
      data={gradesData}
      keys={['Hombres', 'Mujeres']}
      indexBy='grade'
      margin={{ top: 10, right: 10, bottom: 80, left: 200 }}
      padding={0.2}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      // colors={{ scheme: 'nivo' }}
      // colors={{ scheme: 'category10' }}
      colors={['#1f77b4', '#ff7f0f']}
      // colors={[dimColors[2], dimColors[4]]}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      // axisBottom={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: 'country',
      //   legendPosition: 'middle',
      //   legendOffset: 32,
      //   fontSize: 20
      // }}
      // axisLeft={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: 'food',
      //   legendPosition: 'middle',
      //   legendOffset: -40,
      // }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      // legends={[
      //   {
      //     dataFrom: 'keys',
      //     anchor: 'bottom-right',
      //     direction: 'column',
      //     justify: false,
      //     translateX: 120,
      //     translateY: 0,
      //     itemsSpacing: 2,
      //     itemWidth: 100,
      //     itemHeight: 20,
      //     itemDirection: 'left-to-right',
      //     itemOpacity: 0.85,
      //     symbolSize: 20,
      //     effects: [
      //       {
      //         on: 'hover',
      //         style: {
      //           itemOpacity: 1
      //         }
      //       }
      //     ]
      //   }
      // ]}
      legends={[
        {
          anchor: 'top-left',
          direction: 'column',
          justify: false,
          translateX: -200,
          translateY: 0,
          itemsSpacing: 4,
          itemWidth: 100,
          itemHeight: 60,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          itemTextColor: '#777',
          symbolSize: 40,
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
                itemBackground: '#f7fafb'
              }
            }
          ]
        }
      ]}
      animate={false}
      isInteractive={false}
      motionStiffness={90}
      motionDamping={15}
    />
  );
};

export default MyResponsiveBar;

// const data = [
//   {
//     grade: '2do A',
//     Hombres: 6,
//     Mujeres: 14
//   },
//   {
//     grade: '2do B',
//     Hombres: 23,
//     Mujeres: 40
//   },
//   {
//     grade: '2do C',
//     Hombres: 53,
//     Mujeres: 39
//   },
//   {
//     grade: '2do D',
//     Hombres: 32,
//     Mujeres: 32
//   }
// ];
