import { ResponsiveLine } from '@nivo/line';
// import { getDimensionShortName } from '../constants';

const colors = ['#ffffb2', '#fecc5c', '#fd8d3c', '#f03b20', '#bd0026'];

// Arithmetic mean
const getMean = (data = []) => {
  return (
    data.reduce(function (a, b) {
      return Number(a) + Number(b);
    }) / data.length
  );
};

// Standard deviation
const getSD = (data = []) => {
  let m = getMean(data);
  return Math.sqrt(
    data.reduce(function (sq, n) {
      return sq + Math.pow(n - m, 2);
    }, 0) /
      (data.length - 1)
  );
};

const MyResponsiveLinear = ({ user }) => {
  const { results } = user;
  // console.log({ results });
  const lineData = results.map(res => ({
    x: res.Time ? parseFloat(res.Time) : 0,
    y: res.Speed ? parseFloat(res.Speed) : 0
  }));
  // console.log({ lineData });
  const velocityArray = lineData.map(dp => dp.y);
  const velMean = getMean(velocityArray);
  const velSD = getSD(velocityArray);
  // console.log({ velMean, velSD });
  const dataClone = [...lineData];
  const [highestVel] = dataClone.sort((a, b) => b.y - a.y);
  // console.log({ highestVel });
  // console.log({ velMean });
  return (
    <ResponsiveLine
      data={[
        {
          id: 'Simulator Results line',
          data: lineData
          // data: [
          //   { x: 0, y: 7 },
          //   { x: 1, y: 5 },
          //   { x: 2, y: 11 },
          //   { x: 3, y: 9 },
          //   { x: 4, y: 13 },
          //   { x: 7, y: 16 },
          //   { x: 9, y: 12 }
          // ]
        }
      ]}
      lineWidth={1}
      enableArea
      enablePoints={false}
      // keys={['percent']}
      // indexBy='dimension'
      // maxValue='auto'
      // maxValue={100}
      xScale={{
        type: 'linear',
        min: 0,
        max: 'auto'
      }}
      // axisLeft={{
      //   legend: 'linear scale',
      //   legendOffset: 12
      // }}
      // axisBottom={{
      //   legend: 'linear scale',
      //   legendOffset: -12
      // }}
      // axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Tiempo (s)',
        legendOffset: 36,
        legendPosition: 'middle'
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Velocidad (Km/h)',
        legendOffset: -40,
        legendPosition: 'middle'
      }}
      margin={{ top: 25, right: 25, bottom: 50, left: 50 }}
      colors={[colors[1]]}
      fillOpacity={0.25}
      blendMode='multiply'
      animate={false}
      markers={[
        {
          axis: 'y',
          value: highestVel.y - 2, // text positioning
          lineStyle: { stroke: '#dddddd', strokeWidth: 0 },
          legend: `Velocidad media: ${velMean.toFixed(2)} Km/h`,
          legendPosition: 'bottom-left',
          textStyle: {
            fontSize: 14
          }
        },
        {
          axis: 'y',
          value: highestVel.y - 7, // text positioning
          lineStyle: { stroke: '#dddddd', strokeWidth: 0 },
          legend: `Desv. Est. Velocidad: ${velSD.toFixed(2)} Km/h`,
          legendPosition: 'bottom-left',
          textStyle: {
            fontSize: 14
          }
        }
        // {
        //   axis: 'x',
        //   value: item.mean - item.sd,
        //   lineStyle: { stroke: 'rgba(0, 0, 0, .25)', strokeWidth: 2, strokeDasharray: "2,3" },
        //   // legend: 'sd-minus'
        // },
        // {
        //   axis: 'x',
        //   value: item.mean,
        //   lineStyle: { stroke: 'rgba(0, 0, 0, .5)', strokeWidth: 2, },
        //   // legend: 'mean',
        //   // legendOrientation: 'vertical'
        // },
        // {
        //   axis: 'x',
        //   value: item.mean + item.sd,
        //   lineStyle: { stroke: 'rgba(0, 0, 0, .25)', strokeWidth: 2, strokeDasharray: "2,3" },
        //   // legend: 'sd-plus'
        // },
        // {
        //   axis: 'x',
        //   value: 5,
        //   lineStyle: { stroke: '#dddddd', strokeWidth: 2 },
        //   // legend: '5'
        // }
      ]}
      // motionConfig='wobbly'
      isInteractive={false}
      // markers={[
      //   {
      //     axis: 'y',
      //     value: 0,
      //     lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
      //     legend: 'y marker',
      //     legendOrientation: 'vertical'
      //   },
      //   {
      //     axis: 'x',
      //     value: 0,
      //     lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
      //     legend: 'x marker'
      //   }
      // ]}
    />
  );
};

export default MyResponsiveLinear;
