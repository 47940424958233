import React from 'react';
import styled from 'styled-components';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import SmallPieChart from '../SmallPieChart';

export const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => (height ? height : 400)}px;
  width: 100%;
`;

const EquisJuniorInterestAreaDescriptionPage = ({
  user,
  background,
  number
}) => {
  // sub-scales
  const { MA_SUB_SUM, ML_SUB_SUM, MS_SUB_SUM } = user;

  const MA_SUB = (parseInt(MA_SUB_SUM) / 30) * 100;
  const MS_SUB = (parseInt(MS_SUB_SUM) / 30) * 100;
  const ML_SUB = (parseInt(ML_SUB_SUM) / 30) * 100;
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>4. Aproximación al estudio</h1>
          <h2>4.1. Escalas de orientación al aprendizaje</h2>

          <h3>Orientación al aprendizaje profundo</h3>
          <div className='graph-in-text'>
            <div className='chart-col'>
              <ChartWrapper height={150}>
                <SmallPieChart
                  user={user}
                  data={[
                    {
                      id: 'Total',
                      label: 'Total',
                      value: 100 - MA_SUB
                    },
                    {
                      id: 'MA',
                      label: 'MA',
                      value: MA_SUB
                    }
                  ]}
                />
              </ChartWrapper>
            </div>
            <p className='justified'>
              Estudiantes con un puntaje alto en esta escala disfrutan aprender
              y valoran el aprendizaje en sí mismo, independientemente de las
              notas que puedan obtener. Estudian porque encuentran interesante
              el material de estudio pero, tal vez más importante, hay muchos
              temas que les parecen interesantes. Además del interés por
              aprender de manera profunda, es crítico que esta motivación esté
              acompañada de estrategias de estudio profundo y productivo.
            </p>
          </div>

          <h3>Orientación al aprendizaje instrumental</h3>
          <div className='graph-in-text'>
            <div className='chart-col'>
              <ChartWrapper height={150}>
                <SmallPieChart
                  user={user}
                  data={[
                    {
                      id: 'Total',
                      label: 'Total',
                      value: 100 - MS_SUB
                    },
                    {
                      id: 'MS',
                      label: 'MS',
                      value: MS_SUB
                    }
                  ]}
                />
              </ChartWrapper>
            </div>
            <p className='justified'>
              Estudiantes con un puntaje alto en esta escala tienen un interés
              más bien superficial en el aprendizaje. Quieren evitar el fracaso
              escolar, pero al mismo tiempo no quieren invertir más allá del
              mínimo para cumplir con exigencias académicas con las que no se
              sienten verdaderamente comprometidos. Si se combina con
              estrategias instrumentales de estudio, pueden aparecer
              sentimientos de desesperanza, temor al fracaso y permanente
              ansiedad ante la evaluación.
            </p>
          </div>

          <h3>Orientación al logro académico</h3>
          <div className='graph-in-text'>
            <div className='chart-col'>
              <ChartWrapper height={150}>
                <SmallPieChart
                  user={user}
                  data={[
                    {
                      id: 'Total',
                      label: 'Total',
                      value: 100 - ML_SUB
                    },
                    {
                      id: 'ML',
                      label: 'ML',
                      value: ML_SUB
                    }
                  ]}
                />
              </ChartWrapper>
            </div>
            <p className='justified'>
              Estudiantes con un puntaje alto en esta escala buscan demostrar
              que son capaces, tanto ante sí mismos como ante los demás. Buscan
              sobresalir entre sus pares y están dispuestos a hacer grandes
              esfuerzos para alcanzar altas calificaciones incluso cuando los
              temas a estudiar no le parezcan interesantes. La orientación al
              logro académico puede dificultar el logro de aprendizajes
              significativos y duraderos cuando se acompaña de estrategias
              instrumentales de estudio, pero puede ser muy beneficiosa cuando
              hay una orientación al aprendizaje profundo y se implementan
              estrategias de aprendizaje profundo y/o de organización.
            </p>
          </div>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisJuniorInterestAreaDescriptionPage;
