import { ResponsiveRadar } from '@nivo/radar';
import { getDimensionShortName } from '../constants';

const colors = ['#ffffb2', '#fecc5c', '#fd8d3c', '#f03b20', '#bd0026'];

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

// perc_AL_SUM: '96.875',
// perc_AP_SUM: '34.375',
// perc_CD_SUM: '31.25',
// perc_CN_SUM: '53.125',
// perc_FM_SUM: '37.5',
// perc_IP_SUM: '53.125',
// perc_LM_SUM: '37.5',
// perc_SE_SUM: '56.25',

const MyResponsiveRadar = ({ user }) => {
  const {
    perc_AL_SUM,
    perc_AP_SUM,
    perc_CD_SUM,
    perc_CN_SUM,
    perc_FM_SUM,
    perc_IP_SUM,
    perc_LM_SUM,
    perc_SE_SUM
  } = user;

  const data = [
    {
      dimension: getDimensionShortName('CN'),
      percent: parseFloat(perc_CN_SUM)
    },
    {
      dimension: getDimensionShortName('AL'),
      percent: parseFloat(perc_AL_SUM)
    },
    {
      dimension: getDimensionShortName('SE'),
      percent: parseFloat(perc_SE_SUM)
    },
    {
      dimension: getDimensionShortName('IP'),
      percent: parseFloat(perc_IP_SUM)
    },
    {
      dimension: getDimensionShortName('LM'),
      percent: parseFloat(perc_LM_SUM)
    },
    {
      dimension: getDimensionShortName('AP'),
      percent: parseFloat(perc_AP_SUM)
    },
    {
      dimension: getDimensionShortName('CD'),
      percent: parseFloat(perc_CD_SUM)
    },
    {
      dimension: getDimensionShortName('FM'),
      percent: parseFloat(perc_FM_SUM)
    }
  ];

  return (
    <ResponsiveRadar
      data={data}
      keys={['percent']}
      indexBy='dimension'
      // maxValue='auto'
      maxValue={100}
      margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
      curve='cardinalClosed' // 'linearClosed'
      borderWidth={2}
      borderColor={colors[2]}
      gridLevels={5}
      gridShape='circular'
      gridLabelOffset={16}
      enableDots={true}
      dotSize={10}
      // dotColor={{ theme: 'background' }}
      dotBorderWidth={2}
      dotBorderColor={colors[2]}
      enableDotLabel={true}
      dotLabel={v => {
        return parseFloat(v.value).toFixed(0);
      }}
      dotLabelYOffset={-12} // -12
      colors={[colors[1]]}
      fillOpacity={0.25}
      blendMode='multiply'
      animate={false}
      motionConfig='wobbly'
      isInteractive={false}
      // markers={[
      //   {
      //     axis: 'y',
      //     value: 0,
      //     lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
      //     legend: 'y marker',
      //     legendOrientation: 'vertical'
      //   },
      //   {
      //     axis: 'x',
      //     value: 0,
      //     lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
      //     legend: 'x marker'
      //   }
      // ]}
    />
  );
};

export default MyResponsiveRadar;
