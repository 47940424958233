import React from 'react';
import { SlidePage } from '../index';

const EquisOneClosePage = ({ user, ...rest }) => {
  return (
    <SlidePage {...rest}>
      <div className='background-solid primary' />
      <div className='close-center-box'>
        <div className="row-item">www.holos-tech.com | info-holostech@holos.cl | +(56) 2 2894 6530 </div>
      </div>
      <div className='close-logo'>
        <img
          src='https://holos-tech.com/wp-content/uploads/2020/12/holos_tech.svg'
          alt='holos white'
        />
      </div>
    </SlidePage>
  );
};

export default EquisOneClosePage;
