import { ResponsiveBar } from '@nivo/bar';
import { getAreaShortName, dimColors } from '../constants';

const groups = ['Muy Bajo', 'Bajo', 'Neutro', 'Alto', 'Muy Alto'];

const getAreaData = ({ user, first }) => {
  const {
    perc_AC_FIRST_SUM,
    perc_AG_FIRST_SUM,
    perc_AR_FIRST_SUM,
    perc_CN_FIRST_SUM,
    perc_CS_FIRST_SUM,
    perc_D_FIRST_SUM,
    perc_E_FIRST_SUM,
    perc_H_FIRST_SUM,
    perc_S_FIRST_SUM,
    perc_T_FIRST_SUM,
    perc_AC_SECOND_SUM,
    perc_AG_SECOND_SUM,
    perc_AR_SECOND_SUM,
    perc_CN_SECOND_SUM,
    perc_CS_SECOND_SUM,
    perc_D_SECOND_SUM,
    perc_E_SECOND_SUM,
    perc_H_SECOND_SUM,
    perc_S_SECOND_SUM,
    perc_T_SECOND_SUM
  } = user;

  const data = [
    {
      dimension: 'AC',
      value: first
        ? parseFloat(perc_AC_FIRST_SUM)
        : parseFloat(perc_AC_SECOND_SUM)
    },
    {
      dimension: 'AG',
      value: first
        ? parseFloat(perc_AG_FIRST_SUM)
        : parseFloat(perc_AG_SECOND_SUM)
    },
    {
      dimension: 'AR',
      value: first
        ? parseFloat(perc_AR_FIRST_SUM)
        : parseFloat(perc_AR_SECOND_SUM)
    },
    {
      dimension: 'CN',
      value: first
        ? parseFloat(perc_CN_FIRST_SUM)
        : parseFloat(perc_CN_SECOND_SUM)
    },
    {
      dimension: 'CS',
      value: first
        ? parseFloat(perc_CS_FIRST_SUM)
        : parseFloat(perc_CS_SECOND_SUM)
    },
    {
      dimension: 'D',
      value: first
        ? parseFloat(perc_D_FIRST_SUM)
        : parseFloat(perc_D_SECOND_SUM)
    },
    {
      dimension: 'E',
      value: first
        ? parseFloat(perc_E_FIRST_SUM)
        : parseFloat(perc_E_SECOND_SUM)
    },
    {
      dimension: 'H',
      value: first
        ? parseFloat(perc_H_FIRST_SUM)
        : parseFloat(perc_H_SECOND_SUM)
    },
    {
      dimension: 'S',
      value: first
        ? parseFloat(perc_S_FIRST_SUM)
        : parseFloat(perc_S_SECOND_SUM)
    },
    {
      dimension: 'T',
      value: first
        ? parseFloat(perc_T_FIRST_SUM)
        : parseFloat(perc_T_SECOND_SUM)
    }
  ];
  const getValueGroup = val => {
    const value = parseFloat(val);
    if (value < 30) {
      return 'Muy Bajo';
    }
    if (value < 40) {
      return 'Bajo';
    }
    if (value < 60) {
      return 'Neutro';
    }
    if (value < 70) {
      return 'Alto';
    }
    return 'Muy Alto';
  };
  const groupColor = group => {
    switch (group) {
      case 'Muy Bajo':
        return dimColors[0];
      case 'Bajo':
        return dimColors[1];
      case 'Neutro':
        return dimColors[2];
      case 'Alto':
        return dimColors[3];
      case 'Muy Alto':
        return dimColors[4];
      default:
        return dimColors[0];
    }
  };

  const filteredData = data.filter(dp => {
    const isZero = dp.value === 0;
    const isFalsy = !dp.value;
    return !isFalsy || isZero;
  });

  const dataWithDetails = filteredData.map(el => {
    const group = getValueGroup(el.value);
    const color = groupColor(group);
    return { ...el, group, color };
  });

  const groupedData = dataWithDetails.map(dp => {
    const groupsvals = groups.reduce((acc, curr) => {
      let val = 0;
      if (curr === dp.group) {
        val = dp.value;
      }
      return { ...acc, [curr]: val };
    }, {});

    return {
      ...dp,
      dimension: dp.dimension,
      ...groupsvals
    };
  });
  return groupedData;
};

const MyResponsiveBar = ({ user, first, second }) => {
  const areasData = getAreaData({ user, first, second });
  return (
    <ResponsiveBar
      data={areasData}
      keys={groups}
      indexBy='dimension'
      layout='horizontal'
      // groupMode='grouped'
      isInteractive={false}
      margin={{ top: 20, right: 90, bottom: 40, left: 145 }}
      padding={0.3}
      maxValue={100}
      valueScale={{ type: 'linear' }}
      colors={dimColors}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      enableGridX
      enableGridY={false}
      axisLeft={{
        format: v => getAreaShortName(v)
      }}
      label={d => `${parseFloat(d.value).toFixed(0)}`}
      labelSkipWidth={12}
      labelSkipHeight={12}
      legends={[
        {
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 110,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20
        }
      ]}
      animate={false}
      motionStiffness={90}
      motionDamping={15}
    />
  );
};

export default MyResponsiveBar;
