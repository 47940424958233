import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';

const EquisOneInfoDataPage = ({ user, background, number }) => {
  const SEXO = user.SEX === "1" ? 'Masculino' : 'Femenino';

  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h1>1. Datos de identificación</h1>
          <div>
            <ul>
              <li>
                <div className='list-item-label'>Nombre(s):</div>
                <span>{`${user.NAME}`}</span>
              </li>
              <li>
                <div className='list-item-label'>Apellido(s):</div>
                <span>{`${user.LASTNAME}`}</span>
              </li>
              <li>
                <div className='list-item-label'>RUT:</div>
                <span>{user.RUT}</span>
              </li>
              <li>
                <div className='list-item-label'>Edad:</div>
                <span>{user.AGE}</span>
              </li>
              <li>
                <div className='list-item-label'>Sexo: </div>
                <span>{SEXO}</span>
              </li>
              <li>
                <div className='list-item-label'>Fecha evaluación: </div>
                <span>
                  {user.updatedat
                    ? new Date(user.updatedat).toLocaleDateString()
                    : ''}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOneInfoDataPage;
