import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';

const EquisJuniorInterestAreaDescriptionPage = ({ background, number }) => {
  return (
    <SlidePage number={number} background={background}>
      <BodyContainer>
        <div className='content'>
          <h3>3.1.1. Área A</h3>
          <p className='justified'>
            Esta área agrupa los dominios tradicionalmente asociados a las
            Ciencias Sociales y Humanidades, como la Filosofía, Historia,
            Geografía, Lenguaje, Literatura. Las personas interesadas en esta
            área generalmente disfrutan del análisis crítico y discusión sobre
            obras literarias, eventos históricos relevantes, dilemas
            filosóficos, etc. Pueden tener interés en profundizar en temas de la
            sociedad, como la política, economía o desarrollo. También pueden
            disfrutar de la expresión escrita, creativa o informativa.
          </p>
          <h3>3.1.2. Área B</h3>
          <p className='justified'>
            Se agrupan aquí las áreas de conocimiento relacionadas con las
            Ciencias Exactas, como la Química o la Matemática, Ciencias
            Biológicas y Ciencias de la Salud. Las personas interesadas en esta
            área generalmente disfrutan de la investigación y experimentación
            científica, aprender sobre los seres vivos, sobre los fenómenos
            químicos o físicos. Pueden tener interés además en el análisis de
            datos o la resolución de problemas matemáticos. También pueden
            disfrutar de temas de computación, tecnología y desarrollo de
            software.
          </p>
          <h3>3.1.3. Área C</h3>
          <p className='justified'>
            Esta área agrupa por una parte a las disciplinas relacionadas con
            las Artes, la Arquitectura y la Música, y por otra aquellas
            disciplinas relacionadas con las Ciencias del Deporte. Las personas
            interesadas en esta área pueden disfrutar analizando y discutiendo
            sobre una obra artística, ya sea visual, musical, teatral, o bien
            les atrae la creación de una obra artística propia. Pueden también
            tener intereses en el diseño de objetos o la arquitectura. Por otra
            parte, pueden disfrutar de la realización de deportes, de aprender
            cómo funciona el cuerpo humano durante el ejercicio, y cómo
            desarrollar estilos de vida más saludables.
          </p>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisJuniorInterestAreaDescriptionPage;
