import React from 'react';
import { SlidePage } from '../index';
import { BodyContainer } from '../styles';
import HolosLogo from '../imgs/logo_holos.png';
// import BHPLogo from '../imgs/BHP_orange.png';
import UaiMetricsLogo from '../imgs/uaimetrics_logo.png';

const EquisOneAboutUsPage = ({ background, number, ...rest }) => {
  return (
    <SlidePage number={number} background={background} {...rest}>
      <BodyContainer>
        <div className='content'>
          <h1>7. Quiénes somos</h1>
          <p className='justified'>
            UAI Metrics es un centro de medición de la Escuela de Psicología de
            la Universidad Adolfo Ibáñez que se especializa en el desarrollo y
            aplicación de test para la toma de decisiones en contextos
            organizacionales y educacionales.
          </p>
          <p className='justified'>
            El equipo de UAI Metrics está compuesto por profesionales de
            excelencia con una amplia experiencia en el desarrollo de
            instrumentos de medición.
          </p>
          <p className='justified'>
            Para mayor información favor comunicarse con:
            <br />
            <br />
            <strong>UAI Metrics</strong>
            <br />
            Escuela de Psicología | Universidad Adolfo Ibáñez
            <br />
            Diagonal Las Torres 2640, Peñalolén <br />
            Santiago de Chile <br />
            uaimetrics@uai.cl <br />
          </p>
          <br />
          <br />
          <br />
          <div className='logo-wrapper'>
            <img src={UaiMetricsLogo} alt='uaimetrics holos logo' />
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className='logo-wrapper'>
            <img
              src={HolosLogo}
              alt='uaimetrics holos logo'
              style={{ padding: '1rem' }}
            />
          </div>
        </div>
      </BodyContainer>
    </SlidePage>
  );
};

export default EquisOneAboutUsPage;
